import React, { useEffect, useState } from "react";
import { FaMoneyCheckDollar } from "react-icons/fa6";

interface FlightDetailsPriceCardProp {
  flightDetails: any;
  ancillaryPrice?: any;
}

const FlightDetailsPriceCard: React.FC<FlightDetailsPriceCardProp> = ({
  flightDetails,
  ancillaryPrice,
}) => {
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    // Check the scroll position and update the state
    if (window.scrollY > 200) {
      setIsFixed(true); // Change to fixed position after scrolling down 200px
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${
        isFixed
          ? "md:w-[50vw] lg:w-[25vw] lg:fixed bg-white h-fit right-16 top-2"
          : "md:w-[50vw] lg:w-[25vw] lg:fixed bg-white h-fit right-16"
      } transition-all ease-in-out duration-300`}
    >
      <div className="w-full h-fit shadow-md border-[1px] rounded-md">
        <div className="w-full flex items-center bg-gradient-to-l from-[#ec2027] to-[#c81212] h-[3rem] p-2 gap-3 rounded-t-md">
          <FaMoneyCheckDollar className="text-2xl text-white" />
          <h2 className="text-lg font-medium text-white">Price Summary</h2>
        </div>
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="text-sm font-medium text-gray-700">
            Adult x {flightDetails && flightDetails.solution.adults}
          </p>
          <p className="text-sm font-bold text-gray-700">
            {flightDetails &&
              `$${
                flightDetails.solution.adtFare * flightDetails.solution.adults
              }`}
          </p>
        </div>
        {flightDetails && flightDetails.solution.children > 0 && (
          <div className="border-b-[1px] flex w-full justify-between items-center p-2">
            <p className="text-sm font-medium text-gray-700">
              Children x {flightDetails.solution.children}
            </p>
            <p className="text-sm font-medium text-gray-700">{`$${
              flightDetails.solution.chdFare * flightDetails.solution.children
            }`}</p>
          </div>
        )}
        {flightDetails && flightDetails.solution.infants > 0 && (
          <div className="border-b-[1px] flex w-full justify-between items-center p-2">
            <p className="text-sm font-medium text-gray-700">
              Infants x {flightDetails.solution.infants}
            </p>
            <p className="text-sm font-bold text-gray-700">{`$${
              flightDetails.solution.infFare * flightDetails.solution.infants
            }`}</p>
          </div>
        )}
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="text-sm font-medium text-gray-700">
            Total Taxes & Fares
          </p>
          <p className="text-sm font-bold text-gray-700">{`$${
            flightDetails
              ? (
                  flightDetails.solution.adtTax +
                  flightDetails.solution.chdTax +
                  flightDetails.solution.merchantFee +
                  flightDetails.solution.platformServiceFee +
                  flightDetails.solution.tktFee +
                  (flightDetails.solution.infTax
                    ? flightDetails.solution.infTax
                    : 0) +
                  (ancillaryPrice ? ancillaryPrice : 0)
                ).toFixed(2)
              : 0
          }`}</p>
        </div>
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="text-[#ec2027] text-sm font-bold">Grand Total</p>
          <p className="text-sm font-bold text-gray-700">{`$${
            flightDetails
              ? (
                  flightDetails.solution.adtTax *
                    flightDetails.solution.adults +
                  flightDetails.solution.chdTax *
                    flightDetails.solution.children +
                  flightDetails.solution.chdFare *
                    flightDetails.solution.children +
                  flightDetails.solution.adtFare *
                    flightDetails.solution.adults +
                  (ancillaryPrice ? ancillaryPrice : 0) +
                  (flightDetails.solution.infTax
                    ? flightDetails.solution.infTax *
                      flightDetails.solution.infants
                    : 0) +
                  (flightDetails.solution.infFare
                    ? flightDetails.solution.infFare *
                      flightDetails.solution.infants
                    : 0)
                ).toFixed(2)
              : 0
          }`}</p>
        </div>
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="text-sm font-medium text-gray-500">
            Please note: All tares are quoted in USD. some airlines may charge
            paggage rees.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlightDetailsPriceCard;
