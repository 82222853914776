import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { MdCompareArrows } from "react-icons/md";
import { notifyError, notifySuccess } from "../../utils/notifications";
import dayjs from "dayjs";
// import Calendar from "../Calender.component";
import { daysName, monthsName } from "../../utils/ExtraFunction";
// import FlightTripTypeComponent from "./FlightSearch/FlightTripType.component";
import FlightCityFieldInputComponent from "./FlightSearch/FlightCityFieldInput.component";
// import FlightSelectNumberOfTravellersComponet from "./FlightSearch/FlightSelectNumberOfTravellers.componet";
import { useSelector } from "react-redux";
import {
  setIsCabinClassOptionsVisible,
  setIsDepartureCalender,
  setIsFromFieldDropdown,
  setIsReturnCalender,
  setIsToFieldDropdown,
  TravelersPopupVisible,
  updateFlightSearchParams,
} from "../../store/reducers/flightSearch.reducer";
import { useAppDispatch } from "../../store";
import {
  AirportListI,
  cabinClassT,
  tripTypeT,
} from "../../types/flightSearchReducer.types";
import { useLocation, useNavigate } from "react-router-dom";
import TripTypeComponent from "../homeComponent/HeroSection/TripType.component";
import Calendar2 from "../Calender2.component";

const FlightSearchComponent: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get("origin");
  const to = params.get("destination");
  const depDate = params.get("departure-date");
  const retDate = params.get("return-date");
  const _tripType: tripTypeT = params.get("trip-type") as tripTypeT;
  const _cabinClass = params.get("cabin-class") as cabinClassT;
  const adults = params.get("adults");
  const _children = params.get("children");
  const infants = params.get("infants");
  // const departure =
  const Navigation = useNavigate();
  const state = useSelector((state: any) => state.flights);
  const dispatch = useAppDispatch();

  const [tripType, setTripType] = useState<tripTypeT>(_tripType);
  console.log("triptype", tripType);
  // const [fromFieldDropdown, setFromFieldDropdown] = useState<boolean>(false);
  // const [toFieldDropdown, setToFieldDropdown] = useState<boolean>(false);
  const [isTravellersClass, setIsTravellersClass] = useState<boolean>(false);

  const [fromField, setFromField] = useState<AirportListI>({
    id: `${from?.split("_")[3]}`,
    Location: `${from?.split("_")[2]}`,
    airportName: `${from?.split("_")[1]}`,
    IATA: `${from?.split("_")[0]}`,
  });
  const [toField, setToField] = useState<AirportListI>({
    id: `${to?.split("_")[3]}`,
    Location: `${to?.split("_")[2]}`,
    airportName: `${to?.split("_")[1]}`,
    IATA: `${to?.split("_")[0]}`,
  });

  const [departureDate, setDepartureDate] = useState(dayjs(depDate));
  const [returnDate, setReturnDate] = useState(
    retDate ? dayjs(retDate) : dayjs(depDate).add(4, "day")
  );
  const [minDate, setMinDate] = useState(new Date());

  const handleChange = (
    destinationType: "from" | "to",
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (destinationType === "from") {
      dispatch(setIsFromFieldDropdown(!state.isFromFieldDropdown));
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(setIsReturnCalender(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsToFieldDropdown(false));
    } else if (destinationType === "to") {
      dispatch(setIsToFieldDropdown(!state.isToFieldDropdown));
      dispatch(setIsReturnCalender(false));
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
    }
  };

  const onChangeInputFromField = (origin: AirportListI) => {
    const { id, Location, airportName, IATA } = origin;
    setFromField(() => ({ id, Location, airportName, IATA }));
    dispatch(updateFlightSearchParams({ origin: IATA }));
    dispatch(setIsFromFieldDropdown(false));
    // InputRef.current?.value == 'test';
  };
  const onChangeInputToField = (origin: AirportListI) => {
    const { id, airportName, IATA, Location } = origin;
    setToField(() => ({ id, Location, airportName, IATA }));
    dispatch(updateFlightSearchParams({ destination: IATA }));
    dispatch(setIsToFieldDropdown(false));
  };

  const changeField = () => {
    if (fromField.IATA === "" || fromField.IATA === "") {
      notifyError(`Fill all the fields `);
    } else {
      setFromField(toField);
      setToField(fromField);
      notifySuccess("successfully done");
    }
  };

  // const TotalPassengers = adult + children + infant;
  // const CheckNoOfTraveller = () => {
  //   const limitOfPassengers = 9;
  //   if (TotalPassengers > limitOfPassengers) {
  //     notifyError(`Max number of traveller is 9`);
  //   } else {
  //     notifySuccess(`successful Change`);
  //     setIsTravellersClass(false);
  //     return TotalPassengers;
  //   }
  // };

  const departureInput = (e: any) => {
    setDepartureDate(dayjs(e));
    setMinDate(e);
    //if minDate i greater than departureDate then set returnDate to minDate
    if (e > departureDate) {
      setReturnDate(dayjs(e).add(4, "day"));
    }
    dispatch(
      updateFlightSearchParams({
        departureDate: new Date(
          departureDate.toDate().toISOString()
        ).toLocaleDateString("en-CA"),
      })
    );
    dispatch(setIsDepartureCalender(false));
  };

  const returnInput = (e: any) => {
    setReturnDate(dayjs(e));
    dispatch(
      updateFlightSearchParams({
        returnDate: returnDate
          ? new Date(returnDate!.toDate().toISOString()).toLocaleDateString(
              "en-CA"
            )
          : null,
      })
    );
    dispatch(setIsReturnCalender(false));
  };

  const lastCalenderComponentCloseFunction = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (state.isReturnCalender === true) {
      setIsReturnCalender(false);
      dispatch(setIsDepartureCalender(!state.isDepartureCalender));
      dispatch(setIsToFieldDropdown(false));
      dispatch(setIsFromFieldDropdown(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
    }
    dispatch(setIsDepartureCalender(!state.isDepartureCalender));
    dispatch(setIsToFieldDropdown(false));
    dispatch(setIsFromFieldDropdown(false));
    dispatch(setIsCabinClassOptionsVisible(false));
    dispatch(TravelersPopupVisible(false));
  };

  const roundTripActive = (e: React.MouseEvent<HTMLDivElement>) => {
    if (tripType === "oneway") {
      setTripType("roundtrip");
    } else if (tripType === "roundtrip") {
      e.stopPropagation();
      if (state.isDepartureCalender === true) {
        dispatch(setIsCabinClassOptionsVisible(false));
        dispatch(TravelersPopupVisible(false));
        dispatch(setIsFromFieldDropdown(false));
        dispatch(setIsDepartureCalender(false));
        dispatch(setIsCabinClassOptionsVisible(false));
        dispatch(setIsToFieldDropdown(false));
        dispatch(setIsReturnCalender(!state.isReturnCalender));
      }
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
      dispatch(setIsToFieldDropdown(false));
      dispatch(setIsReturnCalender(!state.isReturnCalender));
    }
  };

  const submit = () => {
    dispatch(
      updateFlightSearchParams({
        destination: toField.IATA,
        origin: fromField.IATA,
        departureDate: new Date(
          departureDate.toDate().toISOString()
        ).toLocaleDateString("en-CA"),
        returnDate: returnDate
          ? new Date(returnDate!.toDate().toISOString()).toLocaleDateString(
              "en-CA"
            )
          : null,
        totalTravelers:
          state.searchParams.adults +
          state.searchParams.children +
          state.searchParams.infants,
      })
    );
    if (tripType === "oneway") {
      Navigation(
        `/flight/search?origin=${fromField.IATA}_${fromField.airportName}_${
          fromField.Location
        }_${fromField.id}&destination=${toField.IATA}_${toField.airportName}_${
          toField.Location
        }_${toField.id}&trip-type=${tripType}&cabin-class=${
          state.searchParams.cabinClass
        }&adults=${state.searchParams.adults}&children=${
          state.searchParams.children
        }&infants=${state.searchParams.infants}&departure-date=${new Date(
          departureDate.toDate().toISOString()
        ).toLocaleDateString("en-CA")}`
      );
    } else if (tripType === "roundtrip") {
      console.log("RoundTripe", tripType);
      Navigation(
        `/flight/search?origin=${fromField.IATA}_${fromField.airportName}_${
          fromField.Location
        }_${fromField.id}&destination=${toField.IATA}_${toField.airportName}_${
          toField.Location
        }_${toField.id}&trip-type=${tripType}&cabin-class=${
          state.searchParams.cabinClass
        }&adults=${state.searchParams.adults}&children=${
          state.searchParams.children
        }&infants=${state.searchParams.infants}&departure-date=${new Date(
          departureDate.toDate().toISOString()
        ).toLocaleDateString("en-CA")}&return-date=${new Date(
          returnDate.toDate().toISOString()
        ).toLocaleDateString("en-CA")}`
      );
    }
  };

  // const travellerClass = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   setIsTravellersClass(!isTravellersClass);
  // };

  const CloseAll = () => {
    dispatch(setIsCabinClassOptionsVisible(false));
    dispatch(TravelersPopupVisible(false));
    dispatch(setIsFromFieldDropdown(false));
    dispatch(setIsToFieldDropdown(false));
    setIsTravellersClass(false);
    dispatch(setIsDepartureCalender(false));
    dispatch(setIsReturnCalender(false));
  };

  return (
    <div
      className="flex w-[96vw] mx-auto lg:w-auto flex-col items-center mt-[4rem] md:mt-[2.5rem] lg:mt-0 relative z-40"
      onClick={CloseAll}
    >
      <TripTypeComponent
        textColour="text-white"
        tripType={tripType}
        setTripType={(e: any) => setTripType(e)}
      />
      <div className="flex flex-col w-full">
        <div className="w-full flex flex-col lg:flex-row rounded-xl relative my-4 lg:my-2">
          <FlightCityFieldInputComponent
            handleChange={handleChange}
            fromField={fromField}
            fromFieldDropdown={state.isFromFieldDropdown}
            toField={toField}
            toFieldDropdown={state.isToFieldDropdown}
            onChangeInputFromField={onChangeInputFromField}
            onChangeInputToField={onChangeInputToField}
          />
          <div className="flex flex-col md:flex-row">
            <div className="flex w-full lg:w-auto">
              <div
                className="w-full md:rounded-l-xl lg:rounded-none lg:w-[11rem] h-full border-[2px] cursor-pointer flex flex-col items-start pl-4 justify-center py-1 hover:bg-red-100 bg-white duration-200 border-r-0"
                onClick={lastCalenderComponentCloseFunction}
              >
                <p className="mb-1 md:mb-1 text-xs md:text-sm flex items-center gap-1">
                  Departure
                  <FaAngleDown className="text-[#ec2027]" />
                </p>
                <h2 className="text-lg md:text-xl font-bold flex gap-2 items-end text-gray-700">
                  {departureDate ? departureDate.date() : "26"}
                  <p className="text-lg md:text-xl font-semibold capitalize text-gray-700">
                    {departureDate
                      ? monthsName(departureDate) +
                        " " +
                        departureDate.year().toString()
                      : "JUL'24"}
                  </p>
                </h2>
                <p className="text-xs md:text-sm font-semibold text-gray-500">
                  {departureDate ? daysName(departureDate) : "Friday"}
                </p>
              </div>
              {state.isDepartureCalender && (
                <Calendar2
                  tripType={""}
                  getSelectedDate={(e: any) => departureInput(e)}
                  Title={"Departure"}
                />
              )}
              {tripType !== "multicity" && (
                <div
                  className="w-full lg:w-[11rem] h-full border-[2px] cursor-pointer flex flex-col items-start pl-4 justify-center py-1 hover:bg-red-100 bg-white duration-200 md:rounded-r-xl"
                  onClick={roundTripActive}
                >
                  <p className="mb-1 md:mb-1 text-xs md:text-sm flex items-center gap-1">
                    Return <FaAngleDown className="text-[#ec2027]" />
                  </p>
                  {tripType === "oneway" ? (
                    <p className="h-[2.75rem] text-sm md:text-sm font-semibold">
                      Tap to add a return date
                    </p>
                  ) : (
                    <>
                      <h2 className="text-lg md:text-xl font-bold flex gap-2 items-end text-gray-700">
                        {returnDate ? returnDate.date() : "26"}
                        <p className="text-lg md:text-xl font-semibold hover:bg-red-100 duration-200 text-gray-700">
                          {returnDate
                            ? monthsName(returnDate) +
                              " " +
                              returnDate.year().toString()
                            : `JUL'24`}
                        </p>
                      </h2>
                      <p className="text-xs md:text-sm font-semibold text-gray-500">
                        {returnDate ? daysName(returnDate) : "Friday"}
                      </p>
                    </>
                  )}
                </div>
              )}
              {state.isReturnCalender && (
                <Calendar2
                  tripType={""}
                  getSelectedDate={(e: any) => returnInput(e)}
                  Title={"Arrival"}
                  minDate={minDate}
                />
              )}
            </div>
            <div
              className="flex absolute shadow-md items-center justify-center rounded-full cursor-pointer bg-white p-1 border-[1px] border-black/10 top-[3.6rem] md:top-[1.5rem] left-[80%] md:left-[47.1%] lg:left-[15.8rem] rotate-[90deg] md:rotate-0"
              onClick={changeField}
            >
              <MdCompareArrows className="text-[#ec2027] text-3xl" />
            </div>
          </div>
        </div>
      </div>
      <button
        className="rounded-full shadow-md w-[12rem] h-[2rem] flex items-center justify-center bg-white text-[#ec2027] font-bold py-2"
        onClick={submit}
      >
        Search
      </button>
    </div>
  );
};

export default FlightSearchComponent;
