import React from "react";
import { FaStar } from "react-icons/fa";
import { person } from "../../utils/icons";

interface Props {
  src?: string;
  title?: string;
  review?: string;
}

const ReviewCard: React.FC<Props> = ({ src, title, review }) => {
  return (
    <div className="min-w-[10rem] md:min-w-[20rem] flex flex-col justify-center items-center">
      <div className="flex flex-col items-center gap-y-2">
        {/* <img
          src={`${src ? src : person}`}
          alt={title ? title : "Aakash Gupta"}
          width={100}
        /> */}
        <h2 className="text-sm  text-center font-bold text-gray-700">
          {title ? title : "Aakash Gupta"}
        </h2>
        <div className="bg-white px-2 flex items-center gap-[2px] rounded-full shadow-md w-fit">
          <FaStar className="text-[#ffbc02] text-xs" />
          <FaStar className="text-[#ffbc02] text-xs" />
          <FaStar className="text-[#ffbc02] text-xs" />
          <FaStar className="text-[#ffbc02] text-xs" />
          <FaStar className="text-[#ffbc02] text-xs" />
        </div>
        <p className="text-center text-xs font-medium text-gray-500">
          {review
            ? review
            : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi fuga quisquam sit."}
        </p>
      </div>
    </div>
  );
};

export default ReviewCard;
