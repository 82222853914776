import React, { ChangeEvent, useEffect, useState } from "react";

export interface ContactInfo {
  billingPhone: string;
  mobile: string;
  email: string;
  retypeEmail: string;
  agree: string;
}

interface ContactProp {
  getForm: (obj: ContactInfo) => void;
}
const ContactInfoForm: React.FC<ContactProp> = ({ getForm }) => {
  const [contact, setContact] = useState<ContactInfo>({
    billingPhone: "",
    mobile: "",
    email: "",
    retypeEmail: "",
    agree: "",
  });

  const onChangeInputField = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  useEffect(() => {
    if (
      contact.billingPhone !== "" &&
      contact.email !== "" &&
      contact.mobile !== "" &&
      contact.retypeEmail !== "" &&
      contact.agree !== ""
    ) {
      getForm(contact);
    }
  }, [contact, getForm]);

  return (
    <form action="" className="flex flex-col gap-y-2 items-center px-4 py-5">
      <div className="grid grid-cols-3 w-full items-center">
        <label
          htmlFor="billingPhone"
          className="text-sm font-medium text-gray-700"
        >
          Billing Phone<span className="text-red-600">*</span>
        </label>
        <input
          name="billingPhone"
          id="billingPhone"
          type="text"
          onChange={onChangeInputField}
          pattern="[0-9],{10}"
          minLength={10}
          maxLength={10}
          className="col-span-2 px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
          required
        />
      </div>
      <div className="grid grid-cols-3 w-full items-center">
        <label htmlFor="mobile" className="text-sm font-medium text-gray-700">
          Mobile Phone<span className="text-red-600">*</span>
        </label>
        <input
          type="text"
          onChange={onChangeInputField}
          pattern="[0-9],{10}"
          minLength={10}
          maxLength={10}
          name="mobile"
          id="mobile"
          className="col-span-2 px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
          required
        />
      </div>
      <div className="grid grid-cols-3 w-full items-center">
        <label htmlFor="email" className="text-sm font-medium text-gray-700">
          Email<span className="text-red-600">*</span>
        </label>
        <input
          onChange={onChangeInputField}
          type="email"
          name="email"
          id="email"
          className="col-span-2 px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
          required
        />
      </div>
      <div className="grid grid-cols-3 w-full items-center">
        <label
          htmlFor="retypeEmail"
          className="text-sm font-medium text-gray-700"
        >
          Retype Email<span className="text-red-600">*</span>
        </label>
        <input
          type="email"
          onChange={onChangeInputField}
          name="retypeEmail"
          id="retypeEmail"
          className="col-span-2 px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
          required
        />
      </div>
      <div className="flex gap-x-1 items-start">
        <input
          name="agree"
          id="check"
          type="checkbox"
          className="mt-1"
          onChange={onChangeInputField}
        />
        <label htmlFor="agree" className="text-sm font-medium text-gray-700">
          Send me the latest travel deals, special offers, coupons and fare
          sales.
        </label>
      </div>
    </form>
  );
};

export default ContactInfoForm;
