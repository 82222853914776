import React from "react";
import { CgArrowsExchangeAlt } from "../../utils/solidIcons";
import { useNavigate } from "react-router-dom";
import { airportList } from "../../utils/airportList";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/ExtraFunction";

interface ButtonI {
  imgSrc: string;
  departure: string;
  arrival: string;
  departureTitle: string;
  arrivalTittle: string;
}
const Button: React.FC<ButtonI> = ({
  imgSrc,
  departure,
  arrival,
  departureTitle,
  arrivalTittle,
}) => {
  const state = useSelector((state: any) => state.flights);
  const Navigate = useNavigate();

  // Define the dates
  const today: Date = new Date();
  const departureDate: Date = new Date(today);
  departureDate.setDate(today.getDate() + 7);
  const arrivalDate: Date = new Date(departureDate);
  arrivalDate.setDate(departureDate.getDate() + 7);

  // Convert dates to desired format
  const formattedDepartureDate: string = formatDate(departureDate);
  const formattedArrivalDate: string = formatDate(arrivalDate);

  const fromField = airportList.find((airport) => airport.IATA === departure)!;
  const toField = airportList.find((airport) => airport.IATA === arrival)!;

  const adults = 1;
  const children = 0;
  const infants = 0;
  const tripType = "roundtrip";
  const cabinClass = "Economy";

  const onButtonClickHandler = () => {
    Navigate(
      `/flight/search?origin=${fromField.IATA}_${fromField.airportName}_${fromField.Location}_${fromField.id}&destination=${toField.IATA}_${toField.airportName}_${toField.Location}_${toField.id}&trip-type=${tripType}&cabin-class=${cabinClass}&adults=${adults}&children=${children}&infants=${infants}&departure-date=${formattedDepartureDate}&return-date=${formattedArrivalDate}`
    );
  };
  return (
    <button
      className=" w-full text-xs sm:text-sm  font-medium text-gray-700 h-fit flex items-center justify-between px-3 gap-2 py-2 bg-white/70 shadow-md border-[1px] hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white border-black/15 rounded-2xl"
      onClick={onButtonClickHandler}
    >
      <img
        src={`${imgSrc}`}
        alt={`${departure}-${arrival}`}
        className="rounded-full w-[2rem] h-[2rem] sm:w-[3rem] sm:h-[3rem]"
      />
      <span>{departureTitle}</span>
      <CgArrowsExchangeAlt className="text-2xl" />
      <span>{arrivalTittle}</span>
    </button>
  );
};

export default Button;
