import React from "react";
import { tripTypeT } from "../../../types/flightSearchReducer.types";
import TavelerCount from "../../Form/Taveler.count";
import CabinFrom from "../../Form/Cabin.from";

interface TripTypeProp {
  tripType: tripTypeT;
  setTripType: (e: tripTypeT) => void;
  textColour?: string;
}

const TripTypeComponent: React.FC<TripTypeProp> = ({
  tripType,
  setTripType,
  textColour,
}) => {
  return (
    <div className="flex items-center w-full justify-center md:justify-evenly mt-2">
      <div className="w-full flex flex-col md:flex-row md:hidden lg:flex items-center gap-3 md:gap-5">
        <div className="flex gap-5 items-center">
          <div
            className={
              tripType === "roundtrip"
                ? "flex items-center gap-1 bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white px-2 rounded-full"
                : `${
                    textColour ? textColour : "text-black"
                  } flex items-center gap-1 px-2 rounded-full`
            }
          >
            <input
              type="radio"
              id="roundtrip"
              name="route"
              value={"roundtrip"}
              onChange={(e) => setTripType(e.target.value as tripTypeT)}
              checked={tripType === "roundtrip"}
            />
            <label
              htmlFor="roundtrip"
              className="text-xs md:text-sm font-medium"
            >
              Round Trip
            </label>
          </div>
          <div
            className={
              tripType === "oneway"
                ? "flex items-center gap-1 bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white px-2 rounded-full"
                : `${
                    textColour ? textColour : "text-black"
                  } flex items-center gap-1 px-2 rounded-full`
            }
          >
            <input
              type="radio"
              id="oneway"
              name="route"
              value={"oneway"}
              className="outline-none"
              onChange={(e) => setTripType(e.target.value as tripTypeT)}
              checked={tripType === "oneway"}
            />
            <label htmlFor="oneway" className="text-xs md:text-sm font-medium">
              One Way
            </label>
          </div>
          {/* <div
            className={
              tripType === "multicity"
                ? "flex items-center gap-1 bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white px-2 rounded-full"
                : `${
                    textColour ? textColour : "text-black"
                  } flex items-center gap-1 px-2 rounded-full`
            }
          >
            <input
              type="radio"
              id="multicity"
              name="route"
              value={"multicity"}
              onChange={(e) => setTripType(e.target.value as tripTypeT)}
              checked={tripType === "multicity"}
            />
            <label
              htmlFor="multicity"
              className="text-xs md:text-sm font-medium"
            >
              Multi City
            </label>
          </div> */}
        </div>
        <TavelerCount />
        <CabinFrom />
      </div>
      <div className="shadow-md hidden md:flex lg:hidden items-center px-4 py-2 border-[1px] rounded-lg gap-3 bg-white">
        <div
          className={
            tripType === "roundtrip"
              ? "border-[1px] px-2 rounded-lg shadow-md bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white"
              : "border-[1px] px-2 rounded-lg shadow-md hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white"
          }
          onClick={() => setTripType("roundtrip")}
        >
          Round Trip
        </div>
        <div
          className={
            tripType === "oneway"
              ? "border-[1px] px-2 rounded-lg shadow-md bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white"
              : "border-[1px] px-2 rounded-lg shadow-md hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white"
          }
          onClick={() => setTripType("oneway")}
        >
          One Way
        </div>
        {/* <div
          className={
            tripType === "multicity"
              ? "border-[1px] px-2 rounded-lg shadow-md bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white"
              : "border-[1px] px-2 rounded-lg shadow-md hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white"
          }
          onClick={() => setTripType("multicity")}
        >
          Multi City
        </div> */}
      </div>
      <div className="hidden items-center gap-3 md:flex lg:hidden">
        <TavelerCount />
        <CabinFrom />
      </div>
      <div
        className={`${
          textColour ? textColour : "text-black"
        } hidden lg:block cursor-pointer font-bold text-sm animate-bounce`}
      >
        From Dreams to Destinations: Let's Explore Together!
      </div>
    </div>
  );
};

export default TripTypeComponent;
