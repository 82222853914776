import React, { useRef } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";

interface Props {
  children?: React.ReactNode;
  classcSS?: string;
  gap?: string;
}

const Carousal: React.FC<Props> = ({ children, classcSS, gap }) => {
  const slider = useRef<HTMLDivElement>(null);

  const scrollright = () => {
    if (slider.current) {
      slider.current.scrollLeft += 425;
    }
  };
  const scrollleft = () => {
    if (slider.current) {
      slider.current.scrollLeft -= 425;
    }
  };

  return (
    <div
      className={
        classcSS +
        " w-[95%] md:w-[80%] relative px-4 bg-[#f1f1f1] rounded-2xl shadow-md my-3 overflow-hidden"
      }
    >
      <div className="flex flex-col items-center">
        <div className="flex absolute right-4 top-2 items-center gap-3">
          <div
            className="bg-[#ec2027] rounded-full p-1 text-white cursor-pointer"
            onClick={scrollleft}
          >
            <FaChevronLeft />
          </div>
          <div
            className="bg-[#ec2027] rounded-full p-1 text-white cursor-pointer"
            onClick={scrollright}
          >
            <FaChevronRight />
          </div>
        </div>
      </div>
      <div
        className={
          gap +
          " overflow-hidden flex h-full items-center mt-2 transition-all duration-200 scroll-smooth"
        }
        ref={slider}
        style={{ scrollbarWidth: "none" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Carousal;
