export const reviews = [
  {
    src: "",
    title: "Kola Gaza",
    review: `Thank you Dreams Travel Team for your continued support, it's a delightful experience working with you and appreciate the excellent service and help we are receiving from you.`,
  },
  {
    src: "",
    title: "Puneet Gurnani",
    review: `Prompt service. My call was answered immediately. The agent, Martin was knowledgeable and made sure to follow up with rates and fares quickly. Will do business again!`,
  },
  {
    src: "",
    title: "Varadhan Narasimhan",
    review: `Dreams travel is always my best source of purchasing international tickets for my family. Great service from Praveen and his colleagues.`,
  },
  {
    src: "",
    title: "Assad Iqbal",
    review: `Most reliable flight booking service.All credit goes to martin who is available at all the crucial moments and act very promptly.`,
  },
  {
    src: "",
    title: "Vanitha",
    review: `Every time we book our flight with this company and contact person is Praveen.`,
  },
  {
    src: "",
    title: "Kamil Lamidi",
    review: `Making reservations was seamless and follow up when needed was prompt.`,
  },
  {
    src: "",
    title: "Ade Fash",
    review: `I have been a customer over 10 years now. That says a lot about the company`,
  },
];
