import { Link } from "react-router-dom";
import { contactInfo } from "../dummydata/contactInfo";
import { GoAlertFill } from "../utils/solidIcons";

const NoDataFoundComponent = () => {
  return (
    <div className="w-full h-full flex flex-col gap-y-2 my-5">
      <div className="flex flex-row items-center gap-x-2">
        <GoAlertFill className="text-red-500" />
        <p className="text-base font-semibold text-gray-700">
          We couldn't find flights as per your request,Please update your search
          criteria and re-start the search.
        </p>
      </div>
      <div className="mx-12 flex flex-col gap-y-2">
        <p className="text-base font-semibold text-gray-700">
          You can choose from following options
        </p>
        <ol className="list-decimal px-7 text-sm text-gray-600">
          <li>Confirm the search criteria you have entered,and try again</li>
          <li>Enter new search details</li>
          <li>
            For further assistance,call us toll free at{" "}
            <Link
              to={`tel:${contactInfo.mobileNum}`}
              className="font-semibold text-gray-700 text-base underline"
            >
              {contactInfo.mobileNum}
            </Link>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default NoDataFoundComponent;
