import React from "react";
import { IoCall, IoLogoWhatsapp, MdEmail } from "../../utils/solidIcons";
import { contactInfo } from "../../dummydata/contactInfo";
import { Link } from "react-router-dom";

const ContactButtons: React.FC = () => {
  const phoneNumber = `${contactInfo.mobileNum}`;
  const whatsappNumber = `${contactInfo.chatNumber}`;
  const email = `${contactInfo.email}`;

  return (
    <div className="fixed bottom-4 right-4 z-50 ">
      <div className="flex items-end">
        {
          <div className="flex flex-col gap-2 mr-2">
            <Link
              to={`tel:${phoneNumber}`}
              className="flex items-center justify-center w-14 h-14 p-3 text-5xl shadow-[0_0px_10px_5px_rgba(0,0,0,0.3)] text-white bg-blue-500 rounded-full hover:bg-blue-600 transition opacity-40 hover:opacity-100"
            >
              <IoCall />
            </Link>
            <Link
              to={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-14 h-14 p-3 text-5xl text-white shadow-[0_0px_10px_5px_rgba(0,0,0,0.3)] bg-green-500 rounded-full hover:bg-green-600 transition opacity-40 hover:opacity-100"
            >
              <IoLogoWhatsapp />
            </Link>
            <Link
              to={`mailto:${email}`}
              className="flex items-center justify-center w-14 h-14 p-3 text-5xl text-white shadow-[0_0px_10px_5px_rgba(0,0,0,0.3)] bg-yellow-500 rounded-full hover:bg-yellow-600 transition opacity-40 hover:opacity-100"
            >
              <MdEmail />
            </Link>
          </div>
        }
      </div>
    </div>
  );
};

export default ContactButtons;
