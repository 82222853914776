import React, { FC, useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Plane } from "../../utils/icons";
import { trie } from "../../utils/search";
import { BubbleEvent } from "../../utils/ExtraFunction";
import { AirportListI } from "../../types/flightSearchReducer.types";

interface Props {
  classCss?: string;
  fields: (e: AirportListI) => void;
}

const CitySearch: FC<Props> = ({ classCss, fields }) => {
  const [activeSearch, setActiveSearch] = useState<AirportListI[]>([]);

  const onInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveSearch(() => trie.search(event.target.value.toLowerCase()));
  };

  return (
    <div
      className={
        classCss +
        " duration-200 absolute z-40 w-[18rem] md:w-[20rem] bg-white h-[16rem] rounded-lg top-[2rem] shadow-md border-[1px] border-black/10 pb-2 overflow-hidden"
      }
      onClick={BubbleEvent}
    >
      <form className="w-full relative rounded-lg">
        <div className="relative w-full h-[2rem] border-[1px] border-black/10 shadow-md rounded-lg">
          <input
            type="search"
            placeholder="Type Here"
            className="w-full px-2 py-1 rounded-full bg-white text-sm focus:ring-none outline-none h-full"
            onChange={(e) => onInputChangeHandler(e)}
            autoFocus
          />
          <div className="absolute right-0 top-1/2 -translate-y-1/2 py-1 flex items-center justify-center px-2 h-full bg-[#ec2027] rounded-r-lg">
            <AiOutlineSearch className="text-white font-semibold" />
          </div>
        </div>
      </form>
      {
        <div
          className="h-full pt-1 pb-4 overflow-y-auto"
          style={{ scrollbarWidth: "thin" }}
        >
          {activeSearch.map((e: AirportListI, index: number) => (
            <div
              key={index}
              className="flex items-center justify-between my-3 cursor-pointer hover:bg-black/10 py-1 px-1 rounded-lg"
              onClick={() => fields(e)}
            >
              <div className="flex items-center gap-2">
                <img src={Plane} alt="logo" className="h-[2rem]" />
                <div>
                  <h2 className="font-bold text-sm w-[12rem] whitespace-nowrap text-ellipsis overflow-hidden">
                    {e.Location}
                  </h2>
                  <p className="text-xs text-black/70">{e.airportName}</p>
                </div>
              </div>
              <p className="text-sm font-bold">{e.IATA}</p>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default CitySearch;
