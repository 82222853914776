import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { NavRoutes } from "../dummydata/Data";
import { DTT_LOGO, indFlag, More, Uae, Usa } from "../utils/icons";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { contactInfo } from "../dummydata/contactInfo";
import { IoCall } from "../utils/solidIcons";
import { CustomerSupport } from "../utils/images";
import CallBtn from "./Buttons/CallBtn";

const Navbarcomponent = () => {
  const [isRisRegionalDropdown, setIsRegionalDropdown] = useState(false);
  const [selectCountry, setSelectCountry] = useState(false);
  const [isIcon, setIsIcon] = useState(false);

  const Icon = isRisRegionalDropdown ? FaAngleUp : FaAngleDown;

  return (
    <div
      className={
        "bg-white flex w-full  h-[4rem] px-[1rem] shadow-md lg:px-[4rem] py-2 items-center fixed justify-between z-50 duration-300 transition-all"
      }
    >
      <div className="LEFT flex bg-transparent">
        <Link to={"/"}>
          <img src={DTT_LOGO} alt="DDT" className="h-[3.5rem] w-[3.5rem]" />
        </Link>
        <div className="ml-[1rem] lg:ml-[4rem] hidden md:flex items-center gap-2 lg:gap-4">
          {NavRoutes.map((e, index) => (
            <NavLink
              key={index}
              to={e.route}
              className={({ isActive }) =>
                isActive
                  ? "flex flex-col items-center bg-transparent w-[4rem] justify-center text-[#ec2027] gap-1"
                  : "flex flex-col items-center bg-transparent w-[4rem] justify-center text-black hover:text-[#ec2027] gap-1"
              }
            >
              <img
                src={e.icon}
                alt="Holidays"
                className="h-[1.5rem] w-[1.5rem]"
              />
              <p className="font-bold text-[0.8rem] w-[4rem] text-center whitespace-nowrap text-ellipsis overflow-hidden">
                {e.title}
              </p>
            </NavLink>
          ))}
        </div>
        <div className="ml-[1rem] relative flex md:hidden items-center gap-2 z-50">
          <div
            className="flex items-center shadow-md border-[1px] px-2 py-1 gap-2 rounded-md cursor-pointer"
            onClick={() => setIsIcon(!isIcon)}
          >
            <img src={More} alt="ind" className="h-[1rem]" />
            <div className="flex items-center gap-[2px] font-semibold">
              Options
            </div>
            {isIcon ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          {isIcon && (
            <div className="bg-white absolute border-[1px] border-black/5 top-[3rem] w-[10rem] h-fit rounded-md shadow-md transition-all duration-200 px-2 py-3">
              <div className="w-full flex flex-col mb-2">
                {NavRoutes.map((e, index) => (
                  <NavLink
                    to={e.route}
                    key={index}
                    className="flex items-center w-full p-2 mb-1 gap-3 border-[1px] border-black/10 rounded-md shadow-md"
                  >
                    <img src={e.icon} alt="ind" className="h-[1.5rem]" />
                    <span className="font-semibold w-[4rem] text-center whitespace-nowrap text-ellipsis overflow-hidden">
                      {e.title}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="RIGHT relative lg:flex items-center gap-2 hidden">
        <CallBtn />
        {/* <div
          className="hidden lg:flex items-center bg-black/20 hover:bg-black/10 px-2 py-1 gap-2 rounded-md cursor-pointer"
          onClick={() => setIsRegionalDropdown(!isRisRegionalDropdown)}
        >
          <img src={indFlag} alt="ind" className="h-[1rem]" />
          <div className="flex items-center gap-[2px] font-semibold">
            <span>IN</span>|<span>ENG</span>|<span>INR</span>
          </div>
          <Icon />
        </div> */}
        {/* {isRisRegionalDropdown && (
          <div className="bg-white absolute border-[1px] border-black/5 top-[3rem] w-[18rem] h-fit rounded-md shadow-md transition-all duration-200 px-2 py-3">
            <div
              className="w-full shadow-md h-[3rem] border-[1px] border-black/5 cursor-pointer flex items-center justify-between px-3 py-2 mb-2"
              onClick={() => setSelectCountry(!selectCountry)}
            >
              <div className="flex items-center gap-2 relative">
                <img src={indFlag} alt="ind" className="h-[1.5rem]" />
                <span className="font-semibold">India</span>
              </div>
              {selectCountry ? (
                <FaAngleUp className="text-[#ec2027]" />
              ) : (
                <FaAngleDown className="text-[#ec2027]" />
              )}
            </div>
            {selectCountry ? (
              <div className="w-full flex flex-col mb-2">
                <div className="flex items-center w-full p-2 mb-1 gap-3 border-[1px] border-black/10 rounded-md shadow-md">
                  <input
                    type="radio"
                    id="india"
                    name="country"
                    value={"india"}
                  />
                  <label htmlFor="india" className="flex items-center gap-1">
                    <img src={indFlag} alt="ind" className="h-[1.5rem]" />
                    <span className="font-semibold">India</span>
                  </label>
                </div>
                <div className="flex items-center w-full p-2 mb-1 gap-3 border-[1px] border-black/10 rounded-md shadow-md">
                  <input type="radio" id="uae" name="country" value={"uae"} />
                  <label htmlFor="uae" className="flex items-center gap-1">
                    <img src={Uae} alt="uae" className="h-[1.5rem]" />
                    <span className="font-semibold">Uae</span>
                  </label>
                </div>
                <div className="flex items-center w-full p-2 mb-1 gap-3 border-[1px] border-black/10 rounded-md shadow-md">
                  <input type="radio" id="usa" name="country" value={"usa"} />
                  <label htmlFor="usa" className="flex items-center gap-1">
                    <img src={Usa} alt="ind" className="h-[1rem]" />
                    <span className="font-semibold">USA</span>
                  </label>
                </div>
              </div>
            ) : (
              <div className="w-full flex items-start gap-2 mb-2">
                <div className="w-1/2 flex flex-col py-3">
                  <h2 className="text-sm text-gray-500 mb-2">
                    Select Language
                  </h2>
                  <div className="flex items-center gap-1 mb-1">
                    <input
                      type="radio"
                      id="english"
                      name="language"
                      value={"english"}
                    />
                    <label htmlFor="english" className="text-sm font-semibold">
                      English
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      type="radio"
                      id="hindi"
                      name="language"
                      value={"hindi"}
                    />
                    <label htmlFor="hindi" className="text-sm font-semibold">
                      Hindi
                    </label>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col py-3">
                  <h2 className="text-sm text-gray-500 mb-2">
                    Select Currency
                  </h2>
                  <div className="flex items-center gap-1 mb-1">
                    <input type="radio" id="inr" name="inr" value={"inr"} />
                    <label htmlFor="inr" className="text-sm font-semibold">
                      INR
                    </label>
                  </div>
                </div>
              </div>
            )}
            <button className="rounded-full w-full bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-bold py-2">
              Apply
            </button>
          </div>
        )} */}
        {/* <div className="hidden md:block bg-[#ec2027] rounded-md text-white md:w-[8rem] px-2 py-2 font-semibold leading-4 cursor-pointer hover:bg-red-700">
          Login/Sign up
        </div>
        <div className="block md:hidden bg-[#ec2027] rounded-md text-white md:w-[8rem] px-2 py-2 font-semibold leading-4 cursor-pointer hover:bg-red-700">
          <IoMdPerson />
        </div> */}
      </div>
    </div>
  );
};

export default Navbarcomponent;
