import { airlinesImg } from "./airlineImgList";

// import Airline from "../Airline.json";
export const daysName = (date: any) => {
  if (date.day() === 0) {
    return "Sunday";
  } else if (date.day() === 1) {
    return "Monday";
  } else if (date.day() === 2) {
    return "Tuesday";
  } else if (date.day() === 3) {
    return "Wednesday";
  } else if (date.day() === 4) {
    return "Thursday";
  } else if (date.day() === 5) {
    return "Friday";
  } else if (date.day() === 6) {
    return "Saturday";
  }
};

export const monthsName = (date: any) => {
  if (date.month() === 0) {
    return "JAN";
  } else if (date.month() === 1) {
    return "FEB";
  } else if (date.month() === 2) {
    return "MAR";
  } else if (date.month() === 3) {
    return "APR";
  } else if (date.month() === 4) {
    return "MAY";
  } else if (date.month() === 5) {
    return "JUN";
  } else if (date.month() === 6) {
    return "JUL";
  } else if (date.month() === 7) {
    return "AUG";
  } else if (date.month() === 8) {
    return "SEP";
  } else if (date.month() === 9) {
    return "OCT";
  } else if (date.month() === 10) {
    return "NOV";
  } else if (date.month() === 11) {
    return "DEC";
  }
};

export const formatTime = (time: number) => {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  return `${hours}h ${minutes}m`;
};

export const AirlineFilterFunction = (AirlineName: string) => {
  return airlinesImg.filter(
    (airline) => AirlineName === airline.AirLine_code
  )[0];
};

export const BubbleEvent = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

const reqBody = (
  userInfo: any,
  paymentInfo: any,
  address: any,
  contact: any,
  searchParams: any
) => {
  const userArr: any = [];
  userInfo.map((item: any) =>
    userArr.push({
      firstName: item.firstName,
      lastName: item.lastName,
      nationality: item.nationality,
      DOB: item.birthday,
      gender: item.sex,
      userType: item.psgType as string,
    })
  );
  return {
    searchParams: {
      destination: searchParams.destination,
      origin: searchParams.origin,
      departureDate: searchParams.departureDate,
      returnDate: searchParams.returnDate,
      totalTravelers: searchParams.totalTravelers,
      adults: searchParams.adults,
      children: searchParams.children,
      infants: searchParams.infants,
      tripType: searchParams.tripType,
      cabinClass: searchParams.cabinClass,
    },
    Passengers: userArr,
    payment_method: paymentInfo.cardType,
    credit_debit_card_number: paymentInfo.cardNum,
    expiration_date: paymentInfo.cardExpiredDate,
    CV_number: paymentInfo.cvv,
    card_holder_name: paymentInfo.cardHolderName,
    country: address.country,
    state: address.state,
    address: address.Address,
    city: address.city,
    pinCode: address.zip,
    email: contact.retypeEmail,
    Phone_number: contact.billingPhone,
    alt_Phone_number: contact.mobile,
  };
};

export const AddUserDetails = async (
  userInfo: any,
  paymentInfo: any,
  address: any,
  contact: any,
  searchParams: any
) => {
  const Message = await fetch(
    process.env.REACT_APP_DataCollectingRoute as string,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        reqBody(userInfo, paymentInfo, address, contact, searchParams)
      ),
    }
  );

  const response = await Message.json();
  return response.message;
};

export const validateData = (
  contact: any,
  address: any,
  paymentInfo: any,
  userBasicInfo: any
) => {
  // Helper function to validate email format
  const isEmailValid = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Initialize an empty errors object
  const errors: any = [];

  // User basic info validation
  userBasicInfo.forEach((user: any, index: number) => {
    if (!user.firstName) {
      errors.push(`First name is required for ${user.psgType}.`);
    }
    if (!user.lastName) {
      errors.push(`Last name is required for ${user.psgType}.`);
    }
    if (!user.birthday) {
      errors.push(`Birthday is required for ${user.psgType}.`);
    }
    if (!user.sex) {
      errors.push(`Gender is required for ${user.psgType}.`);
    }
  });

  // Payment info validation
  if (!paymentInfo.cardHolderName) {
    errors.push("Cardholder name is required.");
    return errors;
  }
  if (!paymentInfo.cardNum) {
    errors.push("Credit card number is required.");
    return errors;
  }
  if (!paymentInfo.cvv) {
    errors.push("CVV is required.");
    return errors;
  }

  // Address validation
  if (!address.Address) {
    errors.push("Address is required.");
    return errors;
  }
  if (!address.city) {
    errors.push("City is required.");
    return errors;
  }
  if (!address.state) {
    errors.push("State is required.");
    return errors;
  }
  if (!address.country) {
    errors.push("Country is required.");
    return errors;
  }
  if (!address.zip) {
    errors.push("Zip code is required.");
    return errors;
  }

  // Contact validation
  if (!contact.email || !isEmailValid(contact.email)) {
    errors.push("Invalid or missing email.");
    return errors;
  }
  if (contact.email !== contact.retypeEmail) {
    errors.push("Email and retype email must match.");
    return errors;
  }
  if (!contact.mobile) {
    errors.push("Mobile number is required.");
    return errors;
  }
  if (!contact.billingPhone) {
    errors.push("Billing phone number is required.");
    return errors;
  }
  if (contact.agree !== "on") {
    errors.push("Agreement must be accepted.");
    return errors;
  }

  // Return true if no errors, otherwise return the errors object
  return errors.length === 0 ? [] : errors;
};

export const generateBookingNumber = () => {
  const prefix = "DTT-";
  const randomString = Math.random().toString(36).substring(2, 6).toUpperCase();
  const timestamp = Date.now().toString().slice(-4); // last 4 digits of the timestamp
  return `${prefix}${randomString}${timestamp}`;
};

export const convertToAMPM = (time: any) => {
  // Split the time into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const hours12 = hours % 12 || 12; // Converts 0 (midnight) to 12

  // Return the formatted time in AM/PM format
  return `${hours12}:${String(minutes).padStart(2, "0")} ${period}`;
};

export const calculateJourneyTime = (
  departureTime: string,
  arrivalTime: string,
  departureDate: string,
  arrivalDate: string
): string => {
  // Combine date and time to create Date objects
  const departureDateTime = new Date(`${departureDate}T${departureTime}:00`);
  const arrivalDateTime = new Date(`${arrivalDate}T${arrivalTime}:00`);

  // Calculate the difference in milliseconds
  const timeDifference =
    arrivalDateTime.getTime() - departureDateTime.getTime();

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  // Return the journey time in the format HHh:MMm
  return `${String(hours).padStart(2, "0")}h:${String(minutes).padStart(
    2,
    "0"
  )}m`;
};

export const formatFlightTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60); // Calculate hours
  const mins = minutes % 60; // Calculate remaining minutes
  return `${String(hours).padStart(2, "0")}h:${String(mins).padStart(2, "0")}m`;
};

export const calculateDiscountedFare = (
  fare: number,
  discountPercentage: number
): number => {
  // Ensure discount percentage is within valid range (0-100)
  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error("Discount percentage must be between 0 and 100");
  }

  const discountAmount = (fare * discountPercentage) / 100;
  const discountedFare = fare - discountAmount;

  return parseFloat(discountedFare.toFixed(2)); // Return with 2 decimal places
};

export const calculateDiscountAmt = (
  fare: number,
  discountPercentage: number
): number => {
  // Ensure discount percentage is within valid range (0-100)
  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error("Discount percentage must be between 0 and 100");
  }

  const discountAmount = (fare * discountPercentage) / 100;
  return parseFloat(discountAmount.toFixed(2));
};

// Function to format date to YYYY-MM-DD
export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
