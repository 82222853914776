import { Link } from "react-router-dom";
import HeroNav from "../components/homeComponent/HeroNav.component";
import { Banner } from "../utils/images";
import ScrollToTop from "../components/ScrollToTop";

const TermsAndConditions = () => {
  return (
    <>
      <ScrollToTop />
      <div
        className={`w-full h-full md:h-[35rem] lg:h-[15rem] bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <HeroNav />
        <h1 className="text-white text-7xl font-bold w-[70vw] mx-auto mt-10">
          Terms & Conditions
        </h1>
      </div>
      <div className="flex flex-col gap-y-4 w-[70vw] mx-auto my-10 text-justify">
        <p className="text-base text-gray-900 font-medium">
          We, at <Link to="/">dreamstravelandtours.com</Link> are extremely
          thankful to you for visiting our website. The below mentioned terms
          and conditions are applied to your access on our website, associated
          with all the products/services as well as the website's content.
        </p>
        <p className="text-base text-gray-900 font-medium">
          It's a legal agreement between you and{" "}
          <Link to="/">dreamstravelandtours.com</Link> (Including ITS
          SUBSIDIARIES AND AFFILIATES. THIS DOCUMENT SHOULD BE READ CAREFULLY).
          AND, BY ACCESSING OUR WEBSITE, YOU ARE AGREEING OUR TERMS AND
          CONDITIONS.
        </p>
        <p className="text-base text-gray-900 font-medium">
          YOU ARE ALSO GIVING YOUR ACCEPTANCE THAT ANY DISPUTE AGAINST US WILL
          BE RESOLVED IN INDIA, REGARDLESS OF YOUR GEOGRAPHIC LOCATION.
        </p>
        <p className="text-base text-gray-900 font-medium">
          We may modify our terms and conditions at any point in time for (a)
          our travel website, including removal or modification of any content
          or function; (b) any travel related charge or fees, and (c) these
          Terms and conditions. Here are the terms and conditions:
        </p>
        <ul className="flex flex-col gap-y-2 text-sm text-gray-700 font-medium list-disc ps-5">
          <li>
            In an event of system malfunctioning or failure, effecting pricing
            of airfare, hotel booking, car rental or any other booking, we hold
            a right of denying bookings. Right after fixing the system issue, we
            will certainly update all the customers with new prices.
          </li>
          <li>
            Some of the content, products or services on our website are catered
            by third parties. Moreover, the software and data found on our
            website is a copyrighted possession of us as well as the third-party
            service providers/ distributors.
          </li>
          <li>
            <Link to="/">dreamstravelandtours.com</Link> does not have any joint
            venture, employment, partnership or any fiduciary relationship with
            its customers. <Link to="/">dreamstravelandtours.com</Link> works as
            an independent agent that offers travel related products/services.
            It is to be informed that we receive commission from suppliers for
            meeting their sales targets.
          </li>
          <li>
            In order to make the legitimate reservations or purchases, you may
            use our website. But, you are not allowed to make use of our website
            for speculative, fake, or fraudulent reservations.
          </li>
          <li>
            Exclusive terms and conditions stated by service providers will be
            applied additionally to your reservation on our website. Violations
            of any service provider's terms and conditions may result in (1)
            forfeiting money spent for any purchase, (b) reservation
            cancelation, (c) denied access to flights, hotels or other services.
          </li>
          <li>
            Being a user, you agree that your age is appropriate according to
            law for using any travel website. And, the usage and purchase of any
            travel related service or product is at your sole risk.
          </li>
          <li>
            You understand that our service providers are not our employees, as
            they are independent agents. Hence, we and our service providers
            bear no responsibility in case of any damage to your computer system
            or any other type of property.
          </li>
          <li>
            However, most of the journeys including domestic or international
            are completed without any hassle, but travelling to certain
            destinations involves more risk than others. Hence, it is
            recommended reviewing the travel advisories before travelling to
            some place.
          </li>
          <li>
            Being a user, you agree that your personal information can be shared
            by with various service providers and associates.
          </li>
          <li>
            The service marks, logos, trademarks or other signs displayed on our
            website are our sole property and copyrights protected.
          </li>
          <li>
            The terms and conditions are governed in accordance with the laws of
            India.
          </li>
          <li>
            Being a customer, you agree that you will never have any dispute
            regarding airline tickets, processing fee, or fare adjustment if we
            are abide by our terms and conditions. In case, you need to
            investigate any charge, you can connect with our customer care
            department.
          </li>
        </ul>
      </div>
      ;
    </>
  );
};

export default TermsAndConditions;
