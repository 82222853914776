import React, { useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import {
  setIsCabinClassOptionsVisible,
  setIsDepartureCalender,
  setIsFromFieldDropdown,
  setIsReturnCalender,
  setIsToFieldDropdown,
  TravelersPopupVisible,
  updateFlightSearchParams,
} from "../../store/reducers/flightSearch.reducer";
import { BubbleEvent } from "../../utils/ExtraFunction";

const CabinFrom = () => {
  const state = useSelector((state: any) => state.flights);
  const dispatch = useAppDispatch();

  const onClickOnCabinClassHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(setIsCabinClassOptionsVisible(!state.isCabinClassOptionsVisible));
    dispatch(setIsReturnCalender(false));
    dispatch(setIsDepartureCalender(false));
    dispatch(TravelersPopupVisible(false));
    dispatch(setIsToFieldDropdown(false));
    dispatch(setIsFromFieldDropdown(false));
  };

  const onSelectCabinClassChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFlightSearchParams({
        cabinClass: event.target.value,
      })
    );
    dispatch(setIsCabinClassOptionsVisible(!state.isCabinClassOptionsVisible));
  };

  return (
    <div className="w-full md:w-fit">
      <div
        onClick={onClickOnCabinClassHandler}
        className="w-full md:w-[6rem] cursor-pointer rounded-md p-1.5 bg-gradient-to-r from-[#ec2027] to-[#c81212] flex items-center justify-between gap-2"
      >
        <p className="text-white text-xs md:text-sm capitalize">
          <input
            type="text"
            value={state.searchParams.cabinClass}
            id="cabinClass"
            name="cabinClass"
            hidden
            readOnly
          />
          {state.searchParams.cabinClass}
        </p>
        <FaCaretDown
          onClick={() => onClickOnCabinClassHandler}
          className="text-white text-lg md:text-xl"
        />
      </div>
      {state.isCabinClassOptionsVisible && (
        <div
          className="w-1/2 md:w-[16%] shadow-[0_0px_10px_.1px_rgba(0,0,0,.5)] z-40 absolute mt-1 flex flex-col bg-white py-5 rounded-md"
          onClick={BubbleEvent}
        >
          <label className="flex gap-x-2 text-xs md:text-sm items-center hover:bg-blue-100/50 py-2 px-5 cursor-pointer">
            <input
              type="radio"
              value="Economy"
              name="cabinClass"
              checked={state.searchParams.cabinClass === "Economy"}
              onChange={onSelectCabinClassChangeHandler}
            />
            Economy
          </label>
          <label className="flex gap-x-2 text-xs md:text-sm items-center hover:bg-blue-100/50 py-2 px-5 cursor-pointer">
            <input
              type="radio"
              value="Business"
              name="cabinClass"
              checked={state.searchParams.cabinClass === "Business"}
              onChange={onSelectCabinClassChangeHandler}
            />
            Business
          </label>
          <label className="flex gap-x-2 text-xs md:text-sm items-center hover:bg-blue-100/50 py-2 px-5 cursor-pointer">
            <input
              type="radio"
              value="First"
              name="cabinClass"
              checked={state.searchParams.cabinClass === "First"}
              onChange={onSelectCabinClassChangeHandler}
            />
            First
          </label>
        </div>
      )}
    </div>
  );
};

export default CabinFrom;
