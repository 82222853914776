import React, { useEffect, useState } from "react";
import { TbSunset2 } from "react-icons/tb";
import {
  AiOutlineSun,
  BsCloudMoon,
  IoPartlySunnyOutline,
  RxCross2,
} from "../../utils/solidIcons";
import { useSelector } from "react-redux";
import {
  AirlineDetailsI,
  FlightJourneyResponseDetailsI,
  FlightsStateI,
} from "../../types/flightSearchReducer.types";
import { useAppDispatch } from "../../store";
import { updateFilteredFlightSearchList } from "../../store/reducers/flightSearch.reducer";
import { getAvailableAirlines } from "../../utils/flights.utils";
import { airportList } from "../../utils/airportList";

interface FilterComponentProp {
  flightFilteredData: any;
  destination: string;
  origin: string;
  tripType: string;
  handleFlightFilterData: (filteredData: any) => void;
}

const FilterComponent: React.FC<FilterComponentProp> = ({
  flightFilteredData,
  destination,
  origin,
  tripType,
  handleFlightFilterData,
}) => {
  const { flights, solutions, segments } = flightFilteredData;
  const from = airportList.find((airport) => airport.IATA === origin);
  const to = airportList.find((airport) => airport.IATA === destination);
  const maxPrice = Math.max(
    ...solutions.map((item: any) => item.adtFare + item.adtTax)
  );

  const minPrice = Math.min(
    ...solutions.map((item: any) => item.adtFare + item.adtTax)
  );

  const [price, setPrice] = useState<number>(+maxPrice.toFixed(2));
  const [appliedFilters, setAppliedFilters] = useState<
    Record<string, string>[]
  >([]);

  const handleCheckboxChange = (name: string, value: string) => {
    const isFilterPresent = appliedFilters.some(
      (filter) => filter.name === name && filter.value === value
    );

    // Check if an airline filter exists (name starts with 'airline_')
    const existingAirlineFilter = appliedFilters.find((filter) =>
      filter.name.startsWith("airline_")
    );

    setAppliedFilters((prevFilters) => {
      // If the selected filter is the same as an existing one, remove it
      if (isFilterPresent) {
        return prevFilters.filter(
          (filter) => !(filter.name === name && filter.value === value)
        );
      }

      // If the selected filter is an airline filter and another airline filter is present
      if (name.startsWith("airline_")) {
        // Replace the existing airline filter with the new one
        if (existingAirlineFilter) {
          return prevFilters.map((filter) =>
            filter.name.startsWith("airline_")
              ? { name, value } // Update the airline filter
              : filter
          );
        }
        // If no airline filter is present, add the new airline filter
        return [...prevFilters, { name, value }];
      }

      // For non-airline filters, add the new filter
      return [...prevFilters, { name, value }];
    });
  };

  const handlerClearAll = () => {
    setAppliedFilters([]);
    setPrice(maxPrice);
  };

  useEffect(() => {
    handleFlightFilterData(appliedFilters);
  }, [appliedFilters]);

  const changePrice = (e: any) => {
    setPrice(e);
    setAppliedFilters((prevOptions) => {
      const filterIndex = prevOptions.findIndex(
        (filter) => filter.name === "price_range"
      );

      if (filterIndex === -1) {
        // If "price_range" filter is not found, add it
        return [...prevOptions, { name: "price_range", value: e }];
      } else {
        // If "price_range" filter already exists, update its value
        return prevOptions.map((filter) =>
          filter.name === "price_range" ? { ...filter, value: e } : filter
        );
      }
    });
  };

  const isChecked = (name: string, value: string) => {
    return appliedFilters.some(
      (filter) => filter.name === name && filter.value === value
    );
  };

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    // Check the scroll position and update the state
    if (window.scrollY > 200) {
      setIsFixed(true); // Change to fixed position after scrolling down 200px
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`${
          isFixed
            ? "lg:w-[16rem] xl:w-[20rem] shadow-md p-2 xl:p-5 bg-white rounded-md fixed -mt-[4rem]"
            : "lg:w-[16rem] xl:w-[20rem] shadow-md p-2 xl:p-5 bg-white rounded-md fixed"
        }`}
      >
        {appliedFilters.length > 0 && (
          <div className="w-full flex flex-col pb-2">
            <div className="flex items-center justify-between p-2">
              <p className="font-semibold text-sm text-gray-600">
                Applied Filters
              </p>
              <button
                className="font-semibold text-sm text-white px-4 py-1 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212]"
                onClick={handlerClearAll}
              >
                Clear All
              </button>
            </div>
            {appliedFilters.length > 0 && (
              <div className="flex flex-wrap gap-1 px-1">
                {appliedFilters.map(
                  (appliedFilter: Record<string, string>, index: number) => (
                    <div
                      key={index}
                      className="relative text-xs font-base text-gray-500 border px-2 py-1 rounded-full flex items-center"
                      onClick={() =>
                        handleCheckboxChange(
                          appliedFilter.name,
                          appliedFilter.value
                        )
                      }
                    >
                      <p className="text-xs font-base cursor-pointer">
                        {appliedFilter.value}
                      </p>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col justify-center items-start w-full h-fit p-2 my-4">
          <p className="font-bold mb-2 text-sm text-gray-600">
            Popular Filters
          </p>
          <div className="flex flex-col p-2 text-gray-500">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="nonstop"
                checked={isChecked("nonstop", "Nonstop")}
                onChange={() => handleCheckboxChange("nonstop", "Nonstop")}
              />
              <label htmlFor="nonstop" className="text-sm font-bold">
                Non-stop
              </label>
            </div>
            {/* <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="one-stop"
                checked={isChecked("one-stop", "one-stop")}
                onChange={() => handleCheckboxChange("one-stop", "one-stop")}
              />
              <label htmlFor="one-stop" className="text-sm font-bold">
                One-stop
              </label>
            </div> */}
            {/* <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="morning"
                checked={isChecked("morning", "Morning Departure")}
                onChange={() =>
                  handleCheckboxChange("morning", "Morning Departure")
                }
              />
              <label htmlFor="morning" className="font-bold text-sm">
                Morning Departure
              </label>
            </div> */}
            {/* <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="afternoon"
                checked={isChecked("afternoon", "Afternoon Departure")}
                onChange={() =>
                  handleCheckboxChange("afternoon", "Afternoon Departure")
                }
              />
              <label htmlFor="afternoon" className="font-bold text-sm">
                Afternoon Departure
              </label>
            </div> */}
            {/* <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="earlyMorning"
                checked={isChecked("early_morning", "Early Morning Departure")}
                onChange={() =>
                  handleCheckboxChange(
                    "early_morning",
                    "Early Morning Departure"
                  )
                }
              />
              <label htmlFor="earlyMorning" className="font-bold text-sm">
                Early Morning Departure
              </label>
            </div> */}
            {/* <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="lateDeparture"
                checked={isChecked("late_departure", "Late Departure")}
                onChange={() =>
                  handleCheckboxChange("late_departure", "Late Departure")
                }
              />
              <label htmlFor="lateDeparture" className="font-bold text-sm">
                Late Departure
              </label>
            </div> */}
          </div>
        </div>
        <div className="flex flex-col items-start justify-center my-2 p-2">
          <h2 className="mb-2 font-bold text-gray-600 text-sm">Price Range</h2>
          <div className="w-full">
            {price > 0 && (
              <div className="w-fit border px-4 py-1 text-gray-700 rounded-full bg-blue-100 text-xs">
                ${price}
              </div>
            )}
            <input
              type="range"
              className="w-full"
              value={price}
              min={minPrice}
              max={maxPrice}
              step={(maxPrice - minPrice) / 10}
              onChange={(prev) => changePrice(prev.target.value)}
            />
            <div className="flex items-center justify-between w-full">
              <p className="font-bold text-gray-600 text-xs">
                ${minPrice.toFixed(2)}
              </p>
              <p className="font-bold text-gray-600 text-xs">
                ${maxPrice.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col items-start justify-center my-2 py-2">
          <h2 className="mb-2 font-bold uppercase">Stops From New Delhi</h2>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="nostop"
              checked={isChecked("nostop", "Nonstop")}
              onChange={() => handleCheckboxChange("nostop", "Nonstop")}
            />
            <label htmlFor="nostop" className="font-bold">
              Nonstop
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="1stop"
              checked={isChecked("1stop", "1 stop")}
              onChange={() => handleCheckboxChange("1stop", "1 stop")}
            />
            <label htmlFor="1stop" className="font-bold">
              1 stop
            </label>
          </div>
        </div> */}
        <div>
          {/* <h2>onward Journey</h2> */}
          {/* <div className="p-2">
            <div className="flex flex-col items-start justify-center py-2">
              <h2 className="mb-2 font-bold">{`Departure From ${from?.airportName}`}</h2>
              <div className="flex w-full flex-wrap items-center justify-between gap-1">
                <div
                  className={
                    !appliedFilters.find(
                      (filter: Record<string, string>) =>
                        filter.name === "departure_before_6AM"
                    )
                      ? `w-[4rem] h-[4rem] border cursor-pointer border-black/10 flex flex-col items-center justify-center p-1 gap-2 rounded-md`
                      : `w-[4rem] h-[4rem] border cursor-pointer border-red-400 flex flex-col items-center justify-center p-1 gap-2 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white`
                  }
                  onClick={() =>
                    handleCheckboxChange("departure_before_6AM", "before 6AM")
                  }
                >
                  <TbSunset2 className="w-[2rem] h-[2rem]" />
                  <p className="text-[.5rem] text-center font-medium">
                    Before 6AM
                  </p>
                </div>
                <div
                  className={
                    !appliedFilters.find(
                      (filter: Record<string, string>) =>
                        filter.name === "departure_between_6AM_to_12PM"
                    )
                      ? `w-[4rem] h-[4rem] border cursor-pointer border-black/10 flex flex-col items-center justify-center p-1 gap-2 rounded-md`
                      : `w-[4rem] h-[4rem] border cursor-pointer border-red-400 flex flex-col items-center justify-center p-1 gap-2 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white`
                  }
                  onClick={() =>
                    handleCheckboxChange(
                      "departure_between_6AM_to_12PM",
                      "6AM - 12PM"
                    )
                  }
                >
                  <AiOutlineSun className="w-[2rem] h-[2rem]" />
                  <p className="text-[.5rem] text-center font-medium">
                    6AM - 12PM
                  </p>
                </div>
                <div
                  className={
                    !appliedFilters.find(
                      (filter: Record<string, string>) =>
                        filter.name === "departure_between_12PM_to_6PM"
                    )
                      ? `w-[4rem] h-[4rem] border cursor-pointer border-black/10 flex flex-col items-center justify-center p-1 gap-2 rounded-md`
                      : `w-[4rem] h-[4rem] border cursor-pointer border-red-400 flex flex-col items-center justify-center p-1 gap-2 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white`
                  }
                  onClick={() =>
                    handleCheckboxChange(
                      "departure_between_12PM_to_6PM",
                      "12PM - 6PM"
                    )
                  }
                >
                  <IoPartlySunnyOutline className="h-[2rem] w-[2rem]" />
                  <p className="text-[.5rem] text-center font-medium">
                    12PM - 6PM
                  </p>
                </div>
                <div
                  className={
                    !appliedFilters.find(
                      (filter: Record<string, string>) =>
                        filter.name === "departure_after_6PM"
                    )
                      ? `w-[4rem] h-[4rem] border cursor-pointer border-black/10 flex flex-col items-center justify-center p-1 gap-2 rounded-md`
                      : `w-[4rem] h-[4rem] border cursor-pointer border-red-400 flex flex-col items-center justify-center p-1 gap-2 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white`
                  }
                  onClick={() =>
                    handleCheckboxChange("departure_after_6PM", "after 6PM")
                  }
                >
                  <BsCloudMoon className="h-[2rem] w-[2rem]" />
                  <p className="text-[.5rem] text-center font-medium">
                    After 6PM
                  </p>
                </div>
              </div>
            </div> */}
          {/* <div className="flex flex-col items-start justify-center py-2">
              <h2 className="mb-2 font-bold">{`Arrival at ${to?.airportName}`}</h2>
              <div className="flex w-full flex-wrap items-center justify-between gap-1">
                <div
                  className={
                    !appliedFilters.find(
                      (filter: Record<string, string>) =>
                        filter.name === "arrival_before_6AM"
                    )
                      ? `w-[4rem] h-[4rem] border cursor-pointer border-black/10 flex flex-col items-center justify-center p-1 gap-2 rounded-md`
                      : `w-[4rem] h-[4rem] border cursor-pointer border-red-400 flex flex-col items-center justify-center p-1 gap-2 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white`
                  }
                  onClick={() =>
                    handleCheckboxChange("arrival_before_6AM", "before 6AM")
                  }
                >
                  <TbSunset2 className="w-[2rem] h-[2rem]" />
                  <p className="text-[.5rem] text-center  font-medium">
                    Before 6AM
                  </p>
                </div>
                <div
                  className={
                    !appliedFilters.find(
                      (filter: Record<string, string>) =>
                        filter.name === "arrival_between_6AM_to_12PM"
                    )
                      ? `w-[4rem] h-[4rem] border cursor-pointer border-black/10 flex flex-col items-center justify-center p-1 gap-2 rounded-md`
                      : `w-[4rem] h-[4rem] border cursor-pointer border-red-400 flex flex-col items-center justify-center p-1 gap-2 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white`
                  }
                  onClick={() =>
                    handleCheckboxChange(
                      "arrival_between_6AM_to_12PM",
                      "6AM - 12PM"
                    )
                  }
                >
                  <AiOutlineSun className="w-[2rem] h-[2rem]" />
                  <p className="text-[.5rem] text-center  font-medium">
                    6AM - 12PM
                  </p>
                </div>
                <div
                  className={
                    !appliedFilters.find(
                      (filter: Record<string, string>) =>
                        filter.name === "arrival_between_12PM_to_6PM"
                    )
                      ? `w-[4rem] h-[4rem] border cursor-pointer border-black/10 flex flex-col items-center justify-center p-1 gap-2 rounded-md`
                      : `w-[4rem] h-[4rem] border cursor-pointer border-red-400 flex flex-col items-center justify-center p-1 gap-2 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white`
                  }
                  onClick={() =>
                    handleCheckboxChange(
                      "arrival_between_12PM_to_6PM",
                      "12PM - 6PM"
                    )
                  }
                >
                  <IoPartlySunnyOutline className="h-[2rem] w-[2rem]" />
                  <p className="text-[.5rem] text-center  font-medium">
                    12PM - 6PM
                  </p>
                </div>
                <div
                  className={
                    !appliedFilters.find(
                      (filter: Record<string, string>) =>
                        filter.name === "arrival_after_6PM"
                    )
                      ? `w-[4rem] h-[4rem] border cursor-pointer border-black/10 flex flex-col items-center justify-center p-1 gap-2 rounded-md`
                      : `w-[4rem] h-[4rem] border cursor-pointer border-red-400 flex flex-col items-center justify-center p-1 gap-2 rounded-md bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white`
                  }
                  onClick={() =>
                    handleCheckboxChange("arrival_after_6PM", "after 6PM")
                  }
                >
                  <BsCloudMoon className="h-[2rem] w-[2rem]" />
                  <p className="text-[.5rem] text-center font-medium">
                    After 6PM
                  </p>
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
        <div className="flex flex-col items-start justify-center my-2 py-2">
          <p className="font-bold mb-2 uppercase">Airlines</p>
          <div className="">
            {getAvailableAirlines(segments).map(
              (airlineDetails: AirlineDetailsI) => (
                <div
                  key={airlineDetails.AirLine_code}
                  className="flex items-center gap-2"
                >
                  <input
                    type="checkbox"
                    id={airlineDetails.AirLine_code}
                    checked={isChecked(
                      `airline_${airlineDetails.AirLine_code.trim().toLowerCase()}`,
                      airlineDetails.Company_Name
                    )}
                    onChange={() =>
                      handleCheckboxChange(
                        `airline_${airlineDetails.AirLine_code.trim().toLowerCase()}`,
                        airlineDetails.Company_Name
                      )
                    }
                  />
                  <div className="flex items-center gap-x-2">
                    <img
                      src={airlineDetails.image_link}
                      alt={airlineDetails.Company_Name}
                      width={25}
                    />
                    <label htmlFor="airindia" className="font-bold text-sm">
                      {airlineDetails.Company_Name}
                    </label>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterComponent;
