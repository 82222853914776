import React from "react";
import CitySearch from "../../inputs/CitySearch.component";
import { AirportListI } from "../../../types/flightSearchReducer.types";

interface CityFieldProp {
  InputRef: any;
  onFormChangeHandler: (e: any) => void;
  fromField: AirportListI;
  handleChange: (
    destinationType: "from" | "to",
    e: React.MouseEvent<HTMLDivElement>
  ) => void;
  fromFieldDropdown: boolean;
  onChangeOriginField: (e: AirportListI) => void;
  toField: AirportListI;
  toFieldDropdown: boolean;
  onChangeDestinationField: (e: any) => void;
}

const CityFieldInputComponent: React.FC<CityFieldProp> = ({
  InputRef,
  onFormChangeHandler,
  fromField,
  handleChange,
  fromFieldDropdown,
  onChangeOriginField,
  toField,
  toFieldDropdown,
  onChangeDestinationField,
}) => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* <input
        type="text"
        hidden
        ref={InputRef}
        onChange={onFormChangeHandler}
        value={fromField.IATA}
      /> */}
      <div
        className="w-full lg:w-[17rem] h-full md:rounded-l-xl border-[2px] cursor-pointer flex flex-col items-start pl-4 justify-center py-1 hover:bg-red-100 duration-200 md:border-r-0 border-b-0 lg:border-b-[2px]"
        onClick={(e) => handleChange("from", e)}
      >
        <p className="mb-3 text-sm">From</p>
        <h2 className="text-xl font-bold w-[12rem] whitespace-nowrap text-ellipsis overflow-hidden text-gray-700">
          {fromField.IATA ? fromField.IATA : "Select"}
        </h2>
        <p className="text-xs md:text-sm font-semibold w-[12rem] whitespace-nowrap text-ellipsis overflow-hidden text-gray-500">
          {fromField.airportName
            ? fromField.airportName + ", " + fromField.Location
            : "select"}
        </p>
      </div>
      {fromFieldDropdown && (
        <CitySearch classCss="left-[1rem]" fields={onChangeOriginField} />
      )}
      <div
        className="w-full md:rounded-r-xl lg:rounded-none lg:w-[17rem] h-full border-[2px] cursor-pointer flex flex-col items-start pl-4 md:pl-8 justify-center py-1 hover:bg-red-100 duration-200 lg:border-r-0 border-b-0 lg:border-b-[2px]"
        onClick={(e) => handleChange("to", e)}
      >
        <p className="mb-3 text-xs md:text-sm">To</p>
        <h2 className="text-xl w-[12rem] font-bold whitespace-nowrap text-ellipsis overflow-hidden text-gray-700">
          {toField.IATA ? toField.IATA : "Select"}
        </h2>
        <p className="text-xs md:text-sm font-semibold w-[12rem] whitespace-nowrap text-ellipsis overflow-hidden gap-1 text-gray-500">
          {toField.airportName
            ? toField.airportName + ", " + toField.Location
            : "Select"}
        </p>
      </div>
      {toFieldDropdown && (
        <CitySearch
          classCss="left-[1rem] top-[37%] md:top-[16%] lg:top-[37%] md:left-[53%] lg:left-[18rem]"
          fields={onChangeDestinationField}
        />
      )}
    </div>
  );
};

export default CityFieldInputComponent;
