import React from "react";
import { Outlet } from "react-router-dom";
import Navbarcomponent from "../components/Navbar.component";
import { Show } from "../utils/Show";
import FooterComponent from "../components/Footer.component";
import ContactButtons from "../components/Buttons/GlobalContactBtn";

const Homelayout = () => {
  const show = Show();

  return (
    <>
      {show && <Navbarcomponent />}
      <Outlet />
      <ContactButtons />
      <FooterComponent />
    </>
  );
};

export default Homelayout;
