import React from "react";
import { NO_DATA } from "../../utils/images";
import { AirlineFilterFunction, formatTime } from "../../utils/ExtraFunction";

interface SegmentCardProp {
  index: number;
  _segment: any;
  baggageWeight: any;
  Citys: any;
}

const SegmentCard: React.FC<SegmentCardProp> = ({
  index,
  _segment,
  baggageWeight,
  Citys,
}) => {
  return (
    <div
      key={index}
      className="w-[95%] h-full my-4 rounded-md border-[1px] shadow-md p-1"
    >
      <div>
        <div className="flex items-center text-sm md:text-base h-fit md:h-[2rem] font-bold px-2 border-b-[1px] border-black">
          {_segment.departure
            ? Citys.filter((obj: any) => obj.IATA === _segment.departure)
                .length > 0
              ? Citys.filter((obj: any) => obj.IATA === _segment.departure)[0]
                  .Location
              : _segment.departure
            : `New Delhi, India`}{" "}
          To{" "}
          {_segment.departure
            ? Citys.filter((obj: any) => obj.IATA === _segment.arrival).length >
              0
              ? Citys.filter((obj: any) => obj.IATA === _segment.arrival)[0]
                  .Location
              : _segment.arrival
            : `New Delhi, India`}
        </div>
        <div className="my-3 px-2">
          <div className="flex w-full items-center gap-1">
            <div
              className="bg-cover bg-no-repeat bg-center w-[2rem] md:w-[2rem] h-[2rem] md:h-[2rem]"
              style={{
                backgroundImage: `url(${
                  _segment
                    ? AirlineFilterFunction(_segment.airline)
                      ? AirlineFilterFunction(_segment.airline).image_link
                      : NO_DATA
                    : NO_DATA
                })`,
              }}
            ></div>
            <h2 className=" text-sm md:text-base font-bold">
              {_segment
                ? AirlineFilterFunction(_segment.airline)
                  ? AirlineFilterFunction(_segment.airline).Company_Name
                  : _segment.airline
                : "IndiGo"}
            </h2>
            <p className=" text-sm md:text-base font-semibold">
              {_segment.airline
                ? AirlineFilterFunction(_segment.airline)
                  ? AirlineFilterFunction(_segment.airline).AirLine_code
                  : _segment.airline
                : "6E"}{" "}
              | {_segment.flightNum ? _segment.flightNum : 6114}
            </p>
          </div>
          <div className="flex flex-col md:flex-row items-start mt-4 w-full gap-2 md:gap-0 mb-2">
            <div className="flex items-start w-full md:w-[60%] justify-between">
              <div className="flex flex-col w-[40%] gap-3">
                <div>
                  <span className="text-sm md:text-base font-bold">
                    {_segment.strDepartureTime
                      ? _segment.strDepartureTime
                      : "22:15"}
                  </span>
                  <p className="text-xs md:text-sm font-bold">
                    {_segment.strDepartureDate
                      ? _segment.strDepartureDate
                      : "Sat, 31 Aug 24"}
                  </p>
                </div>
                <div>
                  <p className="text-sm">
                    {_segment.departureTerminal
                      ? `Terminal ${_segment.departureTerminal}`
                      : "No Information"}
                  </p>
                  <p className="text-sm">
                    {_segment.departure
                      ? Citys.filter(
                          (obj: any) => obj.IATA === _segment.departure
                        )[0].Location
                      : `New Delhi, India`}
                  </p>
                </div>
              </div>
              <div className="w-[20%] -ml-[4rem] md:-ml-[8rem] flex flex-col items-center">
                <h2 className="text-xs lg:text-base font-bold">
                  {_segment.flightTime
                    ? formatTime(_segment.flightTime)
                    : "02h 10m"}
                </h2>
                <hr className="w-[5rem] h-[5px] rounded-full bg-[#ec2027]"></hr>
              </div>
              <div className="flex flex-col w-[40%] gap-3">
                <div>
                  <span className="text-sm md:text-base font-bold">
                    {_segment.strArrivalTime
                      ? _segment.strArrivalTime
                      : "00:25"}
                  </span>
                  <p className="text-xs md:text-sm font-bold">
                    {_segment.strArrivalDate
                      ? _segment.strArrivalDate
                      : "Sun, 1 sep 24"}
                  </p>
                </div>
                <div>
                  <p className="text-sm">
                    {_segment.arrivalTerminal
                      ? `Terminal ${_segment.arrivalTerminal}`
                      : `No Information`}
                  </p>
                  <p className="text-sm">
                    {_segment.arrival
                      ? Citys.filter(
                          (obj: any) => obj.IATA === _segment.arrival
                        ).length > 0
                        ? Citys.filter(
                            (obj: any) => obj.IATA === _segment.arrival
                          )[0].Location
                        : _segment.arrival
                      : `New Delhi, India`}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-[40%] lg:w-[40%] flex flex-col items-start justify-start pl-2">
              <div className="flex items-start justify-start gap-2">
                <div className="text-xs md:text-sm lg:text-base font-bold uppercase">
                  Check-in Baggage:
                </div>
                <div className="text-xs md:text-sm lg:text-base font-bold uppercase">
                  {baggageWeight[index] && baggageWeight[index].BaggageW
                    ? baggageWeight[index].BaggageW
                    : baggageWeight[0].BaggageW
                    ? baggageWeight[0].BaggageW
                    : "No Information"}
                </div>
              </div>
              <div className="flex items-start justify-start gap-2">
                <div className="text-xs md:text-sm lg:text-base font-bold uppercase">
                  Cabin Baggage:
                </div>
                <div className="text-xs md:text-sm lg:text-base font-bold uppercase">
                  {baggageWeight[index] && baggageWeight[index].CarryW
                    ? baggageWeight[index].CarryW
                    : baggageWeight[0].CarryW
                    ? baggageWeight[0].CarryW
                    : "No Information"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentCard;
