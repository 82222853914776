import { Link } from "react-router-dom";
import { contactInfo } from "../../dummydata/contactInfo";
import { CustomerSupport } from "../../utils/images";
import { IoCall } from "../../utils/solidIcons";

const CallBtn: React.FC<{}> = () => {
  return (
    <div className="bg-gray-900 text-white border border-[#ec2027] p-1 flex items-center justify-between rounded-full">
      {/* Contact Section */}
      <div className="flex items-center justify-between space-x-2">
        <div className="relative">
          <img
            src={CustomerSupport} // Replace with the actual path of the agent image
            alt="customer support agent"
            className="w-10 h-10 rounded-full border-2 border-white"
          />
          <IoCall className="absolute text-2xl text-[#ec2027] -bottom-2 -left-2 animate-ringWithPause" />
        </div>
        <p className="flex text-xs sm:text-sm font-semibold">
          <span className="hidden sm:block">Book Now - </span>
          <span className="">Call us 24/7</span>
        </p>
        <Link
          to={`tel:${contactInfo.mobileNum}`}
          className=" text-[#ec2027] py-1 text-xs rounded-full md:text-base font-semibold hover:text-white px-2"
        >
          {contactInfo.mobileNum}
        </Link>
      </div>
    </div>
  );
};

export default CallBtn;
