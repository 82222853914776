import React, { useEffect, useState } from "react";
import { Plane2 } from "../utils/icons";
import { IoIosPerson } from "react-icons/io";
import { HiViewList } from "react-icons/hi";
import { CiCreditCard1 } from "react-icons/ci";
import { IoIosMail } from "react-icons/io";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import Userform from "../components/Form/User.form";
// import PopUpComponent from "../components/FlightDetails.component/PopUp.component";
import PaymentInfoform, { Payment } from "../components/Form/PaymentInfo.form";
import CardBillAddressForm, {
  AddressInfo,
} from "../components/Form/CardBillAddress.form";
import ContactInfoForm, {
  ContactInfo,
} from "../components/Form/ContactInfo.form";
import LoadingComponent from "../components/Loading.component";
import SegmentCard from "../components/Cards/Segment.card";
import DisclamerCard from "../components/Cards/Disclamer.card";
import FlightDetailsPriceCard from "../components/Cards/FlightDetailsPrice.Card";
import { useSelector } from "react-redux";
import { transformedData } from "../utils/flightSearchResponseReStructure";
import { useAppDispatch } from "../store";
import {
  fetchFlightAncillaryPricing,
  fetchFlightOrderDetails,
  fetchFlightPreciseBooking,
  fetchFlightPrecisePricing,
  fetchFlightTicket,
  setFlightSearchParams,
  updateFlightSearchParams,
} from "../store/reducers/flightSearch.reducer";
import {
  FlightAncillaryPricingParamsI,
  FlightBookingRequestParamsI,
  PassengerI,
  selectedAncillaryObjectI,
} from "../types/flightSearchReducer.types";
import NoDataFoundComponent from "../components/NoDataFound.component";
import { reStructureFlightBookingRequest } from "../utils/reStructureFlightBookingRequest";
import { addUser } from "../store/reducers/flightSearch.reducer";
// import AncillaryTableComponent from "../components/FlightDetails.component/AncillaryTable.component";
import AncillaryBookingDetails from "../components/FlightDetails.component/AncillaryBookingDetails.component";
import {
  AddUserDetails,
  generateBookingNumber,
  validateData,
} from "../utils/ExtraFunction";
import { notifyError, notifySuccess } from "../utils/notifications";
import { FaCreditCard } from "../utils/solidIcons";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet-async";
import { airportList } from "../utils/airportList";

const ReviewPage = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const state = useSelector((state: any) => state.flights);
  // const flightBookingData = useSelector(
  //   (state: any) => state.flights.flightBookingData
  // );
  // const ancillaryPricingData = useSelector(
  //   (state: any) => state.flights.ancillaryPricingData
  // );
  // const ancillaryBookingData = useSelector(
  //   (state: any) => state.flights.ancillaryBookingData
  // );
  // const bookedFlightTicketData = useSelector(
  //   (state: any) => state.flights.bookedFlightTicketData
  // );
  // const flightOrderDetailsData = useSelector(
  //   (state: any) => state.flights.flightOrderDetailsData
  // );

  // const ancillaryPricingFetchStatus = useSelector(
  //   (state: any) => state.flights.ancillaryPricingFetchStatus
  // );
  // const ancillaryBookingFetchStatus = useSelector(
  //   (state: any) => state.flights.ancillaryBookingFetchStatus
  // );
  // const flightBookingDataFetchStatus = useSelector(
  //   (state: any) => state.flights.flightBookingDataFetchStatus
  // );
  // const flightTicketFetchStatus = useSelector(
  //   (state: any) => state.flights.flightTicketFetchStatus
  // );
  // const flightOrderDetailsFetchStatus = useSelector(
  //   (state: any) => state.flights.flightOrderDetailsFetchStatus
  // );

  const dispatch = useAppDispatch();

  // #GET Data in local Storage
  // const precisePricing = localStorage.getItem("precisePricing");

  // const Data = precisePricing ? JSON.parse(precisePricing) : null;

  // useEffect(() => {
  //   if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
  //     dispatch(fetchFlightPrecisePricing({ ...Data, key: "pricing" }));
  //   }
  // }, []);

  const baggageWeight: { BaggageW: string; CarryW: string }[] = [];
  const segments1: any[] = [];
  const segments2: any[] = [];
  const [popUpOn, setPopUpOn] = useState(false);
  const [ancillaryKey, setAncillaryKey] = useState<any>();
  const Ancillaries: any = [];
  const [ancillaryPrice, setAncillaryPrice] = useState<number>();
  const [userBasicinfo, setUserBasicInfo] = useState<PassengerI[]>([]);
  //TODO: Add payment info if needed
  const [paymentInfo, setPaymentInfo] = useState<Record<string, any>>({});
  const [address, setAddress] = useState<Record<string, any>>({});
  const [contact, setContact] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  // const [ancillary, setAncillary] = useState<selectedAncillaryObjectI[]>([]);
  // const AncillaryObject: any = [];

  // const updateOtherDetails = (paymentInfo: any, address: any, contact: any) => {
  //   if (userBasicinfo) {
  //     let userArr = [...userBasicinfo];
  //     const Passenger1 = {
  //       ...userBasicinfo[0],
  //       cardNum: paymentInfo.cardNum,
  //       cardType: paymentInfo.cardType,
  //       cardExpiredDate: paymentInfo.cardExpiredDate,
  //       cvv: paymentInfo.cvv,
  //       billingPhone: contact.mobile,
  //       email: contact.email,
  //       country: address.country,
  //       state: address.state,
  //       address: address.Address,
  //       city: address.city,
  //       zip: address.zip,
  //     };
  //     userArr[0] = Passenger1;

  //     return userArr;
  //   }
  // };

  // const ancillaryRequestObject = (ancillary: any) => {
  //   ancillary.forEach((selectedAncillary: any) => {
  //     const ancillaryItem = {
  //       ancillaryPassenger: {
  //         firstName: selectedAncillary.name.split(" ")[0],
  //         lastName: selectedAncillary.name.split(" ")[1],
  //         psgType: selectedAncillary.psgType,
  //       },
  //       ancillaries: {
  //         baggage: [
  //           {
  //             ancillaryKey: selectedAncillary.ancillaryKey,
  //             payBaggageType: selectedAncillary.payBaggageType,
  //             baggagePiece: selectedAncillary.baggagePiece,
  //             baggageWeight: selectedAncillary.baggageWeight,
  //             baggageCurrency: selectedAncillary.baggageCurrency,
  //             baggagePrice: selectedAncillary.baggagePrice,
  //             ancillaryJourneys: selectedAncillary.ancillaryJourneys,
  //           },
  //         ],
  //       },
  //     };
  //     AncillaryObject.push(ancillaryItem);
  //   });
  // };

  // const checkPrevSetAncillary = (e: any) => {
  //   if (
  //     ancillary.some(
  //       (item: any) =>
  //         `${item.ancillaryPassenger.firstName} ${item.ancillaryPassenger.lastName}` ===
  //         e.name
  //     )
  //   ) {
  //     setAncillary((prev: any) => ({
  //       ...prev.filter(
  //         (anciPassenger: any) => anciPassenger.name === e.name
  //       )[0],
  //       baggages: [
  //         ...prev
  //           .filter((anciPassenger: any) => anciPassenger.name === e.name)[0]
  //           .baggages.flat(1),
  //         e.key,
  //       ],
  //     }));
  //   } else {
  //     setAncillary((prev: any) => [
  //       ...prev,
  //       {
  //         ancillaryPassenger: {
  //           firstName: e.name.split(" ")[0],
  //           lastName: e.name.split(" ")[1],
  //           psgType: e.psgType,
  //         },
  //         baggages: [e.key],
  //       },
  //     ]);
  //   }
  // };

  if (state.flightPrecisePricingSearchStatus === "succeeded") {
    state.flightPrecisePricingData.data.data.solution.journeys.journey_0.map(
      (item: any) =>
        state.flightPrecisePricingData.data.data.flights
          .filter((flight: any) => flight.flightId === item)
          .map((flight: any) =>
            flight.segmentIds.map((segId: any) =>
              state.flightPrecisePricingData.data.data.segments
                .filter((segment: any) => segment.segmentId === segId)
                .map((segment: any) => segments1.push(segment))
            )
          )
    );

    state.flightPrecisePricingData.data.data.solution.baggageMap.ADT.map(
      (item: any) =>
        baggageWeight.push({
          BaggageW: item.baggageWeight,
          CarryW: item.carryOnWeight,
        })
    );
  }

  if (state.flightPrecisePricingSearchStatus === "succeeded") {
    if (state.flightPrecisePricingData.data.data.solution.journeys.journey_1) {
      state.flightPrecisePricingData.data.data.solution.journeys.journey_1.map(
        (item: any) =>
          state.flightPrecisePricingData.data.data.flights
            .filter((flight: any) => flight.flightId === item)
            .map((flight: any) =>
              flight.segmentIds.map((segId: any) =>
                state.flightPrecisePricingData.data.data.segments
                  .filter((segment: any) => segment.segmentId === segId)
                  .map((segment: any) => segments2.push(segment))
              )
            )
      );
    }
  }

  if (ancillaryKey) {
    Ancillaries.push(ancillaryKey);
  }

  // const GetAncillaryPrice = (price: number) => {
  //   setAncillaryPrice(price);
  // };

  const UserBasicForm = (obj: PassengerI) => {
    setUserBasicInfo((prev: any[]) => {
      // Check if obj already exists to avoid unnecessary state updates
      if (!prev.some((item) => item.passengerIndex === obj.passengerIndex)) {
        return [...prev, obj];
      } else {
        const existingItem = prev.find(
          (item) => item.passengerIndex === obj.passengerIndex
        );

        // Check if the found item has different properties than the new object
        if (
          existingItem &&
          JSON.stringify(existingItem) !== JSON.stringify(obj)
        ) {
          const index = prev.findIndex(
            (item) => item.passengerIndex === obj.passengerIndex
          );

          if (index !== -1) {
            // Create a new array with the updated object, maintaining immutability
            const updatedArray = [...prev];
            updatedArray[index] = { ...obj };
            return updatedArray;
          }
        }
      }
      return prev;
    });
  };

  const handleClick = async () => {
    // if (state.passengersDetails.length === 0) {
    //   dispatch(addUser(updateOtherDetails(paymentInfo, contact, address)));
    // }
    // setIsLoading(true);
    // ancillaryRequestObject(ancillary);
    // //TODO: Add payment info in passenger key if needed
    // const flightBookingRequestParams: FlightBookingRequestParamsI = {
    //   passengers: userBasicinfo,
    //   ancillary: state.flightAncillaryDetails,
    //   solution: reStructureFlightBookingRequest(
    //     state.flightPrecisePricingData.data.data
    //   ),
    //   key: "booking",
    // };
    // dispatch(fetchFlightPreciseBooking(flightBookingRequestParams));

    const results = validateData(contact, address, paymentInfo, userBasicinfo);
    if (results.length !== 0) {
      results.forEach((value: any) => {
        notifyError(value);
      });
      return;
    }
    const message = await AddUserDetails(
      userBasicinfo,
      paymentInfo,
      address,
      contact,
      state.searchParams
    );
    const userTravelData = {
      bookingNum: generateBookingNumber(),
      ticketIssuedDate: Date.now(),
      userBasicinfo,
      paymentInfo,
      address,
      contact,
      searchParams: state.searchParams,
      pricingData: JSON.parse(atob(`${params.get("id")}`)),
      flightData:
        state.flightPrecisePricingSearchStatus === "succeeded"
          ? state.flightPrecisePricingData.data.data
          : {},
    };
    notifySuccess(message);
    Navigate(`/ticket/details?id=${btoa(JSON.stringify(userTravelData))}`);
  };

  // useEffect(() => {
  //   if (flightBookingDataFetchStatus === "succeeded") {
  //     dispatch(
  //       fetchFlightTicket({
  //         key: "ticketing",
  //         email: `${contact.retypeEmail}`,
  //         telNum: `${contact.billingPhone}`,
  //         name: `${userBasicinfo[0].firstName}/${userBasicinfo[0].lastName}`,
  //         PNR: flightBookingData.data.data.pnr,
  //         orderNum: flightBookingData.data.data.orderNum,
  //       })
  //     );
  //   }
  // }, [flightBookingDataFetchStatus]);

  // useEffect(() => {
  //   if (flightTicketFetchStatus === "succeeded") {
  //     dispatch(
  //       fetchFlightOrderDetails({
  //         key: "data",
  //         orderNum: bookedFlightTicketData.data.data.orderNum,
  //         includeFields: "passengers,journeys,solutions,ancillary,checkinInfo",
  //       })
  //     );
  //   }
  // }, [flightTicketFetchStatus]);

  // useEffect(() => {
  //   if (flightOrderDetailsFetchStatus === "succeeded") {
  //     Navigate("/ticketdetails");
  //   }
  // }, [flightOrderDetailsFetchStatus]);

  useEffect(() => {
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      const { adults, children, infants, journeys } = JSON.parse(
        atob(`${params.get("id")}`)
      );
      const tripType = Object.keys(journeys).length;
      if (tripType === 1) {
        dispatch(
          updateFlightSearchParams({
            destination:
              journeys.journey_0[journeys.journey_0.length - 1].arrival,
            origin: journeys.journey_0[0].departure,
            departureDate: journeys.journey_0[0].departureDate,
            totalTravelers: adults + children + infants,
            adults,
            children,
            infants,
            tripType: "oneway",
            cabinClass: journeys.journey_0[0].cabinClass,
          })
        );
      } else if (tripType === 2) {
        dispatch(
          updateFlightSearchParams({
            destination: journeys.journey_1[0].departure,
            origin: journeys.journey_0[0].departure,
            departureDate: journeys.journey_0[0].departureDate,
            returnDate: journeys.journey_1[0].departureDate,
            totalTravelers: adults + children + infants,
            adults,
            children,
            infants,
            tripType: "roundtrip",
            cabinClass: journeys.journey_0[0].cabinClass,
          })
        );
      }
    }
    dispatch(
      fetchFlightPrecisePricing({
        ...JSON.parse(atob(`${params.get("id")}`)),
        key: "pricing",
      })
    );
  }, [location.search]);

  if (isLoading) {
    return (
      <div className="w-full h-[100vh] bg-white text-white absolute z-[49] flex items-center justify-center">
        <LoadingComponent />
      </div>
    );
  }

  // const onAddAncillaryClickHandler = () => {
  //   dispatch(addUser(updateOtherDetails(paymentInfo, address, contact)));
  //   if (state.flightPrecisePricingSearchStatus === "succeeded") {
  //     setPopUpOn(() => !popUpOn);
  //     const ancillarySearchRequestParams: FlightAncillaryPricingParamsI =
  //       transformedData(state.flightPrecisePricingData.data.data);
  //     dispatch(
  //       fetchFlightAncillaryPricing({
  //         ...ancillarySearchRequestParams,
  //         key: "pricing",
  //       })
  //     );
  //   }
  // };
  return (
    <>
      <Helmet>
        <title>Flight Details</title>
        <meta name="description" content="flight details" />
        <meta name="keywords" content="flight,booking,contact us" />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta
          property="og:description"
          content="contact us for flight booking"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <div className=" w-full relative pt-[5rem] flex flex-col lg:flex-row justify-center">
        <div className=" w-full lg:absolute left-14 lg:w-[65vw] h-full rounded-md px-2">
          {/**flight details  */}

          <div className=" w-full flex flex-col rounded-md border-[1px] items-center shadow-md h-fit">
            <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 gap-4 h-[3rem] rounded-t-md">
              <img src={Plane2} alt="logo" className="h-[2.5rem] md:h-[2rem]" />
              <h2 className="text-lg font-medium text-white">Flight Details</h2>
            </div>
            {state.flightPrecisePricingSearchStatus === "loading" && (
              <LoadingComponent />
            )}
            {state.flightPrecisePricingSearchStatus === "failed" && (
              // <NoDataFoundComponent />
              <p>
                The Flight You have selected has 0 seats left.Please choose
                other flight
              </p>
            )}
            {segments1.map((_segment: any, index: number) => (
              <SegmentCard
                key={index}
                _segment={_segment}
                index={index}
                baggageWeight={baggageWeight}
                Citys={airportList}
              />
            ))}
            {segments2.map((_segment: any, index: number) => (
              <SegmentCard
                key={index}
                _segment={_segment}
                index={index}
                baggageWeight={baggageWeight}
                Citys={airportList}
              />
            ))}
          </div>

          {/*********travelers Details********* */}

          <div className=" w-full my-4 h-fit rounded-md border-[1px] shadow-md">
            <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] rounded-t-md gap-x-4">
              <IoIosPerson className="text-2xl text-white" />
              <h2 className="text-lg font-medium text-white">
                Traveler Details
              </h2>
            </div>
            {state.flightPrecisePricingSearchStatus === "succeeded" && (
              <div className="w-full p-2">
                <h3 className="uppercase text-sm font-bold text-[#c81212]">
                  Adults
                </h3>
                {Array.from({
                  length:
                    state.flightPrecisePricingData.data.data.solution.adults,
                }).map((_, index) => (
                  <Userform
                    key={index}
                    passengerId={index}
                    PsgType={"ADT"}
                    getForm={(obj: PassengerI) => UserBasicForm(obj)}
                  />
                ))}
              </div>
            )}
            {state.flightPrecisePricingSearchStatus === "succeeded" &&
            state.flightPrecisePricingData.data.data.solution.children ? (
              <div className=" w-full p-2 my-4">
                <h3 className="uppercase text-sm font-bold text-[#c81212]">
                  Children
                </h3>
                {Array.from({
                  length:
                    state.flightPrecisePricingData.data.data.solution.children,
                }).map((_, index) => (
                  <Userform
                    key={index}
                    passengerId={
                      state.flightPrecisePricingData.data.data.solution.adults +
                      index
                    }
                    PsgType={"CHD"}
                    getForm={(obj: PassengerI) => UserBasicForm(obj)}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
            {state.flightPrecisePricingSearchStatus === "succeeded" &&
            state.flightPrecisePricingData.data.data.solution.infants ? (
              <div className="w-full p-2 my-4">
                <h3 className="uppercase text-sm font-bold text-[#c81212]">
                  Infant
                </h3>
                {Array.from({
                  length:
                    state.flightPrecisePricingData.data.data.solution.infants,
                }).map((_, index) => (
                  <Userform
                    passengerId={
                      state.flightPrecisePricingData.data.data.solution.adults +
                      state.flightPrecisePricingData.data.data.solution
                        .children +
                      index
                    }
                    key={index}
                    PsgType={"INF"}
                    adultsLenght={
                      state.flightPrecisePricingData.data.data.solution.adults
                    }
                    getForm={(obj: PassengerI) => UserBasicForm(obj)}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
            {state.flightPrecisePricingSearchStatus === "succeeded" && (
              <div className="ps-2 -mt-5 mb-2">
                <p className="font-semibold text-sm text-gray-600">
                  Rules require all travelers to provide their full name, date
                  of birth that match exactly with the Passport or Government
                  issued photo ID.
                </p>
              </div>
            )}
          </div>

          {/*Payment Info */}

          <div className=" w-full my-4 h-fit rounded-md border-[1px] shadow-md">
            <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] rounded-t-md gap-x-4">
              <HiViewList className="text-2xl text-white" />
              <h2 className="text-lg font-medium text-white">
                {" "}
                Payment Info (Secure SSL Encrypted Transaction)
              </h2>
            </div>
            {/* Payment info form */}
            <PaymentInfoform getForm={(obj: Payment) => setPaymentInfo(obj)} />
          </div>

          {/*Cart billing & contact information*/}

          <div className=" grid grid-cols-1 items-center gap-x-10">
            <div className="w-full h-fit rounded-md border-[1px] shadow-md">
              <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] rounded-t-md gap-x-4">
                <FaCreditCard className="text-2xl text-white" />
                <h2 className="text-lg font-medium text-white">
                  Card Billing Information
                </h2>
              </div>
              {/* Card Details */}
              <CardBillAddressForm
                getForm={(obj: AddressInfo) => setAddress(obj)}
              />
            </div>
            <div className="w-full h-fit rounded-md border-[1px] shadow-md">
              <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] rounded-t-md gap-x-4">
                <IoIosMail className="text-2xl text-white" />
                <h2 className="text-lg font-medium text-white">
                  Contact Information
                </h2>
              </div>
              {/* Contact info */}
              <ContactInfoForm
                getForm={(obj: ContactInfo) => setContact(obj)}
              />
            </div>
          </div>
          {/* {state.flightPrecisePricingSearchStatus === "succeeded" && (
            <div className="w-full flex justify-end">
              {userBasicinfo.length !== 0 && (
                <button
                  className="w-[10rem] my-4 md:w-[25%] flex items-center justify-center px-2 py-2 bg-[#ec2027] text-white rounded-full text-lg lg:text-xl shadow-md font-semibold"
                  onClick={onAddAncillaryClickHandler}
                >
                  Add Ancillary
                </button>
              )}
            </div>
          )}
          <AncillaryBookingDetails /> */}
          <DisclamerCard />
          <div className="w-full px-3 my-4 h-fit flex flex-col items-center">
            <div className="text-sm font-medium text-gray-500">
              By clicking 'Book Now,' I hereby confirm that I have read and
              accepted the Terms and Conditions and Privacy Policy of Dreams
              Travel and Tours.
            </div>
            <button
              className="w-[10rem] my-4 md:w-[25%] hidden lg:flex items-center justify-center px-2 py-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white rounded-full  shadow-md text-lg font-medium"
              onClick={handleClick}
            >
              Book Now
            </button>
          </div>
        </div>
        {state.flightPrecisePricingSearchStatus === "succeeded" && (
          <FlightDetailsPriceCard
            flightDetails={state.flightPrecisePricingData.data.data}
            ancillaryPrice={ancillaryPrice}
          />
        )}
        <button
          className="w-[10rem] my-4 md:w-[25%] flex lg:hidden items-center justify-center px-2 py-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-full shadow-md text-lg font-medium text-white"
          onClick={handleClick}
        >
          Book Now
        </button>
      </div>
      {/* {popUpOn && (
        <PopUpComponent
          user={userBasicinfo}
          func={() => setPopUpOn(() => !popUpOn)}
          AncillaryKey={(key: any) => setAncillaryKey(key)}
          AncillaryPrice={(price: number) => GetAncillaryPrice(price)}
          Ancillarydata={(e: any) => checkPrevSetAncillary(e)}
        />
      )} */}
    </>
  );
};

export default ReviewPage;
