import { AirportListI } from "../types/flightSearchReducer.types";
import { airportList } from "./airportList";

class TrieNode {
  children: { [key: string]: TrieNode };
  isEndOfWord: boolean;
  data: AirportListI[];
  constructor() {
    this.children = {};
    this.isEndOfWord = false;
    this.data = [];
  }
}

class Trie {
  root: TrieNode;
  constructor() {
    this.root = new TrieNode();
  }

  insert(word: string, data: AirportListI): void {
    let node = this.root;
    for (let char of word) {
      if (!node.children[char]) {
        node.children[char] = new TrieNode();
      }
      node = node.children[char];
      node.data.push(data);
    }
    node.isEndOfWord = true;
  }

  search(prefix: string): AirportListI[] {
    let node = this.root;
    for (let char of prefix) {
      if (!node.children[char]) {
        return [];
      }
      node = node.children[char];
    }
    return node.data;
  }
}

// Initialize Trie and insert data
export const trie = new Trie();

airportList.forEach((airport) => {
  // Insert city and state first for higher priority
  if (airport.Location) {
    trie.insert(airport.Location.toLowerCase(), airport);
  }
  // Insert airport name and code afterwards
  trie.insert(airport.airportName.toLowerCase(), airport);
  trie.insert(airport.IATA.toLowerCase(), airport);
});
