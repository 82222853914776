import React from "react";
import { RouterProvider } from "react-router-dom";
import Router from "./routes/router";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <>
      <HelmetProvider>
        <RouterProvider router={Router} />
      </HelmetProvider>
    </>
  );
}

export default App;
