import React from "react";
import { MapImg } from "../../utils/images";
import Button from "../Buttons/Button";
import { FlightRoutes } from "../../dummydata/flightRouts";

const Routes = () => {
  return (
    <div
      className={` flex flex-wrap w-[90%] md:w-[90%]  md:h-[20rem] lg:h-[16rem] sm:h-[22rem]  h-[45rem] relative rounded-lg`}
    >
      <img
        src={MapImg}
        alt="world map"
        className="flex w-full h-full absolute opacity-50 z-0"
      />
      <div className=" w-full h-full flex flex-wrap justify-center  items-center absolute z-10">
        <div className="w-[90%] sm:w-[100%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4  lg:gap-4 gap-3 md:gap-4">
          {FlightRoutes.map((flightRoute, index) => (
            <Button
              key={index}
              imgSrc={flightRoute.imgSrc}
              departure={flightRoute.departure}
              arrival={flightRoute.arrival}
              departureTitle={flightRoute.departureTitle}
              arrivalTittle={flightRoute.arrivalTittle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Routes;
