import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Homepage from "../pages/Home.page";
import Homelayout from "../layouts/Root.layout";
import SearchLayout from "../layouts/Search.layout";
import FlightSearchPage from "../pages/FlightSearch.page";
import ReviewLayout from "../layouts/Review,layout";
import ReviewPage from "../pages/FlightDetails.page";
import TicketDetailsPage from "../pages/TicketDetails.page";
// import TrainsPage from "../pages/Trains.page";
import NotFoundPage from "../pages/NotFound.page";
import AboutUs from "../pages/About.page";
import Career from "../pages/Career.page";
import ContactUs from "../pages/ContactUS";
import Disclaimer from "../pages/Disclaimer.page";
import RefundPolicy from "../pages/RefundPolicies.page";
import Privacy from "../pages/Privacy.page";
import HowWeWork from "../pages/HowWeWork.page";
import TermsAndConditions from "../pages/TermsAndConditions";
import TopFlightDestinations from "../pages/TopFlightsDestinations.page";
import TopAirlines from "../pages/TopAirlines.page";
import TopAirlinesRoutes from "../pages/TopAirlinesRoute";
import TopFlightRoutes from "../pages/TopFlightsRoutes";
// import Holidays from "../pages/Holidays.page";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Homelayout />,
    children: [
      {
        path: "",
        element: <Homepage />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/career",
        element: <Career />,
      },
      {
        path: "/blog",
        element: <AboutUs />,
      },
      {
        path: "/how-we-work",
        element: <HowWeWork />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "/disclaimer",
        element: <Disclaimer />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/top-flights-destinations",
        element: <TopFlightRoutes />,
      },
      {
        path: "/top-flights-destinations/:id/:url",
        element: <TopFlightDestinations />,
      },
      {
        path: "/top-airlines/",
        element: <TopAirlinesRoutes />,
      },
      {
        path: "/top-airlines/:id/:url",
        element: <TopAirlines />,
      },
    ],
  },
  {
    path: "/flight/search",
    element: <SearchLayout />,
    children: [
      {
        path: "",
        element: <FlightSearchPage />,
      },
    ],
  },
  {
    path: "/flight/details",
    element: <ReviewLayout />,
    children: [
      {
        path: "",
        element: <ReviewPage />,
      },
    ],
  },
  {
    path: "/ticket/details",
    element: <ReviewLayout />,
    children: [
      {
        path: "",
        element: <TicketDetailsPage />,
      },
    ],
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
]);

export default Router;
