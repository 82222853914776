import React, { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PassengerDetailsComponent from "../components/TicketDetailsComponet/PassengerDetails.component";
import StatusDetailsComponent from "../components/TicketDetailsComponet/StatusDetails.component";
import { useSelector } from "react-redux";
import TicketPDF from "../components/TicketDetailsComponet/TicketPDF";
import ScrollToTop from "../components/ScrollToTop";

const TicketDetailsPage = () => {
  // const contentRef = useRef<HTMLDivElement | null>(null);
  // const state = useSelector(
  //   (state: any) => state.flights.flightOrderDetailsData
  // );

  // const { airPnr, journeys, passengers } = state.data.data;

  // const handleDownloadPDF = () => {
  //   let content = contentRef.current;

  //   if (content) {
  //     try {
  //       html2canvas(content, { scale: 2 }).then((canvas) => {
  //         const imgData = canvas.toDataURL("image/png");
  //         const pdf = new jsPDF("p", "mm", "a4");

  //         // Calculate width and height to fit the content
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

  //         pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //         pdf.save("E-AirlineTicket.pdf");
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  return (
    <div className="absolute top-20">
      <ScrollToTop />
      <TicketPDF />
    </div>
  );
};

export default TicketDetailsPage;

//<div className="pt-[5rem] w-full h-fit flex flex-col items-center justify-center">
//   <div
//     className="w-[65%] shadow-md border-[1px] rounded-md border-black/10 h-full p-4 mb-2"
//     ref={contentRef}
//   >
//     {/* <div className="shadow-md p-2 border-[1px] border-black/20 rounded-md flex flex-col items-center justify-center">
//       <h2 className="w-full p-2 bg-[#ec2027] rounded-md text-white font-bold text-xl">
//         PNR Details
//       </h2> */}
//     {/* {journeys.map((journey: any, index: number) => (
//       <PNRComponent
//         index={index}
//         key={index}
//         Pnr={airPnr}
//         journey={journey}
//         />
//       ))} */}
//     {/* </div> */}
//     <PassengerDetailsComponent passengers={passengers} />
//     {/* <div className="shadow-md p-2 border-[1px] border-black/20 rounded-md flex flex-col items-center justify-center">
//       <h2 className="w-full p-2 bg-[#ec2027] rounded-md text-white font-bold text-xl">
//         Flight Ticket Details
//       </h2> */}
//     {journeys.map((journey: any, index: number) => (
//       <StatusDetailsComponent
//         key={index}
//         index={index}
//         journey={journey}
//         pnr={airPnr}
//       />
//     ))}
//     {/* </div> */}
//     {/* {journeys.map((journey: any, index: number) => (
//       <AncillaryDetailsComponent
//         journey={journey}
//         index={index}
//         key={index}
//       />
//     ))} */}
//   </div>
//   <button
//     className={` rounded-full shadow-md z-30 w-[12rem] h-[3rem] flex items-center justify-center bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-bold py-2 mb-3`}
//     onClick={handleDownloadPDF}
//   >
//     Download PDF
//   </button>
// </div>
