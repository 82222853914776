import React, { useState } from "react";
import { Call, DTT_LOGO, indFlag, Uae, Usa } from "../../utils/icons";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CustomerSupport } from "../../utils/images";
import { IoCall } from "../../utils/solidIcons";
import { contactInfo } from "../../dummydata/contactInfo";

const HeroNav = () => {
  const [isRegionalDropdown, setIsRegionalDropdown] = useState(false);
  const [isIcon, setIsIcon] = useState(false);

  const Icon = isRegionalDropdown ? FaAngleUp : FaAngleDown;

  return (
    <div className="w-full xl:w-[80vw] mx-auto px-1 md:px-[4rem] py-1 h-[4rem] flex items-center justify-between">
      <Link to={"/"}>
        <img src={DTT_LOGO} alt="DDT" className="h-[3.5rem] w-[3.5rem]" />
      </Link>
      <div className="lg:flex items-center gap-3 hidden">
        <div className="bg-gray-900/50 text-white border border-[#ec2027] p-1 flex items-center justify-between rounded-full">
          {/* Contact Section */}
          <div className="flex items-center space-x-2">
            <div className="relative">
              <img
                src={CustomerSupport} // Replace with the actual path of the agent image
                alt="customer support agent"
                className="w-10 h-10 rounded-full border-2 border-white"
              />
              <IoCall className="absolute text-2xl text-[#ec2027] -bottom-2 -left-2 animate-ringWithPause" />
            </div>
            <span className="text-sm font-semibold">
              Book Now - Call us 24/7
            </span>
            <a
              href={`tel:${contactInfo.mobileNum}`}
              className="px-3 py-1 rounded-full text-base font-semibold text-white"
            >
              {contactInfo.mobileNum}
            </a>
          </div>
        </div>
        {/* <div className="flex items-center px-2 py-2 md:py-1 border-[1px] bg-black/40 hover:bg-black/80 text-white border-black/10 rounded-md cursor-pointer gap-1">
          <img
            src={Call}
            alt="call"
            className="h-[1rem] md:h-[1.5rem] invert"
          />
          <span className="hidden md:block">Customer Service</span>
          <FaAngleDown />
        </div> */}
        {/* <div
          className="hidden md:flex items-center text-white bg-black/40 hover:bg-black/80 px-2 py-1 gap-2 rounded-md cursor-pointer"
          onClick={() => setIsRegionalDropdown(!isRegionalDropdown)}
        >
          <img src={indFlag} alt="ind" className="h-[1rem]" />
          <div className="flex items-center gap-[2px] font-semibold">
            <span>IN</span>|<span>ENG</span>|<span>INR</span>
          </div>
          <Icon />
        </div> */}
        {/* {isRegionalDropdown && (
          <div className="bg-white z-40 absolute border-[1px] border-black/5 top-[3.5rem] right-[10rem] w-[18rem] h-fit rounded-md shadow-lg transition-all duration-200 px-2 py-3">
            <div
              className="w-full shadow-md h-[3rem] border-[1px] border-black/5 cursor-pointer flex items-center justify-between px-3 py-2 mb-2"
              onClick={() => setIsIcon(!isIcon)}
            >
              <div className="flex items-center gap-2 relative">
                <img src={indFlag} alt="ind" className="h-[1.5rem]" />
                <span className="font-semibold">India</span>
              </div>
              {isIcon ? (
                <FaAngleUp className="text-[#ec2027]" />
              ) : (
                <FaAngleDown className="text-[#ec2027]" />
              )}
            </div>
            {isIcon ? (
              <div className="w-full flex flex-col mb-2">
                <div className="flex items-center w-full p-2 mb-1 gap-3 border-[1px] border-black/10 rounded-md shadow-md">
                  <input
                    type="radio"
                    id="india"
                    name="country"
                    value={"india"}
                  />
                  <label htmlFor="india" className="flex items-center gap-1">
                    <img src={indFlag} alt="ind" className="h-[1.5rem]" />
                    <span className="font-semibold">India</span>
                  </label>
                </div>
                <div className="flex items-center w-full p-2 mb-1 gap-3 border-[1px] border-black/10 rounded-md shadow-md">
                  <input type="radio" id="uae" name="country" value={"uae"} />
                  <label htmlFor="uae" className="flex items-center gap-1">
                    <img src={Uae} alt="uae" className="h-[1.5rem]" />
                    <span className="font-semibold">Uae</span>
                  </label>
                </div>
                <div className="flex items-center w-full p-2 mb-1 gap-3 border-[1px] border-black/10 rounded-md shadow-md">
                  <input type="radio" id="usa" name="country" value={"usa"} />
                  <label htmlFor="usa" className="flex items-center gap-1">
                    <img src={Usa} alt="ind" className="h-[1rem]" />
                    <span className="font-semibold">USA</span>
                  </label>
                </div>
              </div>
            ) : (
              <div className="w-full flex items-start gap-2 mb-2">
                <div className="w-1/2 flex flex-col py-3">
                  <h2 className="text-sm text-gray-500 mb-2">
                    Select Language
                  </h2>
                  <div className="flex items-center gap-1 mb-1">
                    <input
                      type="radio"
                      id="english"
                      name="language"
                      value={"english"}
                    />
                    <label htmlFor="english" className="text-sm font-semibold">
                      English
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      type="radio"
                      id="hindi"
                      name="language"
                      value={"hindi"}
                    />
                    <label htmlFor="hindi" className="text-sm font-semibold">
                      Hindi
                    </label>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col py-3">
                  <h2 className="text-sm text-gray-500 mb-2">
                    Select Currency
                  </h2>
                  <div className="flex items-center gap-1 mb-1">
                    <input type="radio" id="inr" name="inr" value={"inr"} />
                    <label htmlFor="inr" className="text-sm font-semibold">
                      INR
                    </label>
                  </div>
                </div>
              </div>
            )}
            <button className="rounded-full w-full bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-bold py-2">
              Apply
            </button>
          </div>
        )} */}
        {/* <div className="bg-[#ec2027] rounded-md text-white text-sm md:text-base w-[7rem] md:w-[8rem] px-2 py-2 md:py-1 font-semibold leading-4 cursor-pointer hover:bg-red-700">
          Login/Sign up
        </div> */}
      </div>
    </div>
  );
};

export default HeroNav;
