import FlightSearchComponent from "../components/searchComponet/FlightSearch.component";
import { Show } from "../utils/Show";
import TicketDetailAccordin from "../components/Accordion/TicketDetail.accordin";
import { Banner, CallSupport } from "../utils/images";
import FilterComponent from "../components/searchComponet/Filter.component";
import RoundTripAccordin from "../components/Accordion/RoundTrip.accordin";
import { useLocation, useParams } from "react-router-dom";
import LoadingComponent from "../components/Loading.component";
import { useSelector } from "react-redux";
import NoDataFoundComponent from "../components/NoDataFound.component";
import { Cheap, Fast } from "../utils/icons";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../store";
import {
  fetchFlights,
  setFlightSearchParams,
  updateFlightSearchParams,
} from "../store/reducers/flightSearch.reducer";
import { FaSortDown } from "../utils/solidIcons";
import ContactUs from "../components/ContactUs";
import { contactInfo } from "../dummydata/contactInfo";
import { FilterI, FilterOption } from "../types/flightSearchReducer.types";
import ScrollToTop from "../components/ScrollToTop";
import { filteredFlights, filteredSegments } from "../utils/flights.utils";
import ContactBanner from "../components/Banner/ContactBanner";
import CallBtn from "../components/Buttons/CallBtn";
import { Helmet } from "react-helmet-async";

const FlightSearchPage = () => {
  const [sortBy, setSortBy] = useState<string>("");
  const [status, setStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");
  const [isSortCategoriesVisible, setIsCategoriesVisible] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  // const state = useSelector((state: any) => state.flights);
  const [appliedFiltered, setAppliedFiltered] = useState<FilterI[]>([]);
  const [filteredData, setFilteredData] = useState<any>({});
  const [flightListsData, setFlightListData] = useState<any>([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get("origin");
  const to = params.get("destination");
  const depDate = params.get("departure-date");
  const retDate = params.get("return-date");
  const _tripType = params.get("trip-type");
  const _cabinClass = params.get("cabin-class");
  const adults = params.get("adults");
  const children = params.get("children");
  const infants = params.get("infants");

  // useEffect(() => {
  //   dispatch(
  //     setFlightSearchParams({
  //       destination: from,
  //       origin: "",
  //       departureDate: null,
  //       returnDate: null,
  //       totalTravelers: 0,
  //       adults: 1,
  //       children: 0,
  //       infants: 0,
  //       tripType: "roundtrip",
  //       cabinClass: "Economy",
  //     })
  //   );
  // }, []);

  const onSelectSortHandler = (val: string) => {
    setSortBy(() => val);
    setIsCategoriesVisible(() => false);
    console.log("sort by : ", sortBy);
  };

  const onSortCategoriesVisibilityHandler = () => {
    setIsCategoriesVisible(() => !isSortCategoriesVisible);
  };

  const showNav = Show();

  useEffect(() => {
    if (_tripType === "oneway") {
      setStatus("loading");
      const fetchFlightLists = async () => {
        try {
          const response = await fetch(
            process.env.REACT_APP_FlightSearchApiRoute as string,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                search: {
                  adults: +adults!,
                  airline: "",
                  children: +children!,
                  infants: +infants!,
                  searchAirLegs: [
                    {
                      cabinClass: _cabinClass!,
                      departureDate: depDate!,
                      destination: `${to?.split("_")[0]}`,
                      origin: `${from?.split("_")[0]}`,
                    },
                  ],
                },
              }),
            }
          );
          const responseJson = await response.json();
          // Check if data is present and handle error cases
          if (
            !responseJson.success ||
            responseJson.data.errorCode !== "0" ||
            !responseJson.data.data
          ) {
            console.error(
              "Error in fetching data:",
              responseJson.data.data.errorMsg
            );
            setStatus("error");
            return;
          }

          setFlightListData(() => responseJson.data.data);
          setFilteredData(() => responseJson.data.data);

          setStatus("success");
        } catch (error) {
          setStatus("error");
          console.error(`${error}`);
        }
      };
      fetchFlightLists();
      // dispatch(
      //   fetchFlights({
      //     adults: +adults!,
      //     airline: "",
      //     children: +children!,
      //     infants: +infants!,
      //     searchAirLegs: [
      //       {
      //         cabinClass: _cabinClass!,
      //         departureDate: depDate!,
      //         destination: `${to?.split("_")[0]}`,
      //         origin: `${from?.split("_")[0]}`,
      //       },
      //     ],
      //     key: "search",
      //   })
      // );
    } else if (_tripType === "roundtrip") {
      setStatus("loading");
      const fetchFlightLists = async () => {
        try {
          const response = await fetch(
            process.env.REACT_APP_FlightSearchApiRoute as string,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                search: {
                  adults: +adults!,
                  airline: "",
                  children: +children!,
                  infants: +infants!,
                  searchAirLegs: [
                    {
                      cabinClass: _cabinClass!,
                      departureDate: depDate!,
                      destination: `${to?.split("_")[0]}`,
                      origin: `${from?.split("_")[0]}`,
                    },
                    {
                      cabinClass: _cabinClass!,
                      departureDate: retDate!,
                      destination: `${from?.split("_")[0]}`,
                      origin: `${to?.split("_")[0]}`,
                    },
                  ],
                  key: "search",
                },
              }),
            }
          );
          const responseJson = await response.json();
          // console.log(responseJson);

          // Check if data is present and handle error cases
          if (
            !responseJson.success ||
            responseJson.data.errorCode !== "0" ||
            !responseJson.data.data
          ) {
            console.error(
              "Error in fetching data:",
              responseJson.data.data.errorMsg
            );
            setStatus("error");
            return;
          }
          setFlightListData(() => responseJson.data.data);
          setFilteredData(() => responseJson.data.data);
          setStatus("success");
        } catch (error) {
          setStatus("error");
          console.error(`${error}`);
        }
      };
      fetchFlightLists();
      // dispatch(
      //   fetchFlights({
      //     adults: +adults!,
      //     airline: "",
      //     children: +children!,
      //     infants: +infants!,
      //     searchAirLegs: [
      //       {
      //         cabinClass: _cabinClass!,
      //         departureDate: depDate!,
      //         destination: `${to?.split("_")[0]}`,
      //         origin: `${from?.split("_")[0]}`,
      //       },
      //       {
      //         cabinClass: _cabinClass!,
      //         departureDate: retDate!,
      //         destination: `${from?.split("_")[0]}`,
      //         origin: `${to?.split("_")[0]}`,
      //       },
      //     ],
      //     key: "search",
      //   })
      // );
    }
  }, [location.search]);

  // useEffect(() => {
  //   if (state.flightListSearchStatus === "succeeded") {
  //     setFilteredData(() => state.flights.data.data.solutions);
  //   }
  //   console.log(filteredData);
  // }, [state.flightListSearchStatus, location.search]);

  // useEffect(() => {
  //   console.log("flight list :", flightListsData);
  //   console.log("filtered data : ", filteredData);
  //   console.log("flights : ", flightsData);
  //   console.log("segments : ", segmentsData);
  //   console.log("solutions : ", solutionsData);
  // }, [filteredData]);

  const onHandleFlightFilteredData = (filters: any) => {
    setAppliedFiltered(filters);
    setFilteredData(() => flightListsData);
  };

  useEffect(() => {
    if (appliedFiltered.length > 0) {
      appliedFiltered.forEach((filter: FilterI) => {
        if (filter.name === "nonstop") {
          setFilteredData((prevData: any) => {
            let flights = prevData.flights.filter(
              (flight: any) => flight.transferCount === 0
            );
            let flightIds = flights.map((flight: any) => flight.flightId);
            let solutions = prevData.solutions.filter((sol: any) =>
              _tripType === "oneway"
                ? flightIds.includes(`${sol.journeys.journey_0[0]}`)
                : flightIds.includes(`${sol.journeys.journey_0[0]}`) &&
                  flightIds.includes(`${sol.journeys.journey_1[0]}`)
            );
            return { ...prevData, solutions, flights };
          });
        } else if (filter.name === "price_range") {
          setFilteredData((prevData: any) => {
            let solutions = prevData.solutions.filter(
              (sol: any) => +sol.adtFare + +sol.adtTax < +filter.value
            );

            return { ...prevData, solutions };
          });
        } else if (filter.name.startsWith("airline_")) {
          const airlineCodes = filter.name.split("_")[1];
          setFilteredData((prevData: any) => {
            let segments = prevData.segments.filter(
              (segment: any) =>
                segment.airline.toLowerCase() === airlineCodes.toLowerCase()
            );
            let segmentIds = segments.map((segment: any) => segment.segmentId);
            let flights = prevData.flights.filter((flight: any) =>
              flight.segmengtIds.every((segId: string) =>
                segmentIds.includes(segId)
              )
            );
            let flightIds = flights.map((flight: any) => flight.flightId);
            let solutions = prevData.solutions.filter((sol: any) =>
              _tripType === "oneway"
                ? flightIds.includes(`${sol.journeys.journey_0[0]}`)
                : flightIds.includes(`${sol.journeys.journey_0[0]}`) &&
                  flightIds.includes(`${sol.journeys.journey_1[0]}`)
            );
            return { ...prevData, flights, segments, solutions };
          });
        }
      });
    } else {
      setFilteredData(() => flightListsData);
    }
  }, [appliedFiltered]);

  return (
    <>
      <Helmet>
        <title>{`From ${from?.split("_")[1]} to ${to?.split("_")[1]}`}</title>
        <meta
          name="description"
          content={`From ${from?.split("_")[1]} to ${to?.split("_")[1]} - `}
        />
        <meta name="keywords" content="flight,booking,contact us" />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta
          property="og:description"
          content="contact us for flight booking"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <div
        className={
          !showNav
            ? " md:fixed top-[4rem] h-fit py-3 w-full md:flex items-center justify-center z-20 bg-cover bg-center bg-no-repeat"
            : "bg-cover bg-center bg-no-repeat md:fixed transform h-fit py-3 w-full flex items-center justify-center z-20"
        }
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <FlightSearchComponent />
      </div>
      <div className="flex items-start justify-center w-full gap-2 lg:gap-4 h-full min-h-screen bg-[#e5eef4]">
        {status === "loading" && (
          <LoadingComponent classCSS="md:mt-[25rem] lg:mt-[15rem]" />
        )}
        {status === "error" && (
          <div className="w-full md:w-[90%] lg:w-[70%] h-fit p-2 mt-[1rem] md:mt-[35rem] lg:mt-[20rem]">
            <NoDataFoundComponent />
          </div>
        )}
        {status === "success" && (
          <>
            <div className="hidden lg:block w-[25%] h-fit p-2 mt-[17rem] rounded-sm overflow-auto">
              {flightListsData && (
                <FilterComponent
                  flightFilteredData={flightListsData}
                  destination={`${to?.split("_")[0]}`}
                  origin={`${from?.split("_")[0]}`}
                  tripType={`${_tripType}`}
                  handleFlightFilterData={onHandleFlightFilteredData}
                />
              )}
            </div>
            <div className="w-full md:w-[90%] lg:w-[70%] h-fit p-2 mt-[1rem] md:mt-[25rem] lg:mt-[17rem]">
              <p className="text-lg font-bold text-gray-700">
                {status === "success" &&
                  filteredData &&
                  filteredData.solutions &&
                  filteredData.solutions.length}{" "}
                Results Found.
              </p>
              <div className="flex flex-col gap-4">
                <h1 className="flex gap-2 flex-row items-center text-xl font-bold text-[#ec2027]">
                  {`From ${from?.split("_")[1]} to ${to?.split("_")[1]} - `}
                  <span className="text-sm">
                    ({`${new Date(`${depDate}`).toDateString()}`}
                    {_tripType !== "roundtrip" && ")"}
                  </span>
                  {_tripType === "roundtrip" && (
                    <span className="text-sm">
                      {` - ${new Date(`${retDate}`).toDateString()}`})
                    </span>
                  )}
                </h1>
                {/* <div></div> */}
                <p className="text-sm font-medium text-gray-500">
                  <span className="text-sm font-bold text-gray-700">Note:</span>
                  All the fares displayed are in USD, inclusive of base fare,
                  taxes and service fees. Additional baggage fees may apply as
                  per the airline(s) policies. Some of the flights results shown
                  could either be for other dates or nearby airport(s).
                </p>
                <div className=" lg:w-[69vw]">
                  <div className="hidden xs:block">
                    <ContactBanner />
                  </div>
                  <div className=" xs:hidden">
                    <CallBtn />
                  </div>
                </div>
                {/* <div className="flex relative justify-end">
                  <div>
                    <button
                      className="w-fit flex items-center px-4 py-2 text-white bg-gradient-to-l from-[#ec2027] to-[#c81212] text-sm font-medium rounded-md capitalize"
                      onClick={onSortCategoriesVisibilityHandler}
                    >
                      <p>{sortBy ? sortBy : "sort by"}</p>
                      <FaSortDown />
                    </button>
                    {isSortCategoriesVisible && (
                      <div className="w-1/4 right-0 top-full mt-1 rounded-md border cursor-pointer divide-y absolute bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white capitalize">
                        <p
                          className="py-2 px-4 hover:bg-red-400"
                          onClick={() => onSelectSortHandler("by airline")}
                        >
                          By Airline
                        </p>
                        <p
                          className="py-2 px-4 hover:bg-red-400"
                          onClick={() => onSelectSortHandler("by departure")}
                        >
                          By departure
                        </p>
                        <p
                          className="py-2 px-4 hover:bg-red-400"
                          onClick={() => onSelectSortHandler("by arrival")}
                        >
                          By arrival
                        </p>
                        <p
                          className="py-2 px-4 hover:bg-red-400"
                          onClick={() => onSelectSortHandler("by stop")}
                        >
                          By stop
                        </p>
                        <p
                          className="py-2 px-4 hover:bg-red-400"
                          onClick={() => onSelectSortHandler("by price")}
                        >
                          By price
                        </p>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
              {_tripType === "oneway" &&
                filteredData &&
                filteredData.solutions &&
                filteredData.solutions.map((item: any, index: any) => (
                  <TicketDetailAccordin
                    key={index}
                    solutions={item}
                    flights={filteredData.flights}
                    segments={filteredData.segments}
                  />
                ))}

              {_tripType === "roundtrip" &&
                filteredData &&
                filteredData.solutions &&
                filteredData.solutions.map((item: any, index: any) => (
                  <RoundTripAccordin
                    key={index}
                    solution={item}
                    flights={filteredData.flights}
                    segments={filteredData.segments}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FlightSearchPage;
