import { AirportListI } from "../types/flightSearchReducer.types";

export const airportList: AirportListI[] = [
  {
    id: "14d4e944-c0b0-466b-a13c-095cb3e2e24d",
    airportName: "Aarhus",
    Location: "Denmark",
    IATA: "AAR",
  },
  {
    id: "1abd550f-8d10-4b06-93c7-08b3b8b69520",
    airportName: "Abadan",
    Location: "Iran",
    IATA: "ABD",
  },
  {
    id: "86070c16-abb3-4cd6-a731-bdbf12205799",
    airportName: "Abeche",
    Location: "Chad",
    IATA: "AEH",
  },
  {
    id: "4e70d899-dc4f-43d3-bf26-1d749dea78a7",
    airportName: "Aberdeen",
    Location: "United Kingdom",
    IATA: "ABZ",
  },
  {
    id: "3d5a5939-2994-45bb-ac96-ec010394a06e",
    airportName: "Aberdeen,SD",
    Location: "USA",
    IATA: "ABR",
  },
  {
    id: "933df0ee-283d-4f81-bf39-8fcd9e07fd1a",
    airportName: "Abidjan",
    Location: "Ivory Coast",
    IATA: "ABJ",
  },
  {
    id: "2dbbcfd3-f27f-4651-a275-e628627fc238",
    airportName: "Abilene,TX",
    Location: "USA",
    IATA: "ABI",
  },
  {
    id: "4415d9cd-6ea7-465e-b93e-b53207bb14d2",
    airportName: "Abu Dhabi",
    Location: "UAE",
    IATA: "AUH",
  },
  {
    id: "a67517ce-8bdf-4b02-a901-9fa94a88712b",
    airportName: "Abuja International",
    Location: "Nigeria",
    IATA: "ABV",
  },
  {
    id: "1a819c41-70a8-467d-a64e-fa85c11c4d24",
    airportName: "Abu Rudeis",
    Location: "Egypt",
    IATA: "AUE",
  },
  {
    id: "ef1a1df4-1c09-4931-8e2b-6d37643e833c",
    airportName: "Abu Simbel",
    Location: "Egypt",
    IATA: "ABS",
  },
  {
    id: "70c3635a-57f8-41ae-abad-b56e534de09d",
    airportName: "Acapulco",
    Location: "Mexico",
    IATA: "ACA",
  },
  {
    id: "be4a3d03-d4b9-4125-ad09-3134772b89ff",
    airportName: "Accra",
    Location: "Ghana",
    IATA: "ACC",
  },
  {
    id: "5a8cc124-7b2e-4886-a1be-b03367786f1d",
    airportName: "Adana",
    Location: "Turkey",
    IATA: "ADA",
  },
  {
    id: "8fa5bfe4-4584-4267-b11e-8c3069905aaf",
    airportName: "Addis Ababa",
    Location: "Ethiopia",
    IATA: "ADD",
  },
  {
    id: "a33c16f4-9d89-4435-8a8e-396fa1159ac2",
    airportName: "Adelaide",
    Location: "Australia",
    IATA: "ADL",
  },
  {
    id: "af4a4b85-6c9b-4321-a67e-178025566e1c",
    airportName: "Aden",
    Location: "Yemen",
    IATA: "ADE",
  },
  {
    id: "96d2cc68-3e94-4e4f-b163-f47be106e818",
    airportName: "Adler/Sochi",
    Location: "Russia",
    IATA: "AER",
  },
  {
    id: "92907f61-1c65-4821-a182-c6523b6db701",
    airportName: "Agades",
    Location: "Niger",
    IATA: "AJY",
  },
  {
    id: "da70a6e7-1861-4adf-83cf-2ad83dd84a6f",
    airportName: "Agadir",
    Location: "Morocco",
    IATA: "AGA",
  },
  {
    id: "9e5a5199-260e-4290-914a-aa600814b42e",
    airportName: "Agana",
    Location: "Guam",
    IATA: "SUM",
  },
  {
    id: "37b2f7a4-af94-4165-a25b-4048158808be",
    airportName: "Aggeneys",
    Location: "South Africa",
    IATA: "AGZ",
  },
  {
    id: "98ac1f18-87ba-4e9b-9ead-e33ce643b2e3",
    airportName: "Aguadilla",
    Location: "Puerto Rico",
    IATA: "BQN",
  },
  {
    id: "86fc44c5-9ed4-4f93-84af-d942b73feedb",
    airportName: "Aguascaliente",
    Location: "Mexico",
    IATA: "AGU",
  },
  {
    id: "46af33b8-6cf0-4580-b2a6-48025fac0ae6",
    airportName: "Ahmedabad",
    Location: "India",
    IATA: "AMD",
  },
  {
    id: "38ed7d00-e5c6-4545-b93b-c3ae73887088",
    airportName: "Ajaccio",
    Location: "France",
    IATA: "AJA",
  },
  {
    id: "850900ce-5fd8-4922-844c-9d989ad78ee3",
    airportName: "Akita",
    Location: "Japan",
    IATA: "AXT",
  },
  {
    id: "6de212dd-2b27-405d-83a4-ce82f5917012",
    airportName: "Akron,OH",
    Location: "USA",
    IATA: "CAK",
  },
  {
    id: "bbf8d217-ddbf-4d13-8389-8761a5650a65",
    airportName: "Akrotiri - RAF",
    Location: "Cyprus",
    IATA: "AKT",
  },
  {
    id: "c4ef1c60-c6a8-4b40-9168-9b47d623a2c8",
    airportName: "Aktyubinsk",
    Location: "Kazakhstan",
    IATA: "ADX",
  },
  {
    id: "58a860e1-7173-40c7-ab04-838bf2c3ec2d",
    airportName: "Al Ain",
    Location: "United Arab Emirates",
    IATA: "AAN",
  },
  {
    id: "e944b778-5470-47f6-8bac-1553fec89676",
    airportName: "Al Arish",
    Location: "Egypt",
    IATA: "AAC",
  },
  {
    id: "df9389c9-c23b-4f38-9275-ac526b77797a",
    airportName: "Albany",
    Location: "Australia",
    IATA: "ALH",
  },
  {
    id: "2e7fa627-88af-4d80-8fb7-c2717b6f478d",
    airportName: "Albany,GA",
    Location: "USA",
    IATA: "ABY",
  },
  {
    id: "34c69e28-6ffb-4f2e-bc78-22382f8169f6",
    airportName: "Albany,NY",
    Location: "USA",
    IATA: "ALB",
  },
  {
    id: "0e9e9466-779a-43c9-8aa0-bd7e6c0346a6",
    airportName: "Albi",
    Location: "France",
    IATA: "LBI",
  },
  {
    id: "8c7ef99c-e3d4-4f07-84c1-a4e3ebea45a3",
    airportName: "Alborg",
    Location: "Denmark",
    IATA: "AAL",
  },
  {
    id: "de710f42-c14c-49da-b25b-7fa231109866",
    airportName: "Albuquerque,NM",
    Location: "USA",
    IATA: "ABQ",
  },
  {
    id: "ec052aee-5927-4f81-aeac-2c89e7a8cee5",
    airportName: "Albury",
    Location: "Australia",
    IATA: "ABX",
  },
  {
    id: "c3dd17ec-36d0-437c-b09a-15302723153b",
    airportName: "Alesund",
    Location: "Norway",
    IATA: "AES",
  },
  {
    id: "36c319a5-13c5-4680-964a-2fe6d67eec77",
    airportName: "Alexander Bay",
    Location: "South Africa",
    IATA: "ALJ",
  },
  {
    id: "01bdc63f-33fd-4b17-803e-e3fa5ca7f5db",
    airportName: "Alexandria,El Nhouza Airport",
    Location: "Egypt",
    IATA: "ALY",
  },
  {
    id: "2b73c287-095a-4d09-ac0e-32a36f8ba053",
    airportName: "Alexandria,La",
    Location: "USA",
    IATA: "ESF",
  },
  {
    id: "d97977f2-552e-4dad-a036-915b368f4e3e",
    airportName: "Alfujairah (Fujairah)",
    Location: "UAE",
    IATA: "FJR",
  },
  {
    id: "01105267-3a64-4271-985c-d3e1b9ffae2a",
    airportName: "Alghero Sassari",
    Location: "Italy",
    IATA: "AHO",
  },
  {
    id: "e5a533d2-5a82-48fd-b590-5996885781e6",
    airportName: "Algiers",
    Location: "Algeria",
    IATA: "ALG",
  },
  {
    id: "b74e7ed0-f5b8-4cf4-812b-3a4ce584f81f",
    airportName: "Al Hoceima",
    Location: "Morocco",
    IATA: "AHU",
  },
  {
    id: "60c8e4f1-c521-42c0-9605-f9c487852767",
    airportName: "Alicante",
    Location: "Spain",
    IATA: "ALC",
  },
  {
    id: "4adf8b5f-6cb1-469e-a628-0c6c1cbe0731",
    airportName: "Alice Springs",
    Location: "Australia",
    IATA: "ASP",
  },
  {
    id: "0b708b77-639b-4287-9951-f870ab6355cf",
    airportName: "Alldays",
    Location: "South Africa",
    IATA: "ADY",
  },
  {
    id: "bc97d282-62bf-466d-82be-6bf416122af8",
    airportName: "Allentown,PA",
    Location: "USA",
    IATA: "ABE",
  },
  {
    id: "374f93ec-7371-4878-8de1-0382f845ff66",
    airportName: "Alma Ata",
    Location: "Kazakhstan",
    IATA: "ALA",
  },
  {
    id: "8aa4dc09-7498-4e58-8194-a8f9aab8f06e",
    airportName: "Almeria",
    Location: "Spain",
    IATA: "LEI",
  },
  {
    id: "3ea9df60-652f-4c7b-a4e0-6646e14a2fae",
    airportName: "Aleppo",
    Location: "Syria",
    IATA: "ALP",
  },
  {
    id: "27b4e15c-c028-46b8-aece-58e14f02195b",
    airportName: "Alta",
    Location: "Norway",
    IATA: "ALF",
  },
  {
    id: "b4666625-198e-4124-b762-0b2c72878536",
    airportName: "Altenrhein",
    Location: "Switzerland",
    IATA: "ACH",
  },
  {
    id: "af676c5a-4fcf-48e9-9f66-964a00246413",
    airportName: "Altoona,PA",
    Location: "USA",
    IATA: "AOO",
  },
  {
    id: "9351ff82-5044-4ebb-bff3-ce5b14aae8c2",
    airportName: "Amami",
    Location: "Japan",
    IATA: "ASJ",
  },
  {
    id: "7475ca2f-4141-4f63-9fec-cebc88735d6d",
    airportName: "Amarillo,TX",
    Location: "USA",
    IATA: "AMA",
  },
  {
    id: "3a837d82-bc52-4380-8e41-852a14a0b878",
    airportName: "Amman, Queen Alia Intl",
    Location: "Jordan",
    IATA: "AMM",
  },
  {
    id: "bc9f2447-e44c-4d6e-83e8-9580ac2b5365",
    airportName: "Amman,Civil - Marka Airport",
    Location: "Jordan",
    IATA: "ADJ",
  },
  {
    id: "e6833b55-e74a-4c65-9a58-9774338300f4",
    airportName: "Amritsar",
    Location: "India",
    IATA: "ATQ",
  },
  {
    id: "5782950c-32a6-482d-bac6-a0fa07f37e8b",
    airportName: "Amsterdam,Schiphol",
    Location: "Netherlands",
    IATA: "AMS",
  },
  {
    id: "d272364f-d9b1-44b5-b030-546eb3a17fca",
    airportName: "Anand",
    Location: "India",
    IATA: "QNB",
  },
  {
    id: "583961d1-8346-494e-970a-d5d3943f3fd7",
    airportName: "Anchorage,AK",
    Location: "USA",
    IATA: "ANC",
  },
  {
    id: "8e60414b-d938-4897-8856-895a733d8c64",
    airportName: "Ancona",
    Location: "Italy",
    IATA: "AOI",
  },
  {
    id: "a3cd804a-357f-4d93-9bcf-92a0b5e70215",
    airportName: "Andorra La Vella H/P",
    Location: "Andorra",
    IATA: "ALV",
  },
  {
    id: "c33fd675-fd62-4ae0-a249-9241544296e3",
    airportName: "Anguilla",
    Location: "Anguilla",
    IATA: "AXA",
  },
  {
    id: "3def1307-9e5d-4974-905a-e23c8756ac2e",
    airportName: "Anjouan",
    Location: "Comoros (Comores)",
    IATA: "AJN",
  },
  {
    id: "5291bc34-eea3-48c4-bf3d-4f4b787ab918",
    airportName: "Ankara",
    Location: "Turkey",
    IATA: "ANK",
  },
  {
    id: "a2bc6c66-0500-4821-8822-a9eb607d817a",
    airportName: "Annaba",
    Location: "Algeria",
    IATA: "AAE",
  },
  {
    id: "155b758a-05bd-4aef-a6a7-098d120add67",
    airportName: "Ann Arbor,MI",
    Location: "USA",
    IATA: "ARB",
  },
  {
    id: "5048b8c1-3eb5-47dc-89eb-45ad3ac82844",
    airportName: "Annecy",
    Location: "France",
    IATA: "NCY",
  },
  {
    id: "c5cc4dda-3639-48d3-92cd-5a8e572c9cc3",
    airportName: "Anniston,AL",
    Location: "USA",
    IATA: "ANB",
  },
  {
    id: "112a090f-f959-4931-be57-7b2bbbe2e410",
    airportName: "Antalya",
    Location: "Turkey",
    IATA: "AYT",
  },
  {
    id: "68a7bee5-e3b2-4190-b030-91f21b4e0bc2",
    airportName: "Antananarivo (Tanannarive)",
    Location: "Madagascar",
    IATA: "TNR",
  },
  {
    id: "f81abfc5-e483-4470-94f5-a32ebf3b9eec",
    airportName: "Antigua",
    Location: "Antigua",
    IATA: "ANU",
  },
  {
    id: "3eb904a5-d5f9-47d3-8e1f-70c745d7aab9",
    airportName: "Antwerp",
    Location: "Belgium",
    IATA: "ANR",
  },
  {
    id: "409a1a74-c5b0-44ba-9a6f-4ae6d6ba03d6",
    airportName: "Aomori",
    Location: "Japan",
    IATA: "AOJ",
  },
  {
    id: "aa9bb80b-5c3e-46e2-a93b-9dc5ae46ea89",
    airportName: "Apia",
    Location: "Samoa",
    IATA: "APW",
  },
  {
    id: "e0257add-1923-4235-a381-bc60f5c49dab",
    airportName: "Appelton/Neenah/Menasha,WI",
    Location: "USA",
    IATA: "ATW",
  },
  {
    id: "a3bf20ee-d1c2-42cd-bae4-237c48341238",
    airportName: "Aqaba",
    Location: "Jordan",
    IATA: "AQJ",
  },
  {
    id: "3e241d7e-fd61-4be7-8eee-0da338088a89",
    airportName: "Aracaju",
    Location: "Brazil",
    IATA: "AJU",
  },
  {
    id: "e2633b0f-3eb1-4b66-942b-8ea5be311222",
    airportName: "Arkhangelsk",
    Location: "Russian Federation",
    IATA: "ARH",
  },
  {
    id: "92d0dce9-4932-4bd9-8bb4-38f4e46d9895",
    airportName: "Arusha",
    Location: "Tanzania",
    IATA: "ARK",
  },
  {
    id: "cdc12479-4746-447d-9025-4d9808ec6c66",
    airportName: "Araxos",
    Location: "Greece",
    IATA: "GPA",
  },
  {
    id: "0b3cd1ab-5d59-4c8d-8313-ba2525183868",
    airportName: "Arlit",
    Location: "Niger",
    IATA: "RLT",
  },
  {
    id: "2488647c-8ed2-449f-98b4-4494a4cbe979",
    airportName: "Armenia",
    Location: "Colombia",
    IATA: "AXS",
  },
  {
    id: "a3f75019-d4ac-43c3-8e1e-5045c5d37fb9",
    airportName: "Arrecife/Lanzarote",
    Location: "Spain",
    IATA: "ACE",
  },
  {
    id: "63798c71-d9b2-40c5-bcca-f256edebeb4c",
    airportName: "Aruba",
    Location: "Aruba",
    IATA: "AUA",
  },
  {
    id: "099a92d3-ce21-4d18-9b80-f1f0795ea715",
    airportName: "Asheville,NC",
    Location: "USA",
    IATA: "AVL",
  },
  {
    id: "8b6cccb9-c062-4c97-bcf7-76ec39267779",
    airportName: "Ashgabat,Ashkhabat",
    Location: "Turkmenistan",
    IATA: "ASB",
  },
  {
    id: "507aa2ec-1782-4310-a411-f0a71e4ebb44",
    airportName: "Aspen Snowmass,CO",
    Location: "USA",
    IATA: "ASE",
  },
  {
    id: "14ad2497-ae26-4019-9755-213719064adf",
    airportName: "Assiut",
    Location: "Egypt",
    IATA: "ATZ",
  },
  {
    id: "ce98e0a9-eddc-4278-a110-ebf0e7ddf08b",
    airportName: "Astana",
    Location: "Kazakstan",
    IATA: "TSE",
  },
  {
    id: "63187334-26cc-4881-bbf2-4c9649810726",
    airportName: "Asuncion",
    Location: "Paraguay",
    IATA: "ASU",
  },
  {
    id: "e6fb0521-814f-431b-ac75-ec7d7f785c0e",
    airportName: "Aswan,Daraw Airport",
    Location: "Egypt",
    IATA: "ASW",
  },
  {
    id: "c4fd2529-e357-43dd-9614-0197f6b61959",
    airportName: "Athens",
    Location: "Greece",
    IATA: "ATH",
  },
  {
    id: "21fbf23f-738e-44c5-b1d0-1c0ae5c43fbc",
    airportName: "Athens,Hellinikon Airport",
    Location: "Greece",
    IATA: "HEW",
  },
  {
    id: "b71f5a80-c041-4869-ab68-60eb5347661c",
    airportName: "Athens,GA",
    Location: "USA",
    IATA: "AHN",
  },
  {
    id: "b15db925-dd86-458b-97f8-7fd81ae9587b",
    airportName: "Athens,OH",
    Location: "USA",
    IATA: "ATO",
  },
  {
    id: "4925f235-26e8-4bfb-a13c-f19f5d636c60",
    airportName: "Atlanta,Hartsfield Atlanta Int'l Airport",
    Location: "USA",
    IATA: "ATL",
  },
  {
    id: "80c2ce24-64b1-41f5-b443-7e87c7625acb",
    airportName: "Atlantic City,NJ",
    Location: "USA",
    IATA: "ACY",
  },
  {
    id: "0da81e15-0a7e-4eb0-8fcc-8d31334847b8",
    airportName: "Attawapiskat,NT",
    Location: "Canada",
    IATA: "YAT",
  },
  {
    id: "301e182b-72b7-4fe1-bd11-22732280072e",
    airportName: "Auckland",
    Location: "New Zealand",
    IATA: "AKL",
  },
  {
    id: "78a94322-fce6-4547-b5a2-18bfdc790dc1",
    airportName: "Augsburg",
    Location: "Germany",
    IATA: "AGB",
  },
  {
    id: "7cec40df-fcfa-4cc7-af97-baf59d040935",
    airportName: "Augusta, GA",
    Location: "USA",
    IATA: "AGS",
  },
  {
    id: "26a5c2e4-0683-46bf-ad9e-5f131e08178b",
    airportName: "Augusta, ME",
    Location: "USA",
    IATA: "AUG",
  },
  {
    id: "293ec72e-d52d-4b6b-8f53-a1798dd1da89",
    airportName: "Aurillac",
    Location: "France",
    IATA: "AUR",
  },
  {
    id: "3097ebd3-7d67-4d52-89f4-7a9c5718691f",
    airportName: "Austin,TX",
    Location: "USA",
    IATA: "AUS",
  },
  {
    id: "34cec7f2-f730-4ab3-a44a-1ea3f2c63cbb",
    airportName: "Ayers Rock",
    Location: "Australia",
    IATA: "AYQ",
  },
  {
    id: "9bce0f4f-224e-4ac1-b0fd-6870729b4e2f",
    airportName: "Ayr",
    Location: "Australia",
    IATA: "AYR",
  },
  {
    id: "4d438d11-c1fa-401b-b808-7fd63fef6a25",
    airportName: "Badajoz",
    Location: "Spain",
    IATA: "BJZ",
  },
  {
    id: "bad1ccd5-d016-4123-be04-a92ba08c9c0c",
    airportName: "Bagdad, Al Muthana",
    Location: "Iraq",
    IATA: "BGW",
  },
  {
    id: "8ccbc520-ce3a-4b99-8c24-4ea5d65a7750",
    airportName: "Bagdad, Saddam International",
    Location: "Iraq",
    IATA: "SDA",
  },
  {
    id: "e6efc27f-53f3-4d26-a212-acf79a273dc8",
    airportName: "Bagdogra",
    Location: "India",
    IATA: "IXB",
  },
  {
    id: "f3315bcd-7242-4bfc-8093-a3b6b5554b8b",
    airportName: "Bahawalpur",
    Location: "Pakistan",
    IATA: "BHV",
  },
  {
    id: "6d2b5def-306b-473f-93ae-0f5cf7f3f40b",
    airportName: "Bahrain",
    Location: "Bahrain",
    IATA: "BAH",
  },
  {
    id: "f56ea475-7719-494b-9984-0e8bb5e14b89",
    airportName: "Bakersfield, CA",
    Location: "USA",
    IATA: "BFL",
  },
  {
    id: "4b828427-b157-4ebd-a380-9773c8651cba",
    airportName: "Baku",
    Location: "Azerbaijan",
    IATA: "BAK",
  },
  {
    id: "dda3a4c1-b5ff-4ddc-ad69-36dd0512c676",
    airportName: "Ballina",
    Location: "Australia",
    IATA: "BNK",
  },
  {
    id: "c1732a0d-6d12-4e03-a3a9-45156eb9a98f",
    airportName: "Baltimore Washington Int'l Airport; MD",
    Location: "USA",
    IATA: "BWI",
  },
  {
    id: "1813b543-cf63-4304-a009-4647bead677b",
    airportName: "Bamaga",
    Location: "Australia",
    IATA: "ABM",
  },
  {
    id: "d80a7253-2967-4348-873d-e13a4b57de49",
    airportName: "Bamako",
    Location: "Mali",
    IATA: "BKO",
  },
  {
    id: "22fa12f3-fb70-4ca0-a880-56485736040b",
    airportName: "Bambari",
    Location: "Central African Republic",
    IATA: "BBY",
  },
  {
    id: "13ee377e-d409-490d-9d0e-5c321e7b9d39",
    airportName: "Bandar Seri Begawan",
    Location: "Brunei",
    IATA: "BWN",
  },
  {
    id: "7b781fac-86cd-47d0-95b3-b71d3cbbed93",
    airportName: "Bangalore",
    Location: "India",
    IATA: "BLR",
  },
  {
    id: "3c6aa97b-ddf7-42ae-afb6-07af73a96e36",
    airportName: "Bangassou",
    Location: "Central African Republic",
    IATA: "BGU",
  },
  {
    id: "88f2d501-323d-4e39-a5e2-c5f35c5ee29d",
    airportName: "Bangkok",
    Location: "Thailand",
    IATA: "BKK",
  },
  {
    id: "14a0c1a5-977d-403d-9ce9-c9e6c1cf758b",
    airportName: "Bangor, ME",
    Location: "USA",
    IATA: "BGR",
  },
  {
    id: "2603adad-14ac-4f6c-b9a8-89f996badfa0",
    airportName: "Bangui",
    Location: "Central African Republic",
    IATA: "BGF",
  },
  {
    id: "483653c6-a03a-4492-8a60-921f07229fbb",
    airportName: "Banjul",
    Location: "Gambia",
    IATA: "BJL",
  },
  {
    id: "6a8b3b29-6912-4c8c-8b11-8bf01a02b38e",
    airportName: "Bannu",
    Location: "Pakistan",
    IATA: "BNP",
  },
  {
    id: "c9feb2a5-9f8d-4e49-8b19-a47ff36b3215",
    airportName: "Barcelona",
    Location: "Spain",
    IATA: "BCN",
  },
  {
    id: "78e6089b-3047-45b1-9310-1d866cd1de34",
    airportName: "Barcelona",
    Location: "Venezuela",
    IATA: "BLA",
  },
  {
    id: "fc1ee68d-5ed0-46e1-b9c6-5467f415faa4",
    airportName: "Bardufoss",
    Location: "Norway",
    IATA: "BDU",
  },
  {
    id: "fb5c7663-67e1-4f10-919e-50952963a46c",
    airportName: "Bari",
    Location: "Italy",
    IATA: "BRI",
  },
  {
    id: "432e8f18-6215-43e8-9a49-e50bfc7837fc",
    airportName: "Barisal",
    Location: "Bangladesh",
    IATA: "BZL",
  },
  {
    id: "6357b1b4-1db8-474f-adcf-18c70328b0ee",
    airportName: "Baronda",
    Location: "India",
    IATA: "BDQ",
  },
  {
    id: "479651df-a7ba-4ad1-8afc-798d2d900dda",
    airportName: "Barranquilla",
    Location: "Colombia",
    IATA: "BAQ",
  },
  {
    id: "bb705dd1-c53a-4db6-bcf9-07be08b6991c",
    airportName: "Basel",
    Location: "Switzerland",
    IATA: "BSL",
  },
  {
    id: "1f211e42-6732-419d-a125-67c7f63c4bc0",
    airportName: "Basel/Mulhouse",
    Location: "Switzerland/France",
    IATA: "EAP",
  },
  {
    id: "ac3b550c-8c10-4dae-a0ce-d0a150c7b0c9",
    airportName: "Basra, Basrah",
    Location: "Iraq",
    IATA: "BSR",
  },
  {
    id: "2d3d36a3-c2e2-4637-a826-c7f22b5408e1",
    airportName: "Bastia",
    Location: "France",
    IATA: "BIA",
  },
  {
    id: "034dc123-8e1c-47cf-b6bf-a6cdd78c9c72",
    airportName: "Baton Rouge, La",
    Location: "USA",
    IATA: "BTR",
  },
  {
    id: "6dfae723-6b7f-4cb9-a6b5-6eebbac95fdd",
    airportName: "Bayreuth",
    Location: "Germany",
    IATA: "BYU",
  },
  {
    id: "cbaf3115-eed6-4aec-80cf-37b132631220",
    airportName: "Beaumont/Pt. Arthur, TX",
    Location: "USA",
    IATA: "BPT",
  },
  {
    id: "7ec541b2-41fa-493f-a0af-ab6012a6af3e",
    airportName: "Beckley, WV",
    Location: "USA",
    IATA: "BKW",
  },
  {
    id: "0675d073-e9fa-4523-a15f-ed330ad96dd0",
    airportName: "Beef Island",
    Location: "Virgin Islands, British",
    IATA: "EIS",
  },
  {
    id: "ff451aea-5b54-4e86-9203-6e602bd36569",
    airportName: "Beijing",
    Location: "China",
    IATA: "PEK",
  },
  {
    id: "46b637d8-8ea7-4f39-a641-a0975bae9357",
    airportName: "Beijing - Nanyuan Airport",
    Location: "China",
    IATA: "NAY",
  },
  {
    id: "39c0ffb9-c8d1-4416-8970-0d23623119da",
    airportName: "Beira",
    Location: "Mozambique",
    IATA: "BEW",
  },
  {
    id: "2182f4a9-447c-4d4d-9973-bbb7df17c2f4",
    airportName: "Beirut",
    Location: "Lebanon",
    IATA: "BEY",
  },
  {
    id: "f5440f9b-bd1b-4307-8cab-0fd6261ed715",
    airportName: "Belem",
    Location: "Brazil",
    IATA: "BEL",
  },
  {
    id: "1829930a-1dc9-412c-a560-64ea4e8be55b",
    airportName: "Belfast - Harbour",
    Location: "United Kingdom",
    IATA: "BHD",
  },
  {
    id: "d60b87de-4dcb-4568-869e-9583496c5239",
    airportName: "Belfast International",
    Location: "United Kingdom",
    IATA: "BFS",
  },
  {
    id: "ebac3ec9-c1dc-4e7d-9b19-65d44e22e687",
    airportName: "Belgaum",
    Location: "India",
    IATA: "IXG",
  },
  {
    id: "58c2b843-6d83-4c6e-97b3-303bf1489c1f",
    airportName: "Belgrad (Beograd)",
    Location: "Yugoslavia",
    IATA: "BEG",
  },
  {
    id: "f7f254d3-976f-4e78-959d-40d4a95942bd",
    airportName: "Belize City",
    Location: "Belize",
    IATA: "BZE",
  },
  {
    id: "6af80ecf-cabf-4481-9a0f-d0e082558c9e",
    airportName: "Bellingham, WA",
    Location: "USA",
    IATA: "BLI",
  },
  {
    id: "e85a611f-e5b0-4869-83fa-bc17262298eb",
    airportName: "Belo Horizonte",
    Location: "Brazil",
    IATA: "CNF",
  },
  {
    id: "37ef374b-41d2-45cd-98f2-d24dbbdbda3d",
    airportName: "Bemidji, MN",
    Location: "USA",
    IATA: "BJI",
  },
  {
    id: "e7c296a5-0e07-44d2-8aa1-6a36095fea3a",
    airportName: "Bengasi",
    Location: "Libya",
    IATA: "BEN",
  },
  {
    id: "5f2dd0f9-17f8-4a20-b1aa-dd667428e834",
    airportName: "Bengueka",
    Location: "Angola",
    IATA: "BUG",
  },
  {
    id: "6ef645d7-7492-4b8d-bc2c-6062005380b7",
    airportName: "Benton Harbour, MI",
    Location: "USA",
    IATA: "BEH",
  },
  {
    id: "7fb15001-be15-4976-898e-880fa948f303",
    airportName: "Berberati",
    Location: "Central African Republic",
    IATA: "BBT",
  },
  {
    id: "8d6b4129-e090-4ad3-b328-9bedbb526b40",
    airportName: "Bergamo",
    Location: "Italy",
    IATA: "BGY",
  },
  {
    id: "6742b241-0cd0-41f1-8fca-3c36a6d95f76",
    airportName: "Bergen",
    Location: "Norway",
    IATA: "BGO",
  },
  {
    id: "47cca144-5629-4f06-af47-c1793b7b06c2",
    airportName: "Berlin",
    Location: "Germany",
    IATA: "BER",
  },
  {
    id: "904526ba-c5ef-4c42-ac69-7c9eeedebc71",
    airportName: "Berlin, Schoenefeld",
    Location: "Germany",
    IATA: "SXF",
  },
  {
    id: "681b58de-bd10-4d14-a458-6090850e68c0",
    airportName: "Berlin, Tegel",
    Location: "Germany",
    IATA: "TXL",
  },
  {
    id: "5e592f0a-7e34-434d-8f45-f75d89a2d43f",
    airportName: "Berlin, Tempelhof",
    Location: "Germany",
    IATA: "THF",
  },
  {
    id: "4c2e3ab4-6d0d-434e-a934-d588b919cbcf",
    airportName: "Bermuda",
    Location: "Bermuda",
    IATA: "BDA",
  },
  {
    id: "9e3585cd-3918-4144-b595-2dc570d5ccb9",
    airportName: "Bern",
    Location: "Switzerland",
    IATA: "BRN",
  },
  {
    id: "92381207-bbb0-433e-965a-f0b2151d3232",
    airportName: "Bern",
    Location: "Switzerland",
    IATA: "ZDJ",
  },
  {
    id: "880f73da-90be-4313-ad30-3bcdadfd5428",
    airportName: "Bethel, AK",
    Location: "USA",
    IATA: "BET",
  },
  {
    id: "3a2c32e5-d306-492e-bf87-9a2adadf2758",
    airportName: "Bhopal",
    Location: "India",
    IATA: "BHO",
  },
  {
    id: "b11b1280-1e13-4e19-aff9-5eca4b29a0ce",
    airportName: "Bhubaneswar",
    Location: "India",
    IATA: "BBI",
  },
  {
    id: "94b869d1-8c4d-4483-99c4-7f43c61e5353",
    airportName: "Biarritz",
    Location: "France",
    IATA: "BIQ",
  },
  {
    id: "99ed8597-845d-43ce-8986-4aa2945cbe3f",
    airportName: "Bilbao",
    Location: "Spain",
    IATA: "BIO",
  },
  {
    id: "613f5f4b-70e4-4a19-a90d-2f8238da6324",
    airportName: "Billings, MT",
    Location: "USA",
    IATA: "BIL",
  },
  {
    id: "933cab3f-efed-445a-93b5-d7cc9e4b29ad",
    airportName: "Billund",
    Location: "Denmark",
    IATA: "BLL",
  },
  {
    id: "394559ea-8a09-4740-9cfa-cb1f4a375cc8",
    airportName: "Bintulu",
    Location: "Malaysia",
    IATA: "BTU",
  },
  {
    id: "cfe9d86d-18bb-49ea-9750-2665faa04569",
    airportName: "Biraro",
    Location: "Central African Republic",
    IATA: "IRO",
  },
  {
    id: "5250653f-db59-4cba-b326-f7ea77b459ba",
    airportName: "Birmingham",
    Location: "United Kingdom",
    IATA: "BHX",
  },
  {
    id: "39734413-24db-40c2-a365-aa181d4c9399",
    airportName: "Birmingham, AL",
    Location: "USA",
    IATA: "BHM",
  },
  {
    id: "57f18bd2-8d17-41b8-9e82-3908e51375a7",
    airportName: "Bismarck/Mandan, ND",
    Location: "USA",
    IATA: "BIS",
  },
  {
    id: "811e6d75-b4dc-40d2-adf9-45720c18d85c",
    airportName: "Bissau",
    Location: "Guinea Bissau",
    IATA: "BXO",
  },
  {
    id: "c428580d-9e3e-4d80-8c35-34ccecf6084f",
    airportName: "Blackwater",
    Location: "Australia",
    IATA: "BLT",
  },
  {
    id: "f2320b7a-3204-402d-8549-7d2c52a1ec58",
    airportName: "Blantyre",
    Location: "Malawi",
    IATA: "BLZ",
  },
  {
    id: "ea4efa6d-95aa-45ac-9211-a41ad2fe41ad",
    airportName: "Blenheim",
    Location: "New Zealand",
    IATA: "BHE",
  },
  {
    id: "2b6c947a-59a8-4196-9851-930d866a97d2",
    airportName: "Bloemfontein",
    Location: "South Africa",
    IATA: "BFN",
  },
  {
    id: "2ed37d6d-4a79-48f4-93d8-e16195091bdb",
    airportName: "Bloomington, IL",
    Location: "USA",
    IATA: "BMI",
  },
  {
    id: "5917eaa3-80e4-4d4d-ab62-edd3e869a7df",
    airportName: "Bloomington, IN",
    Location: "USA",
    IATA: "BMG",
  },
  {
    id: "0979d96c-7f7a-4ae0-8456-fecc41049320",
    airportName: "Bluefield, WV",
    Location: "USA",
    IATA: "BLF",
  },
  {
    id: "a67a501c-2f3d-4958-928d-659633ecf6cf",
    airportName: "Boa Vista",
    Location: "Brazil",
    IATA: "BVB",
  },
  {
    id: "55173b6b-eb36-4b5a-9aeb-d2f50463b063",
    airportName: "Bobo/Dioulasso",
    Location: "Burkina Faso",
    IATA: "BOY",
  },
  {
    id: "c22a02f8-24b9-4cfd-b44c-9372dc3b1aff",
    airportName: "Bodo",
    Location: "Norway",
    IATA: "BOO",
  },
  {
    id: "18534be9-78f6-424d-b416-e98a366a038e",
    airportName: "Bogota",
    Location: "Colombia",
    IATA: "BOG",
  },
  {
    id: "bbe834c5-07b9-46fc-899b-943169d6aeb0",
    airportName: "Boise, ID",
    Location: "USA",
    IATA: "BOI",
  },
  {
    id: "3452747e-8761-479d-a524-f5145d56fb2b",
    airportName: "Bologna",
    Location: "Italy",
    IATA: "BLQ",
  },
  {
    id: "188ee8fa-4604-4b66-a4a0-4ee637fae8a2",
    airportName: "Bombay",
    Location: "India",
    IATA: "BOM",
  },
  {
    id: "2606966b-2d63-4b8f-bf63-76cb112c1b7b",
    airportName: "Bonaire",
    Location: "Netherlands Antilles",
    IATA: "BON",
  },
  {
    id: "f3bb618b-738e-4518-9fbc-e877b791c8c2",
    airportName: "Bonaventure, PQ",
    Location: "Canada",
    IATA: "YVB",
  },
  {
    id: "7b895b6e-d58a-4cbc-9771-3599f738326d",
    airportName: "Bora Bora",
    Location: "French Polynesia",
    IATA: "BOB",
  },
  {
    id: "1902dd80-b68e-4e18-bffa-c755520fa8eb",
    airportName: "Bordeaux",
    Location: "France",
    IATA: "BOD",
  },
  {
    id: "88962531-c223-4f80-8576-8334365479ae",
    airportName: "Borg El Arab Airport",
    Location: "Egypt",
    IATA: "HBH",
  },
  {
    id: "5584b6b7-2a4e-47e9-bd80-6731528cf57e",
    airportName: "Borrego Springs, CA",
    Location: "USA",
    IATA: "BXS",
  },
  {
    id: "d6e43526-b399-4c57-9b16-9c520a701cee",
    airportName: "Boston - Logan, MA",
    Location: "USA",
    IATA: "BOS",
  },
  {
    id: "6d63bb02-ffff-4aa0-9ba2-6ee0ee73c2f6",
    airportName: "Bouake",
    Location: "Ivory Coast",
    IATA: "BYK",
  },
  {
    id: "9c182614-cd53-46cf-a10f-30a551d0c5ca",
    airportName: "Bourgas/Burgas",
    Location: "Bulgaria",
    IATA: "BOJ",
  },
  {
    id: "b155384d-3aed-4f34-8885-994f49e481aa",
    airportName: "Bowen",
    Location: "Australia",
    IATA: "ZBO",
  },
  {
    id: "a308e907-3d28-4940-b69a-2dd8231c646b",
    airportName: "Bozeman, MT",
    Location: "USA",
    IATA: "BZN",
  },
  {
    id: "7097fce0-fd8b-4646-95a8-db83c60ae01f",
    airportName: "Bradford/Warren, PA/Olean, NY",
    Location: "USA",
    IATA: "BFD",
  },
  {
    id: "197175df-48c1-436e-9d15-71e69febce66",
    airportName: "Brainerd, MN",
    Location: "USA",
    IATA: "BRD",
  },
  {
    id: "07babb3e-85c6-4ae8-aec0-2992473a5830",
    airportName: "Brampton Island",
    Location: "Australia",
    IATA: "BMP",
  },
  {
    id: "e098aa91-ea66-4c3b-8cbe-97e29b00f3ee",
    airportName: "Brasilia",
    Location: "Brazil",
    IATA: "BSB",
  },
  {
    id: "43ab01ff-dca5-460b-865e-a5f9fb654547",
    airportName: "Bratislava",
    Location: "Slovakia",
    IATA: "BTS",
  },
  {
    id: "68fb25c7-e0ed-47bf-95dc-abb94af740ed",
    airportName: "Brazzaville",
    Location: "Congo",
    IATA: "BZV",
  },
  {
    id: "fe4af06a-a358-4568-9f41-4a2f751c7acf",
    airportName: "Bremen",
    Location: "Germany",
    IATA: "BRE",
  },
  {
    id: "0667bcdf-4177-4e33-9df7-77c052ad07dc",
    airportName: "Brescia, Montichiari",
    Location: "Italy",
    IATA: "VBS",
  },
  {
    id: "9e60b8f7-2733-4066-988d-1ca0d3590ed6",
    airportName: "Brest",
    Location: "France",
    IATA: "BES",
  },
  {
    id: "5f77b54f-61f1-4b46-b120-5f938cbbf8b5",
    airportName: "Bria",
    Location: "Central African Republic",
    IATA: "BIV",
  },
  {
    id: "0c660daa-ae47-4124-b99a-a74a4e45f96e",
    airportName: "Bridgeport, CT",
    Location: "USA",
    IATA: "BDR",
  },
  {
    id: "46b4e574-d759-4247-b404-bcbd62c893f8",
    airportName: "Bridgetown, Grantley Adams Int'l",
    Location: "Barbados",
    IATA: "BGI",
  },
  {
    id: "afd5ef82-7ebe-4e83-a8e0-c0ded5ab43fc",
    airportName: "Brindisi",
    Location: "Italy",
    IATA: "BDS",
  },
  {
    id: "d6b9f55d-a3c1-4fe2-81f2-9123e08246d0",
    airportName: "Brisbane",
    Location: "Australia",
    IATA: "BNE",
  },
  {
    id: "5bb46a53-c880-4e16-a50c-0bc308e65284",
    airportName: "Bristol",
    Location: "United Kingdom",
    IATA: "BRS",
  },
  {
    id: "530197b6-8461-4da2-80ec-a26cdb8f6a36",
    airportName: "Broennoeysund",
    Location: "Norway",
    IATA: "BNN",
  },
  {
    id: "44ba95a8-6404-4a02-ad0e-a0ee5155b5cd",
    airportName: "Broken Hill",
    Location: "Australia",
    IATA: "BHQ",
  },
  {
    id: "21d99cf1-bf76-4b7c-8448-411579e55bbc",
    airportName: "Brookings, SD",
    Location: "USA",
    IATA: "BKX",
  },
  {
    id: "63ea231e-0594-4687-8bd3-32a3e92a628c",
    airportName: "Broome",
    Location: "Australia",
    IATA: "BME",
  },
  {
    id: "4c8284a8-8d7f-4aad-99c0-45af4e6a4f29",
    airportName: "Brunswick, GA",
    Location: "USA",
    IATA: "BQK",
  },
  {
    id: "02c85554-533e-4fc9-b8da-411f39e6b6c8",
    airportName: "Brussels",
    Location: "Belgium",
    IATA: "BRU",
  },
  {
    id: "15c8785e-be55-44b9-9947-63e7d22ad475",
    airportName: "Bucaramanga",
    Location: "Colombia",
    IATA: "BGA",
  },
  {
    id: "a2a8c333-3fe5-4bb5-ad4b-1a1fb49129ad",
    airportName: "Budapest, Ferihegy",
    Location: "Hungary",
    IATA: "BUD",
  },
  {
    id: "428861a7-94e1-46c3-9db5-33d4d5b40472",
    airportName: "Buenos Aires",
    Location: "Argentina",
    IATA: "BUE",
  },
  {
    id: "81001bd0-45d4-4ffc-99c5-b1c151bfb900",
    airportName: "Buenos Aires, Ezeiza Int'l Airport",
    Location: "Argentina",
    IATA: "EZE",
  },
  {
    id: "4abbf66c-d2e1-4dee-93b0-89ec11276108",
    airportName: "Buenos Aires, Jorge Newbery",
    Location: "Argentina",
    IATA: "AEP",
  },
  {
    id: "8e95c4b9-7669-42ac-acc4-5f4643d6f5a8",
    airportName: "Buffalo Range",
    Location: "Zimbabwe",
    IATA: "BFO",
  },
  {
    id: "232393b6-6863-42eb-a163-6d07e2163ee8",
    airportName: "Buffalo/Niagara Falls, NY",
    Location: "USA",
    IATA: "BUF",
  },
  {
    id: "fe4e6187-251a-4164-a7f8-bb7415d6b59e",
    airportName: "Bujumbura",
    Location: "Burundi",
    IATA: "BJM",
  },
  {
    id: "5bd03bec-a62e-48c2-81dd-45dd251e6f7f",
    airportName: "Bucharest",
    Location: "Romania",
    IATA: "BUH",
  },
  {
    id: "dbcddca2-99c9-4438-a5a3-626ea25a8707",
    airportName: "Bucharest, Otopeni",
    Location: "Romania",
    IATA: "OTP",
  },
  {
    id: "6e7eb55b-ea7d-4515-a606-2e676dc9f64a",
    airportName: "Bulawayo",
    Location: "Zimbabwe",
    IATA: "BUQ",
  },
  {
    id: "9e97b094-9eb2-4ea7-8d76-149781293bbb",
    airportName: "Bullhead City, NV",
    Location: "USA",
    IATA: "BHC",
  },
  {
    id: "75240877-3fa1-4994-9532-50f3233dca36",
    airportName: "Bundaberg",
    Location: "Australia",
    IATA: "BDB",
  },
  {
    id: "87af47b3-d75d-48c8-869a-489995cb1807",
    airportName: "Burbank, CA",
    Location: "USA",
    IATA: "BUR",
  },
  {
    id: "b8af3786-1b88-45f1-af41-39e8a5a820dc",
    airportName: "Burlington, IA",
    Location: "USA",
    IATA: "BRL",
  },
  {
    id: "396e82a0-18f3-4d2d-bb31-ddc815f7d78e",
    airportName: "Burlington, VT",
    Location: "USA",
    IATA: "BTV",
  },
  {
    id: "aabd9dd9-55d7-4511-aa73-3512083c251b",
    airportName: "Burnie (Wynyard)",
    Location: "Australia",
    IATA: "BWT",
  },
  {
    id: "5e342c58-87e4-4642-b2aa-240407fc7725",
    airportName: "Butte, MT",
    Location: "USA",
    IATA: "BTM",
  },
  {
    id: "6bbdc4ce-c323-4cb4-846b-baed810f1023",
    airportName: "Cabinda",
    Location: "Angola",
    IATA: "CAB",
  },
  {
    id: "7a83852c-8596-4c3e-ac3e-4cfb6f5d808b",
    airportName: "Cagliari",
    Location: "Italy",
    IATA: "CAG",
  },
  {
    id: "b715de86-b74e-4660-8413-1bb24f04df93",
    airportName: "Cairns",
    Location: "Australia",
    IATA: "CNS",
  },
  {
    id: "d1795185-1174-410a-81f2-65e6347fecf1",
    airportName: "Cairo Int'l",
    Location: "Egypt",
    IATA: "CAI",
  },
  {
    id: "a25eb28d-a4e0-4e25-bcd3-2021516ac6e6",
    airportName: "Calcutta (Kolkata) - Netaji Subhas Chandra",
    Location: "India",
    IATA: "CCU",
  },
  {
    id: "c39d6d72-9610-4ae9-824e-fb64aafd0488",
    airportName: "Komatsu",
    Location: "Japan",
    IATA: "KMQ",
  },
  {
    id: "5013f221-9544-4140-a44f-ce9e75a79529",
    airportName: "Calgary/Banff",
    Location: "Canada",
    IATA: "YYC",
  },
  {
    id: "cb8001e9-377f-4c2f-a8a1-1f5d8ef051d4",
    airportName: "Cali",
    Location: "Colombia",
    IATA: "CLO",
  },
  {
    id: "6a948ea2-30b4-497d-af51-a3b636f84cf8",
    airportName: "Calicut",
    Location: "India",
    IATA: "CCJ",
  },
  {
    id: "1d00afa3-e8a5-4801-8d86-6e5ef9224551",
    airportName: "Calvi",
    Location: "France",
    IATA: "CLY",
  },
  {
    id: "2046d98a-22ee-4003-be4c-755adbe0f2fd",
    airportName: "Cambridge Bay",
    Location: "Canada",
    IATA: "YCB",
  },
  {
    id: "4ae2c203-abaf-46a1-808f-9acca7ef4b46",
    airportName: "Cambrigde",
    Location: "United Kingdom",
    IATA: "CBG",
  },
  {
    id: "98a6d215-1004-41b4-bfa2-7caa28b277b9",
    airportName: "Campo Grande",
    Location: "Brazil",
    IATA: "CGR",
  },
  {
    id: "05a40372-6afa-4710-bf29-50988b2a6a6f",
    airportName: "Canberra",
    Location: "Australia",
    IATA: "CBR",
  },
  {
    id: "2cbb1919-107e-4189-91a8-17d38b9b4b28",
    airportName: "Cancun",
    Location: "Mexico",
    IATA: "CUN",
  },
  {
    id: "33846dfe-b0f2-4afa-9b91-6a5070d48c86",
    airportName: "Cape Town",
    Location: "South Africa",
    IATA: "CPT",
  },
  {
    id: "d38f76f2-e06e-4bd3-aa84-f37a47624be7",
    airportName: "Caracas",
    Location: "Venezuela",
    IATA: "CCS",
  },
  {
    id: "e9be7dba-29e6-4e89-854a-d2da710c268f",
    airportName: "Cardiff",
    Location: "United Kingdom",
    IATA: "CWL",
  },
  {
    id: "db62f9e7-1fd2-432b-b5c4-c39d69aa3441",
    airportName: "Carlsbad, CA",
    Location: "USA",
    IATA: "CLD",
  },
  {
    id: "707771f6-2b63-4f7b-9dc4-bc3e43dd7679",
    airportName: "Carnarvon",
    Location: "Australia",
    IATA: "CVQ",
  },
  {
    id: "416bf2c4-7400-4ef8-b1e6-6db02f05a0ce",
    airportName: "Carnot",
    Location: "Central African Republic",
    IATA: "CRF",
  },
  {
    id: "5397d3ed-4a0a-42f6-9f63-833b6158ad91",
    airportName: "Casa de Campo/La Romana",
    Location: "Dominican Republic",
    IATA: "LRM",
  },
  {
    id: "614de15a-c39a-4474-9bbe-3807b33fefae",
    airportName: "Casablanca",
    Location: "Morocco",
    IATA: "CAS",
  },
  {
    id: "295a436f-6c72-4b50-980f-3619977d343d",
    airportName: "Casablanca, Mohamed V",
    Location: "Morocco",
    IATA: "CMN",
  },
  {
    id: "7f5991d5-d0c8-48dc-81a0-7a5ca2e1c701",
    airportName: "Casino",
    Location: "Australia",
    IATA: "CSI",
  },
  {
    id: "c74adbdb-cfdd-40f7-841a-886bd051b1e3",
    airportName: "Casper, WY",
    Location: "USA",
    IATA: "CPR",
  },
  {
    id: "c9ae46ab-5e53-4e78-bfa4-de6d2cfd208d",
    airportName: "Catagena",
    Location: "Colombia",
    IATA: "CTG",
  },
  {
    id: "ed6126fc-3e15-4f9d-93d8-c720dab1cf25",
    airportName: "Catania",
    Location: "Italy",
    IATA: "CTA",
  },
  {
    id: "1fd76f27-c5a1-4877-ab27-0844704228e7",
    airportName: "Cayenne",
    Location: "French Guayana",
    IATA: "CAY",
  },
  {
    id: "66c5e925-9abc-4a8b-bb36-92f315b2a1ae",
    airportName: "Cebu",
    Location: "Philippines",
    IATA: "CBU",
  },
  {
    id: "8f7a5cc1-f776-4d3c-82cb-ea380c2f2175",
    airportName: "Cedar City, UT",
    Location: "USA",
    IATA: "CDC",
  },
  {
    id: "8217ce17-15e2-4150-bcb0-29967f689162",
    airportName: "Cedar Rapids, IA",
    Location: "USA",
    IATA: "CID",
  },
  {
    id: "50749c10-ee34-4787-901a-44f2c199539a",
    airportName: "Ceduna",
    Location: "Australia",
    IATA: "CED",
  },
  {
    id: "573e05b4-fb53-4514-8521-8f7818952e27",
    airportName: "Cessnock",
    Location: "Australia",
    IATA: "CES",
  },
  {
    id: "ccfe0637-c57c-42f9-b199-2cc598f4d4a8",
    airportName: "Chabarovsk (Khabarovsk)",
    Location: "Russia",
    IATA: "KHV",
  },
  {
    id: "ce230bce-3e5a-4846-84dd-73c624b0c774",
    airportName: "Chambery",
    Location: "France",
    IATA: "CMF",
  },
  {
    id: "7f493f93-ae20-4bd6-873b-33d8127e02a3",
    airportName: "Champaign, IL",
    Location: "USA",
    IATA: "CMI",
  },
  {
    id: "4b7f5b0c-45df-4a7f-8e07-bad65a2db987",
    airportName: "Chandigarh",
    Location: "India",
    IATA: "IXC",
  },
  {
    id: "b56f7499-14e0-448e-8e92-6b2c877b0283",
    airportName: "Changchun",
    Location: "Jilin, China",
    IATA: "CGQ",
  },
  {
    id: "e9bf1f3c-c04a-4e59-b236-188341bc9ce3",
    airportName: "Chania",
    Location: "Greece",
    IATA: "CHQ",
  },
  {
    id: "56be3e82-3b28-4e1c-b13d-e31f1691c0f1",
    airportName: "Charleston, SC",
    Location: "USA",
    IATA: "CHS",
  },
  {
    id: "4c90149f-c53d-408f-a986-4975f73ad7d9",
    airportName: "Charleston, WV",
    Location: "USA",
    IATA: "CRW",
  },
  {
    id: "09108c65-bcfc-4c62-8af6-a7eeb5f25d4b",
    airportName: "Charlotte, NC",
    Location: "USA",
    IATA: "CLT",
  },
  {
    id: "af47b612-def6-47d5-af9a-1c62c3eb4176",
    airportName: "Charlottesville, VA",
    Location: "USA",
    IATA: "CHO",
  },
  {
    id: "feade594-2b99-4e3b-ace1-b0343c9e022a",
    airportName: "Charters Towers",
    Location: "Australia",
    IATA: "CXT",
  },
  {
    id: "44e37e4e-bb97-41c1-b514-ab343f7b1b8a",
    airportName: "Chattanooga, TN",
    Location: "USA",
    IATA: "CHA",
  },
  {
    id: "b60ae2f2-b42f-4c35-86ff-2c7ec26b12c3",
    airportName: "Chengdu",
    Location: "Sichuan, China",
    IATA: "CTU",
  },
  {
    id: "25af1eda-a2d3-4e5f-9c63-1e120b94eccf",
    airportName: "Chennai (Madras)",
    Location: "India",
    IATA: "MAA",
  },
  {
    id: "20c55793-2611-4a0f-a703-46086a666543",
    airportName: "Cheyenne, WY",
    Location: "USA",
    IATA: "CYS",
  },
  {
    id: "f2535f6e-30d6-43b2-81fa-9d2e64e2141b",
    airportName: "Chiang Mai",
    Location: "Thailand",
    IATA: "CNX",
  },
  {
    id: "fe4e29e7-ac99-4a8e-b981-5a4e9d564aec",
    airportName: "Chiba City",
    Location: "Japan",
    IATA: "QCB",
  },
  {
    id: "ea4bfe38-612e-4edb-9d68-9fd87634f4e2",
    airportName: "Chicago, Midway, IL",
    Location: "USA",
    IATA: "MDW",
  },
  {
    id: "78b41d01-481a-4bb8-8520-22a6c27772d5",
    airportName: "Chicago O'Hare Int'l Airport, IL",
    Location: "USA",
    IATA: "ORD",
  },
  {
    id: "d546dbbd-35fa-4df9-a879-8fcece0268b9",
    airportName: "Chicago, IL",
    Location: "USA",
    IATA: "CHI",
  },
  {
    id: "59b964e6-0274-4e40-abd6-030399575860",
    airportName: "Chichen Itza",
    Location: "Mexico",
    IATA: "CZA",
  },
  {
    id: "ceae1d2b-174a-4012-873b-5e475ed3f6e3",
    airportName: "Chico, CA",
    Location: "USA",
    IATA: "CIC",
  },
  {
    id: "8fa900fd-f4d5-45e3-b0f0-6e77009b8d2a",
    airportName: "Chihuahua, Gen Fierro Villalobos",
    Location: "Mexico",
    IATA: "CUU",
  },
  {
    id: "ccfb04e4-8054-4ba0-895b-615eb04a64a4",
    airportName: "Chios",
    Location: "Greece",
    IATA: "JKH",
  },
  {
    id: "b9f85576-a648-4415-8ff5-82b007848e85",
    airportName: "Chipata",
    Location: "Zambia",
    IATA: "CIP",
  },
  {
    id: "e06a2123-e854-476f-bd91-400681bc9fbb",
    airportName: "Chita (Tschita)",
    Location: "Russia",
    IATA: "HTA",
  },
  {
    id: "315e52db-18b4-4ebd-980a-20c56f5c6640",
    airportName: "Chitose",
    Location: "Japan",
    IATA: "CTS",
  },
  {
    id: "a274dfce-a9ab-4bd6-834c-3fb29ab89a05",
    airportName: "Chitral",
    Location: "Pakistan",
    IATA: "CJL",
  },
  {
    id: "8f8c4213-6188-4790-9f8a-e26b2f2380be",
    airportName: "Chittagong",
    Location: "Bangladesh",
    IATA: "CGP",
  },
  {
    id: "4c288d7e-ffb8-4be3-b6db-9688c55bf5f8",
    airportName: "Chongqing",
    Location: "Sichuan, China",
    IATA: "CKG",
  },
  {
    id: "8bcb7616-6d48-4411-831e-70e0bd958b2a",
    airportName: "Christchurch",
    Location: "New Zealand",
    IATA: "CHC",
  },
  {
    id: "7ad9ac21-ea3b-4a2a-844f-51e60005390f",
    airportName: "Christmas Line",
    Location: "Iceland",
    IATA: "CXI",
  },
  {
    id: "304b3d7f-3c34-4b9d-a554-2efa22aa255e",
    airportName: "Chub Cay",
    Location: "Bahamas",
    IATA: "CCZ",
  },
  {
    id: "52d02990-f78d-4db3-93f5-52581f1538f0",
    airportName: "Churchill",
    Location: "Canada",
    IATA: "YYQ",
  },
  {
    id: "91d0dffc-2e28-4960-b7ee-d3a34ed974ac",
    airportName: "Cienfuegos",
    Location: "Canada",
    IATA: "CFG",
  },
  {
    id: "6a08e059-9869-4c91-9c9b-bca4006c8d17",
    airportName: "Cincinnati, OH",
    Location: "USA",
    IATA: "CVG",
  },
  {
    id: "869d9227-656f-4fb4-9173-b55610c66f60",
    airportName: "Ciudad Del Carmen",
    Location: "Mexico",
    IATA: "CME",
  },
  {
    id: "e872d9bd-78c9-4770-a07d-9c7c15de9997",
    airportName: "Ciudad Juarez",
    Location: "Mexico",
    IATA: "CJS",
  },
  {
    id: "833ea5c0-efcb-40f3-8873-2deb849c8357",
    airportName: "Ciudad Obregon",
    Location: "Mexico",
    IATA: "CEN",
  },
  {
    id: "2dd5c7e9-fc7f-404a-9f9c-59190dc72a3e",
    airportName: "Ciudad Victoria",
    Location: "Mexico",
    IATA: "CVM",
  },
  {
    id: "afcbe087-1bc3-4e73-b78d-74814faee2e2",
    airportName: "Clarksburg, WV",
    Location: "USA",
    IATA: "CKB",
  },
  {
    id: "de76ebef-2def-48b0-8c7a-6c236f3b32d3",
    airportName: "Clermont",
    Location: "Australia",
    IATA: "CMQ",
  },
  {
    id: "2e3a1de3-841e-4bed-8a0d-0cd26637e8eb",
    airportName: "Clermont Ferrand",
    Location: "France",
    IATA: "CFE",
  },
  {
    id: "d36fc259-c790-4dd0-86cc-0a6f15db678b",
    airportName: "Cleveland, Burke Lakefront, OH",
    Location: "USA",
    IATA: "BKL",
  },
  {
    id: "bd6de991-ce60-40af-bc6f-b1fbca0ca9de",
    airportName: "Cleveland, Hopkins, OH",
    Location: "USA",
    IATA: "CLE",
  },
  {
    id: "3b91b022-5ac0-4830-b8bf-0666005b59d2",
    airportName: "Cochabamba",
    Location: "Bolivia",
    IATA: "CBB",
  },
  {
    id: "85f77eb0-8b8f-4eb8-b5b0-49c56f01efcb",
    airportName: "Cochin",
    Location: "India",
    IATA: "COK",
  },
  {
    id: "325b57c1-f184-46a0-9db0-4b99acfe04b7",
    airportName: "Cody/Powell/Yellowstone, WY",
    Location: "USA",
    IATA: "COD",
  },
  {
    id: "29ce96b2-ae65-4f43-bbd2-300461036f60",
    airportName: "Coffmann Cove, AK",
    Location: "USA",
    IATA: "KCC",
  },
  {
    id: "f2d03ec7-8810-4e10-8c1a-cb2eb3df3ef4",
    airportName: "Coffs Harbour",
    Location: "Australia",
    IATA: "CFS",
  },
  {
    id: "8a7d1f56-f0c2-4caa-9b5d-f186f9438dfd",
    airportName: "Coimbatore",
    Location: "India",
    IATA: "CJB",
  },
  {
    id: "40e2f5d9-8212-4ac7-98ee-78b732f586cd",
    airportName: "Colima",
    Location: "Mexico",
    IATA: "CLQ",
  },
  {
    id: "471034e3-6828-4326-85d6-5ae5e1417f69",
    airportName: "College Station/Bryan, TX",
    Location: "USA",
    IATA: "CLL",
  },
  {
    id: "f40e3dda-c1fd-4957-b725-038e8a86cf25",
    airportName: "Collinsville",
    Location: "Australia",
    IATA: "KCE",
  },
  {
    id: "9b698e70-faf8-4fc0-8b67-7b8e1267d8a2",
    airportName: "Cologne (Koeln)/Bonn",
    Location: "Germany",
    IATA: "CGN",
  },
  {
    id: "2b751f3f-a95b-497e-88e2-0d8e0a6635ff",
    airportName: "Colombo",
    Location: "Sri Lanka",
    IATA: "CMB",
  },
  {
    id: "b5d669d7-a57d-42eb-9220-1802089bc191",
    airportName: "Colorado Springs, CO",
    Location: "USA",
    IATA: "COS",
  },
  {
    id: "35336b27-f347-439f-9ea0-fba746d801fe",
    airportName: "Columbia, SC",
    Location: "USA",
    IATA: "CAE",
  },
  {
    id: "6801c8cb-9b40-4733-bd94-921cb17386a7",
    airportName: "Columbus, GA",
    Location: "USA",
    IATA: "CSG",
  },
  {
    id: "4853b39c-4152-40e7-a688-db46d55c9632",
    airportName: "Columbus, OH",
    Location: "USA",
    IATA: "CMH",
  },
  {
    id: "ed1b5711-cf32-4a88-8227-441741d1b9a6",
    airportName: "Conakry",
    Location: "Guinea",
    IATA: "CKY",
  },
  {
    id: "6076fa76-a04b-40ee-aa10-cda7e34fb2e4",
    airportName: "Concord, CA",
    Location: "USA",
    IATA: "CCR",
  },
  {
    id: "eca4e84b-1db8-416d-bf0e-149b95ce3e12",
    airportName: "Constantine",
    Location: "Algeria",
    IATA: "CZL",
  },
  {
    id: "1973cb1a-f87b-42b8-83d8-bba4063a25ce",
    airportName: "Constanza",
    Location: "Romania",
    IATA: "CND",
  },
  {
    id: "0e768844-fae0-4208-b165-f39b7ce8686c",
    airportName: "Coober Pedy",
    Location: "Australia",
    IATA: "CPD",
  },
  {
    id: "a59735c7-bcc7-4d1e-b787-e9bedfe2be7a",
    airportName: "Cooktown",
    Location: "Australia",
    IATA: "CTN",
  },
  {
    id: "a53bacaf-6704-447a-bdcc-fbda27fd3255",
    airportName: "Cooma",
    Location: "Australia",
    IATA: "OOM",
  },
  {
    id: "1cb64c52-88a6-4353-bc7b-e12ef52b01dd",
    airportName: "Copenhagen",
    Location: "Denmark",
    IATA: "CPH",
  },
  {
    id: "52f3627d-186f-4a17-ae06-cdd260c7f757",
    airportName: "Cordoba",
    Location: "Argentina",
    IATA: "COR",
  },
  {
    id: "1c64882b-f0c5-46b2-96e7-9006b40d7644",
    airportName: "Cordoba",
    Location: "Spain",
    IATA: "ODB",
  },
  {
    id: "332bb158-db40-4157-b6ed-1cfa4a8a23cc",
    airportName: "Cordova, AK",
    Location: "USA",
    IATA: "CDV",
  },
  {
    id: "628f7fc5-2e40-4aef-9ff7-6dacb24962ad",
    airportName: "Corfu",
    Location: "Greece",
    IATA: "CFU",
  },
  {
    id: "563534b0-8cad-404a-82c9-7d123ca4d179",
    airportName: "Cork",
    Location: "Ireland",
    IATA: "ORK",
  },
  {
    id: "861bceca-a431-4733-96cf-7eb3c0270c84",
    airportName: "Corpus Christi, TX",
    Location: "USA",
    IATA: "CRP",
  },
  {
    id: "168117c1-2a9f-46f2-baa0-7ea36e8e2fe5",
    airportName: "Cotonou",
    Location: "Benin",
    IATA: "COO",
  },
  {
    id: "74b0a254-4071-4a92-8fad-e19a2f78682d",
    airportName: "Cozmel",
    Location: "Mexico",
    IATA: "CZM",
  },
  {
    id: "383e172f-da3f-4526-8c06-2df892fdd05f",
    airportName: "Craig, AK",
    Location: "USA",
    IATA: "CGA",
  },
  {
    id: "2d645d05-5476-4171-bd5f-36bcfa9540c0",
    airportName: "Crescent City, CA",
    Location: "USA",
    IATA: "CEC",
  },
  {
    id: "62b7d55e-b627-44a3-9909-58a11ec980c2",
    airportName: "Cuiaba",
    Location: "Brazil",
    IATA: "CGB",
  },
  {
    id: "f7a5b0d3-2d03-47c8-8d3a-fd73445789c8",
    airportName: "Culiacan",
    Location: "Mexico",
    IATA: "CUL",
  },
  {
    id: "28bad01d-2f20-4e79-8f11-3b20dcdcacb1",
    airportName: "Curacao",
    Location: "Netherlands Antilles",
    IATA: "CUR",
  },
  {
    id: "28bbd52a-976a-413f-8db4-1212f571473c",
    airportName: "Curitiba",
    Location: "Brazil",
    IATA: "CWB",
  },
  {
    id: "bb481cf7-c58f-42ca-9d31-80d1d582c545",
    airportName: "Dakar",
    Location: "Senegal",
    IATA: "DKR",
  },
  {
    id: "8b55f938-d080-46f5-9202-d04c8728a43e",
    airportName: "Dalaman",
    Location: "Turkey",
    IATA: "DLM",
  },
  {
    id: "72cf0ec6-e58e-4a23-aabe-58dd9e6ccb1f",
    airportName: "Dalby",
    Location: "Australia",
    IATA: "DBY",
  },
  {
    id: "d5c924e8-ba87-4e4b-9dcc-58a1ae6a1893",
    airportName: "Dalian",
    Location: "Liaoning, China",
    IATA: "DLC",
  },
  {
    id: "cee09588-8dda-4df2-80f5-ec3a7a4cd122",
    airportName: "Dallas, Love Field, TX",
    Location: "USA",
    IATA: "DAL",
  },
  {
    id: "9ca3f65c-b610-4900-b01c-ff4dd297959b",
    airportName: "Dallas/Ft. Worth, TX",
    Location: "USA",
    IATA: "DFW",
  },
  {
    id: "8d41f5d8-1201-4c57-aeb1-9a66ff2b9560",
    airportName: "Daloa",
    Location: "Ivory Coast",
    IATA: "DJO",
  },
  {
    id: "658d57a1-70a4-4fea-85f3-0a07c040004a",
    airportName: "Damascus, International",
    Location: "Syria",
    IATA: "DAM",
  },
  {
    id: "ad10c034-249c-4553-8ee3-943af16edcd5",
    airportName: "Dammam, King Fahad Int'l",
    Location: "Saudi Arabien",
    IATA: "DMM",
  },
  {
    id: "ad10c034-249c-4553-8ee3-943af16edcd51",
    airportName: "Don Mueang International Airport",
    Location: "Thailand,Bangkok",
    IATA: "DMK",
  },
  {
    id: "8f868069-a407-4d27-bb9a-a0261db83479",
    airportName: "Danville, VA",
    Location: "USA",
    IATA: "DAN",
  },
  {
    id: "ccf95166-cf6b-4849-b003-6bb78a85d7a5",
    airportName: "Dar es Salam (Daressalam)",
    Location: "Tanzania",
    IATA: "DAR",
  },
  {
    id: "1fa56e02-f72e-4b15-a455-029e83c15881",
    airportName: "Darwin",
    Location: "Australia",
    IATA: "DRW",
  },
  {
    id: "39545dcb-8130-4ec6-ba16-a880aceff70f",
    airportName: "Daydream Island",
    Location: "Australia",
    IATA: "DDI",
  },
  {
    id: "97b4c589-681e-4975-9286-383ee466b990",
    airportName: "Dayton, OH",
    Location: "USA",
    IATA: "DAY",
  },
  {
    id: "69f91380-c621-42fd-89e3-a098629409b9",
    airportName: "Daytona Beach, FL",
    Location: "USA",
    IATA: "DAB",
  },
  {
    id: "97a3eeb8-b946-434f-b679-a9bf4db9a1e8",
    airportName: "Decatur, IL",
    Location: "USA",
    IATA: "DEC",
  },
  {
    id: "333d053e-4fde-4e0b-8900-818f46e7d997",
    airportName: "Deer Lake/Corner Brook",
    Location: "Canada",
    IATA: "YDF",
  },
  {
    id: "fa774114-0b63-4ffc-97b7-0f9851389ac6",
    airportName: "Delhi,Indira Gandhi",
    Location: "India",
    IATA: "DEL",
  },
  {
    id: "d2d40710-83b1-4909-9d47-5c4cbf5c8112",
    airportName: "Den Haag (The Hague)",
    Location: "Netherlands",
    IATA: "HAG",
  },
  {
    id: "1049a5f0-1db2-4eaf-8280-e151352eb5db",
    airportName: "Denpasar/Bali",
    Location: "Indonesia",
    IATA: "DPS",
  },
  {
    id: "5dd9e5fb-cea3-420c-a55a-fafc30893e16",
    airportName: "Denver International Airport, CO",
    Location: "USA",
    IATA: "DEN",
  },
  {
    id: "1baaef5e-81b9-4362-b9b1-f92a26498eee",
    airportName: "Dera Ismail Khan",
    Location: "Pakistan",
    IATA: "DSK",
  },
  {
    id: "9b732f04-a6a1-4844-bb41-3c31932d5977",
    airportName: "Derby",
    Location: "Australia",
    IATA: "DRB",
  },
  {
    id: "8c4c1283-9f55-402c-af14-9680376dce1a",
    airportName: "Derby (East Midlands)",
    Location: "United Kingdom",
    IATA: "EMA",
  },
  {
    id: "15056b29-742a-4a2a-b431-cdfc1d95d2de",
    airportName: "Derry (Londonderry)",
    Location: "United Kingdom",
    IATA: "LDY",
  },
  {
    id: "f2ef10ce-da15-401a-a5e7-a57dc6a6a75c",
    airportName: "Des Moines, IA",
    Location: "USA",
    IATA: "DSM",
  },
  {
    id: "793deed4-9aff-427a-8cde-c0bd7e7e3770",
    airportName: "Detroit City, MI",
    Location: "USA",
    IATA: "DET",
  },
  {
    id: "a23c9e54-e9bc-49d1-8bb8-f89fdcbdfca0",
    airportName: "Detroit Metropolitan Wayne County Airport, MI",
    Location: "USA",
    IATA: "DTW",
  },
  {
    id: "6e7dba3a-d41d-4906-8e25-301e16c0b984",
    airportName: "Detroit Metropolitan Area, MI",
    Location: "USA",
    IATA: "DTT",
  },
  {
    id: "efbb7dd0-7110-4a90-b361-34e822fc4260",
    airportName: "Devils Lake, ND",
    Location: "USA",
    IATA: "DVL",
  },
  {
    id: "1518d3d3-f157-4ab8-9ad8-d5d45e868680",
    airportName: "Devonport",
    Location: "Australia",
    IATA: "DPO",
  },
  {
    id: "3930e7ed-e8d2-4fb4-b57b-c7a5f8605210",
    airportName: "Dhahran",
    Location: "Saudi Arabia",
    IATA: "DHA",
  },
  {
    id: "110cc3a6-c411-4ad7-bd81-57165232b703",
    airportName: "Dhaka",
    Location: "Bangladesh",
    IATA: "DAC",
  },
  {
    id: "73b2f978-7c15-494a-b552-4a79f832e9b0",
    airportName: "Dillingham, AK",
    Location: "USA",
    IATA: "DLG",
  },
  {
    id: "452e955d-95d7-44e4-a650-e47620fc3f91",
    airportName: "Dinard",
    Location: "France",
    IATA: "DNR",
  },
  {
    id: "8c982e2f-995c-459d-99b1-52f54d54367b",
    airportName: "Djerba",
    Location: "Tunisia",
    IATA: "DJE",
  },
  {
    id: "0bd98a75-fea9-49a6-9a9f-771a537aa470",
    airportName: "Djibouti",
    Location: "Djibouti",
    IATA: "JIB",
  },
  {
    id: "66536f0e-4ad0-41f4-8b41-37ad5e50da88",
    airportName: "Doha",
    Location: "Qatar",
    IATA: "DOH",
  },
  {
    id: "b6cf55d5-8626-4f1b-8d7d-1a819b4ce24a",
    airportName: "Doncaster,Robin Hood Int'l Airport",
    Location: "UK",
    IATA: "DCS",
  },
  {
    id: "86a8ccbd-bd4e-498d-bc14-5210e0dc2b87",
    airportName: "Dongguan",
    Location: "Guangdong,China",
    IATA: "DGM",
  },
  {
    id: "974840fd-bf7b-4f69-a43c-7a65c4256c6c",
    airportName: "Dortmund",
    Location: "Germany",
    IATA: "DTM",
  },
  {
    id: "dd03617a-a8f1-4601-99f8-a815d1e72338",
    airportName: "Dothan,AL",
    Location: "USA",
    IATA: "DHN",
  },
  {
    id: "fddd170f-d223-467b-b8ad-a5ff1bae8bf4",
    airportName: "Douala",
    Location: "Cameroon",
    IATA: "DLA",
  },
  {
    id: "07c2ae03-8445-45b6-83f5-c4276361d85d",
    airportName: "Dresden",
    Location: "Germany",
    IATA: "DRS",
  },
  {
    id: "c109e4e6-5627-4386-8ab9-fb9e716f9019",
    airportName: "Dubai",
    Location: "UAE",
    IATA: "DXB",
  },
  {
    id: "5494dd09-541d-4090-90e7-6dffde6edda7",
    airportName: "Dubbo",
    Location: "Australia",
    IATA: "DBO",
  },
  {
    id: "442bec2d-53c7-4c19-aec6-fadbee31d57e",
    airportName: "Dublin",
    Location: "Ireland",
    IATA: "DUB",
  },
  {
    id: "677eb517-dbfd-4110-af37-1b55b46f642c",
    airportName: "Dubois, PA",
    Location: "USA",
    IATA: "DUJ",
  },
  {
    id: "64570c5a-0539-4fe3-82cb-8581a6326818",
    airportName: "Dubrovnik",
    Location: "Croatia (Hrvatska)",
    IATA: "DBV",
  },
  {
    id: "56b3c29f-63cd-4ebf-b9a7-75c7a1f1057b",
    airportName: "Dubuque, IA",
    Location: "USA",
    IATA: "DBQ",
  },
  {
    id: "03529a32-a10d-4e53-87b4-3c5618d778c9",
    airportName: "Duesseldorf",
    Location: "Germany",
    IATA: "DUS",
  },
  {
    id: "0bc94db4-966f-4f55-8738-667784fc173b",
    airportName: "Duluth, MN/Superior, WI",
    Location: "USA",
    IATA: "DLH",
  },
  {
    id: "e9ef0844-dd7f-448d-9ab9-67fca4f91ace",
    airportName: "Dunedin",
    Location: "New Zealand",
    IATA: "DUD",
  },
  {
    id: "94d2d96b-0773-4745-99b2-43dbc814874a",
    airportName: "Dunk Iceland",
    Location: "Australia",
    IATA: "DKI",
  },
  {
    id: "bb8f3c34-a84a-40c0-9792-b327be66cc33",
    airportName: "Durango, CO",
    Location: "USA",
    IATA: "DRO",
  },
  {
    id: "88e76b35-53f5-48c3-a261-ce9853ed94c3",
    airportName: "Durban",
    Location: "South Africa",
    IATA: "DUR",
  },
  {
    id: "36394235-3ba3-4b13-8c92-a9c1d9b3ece3",
    airportName: "Dushanbe (Duschanbe)",
    Location: "Tadschikistan",
    IATA: "DYU",
  },
  {
    id: "1235072d-30c3-4117-887c-f8049164707e",
    airportName: "Dutch Harbor, AK",
    Location: "USA",
    IATA: "DUT",
  },
  {
    id: "5b49f1bc-be0c-44d3-a3e6-25845fa7d692",
    airportName: "Dysart",
    Location: "Australia",
    IATA: "DYA",
  },
  {
    id: "3dd86952-4a4a-49f7-a8d6-b875f40b25dd",
    airportName: "Dzaoudzi",
    Location: "Mayotte",
    IATA: "DZA",
  },
  {
    id: "548b758f-2af1-49ab-a6da-c139e9959f98",
    airportName: "East London",
    Location: "South Africa",
    IATA: "ELS",
  },
  {
    id: "20edf520-8de6-47e0-9dcf-9ebeaea5bf9c",
    airportName: "Easter Island",
    Location: "Chile",
    IATA: "IPC",
  },
  {
    id: "7dc41a8f-49fd-44dd-be94-c86f4155580a",
    airportName: "Eau Clarie, WI",
    Location: "USA",
    IATA: "EAU",
  },
  {
    id: "8c5a079f-a263-41d3-952f-e4b09e6d22a9",
    airportName: "Edinburgh",
    Location: "United Kingdom",
    IATA: "EDI",
  },
  {
    id: "95cf20fe-4814-4dca-99d1-22f84e0eaf44",
    airportName: "Edmonton",
    Location: "Canada",
    IATA: "YEA",
  },
  {
    id: "95e9dada-887f-4794-8913-5eb50b83f1c3",
    airportName: "Edmonton, International",
    Location: "Canada",
    IATA: "YEG",
  },
  {
    id: "90e49f88-0259-4b7d-bd73-801c7f71c72b",
    airportName: "Edmonton, Municipal",
    Location: "Canada",
    IATA: "YXD",
  },
  {
    id: "b32f8479-bb2d-4416-a6b7-81db67e9d458",
    airportName: "Egilsstadir",
    Location: "Iceland",
    IATA: "EGS",
  },
  {
    id: "637c97aa-2216-4dd3-bc39-b00eeacb71e1",
    airportName: "Eindhoven",
    Location: "Netherlands",
    IATA: "EIN",
  },
  {
    id: "26636690-74fb-412b-aa39-14421db35d28",
    airportName: "El Paso, TX",
    Location: "USA",
    IATA: "ELP",
  },
  {
    id: "96e7164a-a13e-45c4-8505-af851e2664ed",
    airportName: "Elat",
    Location: "Israel",
    IATA: "ETH",
  },
  {
    id: "613a59d5-a015-48b5-9a46-57a43fb81865",
    airportName: "Elat, Ovula",
    Location: "Israel",
    IATA: "VDA",
  },
  {
    id: "8073497b-e5a6-4cfb-a79b-06b34a93c4b5",
    airportName: "Elkhart, IN",
    Location: "USA",
    IATA: "EKI",
  },
  {
    id: "f22fe549-6958-40e0-a073-5ebaa5ee57bd",
    airportName: "Elko, NV",
    Location: "USA",
    IATA: "EKO",
  },
  {
    id: "93572ade-5366-4405-8920-9266004e9299",
    airportName: "Ellisras",
    Location: "South Africa",
    IATA: "ELL",
  },
  {
    id: "14ee30bb-b62d-48b5-84cf-e225ce370f8b",
    airportName: "El Minya",
    Location: "Egypt",
    IATA: "EMY",
  },
  {
    id: "f7644d24-60f3-4b84-9b0a-fcb466833185",
    airportName: "Elmira, NY",
    Location: "USA",
    IATA: "ELM",
  },
  {
    id: "77e38d75-bb30-4640-985c-f9c990622ea8",
    airportName: "Ely, NV",
    Location: "USA",
    IATA: "ELY",
  },
  {
    id: "0cfee9f8-ba5e-4e36-b92a-123d0d6b7faa",
    airportName: "Emerald",
    Location: "Australia",
    IATA: "EDR",
  },
  {
    id: "97143366-47ca-4ead-9244-e691bfc3123c",
    airportName: "Emerald",
    Location: "Australia",
    IATA: "EMD",
  },
  {
    id: "ac2a4e53-8182-4d3d-8636-d31c13df8207",
    airportName: "Enontekioe",
    Location: "Finland",
    IATA: "ENF",
  },
  {
    id: "228db6d0-8979-4c54-8329-f7b94416b4d9",
    airportName: "Entebbe",
    Location: "Uganda",
    IATA: "EBB",
  },
  {
    id: "bc74babd-0951-4793-a33b-b1037223f944",
    airportName: "Erfurt",
    Location: "Germany",
    IATA: "ERF",
  },
  {
    id: "98293d12-adcb-43f5-8342-fe67a24e287e",
    airportName: "Erie, PA",
    Location: "USA",
    IATA: "ERI",
  },
  {
    id: "a627ca5f-52e0-4760-915c-f4d103333f5b",
    airportName: "Eriwan (Yerevan, Jerevan)",
    Location: "Armenia",
    IATA: "EVN",
  },
  {
    id: "f4c06ada-95c6-4fee-8927-2d8555ce30d5",
    airportName: "Esbjerg",
    Location: "Denmark",
    IATA: "EBJ",
  },
  {
    id: "7a9519d6-2a40-401b-91c6-d562fc92b443",
    airportName: "Escabana, MI",
    Location: "USA",
    IATA: "ESC",
  },
  {
    id: "f909ade6-92d2-481c-b280-795cb522d024",
    airportName: "Esperance",
    Location: "Australia",
    IATA: "EPR",
  },
  {
    id: "d96a8e7e-f50a-402e-a6dc-b340fe27396c",
    airportName: "Eugene, OR",
    Location: "USA",
    IATA: "EUG",
  },
  {
    id: "b0d42dfa-b497-43a0-8fd4-1f1bd20d0575",
    airportName: "Eureka, CA",
    Location: "USA",
    IATA: "ACV",
  },
  {
    id: "36453a99-189d-4eff-8480-7809a3698cd1",
    airportName: "Evansville, IN",
    Location: "USA",
    IATA: "EVV",
  },
  {
    id: "638bd7f8-cf7e-4f56-a7a7-494acf3ca5bb",
    airportName: "Evenes",
    Location: "Norway",
    IATA: "EVE",
  },
  {
    id: "5374cc55-8a21-4b12-955e-cdc1b6693f2b",
    airportName: "Fairbanks, AK",
    Location: "USA",
    IATA: "FAI",
  },
  {
    id: "68a6d61c-4fba-423c-b74c-a90be1b08210",
    airportName: "Faisalabad",
    Location: "Pakistan",
    IATA: "LYP",
  },
  {
    id: "2008ee8c-ae67-4562-8eef-24dd1e251708",
    airportName: "Fargo, ND, MN",
    Location: "USA",
    IATA: "FAR",
  },
  {
    id: "80b3ec74-1d7f-4fb7-aa68-c1b5808fbea8",
    airportName: "Farmington, NM",
    Location: "USA",
    IATA: "FMN",
  },
  {
    id: "3e37cbec-6f08-4528-b02e-fafbdd456b54",
    airportName: "Faro",
    Location: "Portugal",
    IATA: "FAO",
  },
  {
    id: "ae9e2308-dd86-409f-8dd5-a43bf4948b78",
    airportName: "Faroer",
    Location: "Denmark",
    IATA: "FAE",
  },
  {
    id: "1c50ef2a-3428-4153-b4aa-a36bf419f8dc",
    airportName: "Fayetteville, AR",
    Location: "USA",
    IATA: "FYV",
  },
  {
    id: "084f1784-45f5-4952-9179-2dfdc6592124",
    airportName: "Fayetteville/Ft. Bragg, NC",
    Location: "USA",
    IATA: "FAY",
  },
  {
    id: "2b5f4c39-c0c6-4c47-9142-93c964b9bbbb",
    airportName: "Fes",
    Location: "Morocco",
    IATA: "FEZ",
  },
  {
    id: "3dfef569-2f83-41f4-88cd-6b347e970a27",
    airportName: "Figari",
    Location: "France",
    IATA: "FSC",
  },
  {
    id: "a861b776-7caf-4787-a931-674aa1a3b013",
    airportName: "Flagstaff, AZ",
    Location: "USA",
    IATA: "FLG",
  },
  {
    id: "47869ae2-0fb8-4b1c-85f0-507aec345170",
    airportName: "Flin Flon",
    Location: "Canada",
    IATA: "YFO",
  },
  {
    id: "968400d5-8ee5-4e01-85fb-3ce7676fe891",
    airportName: "Flint, MI",
    Location: "USA",
    IATA: "FNT",
  },
  {
    id: "b89fb035-3c09-4492-8b71-c3b646974fb1",
    airportName: "Florence",
    Location: "Italy",
    IATA: "FLR",
  },
  {
    id: "e4959991-5218-4102-864c-9ce86fffeefa",
    airportName: "Florence, SC",
    Location: "USA",
    IATA: "FLO",
  },
  {
    id: "db04cc01-ada4-4082-bb4b-9063458862c4",
    airportName: "Florianopolis",
    Location: "Brazil",
    IATA: "FLN",
  },
  {
    id: "37795dbf-8adf-4f72-a34d-b19da30b1468",
    airportName: "Floro",
    Location: "Norway",
    IATA: "FRO",
  },
  {
    id: "4245b906-2088-435b-a639-e65b8ec954c3",
    airportName: "Fort Albert",
    Location: "Canada",
    IATA: "YFA",
  },
  {
    id: "bd26c511-497b-4120-ab53-d821c4e9ad85",
    airportName: "Fort de France",
    Location: "Martinique",
    IATA: "FDF",
  },
  {
    id: "d7bc6d9b-3ae9-44f6-b416-6f1dd1826f82",
    airportName: "Fort Dodge, IA",
    Location: "USA",
    IATA: "FOD",
  },
  {
    id: "b0132cda-e3c7-4f47-b91f-5c48b6b48cee",
    airportName: "Fort Huachuca/Sierra Vista, AZ",
    Location: "USA",
    IATA: "FHU",
  },
  {
    id: "87d33215-a0d2-4f26-898b-e8d4da083ae6",
    airportName: "Fort Lauderdale/Hollywood, FL",
    Location: "USA",
    IATA: "FLL",
  },
  {
    id: "4588cf81-b1d8-4ad9-8a86-b61f3a670fe3",
    airportName: "Fort McMurray",
    Location: "Canada",
    IATA: "YMM",
  },
  {
    id: "50376a05-ed4a-4fc8-a8a2-0965d2cf49d6",
    airportName: "Fort Myers, Metropolitan Area, FL",
    Location: "USA",
    IATA: "FMY",
  },
  {
    id: "05217547-3356-41ab-b970-be00de732e08",
    airportName: "Fort Myers, Southwest Florida Reg, FL",
    Location: "USA",
    IATA: "RSW",
  },
  {
    id: "9e58a613-4819-4d02-8eb5-b3f2c50e55b9",
    airportName: "Fort Smith",
    Location: "Canada",
    IATA: "YSM",
  },
  {
    id: "aaee7621-8305-41e2-a82b-27c31757c723",
    airportName: "Fort Smith, AR",
    Location: "USA",
    IATA: "FSM",
  },
  {
    id: "2a8e845a-9c44-4ec6-9ec2-998aa9a02abf",
    airportName: "Fort St. John",
    Location: "Canada",
    IATA: "YXJ",
  },
  {
    id: "7f8c68bf-7797-4463-bcc5-66c9f6e62232",
    airportName: "Fort Walton Beach, FL",
    Location: "USA",
    IATA: "VPS",
  },
  {
    id: "e9c753b9-e008-4dc6-83d3-2804a1903373",
    airportName: "Fort Wayne, IN",
    Location: "USA",
    IATA: "FWA",
  },
  {
    id: "bb6069d1-1ae2-4bef-b19b-e3242b53f645",
    airportName: "Fortaleza",
    Location: "Brazil",
    IATA: "FOR",
  },
  {
    id: "664a4c71-053a-4241-9b75-3094fe6d55c5",
    airportName: "Francistown",
    Location: "Botswana",
    IATA: "FRW",
  },
  {
    id: "0e5360de-bd43-41f9-a1d4-e9dff0990992",
    airportName: "Frankfurt/Main Int'l Airport",
    Location: "Germany",
    IATA: "FRA",
  },
  {
    id: "036b2c50-96f2-454c-bbb0-3771cd4808d6",
    airportName: "Frankfurt/Hahn",
    Location: "Germany",
    IATA: "HNN",
  },
  {
    id: "0ead353a-8f97-4bc3-ab41-dd16c5a699ad",
    airportName: "Franklin/Oil City, PA",
    Location: "USA",
    IATA: "FKL",
  },
  {
    id: "302fdd4f-9080-4b86-b98d-86c7067e58ba",
    airportName: "Fredericton",
    Location: "Canada",
    IATA: "YFC",
  },
  {
    id: "a0cb97d5-94ba-4daa-a3eb-8274f645fecc",
    airportName: "Freeport",
    Location: "Bahamas",
    IATA: "FPO",
  },
  {
    id: "8c3b208a-0753-4433-ae97-253ec3c26980",
    airportName: "Freetown",
    Location: "Sierra Leone",
    IATA: "FNA",
  },
  {
    id: "14eeb4da-bb36-4189-bc29-285048a43e70",
    airportName: "Frejus",
    Location: "France",
    IATA: "FRJ",
  },
  {
    id: "a1335a84-2d0d-4aa8-99a2-edea447ef9a2",
    airportName: "Fresno, CA",
    Location: "USA",
    IATA: "FAT",
  },
  {
    id: "566f152f-9723-4a64-859e-e49b748b5dab",
    airportName: "Friedrichshafen",
    Location: "Germany",
    IATA: "FDH",
  },
  {
    id: "8d506783-5c10-4cc8-a4c0-0f35289578fd",
    airportName: "Fuerteventura",
    Location: "Spain",
    IATA: "FUE",
  },
  {
    id: "aac2a7c7-2825-468a-a775-9fea26539c93",
    airportName: "Fukuoka",
    Location: "Japan",
    IATA: "FUK",
  },
  {
    id: "a0acc39d-f735-49af-b8ce-4c11df54d84d",
    airportName: "Fukushima-shi, Fukushima Airport",
    Location: "Japan",
    IATA: "FKS",
  },
  {
    id: "592fa0bc-73f3-4082-a256-1467d6d07f1c",
    airportName: "Funchal",
    Location: "Portugal",
    IATA: "FNC",
  },
  {
    id: "36522934-e406-411e-a371-da86e60fbfd9",
    airportName: "Futuna",
    Location: "Wallis and Futuna Islands",
    IATA: "FUT",
  },
  {
    id: "59e695e3-0761-455c-aea0-9128555ea936",
    airportName: "Gaborone",
    Location: "Botswana",
    IATA: "GBE",
  },
  {
    id: "00df263f-0de7-49b0-9497-11fdb15a2dfe",
    airportName: "Gadsden, AL",
    Location: "USA",
    IATA: "GAD",
  },
  {
    id: "8861de7f-7928-4b03-9844-01073e6ec4a7",
    airportName: "Gainesville, FL",
    Location: "USA",
    IATA: "GNV",
  },
  {
    id: "fd484022-3cf7-48f3-a49a-28157f530c16",
    airportName: "Galway",
    Location: "Ireland",
    IATA: "GWY",
  },
  {
    id: "6245f000-572b-4983-9f2d-0f3227746826",
    airportName: "Gander",
    Location: "Canada",
    IATA: "YQX",
  },
  {
    id: "b7e718d1-5a41-4295-b8f5-03d593e54093",
    airportName: "Garoua",
    Location: "Cameroon",
    IATA: "GOU",
  },
  {
    id: "77936e1c-f470-4ef2-872d-41f2c6cca4a2",
    airportName: "Gdansk",
    Location: "Poland",
    IATA: "GDN",
  },
  {
    id: "02dd4e0e-52a9-4dae-b6a7-369e1ca1a14f",
    airportName: "Geelong",
    Location: "Australia",
    IATA: "GEX",
  },
  {
    id: "68619906-c69b-45ab-bf58-43e040bcc073",
    airportName: "Geneva",
    Location: "Switzerland",
    IATA: "GVA",
  },
  {
    id: "3d04fe11-8334-4e45-9bb6-423b42043988",
    airportName: "Genoa",
    Location: "Italy",
    IATA: "GOA",
  },
  {
    id: "a60b15b3-b810-4ff5-8dd9-f7f31fbb48d5",
    airportName: "George",
    Location: "South Africa",
    IATA: "GRJ",
  },
  {
    id: "90556e02-31e7-4ccc-ba8a-3dacdf9fe0b9",
    airportName: "Georgetown",
    Location: "Guyana",
    IATA: "GEO",
  },
  {
    id: "43093eb6-fe87-47bc-b264-ed608ee487ee",
    airportName: "Geraldton",
    Location: "Australia",
    IATA: "GET",
  },
  {
    id: "a9c845ab-7d06-47be-b359-758ada277bed",
    airportName: "Gerona",
    Location: "Spain",
    IATA: "GRO",
  },
  {
    id: "d2b3b201-6524-43cc-8956-c0b26bd2239f",
    airportName: "Gibraltar",
    Location: "Gibraltar",
    IATA: "GIB",
  },
  {
    id: "ce4b52eb-92a3-450e-a980-9021dd6b69e7",
    airportName: "Gilette, WY",
    Location: "USA",
    IATA: "GCC",
  },
  {
    id: "194f3291-50d0-4251-bbb9-c3e013e5e703",
    airportName: "Gilgit",
    Location: "Pakistan",
    IATA: "GIL",
  },
  {
    id: "679aa562-c27b-4de9-a75c-f0628121f617",
    airportName: "Gillam",
    Location: "Canada",
    IATA: "YGX",
  },
  {
    id: "68837b7b-91cc-4d19-91c2-6bd5470615d1",
    airportName: "Gladstone",
    Location: "Australia",
    IATA: "GLT",
  },
  {
    id: "6fdda8f4-ca06-4c58-962b-339b36d9e8de",
    airportName: "Glasgow, Prestwick",
    Location: "United Kingdom",
    IATA: "PIK",
  },
  {
    id: "31b707ec-a606-4fd3-8ee2-848b0c3fc3a9",
    airportName: "Glasgow",
    Location: "United Kingdom",
    IATA: "GLA",
  },
  {
    id: "ecff83c6-4e16-4202-9110-f32ba79ebf59",
    airportName: "Glasgow, MT",
    Location: "USA",
    IATA: "GGW",
  },
  {
    id: "603d41e2-2714-49c4-8c17-ec3ef75fef74",
    airportName: "Glendive, MT",
    Location: "USA",
    IATA: "GDV",
  },
  {
    id: "499a3073-4e57-46e6-af0d-502a2eac825d",
    airportName: "Goa",
    Location: "India",
    IATA: "GOI",
  },
  {
    id: "84d41a31-5951-47e6-a709-b6231968afb4",
    airportName: "Goiania",
    Location: "Brazil",
    IATA: "GYN",
  },
  {
    id: "1332111c-732d-420a-bcad-295b521964ec",
    airportName: "Gold Coast",
    Location: "Australia",
    IATA: "OOL",
  },
  {
    id: "05409d60-9ee0-4243-a79e-79244f1ec712",
    airportName: "Goondiwindi",
    Location: "Australia",
    IATA: "GOO",
  },
  {
    id: "552cdfcc-383a-48b6-9c75-1157b10fa481",
    airportName: "Goose Bay",
    Location: "Canada",
    IATA: "YYR",
  },
  {
    id: "ac0a9f5b-ace0-4d80-aab7-bd0adf29af64",
    airportName: "Gorna",
    Location: "Bulgaria",
    IATA: "GOZ",
  },
  {
    id: "2c9b94e5-e43a-47a7-b2df-fe79ec027f6e",
    airportName: "Goteborg",
    Location: "Sweden",
    IATA: "GOT",
  },
  {
    id: "75352aa9-05e8-433d-96e6-8f9fc9aeae8b",
    airportName: "Gove (Nhulunbuy)",
    Location: "Australia",
    IATA: "GOV",
  },
  {
    id: "cefae720-22c8-43eb-81a5-5f92ec6be14c",
    airportName: "Govenors Harbour",
    Location: "Bahamas",
    IATA: "GHB",
  },
  {
    id: "16118132-dbed-4727-bd47-a1503e9c80f5",
    airportName: "Granada",
    Location: "Spain",
    IATA: "GRX",
  },
  {
    id: "4fb3330d-5839-4fd6-995e-da16e0b9c957",
    airportName: "Grand Bahama",
    Location: "Bahamas",
    IATA: "GBI",
  },
  {
    id: "3bf4aeaa-43d6-423b-a40c-bba3d0ae02ff",
    airportName: "Grand Canyon, AZ",
    Location: "USA",
    IATA: "GCN",
  },
  {
    id: "8d7972fc-7ab1-481f-b51a-81024363fe68",
    airportName: "Grand Cayman",
    Location: "Cayman Islands",
    IATA: "GCM",
  },
  {
    id: "39297d0f-608d-4483-8884-4ed6c9cf41fe",
    airportName: "Grand Forks, ND",
    Location: "USA",
    IATA: "GFK",
  },
  {
    id: "b9e195c4-fa08-45d6-80d2-c79067491797",
    airportName: "Grand Junction, CO",
    Location: "USA",
    IATA: "GJT",
  },
  {
    id: "8784f1b6-e9d7-47f5-bdc1-04eaa65a4bde",
    airportName: "Grand Rapids, MI",
    Location: "USA",
    IATA: "GRR",
  },
  {
    id: "88a5d8dc-6ced-49a0-a55f-6b288cb0c398",
    airportName: "Grand Rapids, MN",
    Location: "USA",
    IATA: "GPZ",
  },
  {
    id: "77ff895d-d045-49b7-98cf-d02d3780d18a",
    airportName: "Graz",
    Location: "Austria",
    IATA: "GRZ",
  },
  {
    id: "8e7b13bd-94fe-4ffc-ac28-8646ab0a2d09",
    airportName: "Great Falls, MT",
    Location: "USA",
    IATA: "GTF",
  },
  {
    id: "28e22c10-d761-464a-a453-58a376b9296a",
    airportName: "Great Keppel Island",
    Location: "Australia",
    IATA: "GKL",
  },
  {
    id: "3eed1f62-3958-4008-85f5-64306492c3ea",
    airportName: "Green Bay, WI",
    Location: "USA",
    IATA: "GRB",
  },
  {
    id: "f6272459-0cb7-455e-bb1b-c9154cebfb0d",
    airportName: "Greenbrier/Lewisburg, WV",
    Location: "USA",
    IATA: "LWB",
  },
  {
    id: "2f82e83b-fc21-441a-bb51-cfde27f1a6ac",
    airportName: "Greensboro/Winston Salem, NC",
    Location: "USA",
    IATA: "GSO",
  },
  {
    id: "fb47848b-4999-4e78-b28b-042c52cbec17",
    airportName: "Greenville, MS",
    Location: "USA",
    IATA: "GLH",
  },
  {
    id: "08736579-b7cd-4c68-ba49-820fda0c7f08",
    airportName: "Greenville, NC",
    Location: "USA",
    IATA: "PGV",
  },
  {
    id: "798f39cd-236d-4ab5-8fde-e41033bf5dfa",
    airportName: "Greenville/Spartanburg, SC",
    Location: "USA",
    IATA: "GSP",
  },
  {
    id: "a99a4876-c549-43ed-ab9d-9f16913fe959",
    airportName: "Grenada",
    Location: "Grenada",
    IATA: "GND",
  },
  {
    id: "d864b472-2e47-45a2-bcee-7628d5b948d3",
    airportName: "Grenoble",
    Location: "France",
    IATA: "GNB",
  },
  {
    id: "74688a54-de75-4491-bac3-eea030076f83",
    airportName: "Griffith",
    Location: "Australia",
    IATA: "GFF",
  },
  {
    id: "d7e14256-e851-4e84-9c0f-16adf367c1ec",
    airportName: "Groote Eyeland",
    Location: "Australia",
    IATA: "GTE",
  },
  {
    id: "5d288651-37e3-431a-87af-fdf59c21b94f",
    airportName: "Groote Eylandt",
    Location: "Australia",
    IATA: "GTI",
  },
  {
    id: "5f68138c-3137-4d7f-963f-2032a69d66f6",
    airportName: "Groton/New London, CT",
    Location: "USA",
    IATA: "GON",
  },
  {
    id: "c9142dc5-d58b-49b9-882e-cf6d90293c54",
    airportName: "Guadalajara",
    Location: "Mexico",
    IATA: "GDL",
  },
  {
    id: "9b5a09e8-fb71-439a-8b01-fadf9e153a7b",
    airportName: "Guadalcanal",
    Location: "Solomon Islands",
    IATA: "GSI",
  },
  {
    id: "33d91ddf-f11f-4e78-b129-9a2389821ad9",
    airportName: "Guam",
    Location: "Guam",
    IATA: "GUM",
  },
  {
    id: "3c3ea672-bf5f-4fef-9235-35515f9af1dd",
    airportName: "Guangzhou (Canton) - Baiyun (White Cloud)",
    Location: "Guangdong, China",
    IATA: "CAN",
  },
  {
    id: "08a6d1b8-741d-4515-a483-38a17e170e8a",
    airportName: "Guarulhos Int'l - Sao Paulo",
    Location: "Brazil",
    IATA: "GRU",
  },
  {
    id: "a7ef2142-cc80-4ff0-98e1-3a6fb5b2e889",
    airportName: "Guatemala City",
    Location: "Guatemala",
    IATA: "GUA",
  },
  {
    id: "fd7fa6a1-569b-4284-a72b-e238464daff9",
    airportName: "Guayaquil",
    Location: "Ecuador",
    IATA: "GYE",
  },
  {
    id: "2fdd2033-cd10-47b7-8384-8f001415dd73",
    airportName: "Guernsey",
    Location: "United Kingdom",
    IATA: "GCI",
  },
  {
    id: "45a92f00-ccfa-4b27-9ba4-d15b9b124483",
    airportName: "Gulfport/Biloxi, MS",
    Location: "USA",
    IATA: "GPT",
  },
  {
    id: "07eefdf2-67cd-4a1a-b289-9ac65873575c",
    airportName: "Gulu",
    Location: "Uganda",
    IATA: "ULU",
  },
  {
    id: "18a0ebd0-5217-499a-877e-b338ee5dffd0",
    airportName: "Gunnison/Crested Butte, CO",
    Location: "USA",
    IATA: "GUC",
  },
  {
    id: "e6f4c25f-f60f-460b-930b-7699cc46e964",
    airportName: "Guwahati",
    Location: "India",
    IATA: "GAU",
  },
  {
    id: "afbf2bfb-8c55-4372-b0be-6a6006d11d44",
    airportName: "Gwadar",
    Location: "Pakistan",
    IATA: "GWD",
  },
  {
    id: "48a61f9e-b7cd-4ef7-8e52-732f6dcf8695",
    airportName: "Gweru",
    Location: "Zimbabwe",
    IATA: "GWE",
  },
  {
    id: "6f939b22-1cca-4185-bfe4-67c1e485c8f0",
    airportName: "Gympie",
    Location: "Australia",
    IATA: "GYP",
  },
  {
    id: "51d98cb8-77ff-4a71-819a-81bd5a07f9ad",
    airportName: "Hachijo Jima",
    Location: "Japan",
    IATA: "HAC",
  },
  {
    id: "5803c813-a8d0-4253-89c5-34b421d99c01",
    airportName: "Haifa",
    Location: "Israel",
    IATA: "HFA",
  },
  {
    id: "4815d3ee-f696-481f-9c1a-10ae44c6a7e3",
    airportName: "Haines, AK",
    Location: "USA",
    IATA: "HNS",
  },
  {
    id: "15dbb27b-261b-49ff-ab7b-2fb6d56208b8",
    airportName: "Hakodate",
    Location: "Japan",
    IATA: "HKD",
  },
  {
    id: "68bcac57-401d-4c87-91de-ac520f6f8ed0",
    airportName: "Halifax Int'l",
    Location: "Canada",
    IATA: "YHZ",
  },
  {
    id: "503c1700-48ef-40c4-ade2-78641a1a7df5",
    airportName: "Hall Beach",
    Location: "Canada",
    IATA: "YUX",
  },
  {
    id: "fec27783-a650-42e3-a681-0c48a0569156",
    airportName: "Hamburg - Fuhlsbuettel",
    Location: "Germany",
    IATA: "HAM",
  },
  {
    id: "b19c6271-b8c5-4cd4-b2de-062f7a713378",
    airportName: "Hamilton",
    Location: "Australia",
    IATA: "HLT",
  },
  {
    id: "8fc183b8-5071-4354-9eec-b97f7118faf5",
    airportName: "Hamilton",
    Location: "Canada",
    IATA: "YHM",
  },
  {
    id: "81b9e221-c7c0-49f8-8bf4-bb132b25dba8",
    airportName: "Hamilton Island",
    Location: "Australia",
    IATA: "HTI",
  },
  {
    id: "29564d2c-603e-4e92-99e1-91baa36bc867",
    airportName: "Hammerfest",
    Location: "Norway",
    IATA: "HFT",
  },
  {
    id: "8d8e860c-7fa7-4b19-9ee5-2de16934ac5d",
    airportName: "Hancock, MI",
    Location: "USA",
    IATA: "CMX",
  },
  {
    id: "67137b0f-5bd2-4301-950b-9b029fd017b3",
    airportName: "Hangchow (Hangzhou)",
    Location: "Zhejiang, China",
    IATA: "HGH",
  },
  {
    id: "201d7952-452f-4f03-a097-43567cfa1f17",
    airportName: "Hannover",
    Location: "Germany",
    IATA: "HAJ",
  },
  {
    id: "2dfe318c-6aa1-4090-91f8-c7c8affb51f5",
    airportName: "Hanoi - Noibai",
    Location: "Viet Nam",
    IATA: "HAN",
  },
  {
    id: "3acfb93a-0c09-442b-8112-c3495e9d2220",
    airportName: "Harare",
    Location: "Zimbabwe",
    IATA: "HRE",
  },
  {
    id: "ea1aaf03-2d0a-43d9-8ab7-b99d8400e36d",
    airportName: "Harbin (Haerbin)",
    Location: "Heilongjiang, China",
    IATA: "HRB",
  },
  {
    id: "b0ab92b7-c6ec-43f1-af0a-2a3e6b80103c",
    airportName: "Harlington/South Padre Island, TX",
    Location: "USA",
    IATA: "HRL",
  },
  {
    id: "32e3db23-da8b-44a6-8566-6f6e4b92e372",
    airportName: "Harrington Harbour, PQ",
    Location: "Canada",
    IATA: "YHR",
  },
  {
    id: "1a0e85f1-e4b5-4da5-8355-cb2ee63f90cc",
    airportName: "Harrisburg, PA - Harrisburg Skyport",
    Location: "USA",
    IATA: "HAR",
  },
  {
    id: "9026c783-d12d-42c8-9fca-7c5f4975c721",
    airportName: "Harrisburg, PA - Harrisburg Int'l",
    Location: "USA",
    IATA: "MDT",
  },
  {
    id: "7cc79366-8b64-49ea-b212-95fd83eaa374",
    airportName: "Hartford, CT/Springfield, MA",
    Location: "USA",
    IATA: "BDL",
  },
  {
    id: "c9fe2b26-e28d-4bb2-8deb-cc6224b08a93",
    airportName: "Hatyai (Hat Yai)",
    Location: "Thailand",
    IATA: "HDY",
  },
  {
    id: "dfd278f9-19da-4c26-9c5a-c4c2e1e97cd0",
    airportName: "Haugesund",
    Location: "Norway",
    IATA: "HAU",
  },
  {
    id: "6a0b951e-5d34-4c6b-af73-bfea7f7ad10f",
    airportName: "Havana - José Martí Int'l",
    Location: "Cuba",
    IATA: "HAV",
  },
  {
    id: "58358dd1-8997-4101-9387-8f81f0faa83f",
    airportName: "Havre, MT",
    Location: "USA",
    IATA: "HVR",
  },
  {
    id: "354859ad-97be-4bd1-b674-8544549eb829",
    airportName: "Hayman Island",
    Location: "Australia",
    IATA: "HIS",
  },
  {
    id: "afd3f955-906e-4e18-a3c5-a4f0b0ada34b",
    airportName: "Helena, MT",
    Location: "USA",
    IATA: "HLN",
  },
  {
    id: "e888ff62-de26-4ec6-b649-b70faec1041a",
    airportName: "Helsingborg",
    Location: "Sweden",
    IATA: "JHE",
  },
  {
    id: "e4ad1604-2b10-47e1-94d2-2dd98c8bbd5b",
    airportName: "Helsinki - Vantaa",
    Location: "Finland",
    IATA: "HEL",
  },
  {
    id: "efe41c15-066b-4530-8682-087936755343",
    airportName: "Heraklion",
    Location: "Greece",
    IATA: "HER",
  },
  {
    id: "0504f9ae-e378-4335-ae88-b0569adde2c1",
    airportName: "Hermosillo - Gen. Pesqueira Garcia",
    Location: "Mexico",
    IATA: "HMO",
  },
  {
    id: "ea05de37-d80c-4606-8727-bd3e86a846d1",
    airportName: "Hervey Bay",
    Location: "Australia",
    IATA: "HVB",
  },
  {
    id: "44d688ec-785c-4177-b697-cd3077678105",
    airportName: "Hibbing, MN",
    Location: "USA",
    IATA: "HIB",
  },
  {
    id: "fba7e571-95c4-4727-8a05-c3d8d61507c4",
    airportName: "Hickory, NC",
    Location: "USA",
    IATA: "HKY",
  },
  {
    id: "d77acdf0-4345-426b-9898-f1a84a8f854f",
    airportName: "Hilo, HI",
    Location: "USA",
    IATA: "ITO",
  },
  {
    id: "0990055c-6e9c-49e9-9a40-43b8192c1f1f",
    airportName: "Hilton Head Island, SC",
    Location: "USA",
    IATA: "HHH",
  },
  {
    id: "643e1536-04b3-45cf-a221-de743f1e7a46",
    airportName: "Hinchinbrook Island",
    Location: "Australia",
    IATA: "HNK",
  },
  {
    id: "17d80a59-3e26-4113-ba88-58e7b3db346a",
    airportName: "Hiroshima International",
    Location: "Japan",
    IATA: "HIJ",
  },
  {
    id: "1463f0a1-e47c-4a4a-a785-28022772e24c",
    airportName: "Ho Chi Minh City (Saigon)",
    Location: "Viet Nam",
    IATA: "SGN",
  },
  {
    id: "d798b6c1-849c-41eb-bf30-ed0214adb9ab",
    airportName: "Hobart",
    Location: "Australia",
    IATA: "HBA",
  },
  {
    id: "cb3eb9a2-3a02-4da8-b422-abbe98273e5b",
    airportName: "Hof",
    Location: "Germany",
    IATA: "HOQ",
  },
  {
    id: "2337b8a3-989e-4d03-ba0c-3e8cf9c014e3",
    airportName: "Holguin",
    Location: "Cuba",
    IATA: "HOG",
  },
  {
    id: "2e9c72ee-fe4e-47ae-9b50-8145c4d792d8",
    airportName: "Home Hill",
    Location: "Australia",
    IATA: "HMH",
  },
  {
    id: "41fcacb1-7de9-47fe-8386-f5f6dc83014f",
    airportName: "Homer, AK",
    Location: "USA",
    IATA: "HOM",
  },
  {
    id: "2b29a445-6dc4-45e0-b802-4a4f5f8d7b9c",
    airportName: "Hong Kong - Int'l Airport (HKIA)",
    Location: "Hong Kong",
    IATA: "HKG",
  },
  {
    id: "6e7dca19-d893-4bab-9cf4-96340ab917ed",
    airportName: "Hong Kong - Chek Lap Kok",
    Location: "Hong Kong",
    IATA: "ZJK",
  },
  {
    id: "9b46cc37-e8e4-4831-b488-8c6fd3263051",
    airportName: "Honiara Henderson Int'l",
    Location: "Solomon Islands",
    IATA: "HIR",
  },
  {
    id: "a2a9e5f6-06f8-4f1d-ae18-6cfe5eaee75b",
    airportName: "Honolulu, HI",
    Location: "USA",
    IATA: "HNL",
  },
  {
    id: "d5ab607a-bb20-41b6-a11e-ca293c4fd22e",
    airportName: "Hoonah, AK",
    Location: "USA",
    IATA: "HNH",
  },
  {
    id: "05b6196c-5f9d-471f-b8ca-dc322ff179f4",
    airportName: "Horta",
    Location: "Portugal",
    IATA: "HOR",
  },
  {
    id: "f6855143-b5ab-4443-8069-17d0e85d31cb",
    airportName: "Houston, Hobby, TX",
    Location: "USA",
    IATA: "HOU",
  },
  {
    id: "225bf8ed-8da4-415a-8227-ea1a00ab5895",
    airportName: "Houston, Intercontinental, TX",
    Location: "USA",
    IATA: "IAH",
  },
  {
    id: "070a9eac-44c1-4f56-9eed-d6a66f337def",
    airportName: "Huahine",
    Location: "French Polynesia",
    IATA: "HUH",
  },
  {
    id: "4b5aa33e-e320-4087-b496-0319785ab595",
    airportName: "Huatulco",
    Location: "Mexico",
    IATA: "HUX",
  },
  {
    id: "dbb5991b-596f-4459-9447-b832384acb2f",
    airportName: "Hue - Phu Bai",
    Location: "Viet Nam",
    IATA: "HUI",
  },
  {
    id: "ca151e9c-e19b-45d0-aaac-f71d84638a5d",
    airportName: "Humberside",
    Location: "United Kingdom",
    IATA: "HUY",
  },
  {
    id: "d15dc5f9-d49b-4c73-b9bd-dbd5dc498c0e",
    airportName: "Huntington, OH",
    Location: "USA",
    IATA: "HTS",
  },
  {
    id: "44ba7be9-0d1f-4510-94b9-9dd4b27f58ef",
    airportName: "Huntsville, AL",
    Location: "USA",
    IATA: "HSV",
  },
  {
    id: "4faf606a-e7bd-4685-98ca-480861f5f960",
    airportName: "Hurghada Int'l",
    Location: "Egypt",
    IATA: "HRG",
  },
  {
    id: "e1679fdb-2a5d-49f6-9541-65f744628fe5",
    airportName: "Huron, SD",
    Location: "USA",
    IATA: "HON",
  },
  {
    id: "902472ee-124d-409a-85a6-285f48f6a923",
    airportName: "Hwange National Park",
    Location: "Zimbabwe",
    IATA: "HWN",
  },
  {
    id: "6e0b41fc-66a0-4fe2-a4bf-cf71ca34a8be",
    airportName: "Hyannis, MA",
    Location: "USA",
    IATA: "HYA",
  },
  {
    id: "5c640b1e-0b2a-42f4-b00f-eddf68df6526",
    airportName: "Hydaburg, AK",
    Location: "USA",
    IATA: "HYG",
  },
  {
    id: "6e3e1707-69ab-43a1-93dc-2b8144ab6a50",
    airportName: "Hyderabad - Begumpet",
    Location: "India",
    IATA: "HYD",
  },
  {
    id: "8da2f7b9-0392-446e-967c-f46741b2df79",
    airportName: "Hyderabad",
    Location: "Pakistan",
    IATA: "HDD",
  },
  {
    id: "d45d322b-5720-4279-b6f3-552ad13ef8ea",
    airportName: "Ibiza",
    Location: "Ibiza/Spain",
    IATA: "IBZ",
  },
  {
    id: "29e4d8ae-5cf0-4fc4-b089-ba5e7291263e",
    airportName: "Idaho Falls, ID",
    Location: "USA",
    IATA: "IDA",
  },
  {
    id: "bd2f779c-d153-4152-b7d0-77513567b223",
    airportName: "Iguazu, Cataratas",
    Location: "Argentina",
    IATA: "IGR",
  },
  {
    id: "efbe8342-e80d-4467-b08f-bc9070f6109a",
    airportName: "Ile des Pins",
    Location: "New Caledonia",
    IATA: "ILP",
  },
  {
    id: "abfa6c1f-0cdc-4021-8eed-3c6580452a1b",
    airportName: "Ile Ouen",
    Location: "New Caledonia",
    IATA: "IOU",
  },
  {
    id: "8232dd30-591a-46aa-a440-1e36ab3e837c",
    airportName: "Iliamna, AK",
    Location: "USA",
    IATA: "ILI",
  },
  {
    id: "2c1b4c0d-0c11-447d-900c-25d780fbfcbb",
    airportName: "Imperial, CA",
    Location: "USA",
    IATA: "IPL",
  },
  {
    id: "e4ca676d-30f3-4624-a2ad-3501abbf180b",
    airportName: "Incercargill",
    Location: "New Zealand",
    IATA: "IVC",
  },
  {
    id: "57049497-40a2-484c-848f-cd16d78bfcc1",
    airportName: "Incheon, Incheon Int'l Airport",
    Location: "Korea South",
    IATA: "ICN",
  },
  {
    id: "bd3a3a0f-7761-4514-9752-679d7e110ac0",
    airportName: "Indianapolis, IN",
    Location: "USA",
    IATA: "IND",
  },
  {
    id: "a83740ae-4966-49da-8c8c-5f198b057435",
    airportName: "Ingham",
    Location: "Australia",
    IATA: "IGH",
  },
  {
    id: "10dc6e8e-5548-4994-8497-cadf03a648fb",
    airportName: "Innisfail",
    Location: "Australia",
    IATA: "IFL",
  },
  {
    id: "8c874286-2721-4057-bd19-1b0f03a2c3fd",
    airportName: "Innsbruck - Kranebitten",
    Location: "Austria",
    IATA: "INN",
  },
  {
    id: "ce2981b6-7d1b-4587-ba52-4638a1922fff",
    airportName: "International Falls, MN",
    Location: "USA",
    IATA: "INL",
  },
  {
    id: "82b11cdd-08bc-4fc0-9d0e-d8f801d662aa",
    airportName: "Inuvik",
    Location: "Canada",
    IATA: "YEV",
  },
  {
    id: "536a772e-c50e-4276-9702-caf36cb1c538",
    airportName: "Inverness",
    Location: "United Kingdom",
    IATA: "INV",
  },
  {
    id: "d9da950f-7192-4f4d-8529-6a182e80f98c",
    airportName: "Inykern, CA",
    Location: "USA",
    IATA: "IYK",
  },
  {
    id: "76415b6c-3ac7-4ca1-83f5-cf6f7fe850fc",
    airportName: "Iqaluit (Frobisher Bay)",
    Location: "Canada",
    IATA: "YFB",
  },
  {
    id: "ea2e8e0b-b162-40b2-95d3-f7176e57a0f6",
    airportName: "Iquitos",
    Location: "Peru",
    IATA: "IQT",
  },
  {
    id: "1acc524c-7716-4596-a30e-9a0862a58ca7",
    airportName: "Irkutsk",
    Location: "Russia",
    IATA: "IKT",
  },
  {
    id: "b49f9b53-3abc-4795-aca7-00449e01f07f",
    airportName: "Ishigaki",
    Location: "Japan",
    IATA: "LSG",
  },
  {
    id: "e156a13a-e67e-4939-bbb2-2adb567ef083",
    airportName: "Islamabad - Islamabad Int'l",
    Location: "Pakistan",
    IATA: "ISB",
  },
  {
    id: "830ffc23-9249-4af2-9518-f6e019712c04",
    airportName: "Isle of Man",
    Location: "United Kingdom",
    IATA: "IOM",
  },
  {
    id: "d26d8453-5b76-4abf-a3d7-92ea5c8cfe10",
    airportName: "Istanbul - Ataturk",
    Location: "Turkey",
    IATA: "IST",
  },
  {
    id: "1fe9f320-eae1-4b01-9164-9957c2c2f534",
    airportName: "Ithaca/Cortland, NY",
    Location: "USA",
    IATA: "ITH",
  },
  {
    id: "29a6d79b-ce9f-4907-9a24-2c3b1039134c",
    airportName: "Ivalo",
    Location: "Finland",
    IATA: "IVL",
  },
  {
    id: "45a1ea50-ae71-47f1-854e-9f00a1e89e38",
    airportName: "Ixtapa/Zihuatenejo",
    Location: "Mexico",
    IATA: "ZIH",
  },
  {
    id: "cf36f41e-1346-4696-bc13-9fd381b8b307",
    airportName: "Izmir",
    Location: "Turkey",
    IATA: "IZM",
  },
  {
    id: "46f5db62-45d2-49d7-9c9d-d81517946311",
    airportName: "Jackson Hole, WY",
    Location: "USA",
    IATA: "JAC",
  },
  {
    id: "9354061c-76c9-48f3-8cce-6d888574c92b",
    airportName: "Jackson, MI",
    Location: "USA",
    IATA: "JXN",
  },
  {
    id: "14a9b330-b40b-4e18-b05b-2c0919d4fc4d",
    airportName: "Jackson, MS",
    Location: "USA",
    IATA: "JAN",
  },
  {
    id: "db54399e-849f-4b64-8b78-74b767e6b8f2",
    airportName: "Jackson, TN",
    Location: "USA",
    IATA: "MKL",
  },
  {
    id: "da332a28-7f75-4302-8efc-dae2905f6397",
    airportName: "Jacksonville, FL",
    Location: "USA",
    IATA: "JAX",
  },
  {
    id: "9b74aa16-d7aa-459d-ad4b-3fe1e33a24c0",
    airportName: "Jacksonville, NC",
    Location: "USA",
    IATA: "OAJ",
  },
  {
    id: "8e1df144-6a26-413a-a7c6-740371cc7127",
    airportName: "Jacobabad",
    Location: "Pakistan",
    IATA: "JAG",
  },
  {
    id: "bdb66098-7d7a-4f31-b24f-fdbf8b862d76",
    airportName: "Jaipur - Sanganeer",
    Location: "India",
    IATA: "JAI",
  },
  {
    id: "9a4a102b-94fe-40d6-81db-525c248e8270",
    airportName: "Jakarta - Halim Perdanakusma",
    Location: "Indonesia",
    IATA: "HLP",
  },
  {
    id: "6015650e-3af3-4516-857a-9b88ab785891",
    airportName: "Jakarta - Kemayoran",
    Location: "Indonesia",
    IATA: "JKT",
  },
  {
    id: "611c9d94-8fef-4a83-bea2-b123737cfa7b",
    airportName: "Jakarta - Soekarno-Hatta",
    Location: "Indonesia",
    IATA: "CGK",
  },
  {
    id: "1f6dccd8-1732-48ba-9863-f8153ead464a",
    airportName: "Jalandhar",
    Location: "India",
    IATA: "JLR",
  },
  {
    id: "d3ff0cae-a0ec-476d-9a37-49473c71b3ee",
    airportName: "Jamestown, ND",
    Location: "USA",
    IATA: "JMS",
  },
  {
    id: "96f7fdd5-b761-4797-b51d-a885bfc5fea2",
    airportName: "Jamestown, NY",
    Location: "USA",
    IATA: "JHW",
  },
  {
    id: "4b33ff0a-8ea6-4459-9259-ee19219501fa",
    airportName: "Jamshedpur - Sonari",
    Location: "India",
    IATA: "IXW",
  },
  {
    id: "e3b9711a-3292-4eb4-82be-36428580920e",
    airportName: "Jeddah - King Abdulaziz Int'l",
    Location: "Saudi Arabia",
    IATA: "JED",
  },
  {
    id: "b4c23ee2-a64f-463b-9950-41756cac4fad",
    airportName: "Jerez de la Frontera/Cadiz",
    Location: "Spain",
    IATA: "XRY",
  },
  {
    id: "26ded91b-3d69-4bf3-9cb9-de8f4ba3b464",
    airportName: "Jersey",
    Location: "United Kingdom",
    IATA: "JER",
  },
  {
    id: "e67ad165-12cc-48cd-afc7-4a15c00b7683",
    airportName: "Jerusalem",
    Location: "Israel",
    IATA: "JRS",
  },
  {
    id: "caa6a8eb-5c18-4a20-8b53-b971b639a56d",
    airportName: "Jinan",
    Location: "Shandong, China",
    IATA: "TNA",
  },
  {
    id: "0d6f9499-6b39-4a1f-9a00-bc453225f844",
    airportName: "Jiwani",
    Location: "Pakistan",
    IATA: "JIW",
  },
  {
    id: "bc0ef925-cfc1-4da2-941b-6300edca78b0",
    airportName: "Joacaba",
    Location: "Brazil",
    IATA: "JCB",
  },
  {
    id: "fc2915d7-3cea-4011-8990-12a95c62efa4",
    airportName: "Joao Pessoa - Castro Pinto",
    Location: "Brazil",
    IATA: "JPA",
  },
  {
    id: "708422ec-2ab3-42fe-aad6-873e872ecd2d",
    airportName: "Joenkoeping",
    Location: "Sweden",
    IATA: "JKG",
  },
  {
    id: "c9f9f079-7cee-460f-a13c-7f9f295b773f",
    airportName: "Joensuu",
    Location: "Finland",
    IATA: "JOE",
  },
  {
    id: "7eb6eff5-eac5-49fa-9627-d9f61a62e8e7",
    airportName: "Johannesburg - Johannesburg Int'l",
    Location: "South Africa",
    IATA: "JNB",
  },
  {
    id: "ff3f776f-ca8f-4766-a568-6b7d11cc3fa6",
    airportName: "Johnstown, PA",
    Location: "USA",
    IATA: "JST",
  },
  {
    id: "867fb1a9-9b43-4f2b-90b4-433558c6ed61",
    airportName: "Johore Bahru",
    Location: "Malaysia",
    IATA: "JHB",
  },
  {
    id: "c33250c6-38f5-4160-84c9-178c5ce266d2",
    airportName: "Joplin, MO",
    Location: "USA",
    IATA: "JLN",
  },
  {
    id: "7a27bcc8-cd90-4530-b6f5-0a52dd502369",
    airportName: "Jounieh",
    Location: "Lebanon",
    IATA: "GJN",
  },
  {
    id: "81f1f2a2-c352-459f-8aee-e0d164e35114",
    airportName: "Juneau, AK - Juneau Int'l",
    Location: "USA",
    IATA: "JNU",
  },
  {
    id: "37a8037e-3f1c-40e9-a4f8-ab6c2e98e1be",
    airportName: "Jyvaeskylae",
    Location: "Finland",
    IATA: "JYV",
  },
  {
    id: "27aaa6bf-adc5-4f51-8278-1508251ace86",
    airportName: "Kabul - Khwaja Rawash",
    Location: "Afghanistan",
    IATA: "KBL",
  },
  {
    id: "e1c3a625-3013-4771-80da-092254fccdec",
    airportName: "Kagoshima",
    Location: "Japan",
    IATA: "KOJ",
  },
  {
    id: "d26bf6b4-f504-47e4-8e2e-a11979e5b24c",
    airportName: "Kahului, HI",
    Location: "USA",
    IATA: "OGG",
  },
  {
    id: "18d45338-34d9-4953-9ccb-ed3612a9357c",
    airportName: "Kajaani",
    Location: "Finland",
    IATA: "KAJ",
  },
  {
    id: "ee99a4af-da42-46c3-b552-ddd9d75e0052",
    airportName: "Kalamata",
    Location: "Greece",
    IATA: "KLX",
  },
  {
    id: "8edf0621-9f34-4d5c-b8f5-cd727bb930e3",
    airportName: "Kalamazoo/Battle Creek, MI",
    Location: "USA",
    IATA: "AZO",
  },
  {
    id: "5b7aca3c-8e0f-421d-ae1a-263eb6e6059c",
    airportName: "Kalgoorlie",
    Location: "Australia",
    IATA: "KGI",
  },
  {
    id: "5b6f781b-324c-4b83-be4b-47b4d1e337fd",
    airportName: "Kalispell, MT",
    Location: "USA",
    IATA: "FCA",
  },
  {
    id: "b33e13b0-93ae-4b89-b52e-86eb3ab9f747",
    airportName: "Kalmar",
    Location: "Sweden",
    IATA: "KLR",
  },
  {
    id: "f8c9445e-02df-4a1d-8ad3-92e5a3c847ec",
    airportName: "Kano",
    Location: "Nigeria",
    IATA: "KAN",
  },
  {
    id: "b0aceaa6-db1b-493c-8100-b8642b611814",
    airportName: "Kamuela, HI",
    Location: "USA",
    IATA: "MUE",
  },
  {
    id: "58054da0-dc23-487e-8876-27a31cb41338",
    airportName: "Kanpur",
    Location: "India",
    IATA: "QJU",
  },
  {
    id: "ef7e0add-43b2-4bd1-a584-32135c7c6020",
    airportName: "Kansas City, MO - Int'l",
    Location: "USA",
    IATA: "MCI",
  },
  {
    id: "fd83ca46-587f-48a0-978f-210917559894",
    airportName: "Kaohsiung Int'l",
    Location: "Taiwan",
    IATA: "KHH",
  },
  {
    id: "fbb15c87-e531-4eda-b414-b29d380c0479",
    airportName: "Kapalua West, HI",
    Location: "USA",
    IATA: "JHM",
  },
  {
    id: "49d77e63-3c9c-4f68-a8bb-5834d7ca272c",
    airportName: "Karachi - Quaid-E-Azam Int'l",
    Location: "Pakistan",
    IATA: "KHI",
  },
  {
    id: "75694ac9-d373-46d4-8fe5-59dff1a15def",
    airportName: "Karlsruhe-Baden - Soellingen",
    Location: "Germany",
    IATA: "FKB",
  },
  {
    id: "2654c90b-2639-49b4-8c82-648fbfd8d5ce",
    airportName: "Karlstad",
    Location: "Sweden",
    IATA: "KSD",
  },
  {
    id: "1888535f-3498-40e8-9ad2-f61cf4b7cfc9",
    airportName: "Karpathos",
    Location: "Greece",
    IATA: "AOK",
  },
  {
    id: "2827e84e-b084-4cae-9abd-93e9ace10bf8",
    airportName: "Karratha",
    Location: "Australia",
    IATA: "KTA",
  },
  {
    id: "bea05fa1-1e52-469e-9655-1f25e57cb883",
    airportName: "Karumba",
    Location: "Australia",
    IATA: "KRB",
  },
  {
    id: "afa04ef5-b46a-4776-99e8-2f723ceb8270",
    airportName: "Karup",
    Location: "Denmark",
    IATA: "KRP",
  },
  {
    id: "6bfd6200-73b9-4923-9e61-eac3b0cd8ca6",
    airportName: "Kaschechawan, PQ",
    Location: "Canada",
    IATA: "ZKE",
  },
  {
    id: "1bd4e68c-fcfc-4dfc-b996-5848d2ce2922",
    airportName: "Kassala",
    Location: "Sudan",
    IATA: "KSL",
  },
  {
    id: "2c11c900-b182-40f6-a77c-77f61f2c6838",
    airportName: "Katherine",
    Location: "Australia",
    IATA: "KTR",
  },
  {
    id: "397e6033-0d16-41a5-b0f4-fee3d7a86320",
    airportName: "Kathmandu - Tribhuvan",
    Location: "Nepal",
    IATA: "KTM",
  },
  {
    id: "d141dc00-15d2-4af0-a74b-bcbec9c1eda5",
    airportName: "Katima Mulilo/Mpacha",
    Location: "Namibia",
    IATA: "MPA",
  },
  {
    id: "c791b04e-a7b4-415b-8a73-9ae6f9898508",
    airportName: "Kauhajoki",
    Location: "Finland",
    IATA: "KHJ",
  },
  {
    id: "af658213-fb5a-40cf-977f-7530713ccfac",
    airportName: "Kaunakakai, HI",
    Location: "USA",
    IATA: "MKK",
  },
  {
    id: "32c9dfe2-0a47-40e3-88e8-b4431a5a1e2e",
    airportName: "Kavalla",
    Location: "Greece",
    IATA: "KVA",
  },
  {
    id: "9399b6dd-5831-4967-b56d-0fb6cad4f130",
    airportName: "Kazan (Ka San)",
    Location: "Russia",
    IATA: "KZN",
  },
  {
    id: "4a7635b9-0124-4050-ab73-87457286af35",
    airportName: "Keetmanshoop",
    Location: "Namibia",
    IATA: "KMP",
  },
  {
    id: "1678dc8b-00d4-4a64-83ce-02b687ba5b4a",
    airportName: "Kelowna, BC",
    Location: "Canada",
    IATA: "YLW",
  },
  {
    id: "4bffb09d-81cd-412c-bb68-b3249b80fff5",
    airportName: "Kemi/Tornio",
    Location: "Finland",
    IATA: "KEM",
  },
  {
    id: "6ddf7b00-febe-4ef5-8fbb-90e8fac6d4c0",
    airportName: "Kempten",
    Location: "Germany",
    IATA: "ZNS",
  },
  {
    id: "50bf63f9-5b52-433a-ba9c-367b96729012",
    airportName: "Kenai, AK",
    Location: "USA",
    IATA: "ENA",
  },
  {
    id: "5504359c-95da-4c5a-a536-e98c2df379e1",
    airportName: "Kerry County",
    Location: "Ireland",
    IATA: "KIR",
  },
  {
    id: "247ad5fe-5f2a-4442-8d24-2a67deb0797c",
    airportName: "Ketchikan, AK",
    Location: "USA",
    IATA: "KTN",
  },
  {
    id: "797470af-5bed-455f-b0de-b010484a3658",
    airportName: "Key West, FL",
    Location: "USA",
    IATA: "EYW",
  },
  {
    id: "96d99cc2-309b-4825-ab3b-1d57c34daffa",
    airportName: "Khamis Mushayat",
    Location: "Saudi Arabia",
    IATA: "AHB",
  },
  {
    id: "b4647be8-efee-4e23-8b0b-5cc924ca2f3d",
    airportName: "Kharga - New Valley",
    Location: "Egypt",
    IATA: "UVL",
  },
  {
    id: "7b0722e9-366e-4586-8405-aaef57458709",
    airportName: "Khartoum",
    Location: "Sudan",
    IATA: "KRT",
  },
  {
    id: "51adf430-2c8e-4252-869e-ec2585049c85",
    airportName: "Khuzdar",
    Location: "Pakistan",
    IATA: "KDD",
  },
  {
    id: "80a4317f-59b7-4065-a189-67ec63237a92",
    airportName: "Kiel - Holtenau",
    Location: "Germany",
    IATA: "KEL",
  },
  {
    id: "0f49ed69-6041-4b58-a99b-3b39e23f30d0",
    airportName: "Kiev - Borispol",
    Location: "Ukraine",
    IATA: "KBP",
  },
  {
    id: "b3ae3c9e-0165-4b99-8fe4-658fe39a61cf",
    airportName: "Kiev - Zhulhany",
    Location: "Ukraine",
    IATA: "IEV",
  },
  {
    id: "935227c3-3bab-4210-862d-df95259cbe20",
    airportName: "Kigali - Gregoire Kayibanda",
    Location: "Rwanda",
    IATA: "KGL",
  },
  {
    id: "044eac67-e9a0-4b54-a720-964c4870d759",
    airportName: "Kilimadjaro",
    Location: "Tanzania",
    IATA: "JRO",
  },
  {
    id: "7b93a6e2-92b6-4d16-b0eb-ea84aaa71ba9",
    airportName: "Killeem, TX",
    Location: "USA",
    IATA: "ILE",
  },
  {
    id: "d34468b5-71ab-4fea-ad1e-413a1a1c2024",
    airportName: "Kimberley",
    Location: "South Africa",
    IATA: "KIM",
  },
  {
    id: "0699f9cf-9aa5-4874-812b-a7c9d053e9a5",
    airportName: "King Island",
    Location: "King Island",
    IATA: "KNS",
  },
  {
    id: "dc08fcb4-2166-4f77-be0e-44a176b67050",
    airportName: "King Salomon, AK",
    Location: "USA",
    IATA: "AKN",
  },
  {
    id: "19fe9eb4-b9b7-4ac9-a9c8-2fb29299564d",
    airportName: "Kingscote",
    Location: "Australia",
    IATA: "KGC",
  },
  {
    id: "76d29855-89de-4d76-b4e9-84637d5680b2",
    airportName: "Kingston - Norman Manley",
    Location: "Jamaica",
    IATA: "KIN",
  },
  {
    id: "5263ad47-043e-44a0-8ae9-8bfe580da843",
    airportName: "Kingston, NC",
    Location: "USA",
    IATA: "ISO",
  },
  {
    id: "91e5de63-737d-46f7-9d81-eba1bde70349",
    airportName: "Kinshasa - N'Djili",
    Location: "Congo (Zaire)",
    IATA: "FIH",
  },
  {
    id: "e1e40a92-60f8-4fa6-8bf1-73e731042154",
    airportName: "Kirkenes",
    Location: "Norway",
    IATA: "KKN",
  },
  {
    id: "ef9c0f28-2d11-4315-a46e-dd882de89f2a",
    airportName: "Kirkuk",
    Location: "Iraq",
    IATA: "KIK",
  },
  {
    id: "75fb88b6-b037-478d-8859-89dfb886f694",
    airportName: "Kiruna",
    Location: "Sweden",
    IATA: "KRN",
  },
  {
    id: "076a88c1-d381-4aa2-8cd3-eeb3537b94ec",
    airportName: "Kisangani",
    Location: "Zaire",
    IATA: "FKI",
  },
  {
    id: "32b7f8da-5b37-4108-8c5e-4c5c878d9a27",
    airportName: "Kittilä",
    Location: "Finland",
    IATA: "KTT",
  },
  {
    id: "cd4f5cb1-8c87-400c-9e3a-1894d36ac8bf",
    airportName: "Kitwe",
    Location: "Zambia",
    IATA: "KIW",
  },
  {
    id: "7d4c51b6-4c1b-4983-b6f4-cf638a1980c2",
    airportName: "Klagenfurt",
    Location: "Austria",
    IATA: "KLU",
  },
  {
    id: "014914e4-93d2-44dc-be27-d418d6a1164b",
    airportName: "Klamath Fall, OR",
    Location: "USA",
    IATA: "LMT",
  },
  {
    id: "017fe23f-248e-49c5-8f6f-34a6ef3f2cb1",
    airportName: "Klawock, AK",
    Location: "USA",
    IATA: "KLW",
  },
  {
    id: "def72494-97c2-47c0-a915-bb3eca3d8577",
    airportName: "Kleinsee",
    Location: "South Africa",
    IATA: "KLZ",
  },
  {
    id: "219bd6b9-d128-4c00-b428-0bbecaf0a71f",
    airportName: "Knock",
    Location: "Ireland",
    IATA: "NOC",
  },
  {
    id: "07fd80af-3ed1-4f9e-8327-6eadab12f35c",
    airportName: "Knoxville, TN",
    Location: "USA",
    IATA: "TYS",
  },
  {
    id: "abc0b50e-31c5-49dd-b638-caba156c2ca9",
    airportName: "Kobe",
    Location: "Japan",
    IATA: "UKB",
  },
  {
    id: "26055ac0-c845-4dd8-98a6-c98f4ef8e4b8",
    airportName: "Kochi",
    Location: "Japan",
    IATA: "KCZ",
  },
  {
    id: "1007fe4f-f5f3-44ad-aeec-40dea3ffbcb2",
    airportName: "Kodiak, AK",
    Location: "USA",
    IATA: "ADQ",
  },
  {
    id: "cced989f-1b0a-42fb-9967-0b1665f080c7",
    airportName: "Kohat",
    Location: "Pakistan",
    IATA: "OHT",
  },
  {
    id: "ff73ede9-af83-4e47-a3c8-741dafb4e738",
    airportName: "Kokkola/Pietarsaari",
    Location: "Finland",
    IATA: "KOK",
  },
  {
    id: "8c90b88b-be40-4c8a-bc08-7280ffa53239",
    airportName: "Kona, HI",
    Location: "USA",
    IATA: "KOA",
  },
  {
    id: "0bbdda3d-68f3-4aca-be3e-a3f8f7431ba7",
    airportName: "Korhogo",
    Location: "Ivory Coast",
    IATA: "HGO",
  },
  {
    id: "8358c938-c03f-47eb-afdf-f9325f94ad7a",
    airportName: "Kos",
    Location: "Greece",
    IATA: "KGS",
  },
  {
    id: "ddc556af-cd42-4022-ba78-43caf1e40f44",
    airportName: "Kota Kinabalu",
    Location: "Malaysia",
    IATA: "BKI",
  },
  {
    id: "c7530141-98f3-4bc2-adfe-c525911b29ad",
    airportName: "Kotzbue, AK",
    Location: "USA",
    IATA: "OTZ",
  },
  {
    id: "8226bd90-4f32-4b6a-b515-2f3a757e7998",
    airportName: "Kowanyama",
    Location: "Australia",
    IATA: "KWM",
  },
  {
    id: "8be49265-4186-4d55-ac36-504743178a34",
    airportName: "Krakau",
    Location: "Poland",
    IATA: "KRK",
  },
  {
    id: "d85543d2-570a-48be-b3c9-9780c8a30610",
    airportName: "Kristiansand",
    Location: "Norway",
    IATA: "KRS",
  },
  {
    id: "26823043-40cd-426a-ba58-99233ed6c58f",
    airportName: "Kristianstad",
    Location: "Sweden",
    IATA: "KID",
  },
  {
    id: "e2a2cdf2-de5e-466c-9fa9-9bdbbbd907cb",
    airportName: "Kristiansund",
    Location: "Norway",
    IATA: "KSU",
  },
  {
    id: "9713d858-0796-45bf-a7ce-053d3bfd579f",
    airportName: "Kuala Belait",
    Location: "Brunei",
    IATA: "KUB",
  },
  {
    id: "483571c5-58f7-451e-8c76-5806dde331d7",
    airportName: "Kuala Lumpur - Int'l Airport",
    Location: "Malaysia",
    IATA: "KUL",
  },
  {
    id: "646c1498-8028-40bb-bd9b-cfb5b42ba5f9",
    airportName: "Kuala Lumpur - Sultan Abdul Aziz Shah",
    Location: "Malaysia",
    IATA: "SZB",
  },
  {
    id: "ba2d34ac-e01c-475b-b651-5382a9168202",
    airportName: "Kuantan",
    Location: "Malaysia",
    IATA: "KUA",
  },
  {
    id: "418ae34c-c116-4122-b1dc-379228ad9e40",
    airportName: "Kuching",
    Location: "Malaysia",
    IATA: "KCH",
  },
  {
    id: "64a30ed0-3285-4aa6-a631-3c8165868797",
    airportName: "Kumamoto",
    Location: "Japan",
    IATA: "KMJ",
  },
  {
    id: "1eab8a17-6602-4b56-875d-cf437dc0d9e0",
    airportName: "Kununurra",
    Location: "Australia",
    IATA: "KNX",
  },
  {
    id: "3f0f4bad-6e43-4725-8f3b-f8fc3765ec4a",
    airportName: "Kuopio",
    Location: "Finland",
    IATA: "KUO",
  },
  {
    id: "7ac0b6f8-dac7-4887-83fb-556f644277d5",
    airportName: "Kushiro",
    Location: "Japan",
    IATA: "KUH",
  },
  {
    id: "0718c855-8ebe-444e-af71-754d52a2bfe4",
    airportName: "Kuujjuaq (FortChimo)",
    Location: "Canada",
    IATA: "YVP",
  },
  {
    id: "1a6f0956-8d37-4c0c-b723-3b5aa0f8e942",
    airportName: "Kuujjuarapik",
    Location: "Canada",
    IATA: "YGW",
  },
  {
    id: "4ed406e2-055a-47d0-9561-0fe507e28473",
    airportName: "Kuusamo",
    Location: "Finland",
    IATA: "KAO",
  },
  {
    id: "45c6a5ae-642c-41bc-b86f-6353dab48189",
    airportName: "Kuwait - Kuwait Int'l",
    Location: "Kuwait",
    IATA: "KWI",
  },
  {
    id: "e39c366f-fa5a-4a71-9ff1-61bc69fb00af",
    airportName: "Kyoto",
    Location: "Japan",
    IATA: "UKY",
  },
  {
    id: "e1161b0f-e1be-4355-be47-14d8d30ae164",
    airportName: "Labe",
    Location: "Guinea",
    IATA: "LEK",
  },
  {
    id: "2d43a3e1-8d8e-493c-bdab-592d85ee9df2",
    airportName: "Labouchere Bay, AK",
    Location: "USA",
    IATA: "WLB",
  },
  {
    id: "24a8bd25-3392-4c04-af76-e0134310af9b",
    airportName: "Labuan",
    Location: "Malaysia",
    IATA: "LBU",
  },
  {
    id: "aa46a463-cdf8-4349-a90e-98e59732fc26",
    airportName: "Lac Brochet, MB",
    Location: "Canada",
    IATA: "XLB",
  },
  {
    id: "d517662b-a1e3-4942-8b87-3859e1ebf20a",
    airportName: "La Coruna",
    Location: "Spain",
    IATA: "LCG",
  },
  {
    id: "f324b6d4-9e8e-4933-b2fc-943e9ed464b8",
    airportName: "La Crosse, WI",
    Location: "USA",
    IATA: "LSE",
  },
  {
    id: "d98374fd-d35b-4edd-b6d7-8ba36f986a52",
    airportName: "Lae",
    Location: "Papua New Guinea",
    IATA: "LAE",
  },
  {
    id: "3750c1ba-bbdc-45e4-9e9b-972d43fa6b48",
    airportName: "La Rochelle",
    Location: "France",
    IATA: "LRH",
  },
  {
    id: "bacb56b6-a357-4ad3-9f07-8bf2ead6eaaf",
    airportName: "Lafayette, IN",
    Location: "USA",
    IATA: "LAF",
  },
  {
    id: "28cb8900-c9bd-4db2-a27f-bbdbe97170e3",
    airportName: "Lafayette, La",
    Location: "USA",
    IATA: "LFT",
  },
  {
    id: "dfc48862-cc2a-4210-be5d-83e3b90eee80",
    airportName: "Lagos - Murtala Muhammed",
    Location: "Nigeria",
    IATA: "LOS",
  },
  {
    id: "277fcf62-c01d-4eb6-b0c8-98fad9bfade2",
    airportName: "La Grande",
    Location: "Canada",
    IATA: "YGL",
  },
  {
    id: "0c2616ac-e3c9-40c6-ab8b-264c9b4200ba",
    airportName: "Lahore",
    Location: "Pakistan",
    IATA: "LHE",
  },
  {
    id: "5f66047c-143b-462f-94b5-632f518342a5",
    airportName: "Lake Charles, La",
    Location: "USA",
    IATA: "LCH",
  },
  {
    id: "afd85a42-4bf8-4562-be93-4bd5630acec9",
    airportName: "Lake Havasu City, AZ",
    Location: "USA",
    IATA: "HII",
  },
  {
    id: "350293a6-6b99-404d-a56c-39f6bf6902c4",
    airportName: "Lake Tahoe, CA",
    Location: "USA",
    IATA: "TVL",
  },
  {
    id: "0756f513-3435-4de3-b325-b4dbd5a7d584",
    airportName: "Lakselv",
    Location: "Norway",
    IATA: "LKL",
  },
  {
    id: "5ed8e8c2-cfb1-46b3-a78d-86a9f89af61f",
    airportName: "Lambarene",
    Location: "Gabon",
    IATA: "LBQ",
  },
  {
    id: "a23f9a47-b868-4c56-bdcb-356b83509f23",
    airportName: "Lamezia Terme",
    Location: "Italy",
    IATA: "SUF",
  },
  {
    id: "1779287f-5abb-44b9-8fcd-3720385ea526",
    airportName: "Lampedusa",
    Location: "Italy",
    IATA: "LMP",
  },
  {
    id: "a066bc05-9b66-48ec-bdfb-2face75ab86f",
    airportName: "Lanai City, HI",
    Location: "USA",
    IATA: "LNY",
  },
  {
    id: "02202175-a2d4-4725-879e-a3ad62fefc9e",
    airportName: "Lancaster, PA",
    Location: "USA",
    IATA: "LNS",
  },
  {
    id: "230fd2a2-dd98-47ce-9ade-a0bf2005514b",
    airportName: "Langkawi",
    Location: "Malaysia",
    IATA: "LGK",
  },
  {
    id: "0861ef38-4c8b-410d-913e-a61023dc65f4",
    airportName: "Lannion",
    Location: "France",
    IATA: "LAI",
  },
  {
    id: "76c1d015-f9f4-4b0b-a3bd-c32c728856ec",
    airportName: "Lanseria",
    Location: "South Africa",
    IATA: "HLA",
  },
  {
    id: "00cab161-5b74-410e-b8d6-da82a83cdb44",
    airportName: "Lansing, MI",
    Location: "USA",
    IATA: "LAN",
  },
  {
    id: "4cd81598-4c25-48aa-a8ef-edf913c69819",
    airportName: "La Paz - El Alto",
    Location: "Bolivia",
    IATA: "LPB",
  },
  {
    id: "816d8d06-8b74-412b-82b9-cd1301cd2a67",
    airportName: "La Paz - Leon",
    Location: "Mexico",
    IATA: "LAP",
  },
  {
    id: "a2931099-70ec-4458-ad6c-1876be5f5869",
    airportName: "Lappeenranta",
    Location: "Finland",
    IATA: "LPP",
  },
  {
    id: "c0a5381a-0c25-4dc0-a4af-feb65f46fdf1",
    airportName: "Laramie, WY",
    Location: "USA",
    IATA: "LAR",
  },
  {
    id: "3858135e-af68-49c5-9089-b52713f56f97",
    airportName: "Laredo, TX",
    Location: "USA",
    IATA: "LRD",
  },
  {
    id: "6999ef6f-a8c2-459f-a15f-25a214d149ec",
    airportName: "Larnaca",
    Location: "Cyprus",
    IATA: "LCA",
  },
  {
    id: "30853bdf-b5a4-401d-b357-12a63761c069",
    airportName: "Las Palmas",
    Location: "Spain",
    IATA: "LPA",
  },
  {
    id: "0eb16cfd-4643-4177-93a5-c497dee60ff3",
    airportName: "Las Vegas, NV",
    Location: "USA",
    IATA: "LAS",
  },
  {
    id: "32ddcd0a-9283-445a-96bd-7e1dee680f73",
    airportName: "Latrobe, PA",
    Location: "USA",
    IATA: "LBE",
  },
  {
    id: "8082fe4a-4cd6-4701-9d92-e95b761276a2",
    airportName: "Launceston",
    Location: "Australia",
    IATA: "LST",
  },
  {
    id: "337dae2c-21d7-4575-920e-c507b243092d",
    airportName: "Laurel/Hattisburg, MS",
    Location: "USA",
    IATA: "PIB",
  },
  {
    id: "a4345311-2e17-422d-b25d-6f447a48a1b6",
    airportName: "Laverton",
    Location: "Australia",
    IATA: "LVO",
  },
  {
    id: "e2089c03-209d-47bf-ab26-1a51f7e999f7",
    airportName: "Lawton, OK",
    Location: "USA",
    IATA: "LAW",
  },
  {
    id: "fd5abec4-8735-4649-bdf7-0010d1ea66e5",
    airportName: "Lazaro Cardenas",
    Location: "Mexico",
    IATA: "LZC",
  },
  {
    id: "20a76f02-2b70-4953-8a25-2b6bfb8691b2",
    airportName: "Leaf Rapids",
    Location: "Canada",
    IATA: "YLR",
  },
  {
    id: "18d5722a-b982-4e7a-9898-35ec88ed3053",
    airportName: "Learmouth (Exmouth)",
    Location: "Australia",
    IATA: "LEA",
  },
  {
    id: "980874b6-e642-4930-9faa-61ff046e35fe",
    airportName: "Lebanon, NH",
    Location: "USA",
    IATA: "LEB",
  },
  {
    id: "4af430b7-b6a1-415b-a1b0-b722b9cb58ea",
    airportName: "Leeds/Bradford",
    Location: "United Kingdom",
    IATA: "LBA",
  },
  {
    id: "44ceff7a-ed14-4d48-ad73-2eae192cf256",
    airportName: "Leinster",
    Location: "Australia",
    IATA: "LER",
  },
  {
    id: "694a7c91-48f6-4ee2-9de8-16c2478c6b4d",
    airportName: "Leipzig",
    Location: "Germany",
    IATA: "LEJ",
  },
  {
    id: "c78a174b-6417-46d4-a526-d9268e5e054d",
    airportName: "Lelystad",
    Location: "Netherlands",
    IATA: "LEY",
  },
  {
    id: "4692f40e-a461-4327-8c2c-e0a814365651",
    airportName: "Leon",
    Location: "Mexico",
    IATA: "BJX",
  },
  {
    id: "bf385937-906e-4faf-af24-fca04b8ebf98",
    airportName: "Leonora",
    Location: "Australia",
    IATA: "LNO",
  },
  {
    id: "7ce73ea0-3b49-439c-b86b-50c05033827f",
    airportName: "Lewiston, ID",
    Location: "USA",
    IATA: "LWS",
  },
  {
    id: "68532f3a-4452-4f38-a448-25c511fb441b",
    airportName: "Lewistown, MT",
    Location: "USA",
    IATA: "LWT",
  },
  {
    id: "3be84338-4f76-43cb-b6a6-258a7181227d",
    airportName: "Lexington, KY",
    Location: "USA",
    IATA: "LEX",
  },
  {
    id: "33cf566e-ac0d-4032-b1d3-0a7dd75081b9",
    airportName: "Libreville",
    Location: "Gabon",
    IATA: "LBV",
  },
  {
    id: "e89a7167-3ac2-455c-9630-b8518e792357",
    airportName: "Lidkoeping",
    Location: "Sweden",
    IATA: "LDK",
  },
  {
    id: "b800378f-e6a7-43dc-b142-0c5c1b4d88d3",
    airportName: "Liege",
    Location: "Belgium",
    IATA: "LGG",
  },
  {
    id: "d7a4331e-da46-414b-930d-c28fb857a2d7",
    airportName: "Lifou",
    Location: "Loyaute, Pazifik",
    IATA: "LIF",
  },
  {
    id: "e7002fe6-deca-48c3-847b-b4aa6271251a",
    airportName: "Lihue, HI",
    Location: "USA",
    IATA: "LIH",
  },
  {
    id: "05a9ae82-fb78-442e-8f5f-f9fc775cdb7d",
    airportName: "Lille",
    Location: "France",
    IATA: "LIL",
  },
  {
    id: "0108c676-dc4f-49e4-8e4a-e34c47a508f7",
    airportName: "Lilongwe - Lilongwe International",
    Location: "Malawi",
    IATA: "LLW",
  },
  {
    id: "2f24cfba-9bcf-4bf9-bf54-d168ab7000cc",
    airportName: "Lima - J Chavez Int'l",
    Location: "Peru",
    IATA: "LIM",
  },
  {
    id: "c95fd25e-361d-41d4-a50a-912508a340ac",
    airportName: "Limassol",
    Location: "Cyprus",
    IATA: "QLI",
  },
  {
    id: "51a91545-bdfb-4cfb-b26f-047a5d0d3777",
    airportName: "Limoges",
    Location: "France",
    IATA: "LIG",
  },
  {
    id: "1c874d05-644c-4aa5-81e7-cc587e257377",
    airportName: "Lincoln, NE",
    Location: "USA",
    IATA: "LNK",
  },
  {
    id: "2439e287-29d6-4b6b-812e-8f0a5e49c128",
    airportName: "Lindeman Island",
    Location: "Australia",
    IATA: "LDC",
  },
  {
    id: "9d38c983-4f42-4ec1-9533-eed7f9cb4c5a",
    airportName: "Linz - Hoersching",
    Location: "Austria",
    IATA: "LNZ",
  },
  {
    id: "b3d519d3-7922-4b15-a6ca-9fcb37e41e67",
    airportName: "Lisala",
    Location: "Congo, Democratic Republic of The",
    IATA: "LIQ",
  },
  {
    id: "763700af-de7a-4ab8-973e-a7d58a6afa8d",
    airportName: "Lisbon - Lisboa",
    Location: "Portugal",
    IATA: "LIS",
  },
  {
    id: "d853c01d-5cf3-4c73-9dba-f6f6c8be24d9",
    airportName: "Lismore",
    Location: "Australia",
    IATA: "LSY",
  },
  {
    id: "3bc1309a-c1de-4ee6-8048-03c4d8489881",
    airportName: "Little Rock, AR",
    Location: "USA",
    IATA: "LIT",
  },
  {
    id: "8df237c3-e081-449f-9c47-c6a35d278a79",
    airportName: "Liverpool",
    Location: "United Kingdom",
    IATA: "LPL",
  },
  {
    id: "5e966c45-3a63-44ff-b480-31935d743b78",
    airportName: "Lizard Island",
    Location: "Australia",
    IATA: "LZR",
  },
  {
    id: "3cbc0559-65d9-44da-a5f8-0f6f70725f57",
    airportName: "Ljubljana - Brnik",
    Location: "Slovenia",
    IATA: "LJU",
  },
  {
    id: "80f55e4b-f4ac-40c0-ad99-24f67701ebeb",
    airportName: "Lockhart River",
    Location: "Australia",
    IATA: "IRG",
  },
  {
    id: "19678209-b809-453b-bc24-97d36696d9cd",
    airportName: "Lome",
    Location: "Togo",
    IATA: "LFW",
  },
  {
    id: "545b96ab-70fd-4eed-bdd6-0ac5f7b67c45",
    airportName: "London",
    Location: "Canada",
    IATA: "YXU",
  },
  {
    id: "e49ee245-7190-4583-99dd-cea0c067f216",
    airportName: "London",
    Location: "United Kingdom",
    IATA: "LON",
  },
  {
    id: "db5c74fd-3338-4c4b-b20e-1170b0e74d74",
    airportName: "London - City Airport",
    Location: "United Kingdom",
    IATA: "LCY",
  },
  {
    id: "dfadf5a7-aabc-4ffe-8db3-24acfad0fd8b",
    airportName: "London - Gatwick",
    Location: "United Kingdom",
    IATA: "LGW",
  },
  {
    id: "2f4244b2-50af-4241-9f0a-c674ecadff0c",
    airportName: "London - Heathrow",
    Location: "United Kingdom",
    IATA: "LHR",
  },
  {
    id: "6f66ebef-f5b3-466b-8ee1-745bae416d3c",
    airportName: "London - Luton",
    Location: "United Kingdom",
    IATA: "LTN",
  },
  {
    id: "0619dbfd-f24b-4f70-af17-0719423aba62",
    airportName: "London - Stansted",
    Location: "United Kingdom",
    IATA: "STN",
  },
  {
    id: "c5777999-cb6e-4b9d-895e-b558061c1348",
    airportName: "Long Beach, CA",
    Location: "USA",
    IATA: "LGB",
  },
  {
    id: "118ed5d9-fa7d-4d3a-aa45-32b5e9dcc5cc",
    airportName: "Long Island, AK",
    Location: "USA",
    IATA: "LIJ",
  },
  {
    id: "c4a0664e-3aab-40c5-876e-1122604334d9",
    airportName: "Long Island, Islip, NY - Mac Arthur",
    Location: "USA",
    IATA: "ISP",
  },
  {
    id: "e8611c12-c93f-449b-bf47-ec4a0fbfc3fe",
    airportName: "Longreach",
    Location: "Australia",
    IATA: "LRE",
  },
  {
    id: "ba1a3f1f-ac28-4565-a752-4ac7b9b86b08",
    airportName: "Longview/Kilgore, TX",
    Location: "USA",
    IATA: "GGG",
  },
  {
    id: "084cded6-9c08-4c2a-84f1-7f0f0fa31824",
    airportName: "Longyearbyen - Svalbard",
    Location: "Svalbard/Norway",
    IATA: "LYR",
  },
  {
    id: "64f7829e-e485-43dd-a0a4-bf36ab02a977",
    airportName: "Loreto",
    Location: "Mexico",
    IATA: "LTO",
  },
  {
    id: "3ba6374a-e737-4e58-b05d-8504f718689f",
    airportName: "Lorient",
    Location: "France",
    IATA: "LRT",
  },
  {
    id: "29d19a79-eb37-4de1-939e-f13f720590d4",
    airportName: "Los Angeles, CA - Int'l",
    Location: "USA",
    IATA: "LAX",
  },
  {
    id: "7208749b-4d7a-42ba-8152-0451256a16c6",
    airportName: "Los Cabos",
    Location: "Mexico",
    IATA: "SJD",
  },
  {
    id: "4dd139d3-76cd-4d5b-b923-2550af11008d",
    airportName: "Los Mochis",
    Location: "Mexico",
    IATA: "LMM",
  },
  {
    id: "a7d29e60-fbf1-46ea-9711-89c011345336",
    airportName: "Los Rodeos",
    Location: "Teneriffa/Spain",
    IATA: "TFN",
  },
  {
    id: "c8aef2d8-7f57-492e-bda7-d831234c07b7",
    airportName: "(Mali) Losinj - Losinj Arpt",
    Location: "Croatia (Hrvatska)",
    IATA: "LSZ",
  },
  {
    id: "7f6faf3a-e7cd-451c-9829-1e4bd1e7dd38",
    airportName: "Lourdes/Tarbes",
    Location: "France",
    IATA: "LDE",
  },
  {
    id: "dec56287-ae8a-4b9c-aad2-0af266080a5b",
    airportName: "Louisville, KY",
    Location: "USA",
    IATA: "SDF",
  },
  {
    id: "d6a8587d-fe7c-4a5d-8512-a78a065c47c8",
    airportName: "Luanda - 4 de Fevereiro",
    Location: "Angola",
    IATA: "LAD",
  },
  {
    id: "3e364d82-6632-441e-9d5e-a3ac3ede22c2",
    airportName: "Lubbock, TX",
    Location: "USA",
    IATA: "LBB",
  },
  {
    id: "b2234471-b2b0-4b64-ac88-1f00b53b2fde",
    airportName: "Lucknow",
    Location: "India",
    IATA: "LKO",
  },
  {
    id: "d96876c2-353f-46d6-8fc3-baeea3e4e5ed",
    airportName: "Luederitz",
    Location: "Namibia",
    IATA: "LUD",
  },
  {
    id: "6edf690b-f823-4c5d-8f83-b77681979673",
    airportName: "Luga",
    Location: "Malta",
    IATA: "MLA",
  },
  {
    id: "62f65c61-5482-4e08-8d29-ccbbfff739a2",
    airportName: "Lugano",
    Location: "Switzerland",
    IATA: "LUG",
  },
  {
    id: "360497f2-d471-43eb-b9b4-e1d024aaf403",
    airportName: "Lulea",
    Location: "Sweden",
    IATA: "LLA",
  },
  {
    id: "3b8ca491-211f-434f-8b06-b1cc37eda46c",
    airportName: "Lumbumbashi",
    Location: "Zaire",
    IATA: "FBM",
  },
  {
    id: "9122498a-c5bf-498e-b12e-b515d731f06c",
    airportName: "Lusaka",
    Location: "Zambia",
    IATA: "LUN",
  },
  {
    id: "d75648a7-222e-410f-ace2-88f47c703360",
    airportName: "Lusisiki",
    Location: "South Africa",
    IATA: "LUJ",
  },
  {
    id: "d43b2280-3044-4532-a444-fe422fc535a0",
    airportName: "Luxembourg",
    Location: "Luxembourg",
    IATA: "LUX",
  },
  {
    id: "9ac92e0b-49ff-4652-b520-1ab5b5365073",
    airportName: "Luxor",
    Location: "Egypt",
    IATA: "LXR",
  },
  {
    id: "d34ab10d-ff75-4846-ab72-74d126b9a76f",
    airportName: "Lvov (Lwow, Lemberg)",
    Location: "Ukraine",
    IATA: "LWO",
  },
  {
    id: "02499e8f-ef92-44ca-8ef6-98b0e979c77d",
    airportName: "Lynchburg, VA",
    Location: "USA",
    IATA: "LYH",
  },
  {
    id: "7d17767f-c92b-4b7a-85f6-19e86c4ea6c8",
    airportName: "Lyon",
    Location: "France",
    IATA: "LYS",
  },
  {
    id: "e562346a-93ac-4f4f-b6ad-2338122d4871",
    airportName: "Maastricht/Aachen",
    Location: "Netherlands",
    IATA: "MST",
  },
  {
    id: "42b29744-5554-4b0c-81d3-c81d51340c64",
    airportName: "Macapa",
    Location: "Brazil",
    IATA: "MCP",
  },
  {
    id: "6534f86f-0e06-414f-9448-3f8881235723",
    airportName: "Macau",
    Location: "Macau, China SAR",
    IATA: "MFM",
  },
  {
    id: "5c94fdd1-c066-49b2-9f82-04799486172a",
    airportName: "Maceio",
    Location: "Brazil",
    IATA: "MCZ",
  },
  {
    id: "15a62c7c-228f-48eb-aec6-328bda1fe1c4",
    airportName: "Mackay",
    Location: "Australia",
    IATA: "MKY",
  },
  {
    id: "db2288c3-7231-488c-b7b0-94f32053ea52",
    airportName: "Macon, GA",
    Location: "USA",
    IATA: "MCN",
  },
  {
    id: "2209a30f-957a-41f9-86ec-b39033dd37dc",
    airportName: "Mactan Island - Nab",
    Location: "Philippines",
    IATA: "NOP",
  },
  {
    id: "44ef7fe1-c11a-42c1-826c-8f6629930f18",
    airportName: "Madinah, Mohammad Bin Abdulaziz",
    Location: "Saudi Arabia",
    IATA: "MED",
  },
  {
    id: "cf64d29d-2df0-436d-86d3-5312c01f7681",
    airportName: "Madison, WI",
    Location: "USA",
    IATA: "MSN",
  },
  {
    id: "52421bf0-58ac-4ef0-95fd-f04ff16bdf7f",
    airportName: "Madrid - Barajas",
    Location: "Spain",
    IATA: "MAD",
  },
  {
    id: "17c51223-e468-49ee-ac4e-20f003faa6a7",
    airportName: "Mahe - Seychelles Int'l",
    Location: "Seychelles",
    IATA: "SEZ",
  },
  {
    id: "ad79ac83-6f9f-499a-a5aa-e51936aab383",
    airportName: "Mahon",
    Location: "Spain",
    IATA: "MAH",
  },
  {
    id: "5166e48e-ea86-458a-9a79-20bc05685af3",
    airportName: "Maitland",
    Location: "Australia",
    IATA: "MTL",
  },
  {
    id: "e04355ac-a9ab-47b8-8e2d-38142eb11086",
    airportName: "Majunga",
    Location: "Madagascar",
    IATA: "MJN",
  },
  {
    id: "ca60fb28-c2a7-4645-823e-9701a55cab5d",
    airportName: "Makung",
    Location: "Taiwan",
    IATA: "MZG",
  },
  {
    id: "2219c4b0-39b4-47bd-90b6-4e3a0c3b7d76",
    airportName: "Malabo",
    Location: "Equatorial Guinea",
    IATA: "SSG",
  },
  {
    id: "cb0359d3-d3d7-4b73-9d38-a290653caf2a",
    airportName: "Malaga",
    Location: "Spain",
    IATA: "AGP",
  },
  {
    id: "8623f04d-dd87-4044-b98d-512921dbd969",
    airportName: "Male - International",
    Location: "Maledives",
    IATA: "MLE",
  },
  {
    id: "e6c2d5b7-f38f-43aa-abbd-8bf1238e0d0b",
    airportName: "Malindi",
    Location: "Kenya",
    IATA: "MYD",
  },
  {
    id: "a7201d0f-2022-47be-a870-a915735e194b",
    airportName: "Malmo (Malmoe)",
    Location: "Sweden",
    IATA: "MMA",
  },
  {
    id: "edc3d6a9-1897-4d70-93b7-57f849cbcf75",
    airportName: "Malmo (Malmoe) - Sturup",
    Location: "Sweden",
    IATA: "MMX",
  },
  {
    id: "fb950294-3178-4ea4-8211-9c47e608cbd4",
    airportName: "Man",
    Location: "Ivory Coast",
    IATA: "MJC",
  },
  {
    id: "d30e63e6-96d7-4971-8406-8054c76c3a93",
    airportName: "Managua - Augusto C Sandino",
    Location: "Nicaragua",
    IATA: "MGA",
  },
  {
    id: "058990da-6bbf-40bc-b832-df6b1cdc1495",
    airportName: "Manaus",
    Location: "Brazil",
    IATA: "MAO",
  },
  {
    id: "190173a7-b0ba-489a-8c1d-41a6e093a312",
    airportName: "Manchester",
    Location: "United Kingdom",
    IATA: "MAN",
  },
  {
    id: "be376010-6f0d-4afb-8fdc-3a11cfe62ffa",
    airportName: "Manchester, NH",
    Location: "USA",
    IATA: "MHT",
  },
  {
    id: "07ec7889-51ec-44bc-896f-f96b8b57caf4",
    airportName: "Manguna",
    Location: "Papua New Guinea",
    IATA: "MFO",
  },
  {
    id: "02208255-9984-4684-bb12-c9a717fc17d3",
    airportName: "Manihi",
    Location: "French Polynesia",
    IATA: "XMH",
  },
  {
    id: "46ff8bcd-1bf5-4fd8-bedf-cd1ab69c9fd0",
    airportName: "Manila - Ninoy Aquino Int'l",
    Location: "Philippines",
    IATA: "MNL",
  },
  {
    id: "60ae36fd-b49e-4d2b-9db2-cfaea7176536",
    airportName: "Manzanillo",
    Location: "Mexico",
    IATA: "ZLO",
  },
  {
    id: "bfef053a-360f-42fd-aa60-21d43ec04b13",
    airportName: "Manzini - Matsapha Int'l",
    Location: "Swaziland",
    IATA: "MTS",
  },
  {
    id: "07414953-872e-4e60-88b8-9a3a2a79e6f5",
    airportName: "Maputo - Maputo International",
    Location: "Mozambique",
    IATA: "MPM",
  },
  {
    id: "483b140e-b0d5-4532-ba71-2027af269560",
    airportName: "Mar del Plata",
    Location: "Argentina",
    IATA: "MDQ",
  },
  {
    id: "94a3905d-b440-4c8a-a7c2-07ea73457fa1",
    airportName: "Maracaibo - La Chinita",
    Location: "Venezuela",
    IATA: "MAR",
  },
  {
    id: "ff27589c-c77d-490f-8183-f504871ad671",
    airportName: "Maradi",
    Location: "Niger",
    IATA: "MFQ",
  },
  {
    id: "1968e171-0bfc-4d7e-970c-6ee3560a5814",
    airportName: "Marathon, FL",
    Location: "USA",
    IATA: "MTH",
  },
  {
    id: "a228649d-a309-47e8-af3d-d17655308533",
    airportName: "Mare",
    Location: "New Caledonia",
    IATA: "MEE",
  },
  {
    id: "07ec1ba4-aa1d-49f9-b812-29647c0bf96a",
    airportName: "Margate",
    Location: "South Africa",
    IATA: "MGH",
  },
  {
    id: "2a5bc5e6-94d0-4e40-a476-6ac380ae9763",
    airportName: "Margerita",
    Location: "Venezuela",
    IATA: "PMV",
  },
  {
    id: "3c13b217-75f8-461b-83fe-fe9d19b7fa65",
    airportName: "Maribor",
    Location: "Slovenia",
    IATA: "MBX",
  },
  {
    id: "e8abd3e1-6749-43ef-af1e-9a63790b1b49",
    airportName: "Mariehamn (Maarianhamina)",
    Location: "Finland",
    IATA: "MHQ",
  },
  {
    id: "322cbf38-ce9b-4258-b737-456d96674e66",
    airportName: "Maroua",
    Location: "Cameroon",
    IATA: "MVR",
  },
  {
    id: "bac3171f-d330-43a5-8183-11f1e96d78b6",
    airportName: "Marquette, MI",
    Location: "USA",
    IATA: "MQT",
  },
  {
    id: "47e0895b-fbd3-415b-8d17-9f451bdcb023",
    airportName: "Marrakech - Menara",
    Location: "Morocco",
    IATA: "RAK",
  },
  {
    id: "4bc8a835-9dca-48c6-b9a2-3359ec45c0cd",
    airportName: "Marsa Matrah (Marsa Matruh)",
    Location: "Egypt",
    IATA: "MUH",
  },
  {
    id: "67c207f4-2375-4c64-bef8-02f3cddbf59c",
    airportName: "Marseille",
    Location: "France",
    IATA: "MRS",
  },
  {
    id: "9ed19736-d9fd-437e-8283-1cd677265fb0",
    airportName: "Marsh Harbour",
    Location: "Bahamas",
    IATA: "MHH",
  },
  {
    id: "aa2b3c60-de59-4b10-85e2-de61c96da323",
    airportName: "Martha's Vineyard, MA",
    Location: "USA",
    IATA: "MVY",
  },
  {
    id: "ac0ac941-cb50-4f05-bb74-fd04c482541a",
    airportName: "Maryborough",
    Location: "Australia",
    IATA: "MBH",
  },
  {
    id: "15f6d93e-e75f-47c3-a0fe-adacdb2035c5",
    airportName: "Maseru - Moshoeshoe Int'l",
    Location: "Lesotho",
    IATA: "MSU",
  },
  {
    id: "6578f6ab-2542-4109-a27f-7ccf54c14314",
    airportName: "Mason City, IA",
    Location: "USA",
    IATA: "MCW",
  },
  {
    id: "4e697dd8-2fe8-4db4-9e6a-95b41a7ac19d",
    airportName: "Masvingo",
    Location: "Zimbabwe",
    IATA: "MVZ",
  },
  {
    id: "f4aeb951-1029-43d1-8160-bff31d629886",
    airportName: "Matsumoto, Nagano",
    Location: "Japan",
    IATA: "MMJ",
  },
  {
    id: "acb30eb1-cdfb-4049-b0e3-08770004dbe0",
    airportName: "Matsuyama",
    Location: "Japan",
    IATA: "MYJ",
  },
  {
    id: "2103b02d-0698-4047-8d18-e618b9bdbaec",
    airportName: "Mattoon, IL",
    Location: "USA",
    IATA: "MTO",
  },
  {
    id: "e8dfb232-6cb3-4b03-aa54-b1177297bd09",
    airportName: "Maun",
    Location: "Botswana",
    IATA: "MUB",
  },
  {
    id: "e438cf09-ddbc-4bb8-8470-d8fc0a1253dd",
    airportName: "Maupiti",
    Location: "French Polynesia",
    IATA: "MAU",
  },
  {
    id: "01118ea4-4168-491c-8397-168914f70048",
    airportName: "Mauritius - S.Seewoosagur Ram Int'l",
    Location: "Mauritius",
    IATA: "MRU",
  },
  {
    id: "b76cbf83-41e1-458a-9d57-e7a761ad7163",
    airportName: "Mayaguez",
    Location: "Puerto Rico",
    IATA: "MAZ",
  },
  {
    id: "8526dd24-bd59-479a-b510-6320658d3379",
    airportName: "Mazatlan",
    Location: "Mexico",
    IATA: "MZT",
  },
  {
    id: "02dce156-9a87-4c5b-aaed-084f25eed336",
    airportName: "McAllen, TX",
    Location: "USA",
    IATA: "MFE",
  },
  {
    id: "ebbf6f23-276b-49a2-aef6-0bb12090b224",
    airportName: "Medan",
    Location: "Indonesia",
    IATA: "MES",
  },
  {
    id: "1f8c92b0-3dc8-438b-b9a4-593cef698a39",
    airportName: "Medellin",
    Location: "Colombia",
    IATA: "MDE",
  },
  {
    id: "f3770ec9-7e90-4cbd-afad-b445f45bb98e",
    airportName: "Medford, OR",
    Location: "USA",
    IATA: "MFR",
  },
  {
    id: "9968ffa0-8c61-48e4-a889-164820949a68",
    airportName: "Meekatharra",
    Location: "Australia",
    IATA: "MKR",
  },
  {
    id: "12a86635-e0bb-4e37-8a5d-c502d9ed4ec3",
    airportName: "Melbourne - Tullamarine",
    Location: "Australia",
    IATA: "MEL",
  },
  {
    id: "4e79c7e5-7011-459d-8d08-f283b67ddd93",
    airportName: "Melbourne, FL",
    Location: "USA",
    IATA: "MLB",
  },
  {
    id: "57579da4-469a-42c1-a13f-a1aa0992a051",
    airportName: "Memphis, TN",
    Location: "USA",
    IATA: "MEM",
  },
  {
    id: "c4dd62df-91e8-4d68-8246-ccc99f16534a",
    airportName: "Mendoza",
    Location: "Argentina",
    IATA: "MDZ",
  },
  {
    id: "9edd6eae-7248-451d-acd4-315b869a8f2e",
    airportName: "Manado",
    Location: "Indonesia",
    IATA: "MDC",
  },
  {
    id: "33ad03cc-aeb8-48b0-b5df-37b79b600eb1",
    airportName: "Merced, CA",
    Location: "USA",
    IATA: "MCE",
  },
  {
    id: "b5a4b7ba-6ae4-44b2-b0b3-d02ae89bd997",
    airportName: "Merida",
    Location: "Mexico",
    IATA: "MID",
  },
  {
    id: "6de73b44-aa4d-44e4-92b8-e292578a9103",
    airportName: "Meridian, MS",
    Location: "USA",
    IATA: "MEI",
  },
  {
    id: "b81f2f94-aaf4-410f-ba99-a6d0f4255389",
    airportName: "Merimbula",
    Location: "Australia",
    IATA: "MIM",
  },
  {
    id: "f5f9edfa-bbf7-46af-af73-be581c540c3c",
    airportName: "Messina",
    Location: "South Africa",
    IATA: "MEZ",
  },
  {
    id: "56604431-eb3b-4d6c-8eac-b6e0928a5085",
    airportName: "Metlakatla, AK",
    Location: "USA",
    IATA: "MTM",
  },
  {
    id: "09d6c964-887c-4d67-9a77-715d26b46c39",
    airportName: "Metz",
    Location: "France",
    IATA: "MZM",
  },
  {
    id: "46b2593b-0d16-4d2f-b028-c921e18c188a",
    airportName: "Mexicali",
    Location: "Mexico",
    IATA: "MXL",
  },
  {
    id: "bb8eb865-fa5c-4e1a-85ab-91f86c6fb80a",
    airportName: "Mexico City",
    Location: "Mexico",
    IATA: "MEX",
  },
  {
    id: "62d12007-8e7c-43c1-a675-ea27561ddd12",
    airportName: "Mfuwe",
    Location: "Zambia",
    IATA: "MFU",
  },
  {
    id: "392f9e3b-88d4-43ba-b06c-6f882fe1f8e7",
    airportName: "Miami, FL",
    Location: "USA",
    IATA: "MIA",
  },
  {
    id: "d678fceb-652e-4066-9d84-a5d34905f7c7",
    airportName: "Mianwali",
    Location: "Pakistan",
    IATA: "MWD",
  },
  {
    id: "19983904-8efd-46e8-a08c-a87069b312b1",
    airportName: "Middlemount",
    Location: "Australia",
    IATA: "MMM",
  },
  {
    id: "d28bb3aa-6fc8-419b-8144-5cd1f85a99be",
    airportName: "Midland/Odessa, TX",
    Location: "USA",
    IATA: "MAF",
  },
  {
    id: "bf94b6e0-b53d-48da-bfbd-c28d786817ed",
    airportName: "Mikkeli",
    Location: "Finland",
    IATA: "MIK",
  },
  {
    id: "f49e97aa-d4ce-40c6-9943-9bdb8488c1bc",
    airportName: "Milan",
    Location: "Italy",
    IATA: "MIL",
  },
  {
    id: "e04def1c-4e0c-4ff1-b00d-2391d97f43b1",
    airportName: "Milan - Linate",
    Location: "Italy",
    IATA: "LIN",
  },
  {
    id: "be1345fd-ceb7-4b19-830a-eaad90ab66b8",
    airportName: "Milan - Malpensa",
    Location: "Italy",
    IATA: "MXP",
  },
  {
    id: "7794a3c1-7997-4752-b256-99d4517d100a",
    airportName: "Mildura",
    Location: "Australia",
    IATA: "MQL",
  },
  {
    id: "114bbf0f-5232-42a7-8626-f5c3fc79417b",
    airportName: "Miles City, MT",
    Location: "USA",
    IATA: "MLS",
  },
  {
    id: "f4fcf087-bac8-4034-9214-04dff997835f",
    airportName: "Milford Sound",
    Location: "New Zealand",
    IATA: "MFN",
  },
  {
    id: "5fd93067-edc2-4660-80e4-9ca5e0200afe",
    airportName: "Milwaukee, WI",
    Location: "USA",
    IATA: "MKE",
  },
  {
    id: "5546e568-fe33-4067-bb65-8f4fe136630e",
    airportName: "Minatitlan",
    Location: "Mexico",
    IATA: "MTT",
  },
  {
    id: "9797c1c7-0646-4cc6-81fe-bd1145e9ecac",
    airportName: "Mineralnye Vody",
    Location: "Russia",
    IATA: "MRV",
  },
  {
    id: "aa948ae6-497e-417f-b73e-bc25dab60ed7",
    airportName: "Minneapolis - St. Paul Int'l Airport, MN",
    Location: "USA",
    IATA: "MSP",
  },
  {
    id: "17066998-1d1c-481f-9b13-ab3310e27aa1",
    airportName: "Minot, ND",
    Location: "USA",
    IATA: "MOT",
  },
  {
    id: "f4ade535-b889-4d87-8ee5-daf9bffe0d0a",
    airportName: "Minsk, International",
    Location: "Belarus",
    IATA: "MSQ",
  },
  {
    id: "63ec0666-b838-47e4-8641-88db91c22292",
    airportName: "Miri",
    Location: "Malaysia",
    IATA: "MYY",
  },
  {
    id: "2bb823ef-6b50-4041-9581-cb1436a365f3",
    airportName: "Mirpur",
    Location: "Pakistan",
    IATA: "QML",
  },
  {
    id: "0b18458c-e04c-4966-a1e3-b9378eeae20d",
    airportName: "Missula, MT",
    Location: "USA",
    IATA: "MSO",
  },
  {
    id: "617f03ef-23cb-43bf-9c1c-9cdd44b12c76",
    airportName: "Mitchell, SD",
    Location: "USA",
    IATA: "MHE",
  },
  {
    id: "c3f68a51-f346-419a-aa93-40ba8cd7099c",
    airportName: "Miyako Jima (Ryuku Islands) - Hirara",
    Location: "Japan",
    IATA: "MMY",
  },
  {
    id: "f1e56771-0014-4ff9-9d78-a7159c9e0680",
    airportName: "Mkambati",
    Location: "South Africa",
    IATA: "MBM",
  },
  {
    id: "1ad03cb8-e7ea-4dea-b2ea-e6218c690571",
    airportName: "Moanda",
    Location: "Gabon",
    IATA: "MFF",
  },
  {
    id: "e9e6acac-fa8b-44b2-9e35-5318ae84699b",
    airportName: "Mobile, AL - Pascagoula, MS",
    Location: "USA",
    IATA: "MOB",
  },
  {
    id: "1d7bd400-de5a-405e-9f80-3978520c0aa9",
    airportName: "Modesto, CA",
    Location: "USA",
    IATA: "MOD",
  },
  {
    id: "0d4eb011-3317-40fc-b0c8-bc1ba98d2cc7",
    airportName: "Moenjodaro",
    Location: "Pakistan",
    IATA: "MJD",
  },
  {
    id: "5f44827e-6d00-4d54-bd5f-27abe06427ae",
    airportName: "Mogadischu",
    Location: "Somalia",
    IATA: "MGQ",
  },
  {
    id: "5ad16d8a-9fca-4012-b43d-f53de2c6eb16",
    airportName: "Mokuti",
    Location: "Namibia",
    IATA: "OKU",
  },
  {
    id: "6bc1f5c3-a049-427e-b105-e53e3cd5675e",
    airportName: "Moline/Quad Cities, IL",
    Location: "USA",
    IATA: "MLI",
  },
  {
    id: "083e5af0-700e-4d52-b169-63d3431e8c87",
    airportName: "Mombasa - Moi International",
    Location: "Kenya",
    IATA: "MBA",
  },
  {
    id: "4213b597-3c36-49e9-8192-d1c629ee0f35",
    airportName: "Monastir",
    Location: "Tunisia",
    IATA: "MIR",
  },
  {
    id: "77cf0123-1a3b-4951-8107-efd1ca0f5f9d",
    airportName: "Moncton",
    Location: "Canada",
    IATA: "YQM",
  },
  {
    id: "67690771-0434-47ad-9ece-1483e210efed",
    airportName: "Monroe, La",
    Location: "USA",
    IATA: "MLU",
  },
  {
    id: "b4f4e68d-5d7a-460b-868a-e6f1f0a94d4c",
    airportName: "Monrovia",
    Location: "Liberia",
    IATA: "MLW",
  },
  {
    id: "5501081b-0761-4959-9dd5-5eb45e652bfb",
    airportName: "Monrovia - Roberts Int'l",
    Location: "Liberia",
    IATA: "ROB",
  },
  {
    id: "e3864445-9e63-47c0-83ee-12bb46d1ac11",
    airportName: "Montenego Bay",
    Location: "Jamaica",
    IATA: "MBJ",
  },
  {
    id: "ac547af7-983a-439f-8fe6-3df62866b292",
    airportName: "Montenegro",
    Location: "Brazil",
    IATA: "QGF",
  },
  {
    id: "2accbf65-fb89-4f11-9406-37113bf39f5a",
    airportName: "Monterey, CA",
    Location: "USA",
    IATA: "MRY",
  },
  {
    id: "e2b72c34-f294-4f92-82e2-1c18eed4fc76",
    airportName: "Monterrey",
    Location: "Mexico",
    IATA: "MTY",
  },
  {
    id: "9d49063b-8b06-4c11-8741-c2e5c6d78058",
    airportName: "Monterrey - Aeropuerto Del Norte",
    Location: "Mexico",
    IATA: "NTR",
  },
  {
    id: "582aacb0-4c7a-46ff-9e4f-62d1530bc2f2",
    airportName: "Montevideo - Carrasco",
    Location: "Uruguay",
    IATA: "MVD",
  },
  {
    id: "ec8fbecd-58dc-49aa-9914-74d0be34d5dd",
    airportName: "Montgomery, AL",
    Location: "USA",
    IATA: "MGM",
  },
  {
    id: "c082b006-5c36-48ef-a559-6c899f28bf56",
    airportName: "Montpellier - Frejorgues",
    Location: "France",
    IATA: "MPL",
  },
  {
    id: "d92b0075-a389-43a0-a0d6-4505a73bdde9",
    airportName: "Montreal",
    Location: "Canada",
    IATA: "YMQ",
  },
  {
    id: "9a16e074-5ed0-486a-b1d1-db708df93c3a",
    airportName: "Montreal - Dorval (Montréal-Trudeau)",
    Location: "Canada",
    IATA: "YUL",
  },
  {
    id: "061be0d9-bbce-497c-b9df-8096b002b782",
    airportName: "Montreal - Mirabel",
    Location: "Canada",
    IATA: "YMX",
  },
  {
    id: "8affd457-6972-4ac0-a78f-5f14d33bec20",
    airportName: "Montrose/Tellruide, CO",
    Location: "USA",
    IATA: "MTJ",
  },
  {
    id: "f786b472-4979-4ae6-b946-3e3471bc2396",
    airportName: "Moorea",
    Location: "French Polynesia",
    IATA: "MOZ",
  },
  {
    id: "eb94d114-c360-45aa-8a31-03bf930d3d31",
    airportName: "Moranbah",
    Location: "Australia",
    IATA: "MOV",
  },
  {
    id: "bf18ea7e-3c03-441b-b771-ecfef2667965",
    airportName: "Moree",
    Location: "Australia",
    IATA: "MRZ",
  },
  {
    id: "354174fc-2c1f-44fa-9c53-f002e9e85ac4",
    airportName: "Morelia",
    Location: "Mexico",
    IATA: "MLM",
  },
  {
    id: "d1c89dac-7092-4b09-aa1f-8c4eccd8c09a",
    airportName: "Morgantown, WV",
    Location: "USA",
    IATA: "MGW",
  },
  {
    id: "f3a36f0f-85d5-455a-8887-d3ad5b25d744",
    airportName: "Morioka, Hanamaki",
    Location: "Japan",
    IATA: "HNA",
  },
  {
    id: "de544ee2-a79c-44f3-b131-624d4ff07eaf",
    airportName: "Moroni - Prince Said Ibrahim",
    Location: "Comoros (Comores)",
    IATA: "HAH",
  },
  {
    id: "24c61823-a847-4b4f-8c1a-ec37c6f80fa9",
    airportName: "Moroni - Iconi",
    Location: "Comoros (Comores)",
    IATA: "YVA",
  },
  {
    id: "53d90f7e-4c04-4698-91ce-5f239e6d40ac",
    airportName: "Moscow - Metropolitan Area",
    Location: "Russia",
    IATA: "MOW",
  },
  {
    id: "f94244e7-c353-4924-b91b-91f95ec59d3d",
    airportName: "Moscow - Domodedovo",
    Location: "Russia",
    IATA: "DME",
  },
  {
    id: "7f1db115-e26e-4b8b-b026-cb629d54f54e",
    airportName: "Moscow - Sheremetyevo",
    Location: "Russia",
    IATA: "SVO",
  },
  {
    id: "9ab2c733-11c9-444f-813e-c2c431398ae6",
    airportName: "Moscow - Vnukovo",
    Location: "Russia",
    IATA: "VKO",
  },
  {
    id: "030e43c5-2c33-4242-8960-ab96127f7696",
    airportName: "Moses Lake, WA",
    Location: "USA",
    IATA: "MWH",
  },
  {
    id: "195b53cc-ee39-44f8-adc6-2f39b88c09a9",
    airportName: "Mostar",
    Location: "Bosnia and Herzegovina",
    IATA: "OMO",
  },
  {
    id: "74d1562f-202e-495c-adde-1f6b122e423b",
    airportName: "Mosul",
    Location: "Iraq",
    IATA: "OSM",
  },
  {
    id: "385c5b34-2be5-4e11-a244-92993fd57af6",
    airportName: "Mouila",
    Location: "Gabon",
    IATA: "MJL",
  },
  {
    id: "43d34fe8-2658-4829-bcad-278c88a2c21e",
    airportName: "Moundou",
    Location: "Chad",
    IATA: "MQQ",
  },
  {
    id: "c7ad3b4b-5705-4381-baec-bf4d60046316",
    airportName: "Mount Cook",
    Location: "New Zealand",
    IATA: "GTN",
  },
  {
    id: "4781b4fc-7446-4a2e-8c0e-37240d2d6127",
    airportName: "Mount Gambier",
    Location: "Australia",
    IATA: "MGB",
  },
  {
    id: "486348d3-7cfa-4987-8ec7-fa7600b45d2d",
    airportName: "Mount Magnet",
    Location: "Australia",
    IATA: "MMG",
  },
  {
    id: "776a3b98-588f-4b2c-b483-649460a28fff",
    airportName: "Mt. Isa",
    Location: "Australia",
    IATA: "ISA",
  },
  {
    id: "2f9b49bf-413a-43e9-8572-3ea1a2392d9f",
    airportName: "Mt. McKinley, AK",
    Location: "USA",
    IATA: "MCL",
  },
  {
    id: "4784aa7d-8031-448f-9c3d-f36c844114bc",
    airportName: "Muenchen (Munich) - Franz Josef Strauss",
    Location: "Germany",
    IATA: "MUC",
  },
  {
    id: "509f507b-0436-4aaf-85a8-dc8ff543d0d4",
    airportName: "Muenster/Osnabrueck",
    Location: "Germany",
    IATA: "FMO",
  },
  {
    id: "7f332bd7-363c-4dba-9769-8778aff545b5",
    airportName: "Muenster/Osnabrueck",
    Location: "Germany",
    IATA: "MSR",
  },
  {
    id: "84203b7f-a2c3-44c9-a0cc-41b2cc665ed8",
    airportName: "Mulhouse",
    Location: "France",
    IATA: "MLH",
  },
  {
    id: "b2623527-26f7-4980-aaa8-34cab3c50fd2",
    airportName: "Multan",
    Location: "Pakistan",
    IATA: "MUX",
  },
  {
    id: "cd879025-016c-44d0-a600-2b1f5d1b81ac",
    airportName: "Murcia",
    Location: "Spain",
    IATA: "MJV",
  },
  {
    id: "a7f2cbd1-1f19-4174-905d-5e9dca9f7cf9",
    airportName: "Murmansk",
    Location: "Russia",
    IATA: "MMK",
  },
  {
    id: "7fe576c7-f9bd-495a-8353-5076eb877ea9",
    airportName: "Muscat - Seeb",
    Location: "Oman",
    IATA: "MCT",
  },
  {
    id: "c4e6797c-f768-410d-bd0a-569bfc3135da",
    airportName: "Muscle Shoals, AL",
    Location: "USA",
    IATA: "MSL",
  },
  {
    id: "c7754f38-d0d0-4d89-a068-9c322c560478",
    airportName: "Muskegon, MI",
    Location: "USA",
    IATA: "MKG",
  },
  {
    id: "ab75077c-d980-4e85-9693-a7fd4cf1d9fa",
    airportName: "Muzaffarabad",
    Location: "Pakistan",
    IATA: "MFG",
  },
  {
    id: "ba5abaf0-e684-4ec0-b0c2-cc26bd5e2769",
    airportName: "Mvengue",
    Location: "Gabon",
    IATA: "MVB",
  },
  {
    id: "cfb9c16e-502c-46d2-83f1-5b5426c54b94",
    airportName: "Mykonos",
    Location: "Greece",
    IATA: "JMK",
  },
  {
    id: "c90bda8d-801f-48cb-afd3-0f04ab8c51a2",
    airportName: "Myrtle Beach, SC",
    Location: "USA",
    IATA: "MYR",
  },
  {
    id: "5fb17ae5-16d5-43ee-a5a3-eb7e0b47fb00",
    airportName: "Miyazaki",
    Location: "Japan",
    IATA: "KMI",
  },
  {
    id: "9a0dc93d-f5b5-4286-a055-d479b5b31c50",
    airportName: "Mytilene (Lesbos)",
    Location: "Greece",
    IATA: "MJT",
  },
  {
    id: "91d25450-d39d-4165-8c07-dd6457f161a1",
    airportName: "Mzamba",
    Location: "South Africa",
    IATA: "MZF",
  },
  {
    id: "ec4d36e1-de07-49c2-8380-b9c80b599560",
    airportName: "Nadi",
    Location: "Fiji",
    IATA: "NAN",
  },
  {
    id: "7fc98612-e943-486d-8440-61ff229802fe",
    airportName: "Nagasaki",
    Location: "Japan",
    IATA: "NGS",
  },
  {
    id: "60e24483-8a3c-4033-aaeb-814a20896108",
    airportName: "Nagoya - Komaki AFB",
    Location: "Japan",
    IATA: "NGO",
  },
  {
    id: "2910b457-ac82-4ea8-8eed-8c8c086c3dde",
    airportName: "Nagpur",
    Location: "India",
    IATA: "NAG",
  },
  {
    id: "49eaa59b-7661-4564-988d-21aa1ace2eab",
    airportName: "Nairobi",
    Location: "Kenya",
    IATA: "NBO",
  },
  {
    id: "952b4bed-6e26-47e0-9ee7-a7d103ce2928",
    airportName: "Nancy",
    Location: "France",
    IATA: "ENC",
  },
  {
    id: "fe6ccd65-8dd8-4c0b-8180-8ce7c82007b9",
    airportName: "Nanisivik",
    Location: "Canada",
    IATA: "YSR",
  },
  {
    id: "6c4a544f-c79c-4a75-b811-a0cbc5e11401",
    airportName: "Nanning",
    Location: "Guangxi, China",
    IATA: "NNG",
  },
  {
    id: "a237fd32-bb54-4cff-b187-0e8090d7439c",
    airportName: "Nantes",
    Location: "France",
    IATA: "NTE",
  },
  {
    id: "940d36f0-4866-4864-968e-6eec856ee5e9",
    airportName: "Nantucket, MA",
    Location: "USA",
    IATA: "ACK",
  },
  {
    id: "23d9572a-4191-4bf7-b803-237d5e3698c7",
    airportName: "Naples",
    Location: "Italy",
    IATA: "NAP",
  },
  {
    id: "5c80fab9-2d47-456c-92f9-50e9466c96a1",
    airportName: "Naples, FL",
    Location: "USA",
    IATA: "APF",
  },
  {
    id: "895f4f31-84d4-4dac-9f39-7406a90abd22",
    airportName: "Narrabri",
    Location: "Australia",
    IATA: "NAA",
  },
  {
    id: "21f7dd3e-840f-4387-9d47-e07468baf00b",
    airportName: "Narrandera",
    Location: "Australia",
    IATA: "NRA",
  },
  {
    id: "5c3204e0-1e38-4904-976c-cbe72deeafe6",
    airportName: "Narsarsuaq",
    Location: "Greenland",
    IATA: "UAK",
  },
  {
    id: "abdb8830-ac90-4e30-8c65-f41bfa6bfde6",
    airportName: "Nashville, TN",
    Location: "USA",
    IATA: "BNA",
  },
  {
    id: "40617ff3-0dd2-4839-9139-dd019f9770e7",
    airportName: "Nassau",
    Location: "Bahamas",
    IATA: "NAS",
  },
  {
    id: "14bd6617-62b0-4ab9-8b8b-c1a6c8836798",
    airportName: "Natal",
    Location: "Brazil",
    IATA: "NAT",
  },
  {
    id: "291d68a3-43f2-4046-a35a-d00991ce9b81",
    airportName: "Nausori",
    Location: "Fiji/Suva",
    IATA: "SUV",
  },
  {
    id: "650ea1b1-919f-4ad7-80fe-3f9c889ac279",
    airportName: "Nawab Shah",
    Location: "Pakistan",
    IATA: "WNS",
  },
  {
    id: "c86c2819-f677-4110-ac2b-4f4ebc53216b",
    airportName: "N'Djamena",
    Location: "Chad",
    IATA: "NDJ",
  },
  {
    id: "c9789a56-9bf2-40d3-b7c6-6783fe63818b",
    airportName: "N'Dola",
    Location: "Zambia",
    IATA: "NLA",
  },
  {
    id: "d7e64004-77e0-41f9-a2f0-6b97da5ce929",
    airportName: "Nelson",
    Location: "New Zealand",
    IATA: "NSN",
  },
  {
    id: "d30394c2-8952-48b5-a8e0-7c596dd46c66",
    airportName: "Nelspruit",
    Location: "South Africa",
    IATA: "NLP",
  },
  {
    id: "2e600540-e165-484b-81b8-18d241b1c93c",
    airportName: "Nelspruit - Kruger Mpumalanga Int'l Airport",
    Location: ", ZA",
    IATA: "MQP",
  },
  {
    id: "e2f6cbdd-3917-4765-b2ba-4ef5f2842a28",
    airportName: "Nevis",
    Location: "Nevis",
    IATA: "NEV",
  },
  {
    id: "3b4fa9b0-2a9c-481f-b160-3e8bc84d9899",
    airportName: "New Bern, NC",
    Location: "USA",
    IATA: "EWN",
  },
  {
    id: "9d6671b9-844b-4eb7-94cc-64f91f5b0035",
    airportName: "New Haven, CT",
    Location: "USA",
    IATA: "HVN",
  },
  {
    id: "39bfc431-f855-486c-b823-45bffb31d6d1",
    airportName: "New Orleans, La",
    Location: "USA",
    IATA: "MSY",
  },
  {
    id: "1249f6e8-4440-4bb0-8209-df015f324b8d",
    airportName: "New York - John F. Kennedy, NY",
    Location: "USA",
    IATA: "JFK",
  },
  {
    id: "8ba6f271-12b0-45fc-a835-f1fa48a4076a",
    airportName: "New York - La Guardia, NY",
    Location: "USA",
    IATA: "LGA",
  },
  {
    id: "2019551f-5cbb-4968-89b5-1e2045bf8bd8",
    airportName: "New York - Newark, NJ",
    Location: "USA",
    IATA: "EWR",
  },
  {
    id: "3e1406f9-b186-40fb-9543-9d5ab5a81d6f",
    airportName: "New York, NY",
    Location: "USA",
    IATA: "NYC",
  },
  {
    id: "a65d4944-0193-4cba-b8ca-719819d4994a",
    airportName: "Newburgh, NY",
    Location: "USA",
    IATA: "SWF",
  },
  {
    id: "7974de71-e43c-44b7-8f99-cf18be0d5ffa",
    airportName: "Newcastle - Belmont",
    Location: "Australia",
    IATA: "BEO",
  },
  {
    id: "4b282fa9-dea5-4171-a5c5-c5a05c486156",
    airportName: "Newcastle - Williamtown",
    Location: "Australia",
    IATA: "NTL",
  },
  {
    id: "b3737a5b-b4d3-42d9-8e28-df4f43621a4f",
    airportName: "Newcastle",
    Location: "United Kingdom",
    IATA: "NCL",
  },
  {
    id: "df8f539a-5cda-4465-ab22-f9409227da22",
    airportName: "Newcastle",
    Location: "South Africa",
    IATA: "NCS",
  },
  {
    id: "a21e19f3-44c4-4224-b195-2e9d42a840c9",
    airportName: "Newman",
    Location: "Australia",
    IATA: "ZNE",
  },
  {
    id: "8ad62dcf-ecb8-415c-be85-3270e91b3459",
    airportName: "Newport News/Williamsburg, VA",
    Location: "USA",
    IATA: "PHF",
  },
  {
    id: "3bd4c029-d39d-4802-b67e-140c00ac14f5",
    airportName: "N'Gaoundere",
    Location: "Cameroon",
    IATA: "NGE",
  },
  {
    id: "b87857f3-07ee-4539-b7d9-5b619ecf4a97",
    airportName: "Niagara Falls Int'l",
    Location: "USA",
    IATA: "IAG",
  },
  {
    id: "d667efc4-5dac-4a9d-9873-2ffe9fc5871a",
    airportName: "Niamey",
    Location: "Niger",
    IATA: "NIM",
  },
  {
    id: "d209fd13-e187-44f2-8e7b-a3e90dc47de9",
    airportName: "Nice - Cote D'Azur",
    Location: "France",
    IATA: "NCE",
  },
  {
    id: "e05f8b89-707f-487d-ad29-afa12bc53fa9",
    airportName: "Nicosia",
    Location: "Cyprus",
    IATA: "NIC",
  },
  {
    id: "b726d354-2111-42b0-bd36-957e57d21199",
    airportName: "Nikolaev",
    Location: "Ukraine",
    IATA: "NLV",
  },
  {
    id: "63c4502e-f840-4090-baf3-f645cbb2b131",
    airportName: "Niigata",
    Location: "Japan",
    IATA: "KIJ",
  },
  {
    id: "5edd80f5-3e54-43c0-96e9-8c2d20ddf4b6",
    airportName: "Nimes",
    Location: "France",
    IATA: "FNI",
  },
  {
    id: "a95edb37-b4de-47a1-85f0-862611fddf12",
    airportName: "Nis",
    Location: "Yugoslavia",
    IATA: "INI",
  },
  {
    id: "8d09d623-bdbb-42c5-9517-3ab52ba5209a",
    airportName: "Nome, AK",
    Location: "USA",
    IATA: "OME",
  },
  {
    id: "fa29d309-4388-4f39-8f2d-2fcafa63c92b",
    airportName: "Noosa",
    Location: "Australia",
    IATA: "NSA",
  },
  {
    id: "1d366c07-f0d6-4d7f-a8b1-48498ae8c6ab",
    airportName: "Norfolk Island",
    Location: "Australia",
    IATA: "NLK",
  },
  {
    id: "091ddef9-d3e7-4588-8426-c0dedbb05c38",
    airportName: "Norfolk/Virginia Beach, VA",
    Location: "USA",
    IATA: "ORF",
  },
  {
    id: "b78e456b-f1b1-4f53-950b-7518f9ee9b4e",
    airportName: "Norman Wells",
    Location: "Canada",
    IATA: "YVQ",
  },
  {
    id: "410ca56c-da37-47de-8915-cef3542cdd47",
    airportName: "Norrkoeping",
    Location: "Sweden",
    IATA: "NRK",
  },
  {
    id: "4b46e272-2cd5-4f0e-a2c1-6b5991ac6bee",
    airportName: "North Bend, OR",
    Location: "USA",
    IATA: "OTH",
  },
  {
    id: "45b3d2b7-93f7-44f9-8bb7-e6c99a93ac9b",
    airportName: "North Eleuthera",
    Location: "Bahamas",
    IATA: "ELH",
  },
  {
    id: "c385bd53-e896-48e7-998c-47a3666b39cf",
    airportName: "Nouadhibou",
    Location: "Mauritania",
    IATA: "NDB",
  },
  {
    id: "d0f02fe3-524a-4cb5-a6cc-8d4bfee6fab4",
    airportName: "Nouakchott",
    Location: "Mauritania",
    IATA: "NKC",
  },
  {
    id: "698163c7-d1f4-4fb6-8eea-39d11bbfc250",
    airportName: "Noumea",
    Location: "New Caledonia",
    IATA: "NOU",
  },
  {
    id: "9d5d92e2-adf3-470f-bf6a-dded8cf91555",
    airportName: "Novi Sad",
    Location: "Yugoslavia",
    IATA: "QND",
  },
  {
    id: "2b17367c-ce3a-4817-9037-e9e11e247209",
    airportName: "Novosibirsk - Tolmachevo",
    Location: "Russia",
    IATA: "OVB",
  },
  {
    id: "325705dc-28b0-40e5-a02b-dd5990821017",
    airportName: "Nürnberg (Nuremberg)",
    Location: "Germany",
    IATA: "NUE",
  },
  {
    id: "b5517b86-f2fb-440e-87e7-35c41efcc5bd",
    airportName: "Nuevo Laredo",
    Location: "Mexico",
    IATA: "NLD",
  },
  {
    id: "0ff60a33-5889-4798-b219-8b1955b0bf03",
    airportName: "Nuku'alofa - Fua'Amotu International",
    Location: "Tonga",
    IATA: "TBU",
  },
  {
    id: "e3938060-b284-4319-a6c1-f2eb6380f174",
    airportName: "Oakland, CA",
    Location: "USA",
    IATA: "OAK",
  },
  {
    id: "78066929-acba-4df4-b947-d37357a5ca93",
    airportName: "Oaxaca",
    Location: "Mexico",
    IATA: "OAX",
  },
  {
    id: "cba95e44-ecc3-459c-a443-b7f4f3ff4807",
    airportName: "Odense",
    Location: "Denmark",
    IATA: "ODE",
  },
  {
    id: "2057cfcc-5dc7-4964-875d-169b6b478bea",
    airportName: "Odessa",
    Location: "Ukraine",
    IATA: "ODS",
  },
  {
    id: "5cb0b58e-5cf5-4363-bbb6-88ba583ac8c3",
    airportName: "Oerebro",
    Location: "Sweden",
    IATA: "ORB",
  },
  {
    id: "c8840364-3b8e-4f8a-a2c9-1fc4da877707",
    airportName: "Ohrid",
    Location: "Macedonia",
    IATA: "OHD",
  },
  {
    id: "120b11b9-b992-45a1-9898-c09e50200377",
    airportName: "Oita",
    Location: "Japan",
    IATA: "OIT",
  },
  {
    id: "a072052c-fed9-419e-ac56-6ad562a31343",
    airportName: "Okayama",
    Location: "Japan",
    IATA: "OKJ",
  },
  {
    id: "5e725da7-c93a-4d67-903e-3497c7422447",
    airportName: "Okinawa, Ryukyo Island - Naha",
    Location: "Japan",
    IATA: "OKA",
  },
  {
    id: "8a3ca7d7-e8b7-4200-b121-a1463570a43e",
    airportName: "Oklahoma City, OK - Will Rogers World",
    Location: "USA",
    IATA: "OKC",
  },
  {
    id: "6d773643-b523-41da-a86e-7da9136bbddf",
    airportName: "Olbia",
    Location: "Italy",
    IATA: "OLB",
  },
  {
    id: "5c194cbe-8a35-4028-9022-ab1fb5e7569b",
    airportName: "Olympic Dam",
    Location: "Australia",
    IATA: "OLP",
  },
  {
    id: "fd951feb-142a-42b8-a5a6-b335bd3ed13c",
    airportName: "Omaha, NE",
    Location: "USA",
    IATA: "OMA",
  },
  {
    id: "051ea691-3844-454b-a3a3-e6bb7dfa3163",
    airportName: "Ondangwa",
    Location: "Namibia",
    IATA: "OND",
  },
  {
    id: "a88a2731-e232-4411-a248-0836721af542",
    airportName: "Ontario, CA",
    Location: "USA",
    IATA: "ONT",
  },
  {
    id: "e9dd1eff-95d5-412c-9768-272b2eb88cac",
    airportName: "Oran (Ouahran)",
    Location: "Algeria",
    IATA: "ORN",
  },
  {
    id: "c809f45f-1071-4c62-9459-c89a7297d206",
    airportName: "Orange",
    Location: "Australia",
    IATA: "OAG",
  },
  {
    id: "d20e91b8-2309-474a-a9a6-2208d0080fba",
    airportName: "Orange County (Santa Ana), CA",
    Location: "USA",
    IATA: "SNA",
  },
  {
    id: "88d9f828-c15d-4556-ae92-cb8a51cc09f5",
    airportName: "Oranjemund",
    Location: "Namibia",
    IATA: "OMD",
  },
  {
    id: "2c8e77bc-fa17-468f-8ba3-3936562eb188",
    airportName: "Orkney",
    Location: "United Kingdom",
    IATA: "KOI",
  },
  {
    id: "f26a1cd8-2f7e-4a7a-b57a-dbdc386afa19",
    airportName: "Orlando Metropolitan Area, FL",
    Location: "USA",
    IATA: "ORL",
  },
  {
    id: "3789a22e-f94e-480c-bad3-5c735393eaba",
    airportName: "Orpheus Island",
    Location: "Australia",
    IATA: "ORS",
  },
  {
    id: "43228568-da11-4a1b-9920-951d0ec49590",
    airportName: "Osaka, Metropolitan Area",
    Location: "Japan",
    IATA: "OSA",
  },
  {
    id: "f7a0f9d6-9961-4e38-946f-7bb4ea557337",
    airportName: "Osaka - Itami",
    Location: "Japan",
    IATA: "ITM",
  },
  {
    id: "0271c8da-87cf-4c6b-b8c4-9af0c9a3e4e6",
    airportName: "Osaka - Kansai Int'l Airport",
    Location: "Japan",
    IATA: "KIX",
  },
  {
    id: "03838532-471a-4a16-a3fa-69d0756b3949",
    airportName: "Oshkosh, WI",
    Location: "USA",
    IATA: "OSH",
  },
  {
    id: "46d5a182-e584-4479-8409-78cebf83329b",
    airportName: "Osijek",
    Location: "Croatia (Hrvatska)",
    IATA: "OSI",
  },
  {
    id: "3fc69887-f332-4703-8730-5b5769bac6dc",
    airportName: "Oslo",
    Location: "Norway",
    IATA: "OSL",
  },
  {
    id: "18c3b58d-e73c-454c-ac92-b88be21c5d35",
    airportName: "Oslo - Fornebu",
    Location: "Norway",
    IATA: "FBU",
  },
  {
    id: "3e3e9f02-a844-4817-9e29-6d527c890085",
    airportName: "Oslo - Sandefjord",
    Location: "Norway",
    IATA: "TRF",
  },
  {
    id: "fee3bb13-d7b5-478b-9830-1f565773a8f9",
    airportName: "Ottawa - Hull",
    Location: "Canada",
    IATA: "YOW",
  },
  {
    id: "25e8352c-c913-4bcf-8baa-6b2af6b913bf",
    airportName: "Ouadda",
    Location: "Central African Republic",
    IATA: "ODA",
  },
  {
    id: "2f27cfab-72a1-49e4-86ce-630b02d7f82c",
    airportName: "Ouarzazate",
    Location: "Morocco",
    IATA: "OZZ",
  },
  {
    id: "437fbc38-56be-42e7-aab6-a539da54e16a",
    airportName: "Oudtshoorn",
    Location: "South Africa",
    IATA: "OUH",
  },
  {
    id: "18e67eba-3982-4769-9c28-6292dabdd957",
    airportName: "Ouagadougou",
    Location: "Burkina Faso",
    IATA: "OUA",
  },
  {
    id: "eb89d8ce-b583-40c1-a027-b1467000d01b",
    airportName: "Oujda",
    Location: "Morocco",
    IATA: "OUD",
  },
  {
    id: "44e63df0-cd8b-475a-91f6-fe87c444b765",
    airportName: "Oulu",
    Location: "Finland",
    IATA: "OUL",
  },
  {
    id: "3db86ae7-98e6-47d6-9d34-7839ca6b347a",
    airportName: "Oviedo",
    Location: "Spain",
    IATA: "OVD",
  },
  {
    id: "85fe4630-4543-40b1-a2ce-b6fba9f17791",
    airportName: "Owensboro, KY",
    Location: "USA",
    IATA: "OWB",
  },
  {
    id: "1bd6c419-bff2-4290-93ae-6d1776b3e18d",
    airportName: "Oxnard, CA",
    Location: "USA",
    IATA: "OXR",
  },
  {
    id: "f6fc5495-37ba-47cb-a8d1-dcf46e95c0b7",
    airportName: "Oyem",
    Location: "Gabon/Loyautte",
    IATA: "UVE",
  },
  {
    id: "9c426e2d-7701-482f-8d09-e8a3b0f82c46",
    airportName: "Paderborn/Lippstadt",
    Location: "Germany",
    IATA: "PAD",
  },
  {
    id: "fb50155f-52c8-4261-937f-83fe4b28b89f",
    airportName: "Paducah, KY",
    Location: "USA",
    IATA: "PAH",
  },
  {
    id: "7957301d-015d-4e46-bd84-adf33fd7ed45",
    airportName: "Page/Lake Powell, AZ",
    Location: "USA",
    IATA: "PGA",
  },
  {
    id: "52322cac-2806-47dd-ae3d-4bd6dd32c253",
    airportName: "Pago Pago",
    Location: "American Samoa",
    IATA: "PPG",
  },
  {
    id: "d607fd16-4eb1-4f38-abe9-791d94bb6bd8",
    airportName: "Pakersburg, WV/Marietta, OH",
    Location: "USA",
    IATA: "PKB",
  },
  {
    id: "707f2e47-f0f8-4316-a0a0-d4d185755aa4",
    airportName: "Palermo - Punta Raisi",
    Location: "Italy",
    IATA: "PMO",
  },
  {
    id: "6b1a3360-17fa-4ab9-a028-f334357a16bb",
    airportName: "Palm Springs, CA",
    Location: "USA",
    IATA: "PSP",
  },
  {
    id: "e7fc84dc-34d9-4c87-b5f4-08a1094a933b",
    airportName: "Palma de Mallorca",
    Location: "Spain",
    IATA: "PMI",
  },
  {
    id: "2dc6992a-f533-411e-a10a-0b37cf6f7eda",
    airportName: "Palmdale/Lancaster, CA",
    Location: "USA",
    IATA: "PMD",
  },
  {
    id: "c12c98af-a713-4eaa-a081-1bdef1764492",
    airportName: "Palmerston North",
    Location: "New Zealand",
    IATA: "PMR",
  },
  {
    id: "2b729c3c-8a30-4f87-8823-5b2fcec6734d",
    airportName: "Panama City - Tocumen International",
    Location: "Panama",
    IATA: "PTY",
  },
  {
    id: "d6a712fe-02d4-4f64-97c9-e0689c73e77c",
    airportName: "Panama City, FL",
    Location: "USA",
    IATA: "PFN",
  },
  {
    id: "7b127254-5ee9-4111-999f-bccda9d08dd4",
    airportName: "Panjgur",
    Location: "Pakistan",
    IATA: "PJG",
  },
  {
    id: "bdecbc77-11c4-4781-8bff-b3509b4803f7",
    airportName: "Pantelleria",
    Location: "Italy",
    IATA: "PNL",
  },
  {
    id: "502317d2-2268-4ba7-a41f-2cff499c367f",
    airportName: "Papeete - Faaa",
    Location: "French Polynesia (Tahiti)",
    IATA: "PPT",
  },
  {
    id: "e5479a9e-b8a1-47df-a61c-efc1d4b20ad2",
    airportName: "Paphos",
    Location: "Cyprus",
    IATA: "PFO",
  },
  {
    id: "8ce993cd-6009-477c-b89c-58aa8520c8d5",
    airportName: "Paraburdoo",
    Location: "Australia",
    IATA: "PBO",
  },
  {
    id: "2590d3e2-dce5-4d02-a823-d69f3785a58c",
    airportName: "Paramaribo - Zanderij Int'l",
    Location: "Suriname",
    IATA: "PBM",
  },
  {
    id: "4899fca6-f8bb-44a0-8e2e-8c4c6a88cdc5",
    airportName: "Paris",
    Location: "France",
    IATA: "PAR",
  },
  {
    id: "9c8f3c16-311f-4c56-9e8b-91c4e954e56b",
    airportName: "Paris - Charles de Gaulle",
    Location: "France",
    IATA: "CDG",
  },
  {
    id: "78c90977-c472-4fd4-841c-b362347d5526",
    airportName: "Paris - Le Bourget",
    Location: "France",
    IATA: "LBG",
  },
  {
    id: "67bf486d-6a7f-4cfa-a7d7-acf02c58fc34",
    airportName: "Paris - Orly",
    Location: "France",
    IATA: "ORY",
  },
  {
    id: "099d5a4e-59fa-414d-ab7b-59dd447f3524",
    airportName: "Paro",
    Location: "Bhutan",
    IATA: "PBH",
  },
  {
    id: "19561855-ddaf-4466-b5b6-05154ee11da2",
    airportName: "Pasco, WA",
    Location: "USA",
    IATA: "PSC",
  },
  {
    id: "007ad5e8-255a-4078-8aeb-4bd8c178d338",
    airportName: "Pasni",
    Location: "Pakistan",
    IATA: "PSI",
  },
  {
    id: "c0a35adf-c367-46ad-9f74-b61fdde14aba",
    airportName: "Patna",
    Location: "India",
    IATA: "PAT",
  },
  {
    id: "160c5754-b6b2-4438-8e83-a446e90a19b5",
    airportName: "Pattaya",
    Location: "Thailand",
    IATA: "PYX",
  },
  {
    id: "6d6a8005-63ca-4101-aef0-449204ec6674",
    airportName: "Pau",
    Location: "France",
    IATA: "PUF",
  },
  {
    id: "a2b1e309-def4-4541-ab58-57ed9ccd1ef1",
    airportName: "Pellston, MI",
    Location: "USA",
    IATA: "PLN",
  },
  {
    id: "895c552f-b0a4-426b-850d-179de8b9fdad",
    airportName: "Penang International",
    Location: "Malaysia",
    IATA: "PEN",
  },
  {
    id: "b8be1dce-7bdf-430c-b1fb-9365525e1041",
    airportName: "Pendelton, OR",
    Location: "USA",
    IATA: "PDT",
  },
  {
    id: "98617e62-0374-469b-86ef-2e8d74cde2d6",
    airportName: "Pensacola, FL",
    Location: "USA",
    IATA: "PNS",
  },
  {
    id: "f591c726-0626-4ee5-8746-c9936325e2bc",
    airportName: "Peoria/Bloomington, IL",
    Location: "USA",
    IATA: "PIA",
  },
  {
    id: "544a6f87-bafa-4354-8874-ccbc2103e0b1",
    airportName: "Pereira",
    Location: "Colombia",
    IATA: "PEI",
  },
  {
    id: "5455a741-4b31-46aa-8028-2c4df706d1f0",
    airportName: "Perpignan",
    Location: "France",
    IATA: "PGF",
  },
  {
    id: "72eb3c02-3797-4fc6-9d54-305286e9667b",
    airportName: "Perth Int'l",
    Location: "Australia",
    IATA: "PER",
  },
  {
    id: "205caf35-2c67-4c55-b349-23de601e867b",
    airportName: "Perugia",
    Location: "Italy",
    IATA: "PEG",
  },
  {
    id: "58975b32-ca15-46e7-a41d-510562e35b58",
    airportName: "Pescara",
    Location: "Italy",
    IATA: "PSR",
  },
  {
    id: "e948c076-3767-4371-87cc-f3baedd65c3f",
    airportName: "Peshawar",
    Location: "Pakistan",
    IATA: "PEW",
  },
  {
    id: "d79e4db4-9fe5-4902-adc7-36af2ae3348a",
    airportName: "Petersburg, AK",
    Location: "USA",
    IATA: "PSG",
  },
  {
    id: "c244a4db-186a-4214-897c-32b62196d760",
    airportName: "Phalaborwa",
    Location: "South Africa",
    IATA: "PHW",
  },
  {
    id: "9b2db9c1-989e-4d1d-b1ae-4df1bf487c3b",
    airportName: "Philadelphia, PA - Int'l",
    Location: "USA",
    IATA: "PHL",
  },
  {
    id: "b735af77-a8f1-43d5-a5a0-82aec0e66044",
    airportName: "Phnom Penh - Pochentong",
    Location: "Cambodia",
    IATA: "PNH",
  },
  {
    id: "866c835f-6e46-45bc-9290-f37fd46bc930",
    airportName: "Phoenix, AZ - Sky Harbor Int'l",
    Location: "USA",
    IATA: "PHX",
  },
  {
    id: "353c5ab7-a397-415b-9f94-ae06b28aad12",
    airportName: "Phuket",
    Location: "Thailand",
    IATA: "HKT",
  },
  {
    id: "d9814aa8-2e8a-473f-a151-af2758be166f",
    airportName: "Pierre, SD",
    Location: "USA",
    IATA: "PIR",
  },
  {
    id: "d52db155-0421-4f8f-8cf9-616d44907c26",
    airportName: "Pietermaritzburg",
    Location: "South Africa",
    IATA: "PZB",
  },
  {
    id: "5467d720-26c3-4ac3-a6fb-e2d654bd5792",
    airportName: "Pietersburg",
    Location: "South Africa",
    IATA: "PTG",
  },
  {
    id: "d3e3ce09-89f8-48d1-b570-560f7d6e236d",
    airportName: "Pilanesberg/Sun City",
    Location: "South Africa",
    IATA: "NTY",
  },
  {
    id: "59f6a4be-3978-4bb0-a333-0a4ae650e3f7",
    airportName: "Pisa - Gal Galilei",
    Location: "Italy",
    IATA: "PSA",
  },
  {
    id: "3e1747f5-49b0-43db-aa86-ead5768eb2a8",
    airportName: "Pittsburgh Int'l Airport, PA",
    Location: "USA",
    IATA: "PIT",
  },
  {
    id: "8d961234-7a30-4a05-881a-35446821a647",
    airportName: "Plattsburgh, NY",
    Location: "USA",
    IATA: "PLB",
  },
  {
    id: "e14a4805-ee78-40b9-8fad-8e84886169ae",
    airportName: "Plettenberg Bay",
    Location: "South Africa",
    IATA: "PBZ",
  },
  {
    id: "42e4fb1f-ca2d-4138-a383-8be5776f4363",
    airportName: "Pocatello, ID",
    Location: "USA",
    IATA: "PIH",
  },
  {
    id: "23925fe6-6847-4448-85c4-6b74eebf116a",
    airportName: "Podgorica",
    Location: "Yugoslavia",
    IATA: "TGD",
  },
  {
    id: "4956684d-7a8a-4c42-a68c-58c416e7733d",
    airportName: "Pohnpei",
    Location: "Micronesia",
    IATA: "PNI",
  },
  {
    id: "2dec7f6f-1b15-42e1-8523-606e710bfc68",
    airportName: "Pointe a Pitre",
    Location: "Guadeloupe",
    IATA: "PTP",
  },
  {
    id: "ccb62847-58e6-46a5-8226-c257b033b9f2",
    airportName: "Pointe Noire",
    Location: "Congo",
    IATA: "PNR",
  },
  {
    id: "1e9ba0db-4c40-4022-9033-8d30598696af",
    airportName: "Ponce",
    Location: "Puerto Rico",
    IATA: "PSE",
  },
  {
    id: "2566bd38-e36e-416f-be4c-e080d5532dea",
    airportName: "Ponta Delgada",
    Location: "Portugal",
    IATA: "PDL",
  },
  {
    id: "acfa060d-65a6-45a8-bd25-93fd05d29399",
    airportName: "Pori",
    Location: "Finland",
    IATA: "POR",
  },
  {
    id: "eee5bcf7-62a9-41ca-9cfd-504b00b22fb5",
    airportName: "Port Angeles, WA",
    Location: "USA",
    IATA: "CLM",
  },
  {
    id: "15e7b81a-8e96-4b7b-a602-7f234b3ac961",
    airportName: "Port au Prince",
    Location: "Haiti",
    IATA: "PAP",
  },
  {
    id: "cda2ee69-919d-480c-91bb-74a619344ac2",
    airportName: "Port Augusta",
    Location: "Australia",
    IATA: "PUG",
  },
  {
    id: "f5f37c73-d80f-452a-8561-a40b849f704c",
    airportName: "Port Elizabeth",
    Location: "South Africa",
    IATA: "PLZ",
  },
  {
    id: "5efdc5ed-a594-494b-9d46-332f6124df67",
    airportName: "Port Gentil",
    Location: "Gabon",
    IATA: "POG",
  },
  {
    id: "4150437c-52c3-4cad-816d-693f15e04af5",
    airportName: "Port Harcourt",
    Location: "Nigeria",
    IATA: "PHC",
  },
  {
    id: "560903cf-e02c-49f0-be5a-e06ef67ff086",
    airportName: "Port Hedland",
    Location: "Australia",
    IATA: "PHE",
  },
  {
    id: "55381da8-7745-4f99-8bc2-2144c8c12649",
    airportName: "Portland",
    Location: "Australia",
    IATA: "PTJ",
  },
  {
    id: "e63a2d06-caa9-4806-a07e-cf5e9ba464c5",
    airportName: "Portland, ME",
    Location: "USA",
    IATA: "PWM",
  },
  {
    id: "0cdf10b5-15cf-4f9b-8738-6a8c696d2efa",
    airportName: "Portland Int'l, OR",
    Location: "USA",
    IATA: "PDX",
  },
  {
    id: "76f803f6-7319-489d-9302-dcdc90cd0c03",
    airportName: "Port Lincoln",
    Location: "Australia",
    IATA: "PLO",
  },
  {
    id: "10719ff0-8c36-4448-881b-9bb1d8d31fab",
    airportName: "Port Macquarie",
    Location: "Australia",
    IATA: "PQQ",
  },
  {
    id: "5d348c09-3705-4039-a83e-51570a277442",
    airportName: "Port Menier, PQ",
    Location: "Canada",
    IATA: "YPN",
  },
  {
    id: "2a829499-fab6-4a09-a1ac-70a06c767938",
    airportName: "Port Moresby - Jackson Field",
    Location: "Papua New Guinea",
    IATA: "POM",
  },
  {
    id: "98ae1540-300d-4bf4-9609-7277495af77e",
    airportName: "Porto",
    Location: "Portugal",
    IATA: "OPO",
  },
  {
    id: "2f414bb3-0489-49cb-9f32-a03a721f77f6",
    airportName: "Porto Alegre",
    Location: "Brazil",
    IATA: "POA",
  },
  {
    id: "0fe11f3f-c941-46cc-844f-3b1979b8e8e5",
    airportName: "Port of Spain",
    Location: "Trinidad",
    IATA: "POS",
  },
  {
    id: "647202c7-e081-458e-88cb-8386444b0606",
    airportName: "Port Said",
    Location: "Egypt",
    IATA: "PSD",
  },
  {
    id: "51702ac1-0936-45b8-8091-c4a87988ea79",
    airportName: "Porto Santo",
    Location: "Portugal",
    IATA: "PXO",
  },
  {
    id: "3c5bd8b0-6832-4e38-ab3a-324464e26f15",
    airportName: "Porto Velho",
    Location: "Brazil",
    IATA: "PVH",
  },
  {
    id: "34a4d6a6-a5ee-4a15-86db-a28dfa44bae0",
    airportName: "Port Vila",
    Location: "Vanuatu",
    IATA: "VLI",
  },
  {
    id: "dbaf0ad1-aea5-4cd4-9aca-7f8292b0e25f",
    airportName: "Poughkeepsie, NY",
    Location: "USA",
    IATA: "POU",
  },
  {
    id: "e0e7ad6f-ca8b-4813-bfdd-4e45ad51e7d5",
    airportName: "Poznan, Lawica",
    Location: "Poland",
    IATA: "POZ",
  },
  {
    id: "6ae4a270-a42f-4b16-b123-afb266cb11f1",
    airportName: "Prague - Ruzyne",
    Location: "Czech Republic",
    IATA: "PRG",
  },
  {
    id: "0f38b8cf-2377-4992-bafa-75a311b4a6f0",
    airportName: "Praia",
    Location: "Cape Verde",
    IATA: "RAI",
  },
  {
    id: "3cb5d835-9d98-4b17-bac4-e01a4b89f776",
    airportName: "Presque Island, ME",
    Location: "USA",
    IATA: "PQI",
  },
  {
    id: "9b2ce6ae-bea7-4a00-8cb9-9aee2656861f",
    airportName: "Pretoria - Wonderboom Apt.",
    Location: "South Africa",
    IATA: "PRY",
  },
  {
    id: "ff815bac-3f92-4ee3-a184-c14fb71118b9",
    airportName: "Preveza/Lefkas",
    Location: "Greece",
    IATA: "PVK",
  },
  {
    id: "fb627e8f-d3bf-491b-bbbd-b3df10fd52a2",
    airportName: "Prince George",
    Location: "Canada",
    IATA: "YXS",
  },
  {
    id: "81e82063-1128-4f92-b5f5-db0358605dec",
    airportName: "Prince Rupert/Digby Island",
    Location: "Canada",
    IATA: "YPR",
  },
  {
    id: "e9b0e716-284f-4017-9ecb-9bd0539ed8e8",
    airportName: "Pristina",
    Location: "Yugoslavia",
    IATA: "PRN",
  },
  {
    id: "26c89831-d22d-4700-85e8-c04490ca5175",
    airportName: "Prosperpine",
    Location: "Australia",
    IATA: "PPP",
  },
  {
    id: "5e6b4e82-edaf-4f90-b339-684096d8f140",
    airportName: "Providence, RI",
    Location: "USA",
    IATA: "PVD",
  },
  {
    id: "30cd9a44-a12c-4bb0-b712-bcf6c3cccb7e",
    airportName: "Prudhoe Bay, AK",
    Location: "USA",
    IATA: "SCC",
  },
  {
    id: "e8f5aeed-9329-483b-a53e-9c5e70362c19",
    airportName: "Puebla",
    Location: "Mexico",
    IATA: "PBC",
  },
  {
    id: "47e86649-1dde-493c-83b5-115acd05eae7",
    airportName: "Pueblo, CO",
    Location: "USA",
    IATA: "PUB",
  },
  {
    id: "c797dfda-2c06-4ff0-8822-1fe912172faf",
    airportName: "Puerto Escondido",
    Location: "Mexico",
    IATA: "PXM",
  },
  {
    id: "9ccc304b-f7ea-4a8d-b93a-e08401402561",
    airportName: "Puerto Ordaz",
    Location: "Venezuela",
    IATA: "PZO",
  },
  {
    id: "0a4cb740-1293-47d7-b9af-7cd3c2b92008",
    airportName: "Puerto Plata",
    Location: "Dominican Republic",
    IATA: "POP",
  },
  {
    id: "0237d199-c601-4634-a890-c23750b9bc97",
    airportName: "Puerto Vallarta",
    Location: "Mexico",
    IATA: "PVR",
  },
  {
    id: "ba669fa1-58d3-4aa7-b54e-02f3c1b18200",
    airportName: "Pukatawagan",
    Location: "Canada",
    IATA: "XPK",
  },
  {
    id: "b7196ec6-63f9-4708-b30d-20bca7341577",
    airportName: "Pula",
    Location: "Croatia (Hrvatska)",
    IATA: "PUY",
  },
  {
    id: "a33a6993-b60c-4124-9a7e-2443e788258f",
    airportName: "Pullman, WA",
    Location: "USA",
    IATA: "PUW",
  },
  {
    id: "09c6e8b7-aa06-4eba-842c-b32557ec9563",
    airportName: "Pune",
    Location: "India",
    IATA: "PNQ",
  },
  {
    id: "ba60e3f4-e24a-46e5-a734-32ba120bdf44",
    airportName: "Punta Arenas",
    Location: "Chile",
    IATA: "PUQ",
  },
  {
    id: "a7b6018c-b09e-4467-9dad-d11b3349f33b",
    airportName: "Punta Cana",
    Location: "Dominican Republic",
    IATA: "PUJ",
  },
  {
    id: "7d50fe10-4258-46c9-98e0-33b272eb6b05",
    airportName: "Pu San (Pusan) - Kimhae",
    Location: "South Korea",
    IATA: "PUS",
  },
  {
    id: "b77b4ea8-e0f3-462c-be40-873289740ba5",
    airportName: "Pyongyang - Sunan",
    Location: "North Korea",
    IATA: "FNJ",
  },
  {
    id: "76093ce9-bb3d-468f-9afb-e6c0823fa5de",
    airportName: "Quebec - Quebec Int'l",
    Location: "Canada",
    IATA: "YQB",
  },
  {
    id: "11e20064-a7da-4096-9d25-93fe9029dd1f",
    airportName: "Queenstown",
    Location: "Australia",
    IATA: "UEE",
  },
  {
    id: "50161a82-2707-4ca1-b9f9-a56249a9d223",
    airportName: "Queenstown",
    Location: "New Zealand",
    IATA: "ZQN",
  },
  {
    id: "92e57a23-7fb9-47fd-a4c7-6fe5dfa8efff",
    airportName: "Quetta",
    Location: "Pakistan",
    IATA: "UET",
  },
  {
    id: "f3e3f086-16df-40f7-8468-56aa04e09039",
    airportName: "Qingdao",
    Location: "Shandong, China",
    IATA: "TAO",
  },
  {
    id: "2cc229cb-dff3-44cd-b39c-5f6aa09e1112",
    airportName: "Quimper",
    Location: "France",
    IATA: "UIP",
  },
  {
    id: "258e4498-bab1-46b1-b317-a6e0407e409d",
    airportName: "Quincy, IL",
    Location: "USA",
    IATA: "UIN",
  },
  {
    id: "4b5b898e-dff0-416a-8edf-d6bd25d74979",
    airportName: "Quito - Mariscal Sucr",
    Location: "Ecuador",
    IATA: "UIO",
  },
  {
    id: "81572567-081f-4517-965f-fda99f92af05",
    airportName: "Rabat - Sale",
    Location: "Morocco",
    IATA: "RBA",
  },
  {
    id: "f7a3df3c-d787-4187-b432-107e329745da",
    airportName: "Rahim Yar Khan",
    Location: "Pakistan",
    IATA: "RYK",
  },
  {
    id: "ed3892b6-cddf-427b-9cb4-78d0f346cab2",
    airportName: "Raiatea",
    Location: "French Polynesia",
    IATA: "RFP",
  },
  {
    id: "7c2485c1-d75a-4e59-81e1-2ce526450f06",
    airportName: "Rainbow Lake, AB",
    Location: "Canada",
    IATA: "YOP",
  },
  {
    id: "9b66e3f4-7fb5-4f69-8be2-ea2bb471403c",
    airportName: "Rajkot",
    Location: "India",
    IATA: "RAJ",
  },
  {
    id: "8fba13ed-1450-44f3-87af-08c9dec35c6c",
    airportName: "Raleigh/Durham, NC",
    Location: "USA",
    IATA: "RDU",
  },
  {
    id: "94896bab-6979-40dc-b2fc-88854af64eb3",
    airportName: "Ranchi",
    Location: "India",
    IATA: "IXR",
  },
  {
    id: "1394b3c2-4874-439b-8bde-ed43f8fdc8ce",
    airportName: "Rangiroa",
    Location: "French Polynesia",
    IATA: "RGI",
  },
  {
    id: "3505f86e-feef-4ab8-abb1-a25854fd473e",
    airportName: "Rangoon (Yangon) - Mingaladon",
    Location: "Myanmar (Burma)",
    IATA: "RGN",
  },
  {
    id: "1b9babe4-4298-4ebf-8f51-fd9416d644d2",
    airportName: "Rapid City, SD",
    Location: "USA",
    IATA: "RAP",
  },
  {
    id: "a4b0678e-298e-4a99-84c1-132102188d09",
    airportName: "Rarotonga Cook",
    Location: "Island",
    IATA: "RAR",
  },
  {
    id: "23a5b57c-62f2-490a-853c-c4ca91f4973b",
    airportName: "Ras al Khaymah (Ras al Khaimah)",
    Location: "UAE",
    IATA: "RKT",
  },
  {
    id: "847fb31c-bc06-4d32-9652-8d663811e03f",
    airportName: "Rawala Kot",
    Location: "Pakistan",
    IATA: "RAZ",
  },
  {
    id: "a3d335ea-0a39-432c-bcd9-3e5f740b6c03",
    airportName: "Rawalpindi",
    Location: "Pakistan",
    IATA: "RWP",
  },
  {
    id: "d4690251-bd88-45da-9fd2-7b610291840c",
    airportName: "Reading, PA",
    Location: "USA",
    IATA: "RDG",
  },
  {
    id: "bd10c4ab-7399-49df-87d0-6e2cbe9614ac",
    airportName: "Recife",
    Location: "Brazil",
    IATA: "REC",
  },
  {
    id: "3303eba2-74ca-4e46-9a50-eabe04b15787",
    airportName: "Redding, CA",
    Location: "USA",
    IATA: "RDD",
  },
  {
    id: "8931d3b4-c5e4-4e80-8314-4818558c1f91",
    airportName: "Redmond, OR",
    Location: "USA",
    IATA: "RDM",
  },
  {
    id: "0329634c-c610-46e0-b348-b6fc0173feb5",
    airportName: "Reggio Calabria",
    Location: "Italy",
    IATA: "REG",
  },
  {
    id: "4028b9ce-c16b-45e4-a59d-7e9347802845",
    airportName: "Regina",
    Location: "Canada",
    IATA: "YQR",
  },
  {
    id: "c8ff8531-265e-4139-89ec-0d986fa175a4",
    airportName: "Reina Sofia",
    Location: "Teneriffa/Spain",
    IATA: "TFS",
  },
  {
    id: "edd13560-027d-4706-8357-76b4327c8dc1",
    airportName: "Rennes",
    Location: "France",
    IATA: "RNS",
  },
  {
    id: "6a8e6f69-e825-4943-a84a-915c425b9954",
    airportName: "Reno, NV",
    Location: "USA",
    IATA: "RNO",
  },
  {
    id: "410e8f46-0fa8-417e-b1e5-066f81e00c23",
    airportName: "Resolute Bay",
    Location: "Canada",
    IATA: "YRB",
  },
  {
    id: "7a238b63-b612-4582-9ab3-0e5209d2c5e7",
    airportName: "Reus",
    Location: "Spain",
    IATA: "REU",
  },
  {
    id: "70d46c67-d0a6-4c57-8b04-15155b6d2947",
    airportName: "Reykjavik - Metropolitan Area",
    Location: "Iceland",
    IATA: "REK",
  },
  {
    id: "093bbd39-503b-4658-b520-8b5623844089",
    airportName: "Reykjavik - Keflavik International",
    Location: "Iceland",
    IATA: "KEF",
  },
  {
    id: "2cb406f7-5e95-4085-a399-b5f957012981",
    airportName: "Rhinelander, WI",
    Location: "USA",
    IATA: "RHI",
  },
  {
    id: "fcf1a00d-25ba-4ec1-9ac4-d76df3ce1c37",
    airportName: "Rhodos",
    Location: "Greece",
    IATA: "RHO",
  },
  {
    id: "428dd943-4cfa-4240-8262-30028060e890",
    airportName: "Richards Bay",
    Location: "South Africa",
    IATA: "RCB",
  },
  {
    id: "6523d8f6-d5f3-463e-8892-22b8d230715f",
    airportName: "Richmond, VA",
    Location: "USA",
    IATA: "RIC",
  },
  {
    id: "b2a411d5-7c17-45f4-82c9-c4f299383c3b",
    airportName: "Riga",
    Location: "Latvia",
    IATA: "RIX",
  },
  {
    id: "592287fb-ca92-4913-b1d0-15104544d59a",
    airportName: "Rijeka",
    Location: "Croatia (Hrvatska)",
    IATA: "RJK",
  },
  {
    id: "6be0fbf0-9966-4e9a-a72f-797a3a7bf94f",
    airportName: "Rimini",
    Location: "Italy",
    IATA: "RMI",
  },
  {
    id: "704097a9-c761-475e-b4f2-6915407d3831",
    airportName: "Rio Branco",
    Location: "Brazil",
    IATA: "RBR",
  },
  {
    id: "7b6b63ab-420a-47f6-9e3d-53de30ff1a89",
    airportName: "Rio de Janeiro - Galeao",
    Location: "Brazil",
    IATA: "GIG",
  },
  {
    id: "520b8fe8-7060-4a2e-a941-b45ad6b641ad",
    airportName: "Rio de Janeiro - Santos Dumont",
    Location: "Brazil",
    IATA: "SDU",
  },
  {
    id: "f01dc769-ac0d-429e-a8e4-94aa610f9ce9",
    airportName: "Rio de Janeiro",
    Location: "Brazil",
    IATA: "RIO",
  },
  {
    id: "02a202de-9e40-4efb-8313-fff3e376e2a8",
    airportName: "Riyadh - King Khaled Int'l",
    Location: "Saudi Arabia",
    IATA: "RUH",
  },
  {
    id: "711325fc-fc2a-4ea7-ba43-4025fcba75ae",
    airportName: "Roanne",
    Location: "France",
    IATA: "RNE",
  },
  {
    id: "475ec299-3596-474d-8773-6b8e969e057b",
    airportName: "Roanoke, VA",
    Location: "USA",
    IATA: "ROA",
  },
  {
    id: "7dfa69ac-dc3e-4b65-bbab-2ef55f347be9",
    airportName: "Roatan",
    Location: "Honduras",
    IATA: "RTB",
  },
  {
    id: "2876ff96-3ac9-4bf3-8f8c-9dea1a7cc50c",
    airportName: "Rochester, MN",
    Location: "USA",
    IATA: "RST",
  },
  {
    id: "5ac16690-8f15-4bc1-b54b-2a7f991e600e",
    airportName: "Rochester, NY",
    Location: "USA",
    IATA: "ROC",
  },
  {
    id: "88fa513a-d5d7-4807-b166-8900d19524b0",
    airportName: "Rock Sound",
    Location: "Bahamas",
    IATA: "RSD",
  },
  {
    id: "135b2fcd-5dc1-4b5a-b79f-72f24308e480",
    airportName: "Rock Springs, WY",
    Location: "USA",
    IATA: "RKS",
  },
  {
    id: "a8988553-d5d3-4007-8e43-9b208a54982e",
    airportName: "Rockford, IL",
    Location: "USA",
    IATA: "RFD",
  },
  {
    id: "966f5c4d-af00-429d-9160-06e5ba2ab49b",
    airportName: "Rockhampton",
    Location: "Australia",
    IATA: "ROK",
  },
  {
    id: "f34599ea-a256-4893-b6dc-b4ae0177f04f",
    airportName: "Rockland, ME",
    Location: "USA",
    IATA: "RKD",
  },
  {
    id: "dac771e3-fe64-43ae-b6f0-ece50d9aad1f",
    airportName: "Rocky Mount - Wilson, NC",
    Location: "USA",
    IATA: "RWI",
  },
  {
    id: "09dbe280-e589-4911-837c-dbd32ddb8850",
    airportName: "Rodez",
    Location: "France",
    IATA: "RDZ",
  },
  {
    id: "61bd2384-99a7-43d4-9d73-8dca09a47d6a",
    airportName: "Rodrigues Island",
    Location: "Mauritius",
    IATA: "RRG",
  },
  {
    id: "a64e4487-d47c-4bcf-94fb-dd6cb0a0d7c0",
    airportName: "Roenne",
    Location: "Denmark",
    IATA: "RNN",
  },
  {
    id: "60678de3-e979-4d3e-add1-b935941bc801",
    airportName: "Rome",
    Location: "Italy",
    IATA: "ROM",
  },
  {
    id: "77e23985-0113-4776-8a81-4e5718f37011",
    airportName: "Rome - Ciampino",
    Location: "Italy",
    IATA: "CIA",
  },
  {
    id: "e735d089-3bae-46bc-b430-e8718446a46e",
    airportName: "Rome - Fuimicino",
    Location: "Italy",
    IATA: "FCO",
  },
  {
    id: "7180b3be-e964-4f18-ac6e-872b75a9a92d",
    airportName: "Ronneby",
    Location: "Sweden",
    IATA: "RNB",
  },
  {
    id: "ccc56cc1-8bff-45ed-8173-93bab27057dd",
    airportName: "Rosario",
    Location: "Argentina",
    IATA: "ROS",
  },
  {
    id: "950d961c-05a0-40b6-bd65-04542a5df9cb",
    airportName: "Rotorua",
    Location: "New Zealand",
    IATA: "ROT",
  },
  {
    id: "70bcecdc-06f3-4744-b344-e44636938fcf",
    airportName: "Rotterdam",
    Location: "Netherlands",
    IATA: "RTM",
  },
  {
    id: "50f261ab-0dbd-4638-b242-5446e5088fee",
    airportName: "Rovaniemi",
    Location: "Finland",
    IATA: "RVN",
  },
  {
    id: "5e6a9133-56a0-42f0-a99e-a55cadedaecb",
    airportName: "Rundu",
    Location: "Namibia",
    IATA: "NDU",
  },
  {
    id: "95f65d24-260f-47d3-ba30-d935f9f81af1",
    airportName: "Ruse",
    Location: "Bulgaria",
    IATA: "ROU",
  },
  {
    id: "64f8c3ff-eb0d-4a4d-ae0f-9f44e8d09b6d",
    airportName: "Saarbruecken",
    Location: "Germany",
    IATA: "SCN",
  },
  {
    id: "9dc9cc6c-ea96-4b5f-827b-c4a978936f16",
    airportName: "Sacramento, CA",
    Location: "USA",
    IATA: "SMF",
  },
  {
    id: "81b3f9dc-59fa-4d13-99b7-243a23d5fefb",
    airportName: "Sado Shima",
    Location: "Japan",
    IATA: "SDS",
  },
  {
    id: "2d83d66f-8e21-4d4f-925b-0a958eaf50e0",
    airportName: "Saginaw/Bay City/Midland, MI",
    Location: "USA",
    IATA: "MBS",
  },
  {
    id: "44236998-6aa6-4f7c-873f-e79308ac0c1a",
    airportName: "Saidu Sharif",
    Location: "Pakistan",
    IATA: "SDT",
  },
  {
    id: "bda2294f-8625-41b0-9703-c1f7d4bd8d02",
    airportName: "Saint Brieuc",
    Location: "France",
    IATA: "SBK",
  },
  {
    id: "a75307a1-193a-43d3-8fbe-ac6b1b29c4bc",
    airportName: "Saint Denis de la Reunion",
    Location: "Reunion",
    IATA: "RUN",
  },
  {
    id: "fa8ddb00-6a25-4089-a2e4-57b5d698ac50",
    airportName: "Saint John",
    Location: "Canada",
    IATA: "YSJ",
  },
  {
    id: "c863a5c9-9e45-45fe-8d93-b6b89c02e98d",
    airportName: "Saipan",
    Location: "Northern Mariana Islands",
    IATA: "SPN",
  },
  {
    id: "a760f19e-ff63-4c73-bf56-78e27c5ff8ea",
    airportName: "Sal",
    Location: "Cape Verde",
    IATA: "SID",
  },
  {
    id: "3178cd1f-30e0-4240-8f97-89e4b3cb6422",
    airportName: "Salalah",
    Location: "Oman",
    IATA: "SLL",
  },
  {
    id: "a32d856a-a082-4c6d-b715-4fc56b16d97b",
    airportName: "Salem, OR",
    Location: "USA",
    IATA: "SLE",
  },
  {
    id: "8ed8efea-5714-47a8-97ce-cd0b427f7c0c",
    airportName: "Salinas, CA",
    Location: "USA",
    IATA: "SNS",
  },
  {
    id: "840bbb3e-d3a9-47b9-b771-9c026f6d5786",
    airportName: "Salinas",
    Location: "Ecuador",
    IATA: "SNC",
  },
  {
    id: "ae488176-b218-4b73-b7d5-522c505491e9",
    airportName: "Salisbury",
    Location: "Zimbabwe",
    IATA: "SAY",
  },
  {
    id: "c8632f83-a9e5-4be1-88f5-7d8a6bf3cab7",
    airportName: "Salisbury, MD",
    Location: "USA",
    IATA: "SBY",
  },
  {
    id: "96d26932-7bae-42a7-9cd9-3e727b407f7f",
    airportName: "Saloniki",
    Location: "Greece",
    IATA: "SKG",
  },
  {
    id: "47b5e44d-73da-4e6b-9a67-24b7d99fde2e",
    airportName: "Salta, Gen Belgrano",
    Location: "Argentina",
    IATA: "SLA",
  },
  {
    id: "de25cff5-878d-45a9-b44a-393038245d89",
    airportName: "Salt Lake City, UT",
    Location: "USA",
    IATA: "SLC",
  },
  {
    id: "cde9a2cd-12c8-4b7d-91b6-efd09109691a",
    airportName: "Salvador",
    Location: "Brazil",
    IATA: "SSA",
  },
  {
    id: "e7efb66f-1939-4751-8b46-e46fdef51150",
    airportName: "Salzburg - W.A. Mozart",
    Location: "Austria",
    IATA: "SZG",
  },
  {
    id: "d737d37c-bdf8-48ec-906d-ef7597c2d4a9",
    airportName: "Samarkand",
    Location: "Uzbekistan",
    IATA: "SKD",
  },
  {
    id: "720a9ceb-bb76-4678-bd64-c68540ef37da",
    airportName: "Samos",
    Location: "Greece",
    IATA: "SMI",
  },
  {
    id: "1373dc64-4eaf-4b25-9837-ea07fdeff99a",
    airportName: "San Andres",
    Location: "Colombia",
    IATA: "ADZ",
  },
  {
    id: "c7df07b6-eefe-4a4a-8ca1-21b8d72271ed",
    airportName: "San Angelo, TX",
    Location: "USA",
    IATA: "SJT",
  },
  {
    id: "fbf43099-871a-41d9-ae74-5bda03fd9a5d",
    airportName: "San Antonio, TX",
    Location: "USA",
    IATA: "SAT",
  },
  {
    id: "e7cae79c-4107-47c7-8efd-4d40ff8aa257",
    airportName: "San Carlos de Bariloche",
    Location: "Argentina",
    IATA: "BRC",
  },
  {
    id: "0aee9cda-47f1-46be-b2da-d2920405819a",
    airportName: "San Diego - Lindberg Field Int'l, CA",
    Location: "USA",
    IATA: "SAN",
  },
  {
    id: "b8309b90-8669-44fd-83b7-efe61d711ff0",
    airportName: "San Francisco - Int'l Airport, SA",
    Location: "USA",
    IATA: "SFO",
  },
  {
    id: "5e10e795-e0f9-4a02-903d-6cc8d58718ed",
    airportName: "San Jose",
    Location: "Costa Rica",
    IATA: "SJO",
  },
  {
    id: "3e3a583e-3a30-4a34-9662-f83371d8c531",
    airportName: "San Jose, CA",
    Location: "USA",
    IATA: "SJC",
  },
  {
    id: "08d2b9f5-8fe9-4bb0-ac99-d944def11dce",
    airportName: "San Juan Perto",
    Location: "Rico",
    IATA: "SJU",
  },
  {
    id: "50bb8d29-74c7-4b21-9bf5-76e08bbe5e21",
    airportName: "San Luis Obisco, CA",
    Location: "USA",
    IATA: "SBP",
  },
  {
    id: "cc56c13f-6df5-48aa-8f94-2343c5e6d3ee",
    airportName: "San Luis Potosi",
    Location: "Mexico",
    IATA: "SLP",
  },
  {
    id: "57397f82-8d2e-4ed4-affc-ed5bffafdc95",
    airportName: "San Pedro",
    Location: "Ivory Coast",
    IATA: "SPY",
  },
  {
    id: "54045646-ce5f-4e10-adf8-3fa45238efb6",
    airportName: "San Pedro Sula",
    Location: "Honduras",
    IATA: "SAP",
  },
  {
    id: "edcfa7ab-458c-4893-a32d-df51731ff6ef",
    airportName: "San Salvador",
    Location: "Bahamas",
    IATA: "ZSA",
  },
  {
    id: "79d69065-e5f5-4ed9-b12f-573c30aefcea",
    airportName: "San Salvador",
    Location: "El Salvador",
    IATA: "SAL",
  },
  {
    id: "63a4f85d-51bd-4f99-82cd-9f27d5683a53",
    airportName: "San Sebastian",
    Location: "Spain",
    IATA: "EAS",
  },
  {
    id: "6448cfc9-03db-4080-be9e-7ee172835e07",
    airportName: "Sanaa (Sana'a) - Sana'a International",
    Location: "Yemen",
    IATA: "SAH",
  },
  {
    id: "f7fbc8bc-b5a2-46d3-bdff-28f6ac72a44c",
    airportName: "Sandspit",
    Location: "Canada",
    IATA: "YZP",
  },
  {
    id: "bd74ff68-0e35-4230-8fa6-2363c2451d6e",
    airportName: "Santa Barbara, CA",
    Location: "USA",
    IATA: "SBA",
  },
  {
    id: "8f90d17b-8ce1-4ce4-a144-7480b2020342",
    airportName: "Santa Cruz de la Palma",
    Location: "Spain",
    IATA: "SPC",
  },
  {
    id: "0b4c37b7-44a7-4e02-8f11-8162d60eddf8",
    airportName: "Santa Cruz de la Sierra",
    Location: "Bolivia",
    IATA: "SRZ",
  },
  {
    id: "d3248ea7-26c0-4156-926b-0ada981888a8",
    airportName: "Santa Katarina - Mount Sinai",
    Location: "Egypt",
    IATA: "SKV",
  },
  {
    id: "1ef4b325-b1ae-4176-9b7c-5710c3bf0bd3",
    airportName: "Santa Maria",
    Location: "Portugal",
    IATA: "SMA",
  },
  {
    id: "27646328-1373-4311-b36f-5c7cde20e564",
    airportName: "Santa Maria, CA",
    Location: "USA",
    IATA: "SMX",
  },
  {
    id: "4afb8dae-536e-4e99-9adf-7382ee451521",
    airportName: "Santander",
    Location: "Spain",
    IATA: "SDR",
  },
  {
    id: "8d154204-2b24-4904-ab3b-2d6c980585e4",
    airportName: "Santa Rosa, CA",
    Location: "USA",
    IATA: "STS",
  },
  {
    id: "bcd39851-b5fc-419b-8d6e-514868a3047d",
    airportName: "Santa Rosa",
    Location: "Bolivia",
    IATA: "SRB",
  },
  {
    id: "f0a28a2e-0a06-4869-8f8b-ff2b44f1eca2",
    airportName: "Santa Rosa",
    Location: "Brazil",
    IATA: "SRA",
  },
  {
    id: "9260e71b-5c4d-4c8d-876a-adf960a32799",
    airportName: "Santa Rosa",
    Location: "Argentina",
    IATA: "RSA",
  },
  {
    id: "18de02d2-eeda-47f6-bc46-4fb07fb8538d",
    airportName: "Santa Rosa Copan",
    Location: "Honduras",
    IATA: "SDH",
  },
  {
    id: "f3851361-a925-4154-a928-b6f58714b7fa",
    airportName: "Santa Rosalia",
    Location: "Colombia",
    IATA: "SSL",
  },
  {
    id: "78e25e91-fe80-4daa-9ed5-43181b8a6709",
    airportName: "Santa Rosalia",
    Location: "Mexico",
    IATA: "SRL",
  },
  {
    id: "969c9417-a9dd-4966-bc03-554acb7b2268",
    airportName: "Santiago - Antonio Maceo Airport",
    Location: "Cuba",
    IATA: "SCU",
  },
  {
    id: "6730955f-95fa-48df-9b00-80928e24f795",
    airportName: "Santiago de Chile - Arturo Merino Benitez",
    Location: "Chile",
    IATA: "SCL",
  },
  {
    id: "95d6dc99-d5bc-4efa-a5a4-c18cc37ccbea",
    airportName: "Santiago de Compostela",
    Location: "Spain",
    IATA: "SCQ",
  },
  {
    id: "ab029608-b444-47b2-8a81-c9ca53a6330f",
    airportName: "Santo",
    Location: "Vanuatu",
    IATA: "SON",
  },
  {
    id: "00cd7374-f30f-48c5-ba49-49a94b4f46f9",
    airportName: "Santo Domingo",
    Location: "Dominican Republic",
    IATA: "SDQ",
  },
  {
    id: "ae4c14c5-19af-4628-bc75-49f3442f2bcd",
    airportName: "Sao Luis",
    Location: "Brazil",
    IATA: "SLZ",
  },
  {
    id: "22c3f534-35d1-413a-981d-c0c9f1d0e127",
    airportName: "Sao Paulo",
    Location: "Brazil",
    IATA: "SAO",
  },
  {
    id: "275a3584-73bd-4182-93ab-305f41c314d6",
    airportName: "Sao Paulo - Congonhas",
    Location: "Brazil",
    IATA: "CGH",
  },
  {
    id: "8ce85e52-a161-483a-86c6-63caedbf83c8",
    airportName: "Sao Paulo - Viracopos",
    Location: "Brazil",
    IATA: "VCP",
  },
  {
    id: "de4859b5-d7e6-4300-b113-5dd6b341fd46",
    airportName: "Sao Tome",
    Location: "Sao Tome & Principe",
    IATA: "TMS",
  },
  {
    id: "283d0ced-d5d8-4f31-b2d8-8b8b7b806982",
    airportName: "Sapporo",
    Location: "Hokkaido, Japan",
    IATA: "SPK",
  },
  {
    id: "08d41d83-38f7-4aed-925f-c85251b1b3f5",
    airportName: "Sapporo - Okadama",
    Location: "Hokkaido, Japan",
    IATA: "OKD",
  },
  {
    id: "2cb2083f-95a9-4e40-a257-bc687d2eaa3d",
    airportName: "Sarajevo",
    Location: "Bosnia and Herzegovina",
    IATA: "SJJ",
  },
  {
    id: "d972e799-0cae-41c4-8a2a-c9825827284c",
    airportName: "Sarasota/Bradenton, FL",
    Location: "USA",
    IATA: "SRQ",
  },
  {
    id: "1048ebc0-7651-4c63-a1d5-d184d67c24d2",
    airportName: "Saskatoon",
    Location: "Canada",
    IATA: "YXE",
  },
  {
    id: "b1f8b92b-968b-4255-a969-0e4044151ecb",
    airportName: "Sassandra",
    Location: "Ivory Coast",
    IATA: "ZSS",
  },
  {
    id: "ed277b2d-dfd6-4502-8c50-6504eae39105",
    airportName: "Savannah, GA",
    Location: "USA",
    IATA: "SAV",
  },
  {
    id: "cb68446b-1a97-461f-b538-5be08daaf610",
    airportName: "Savonlinna",
    Location: "Finland",
    IATA: "SVL",
  },
  {
    id: "76ef1781-35dc-406d-891f-b4264ee5ad97",
    airportName: "Scone",
    Location: "Australia",
    IATA: "NSO",
  },
  {
    id: "fa03312f-bc3b-41d3-a6d2-e40cff5e51ec",
    airportName: "Scottsdale, AZ",
    Location: "USA",
    IATA: "SCF",
  },
  {
    id: "f41f26a5-e480-4b2b-b753-d4d55511a87e",
    airportName: "Seattle/Tacoma, WA",
    Location: "USA",
    IATA: "SEA",
  },
  {
    id: "7a64bac4-02ed-4e0e-b497-8519db545389",
    airportName: "Sehba",
    Location: "Libya",
    IATA: "SEB",
  },
  {
    id: "8171ad49-4585-4f1b-9f69-d3fe09c2caa4",
    airportName: "Seinaejoki",
    Location: "Finland",
    IATA: "SJY",
  },
  {
    id: "02df2e7d-2869-42bd-bc42-07450e29f681",
    airportName: "Selibi Phikwe",
    Location: "Botswana",
    IATA: "PKW",
  },
  {
    id: "9525c79b-83e3-4320-8361-9dad1f13073a",
    airportName: "Sendai",
    Location: "Japan",
    IATA: "SDJ",
  },
  {
    id: "f8bd16ec-d6f3-4d2f-8919-58289b358bfe",
    airportName: "Seoul - Kimpo",
    Location: "South Korea",
    IATA: "SEL",
  },
  {
    id: "87ce6bb6-3204-47c1-b201-c249ec19e545",
    airportName: "Sevilla",
    Location: "Spain",
    IATA: "SVQ",
  },
  {
    id: "ed5f1570-53e6-4dd4-8a15-cc2fc5992343",
    airportName: "Sfax",
    Location: "Tunisia",
    IATA: "SFA",
  },
  {
    id: "49b1a498-d739-46a1-9cd1-cffccb4e33bb",
    airportName: "Shamattawa, MB",
    Location: "Canada",
    IATA: "ZTM",
  },
  {
    id: "ceecf29a-1e7c-4863-ac27-61f2e84763bb",
    airportName: "Shanghai - Hongqiao",
    Location: "China",
    IATA: "SHA",
  },
  {
    id: "b93ff822-bbde-457c-8f9a-8e7458e5eb9e",
    airportName: "Shanghai - Pu Dong",
    Location: "China",
    IATA: "PVG",
  },
  {
    id: "507eb6d0-9e11-46e7-a962-b8c193955654",
    airportName: "Shannon (Limerick)",
    Location: "Ireland",
    IATA: "SNN",
  },
  {
    id: "91ce52e8-ef54-4fff-a95b-2007c86023ea",
    airportName: "Sharjah",
    Location: "UAE",
    IATA: "SHJ",
  },
  {
    id: "c42d47e7-be37-45a3-a852-ec1ae007223b",
    airportName: "Sharm El Sheikh",
    Location: "Egypt",
    IATA: "SSH",
  },
  {
    id: "e1fbff83-e6fd-4a6f-9c61-a703e29495da",
    airportName: "Sheffield, City Airport",
    Location: "UK",
    IATA: "SZD",
  },
  {
    id: "1c7e5e26-ccf6-4ae5-89ab-c97fb737f246",
    airportName: "Shenandoah Valley/Stauton, VA",
    Location: "USA",
    IATA: "SHD",
  },
  {
    id: "481736e9-1c86-4aef-b2c1-175dd6dacfe9",
    airportName: "Shenyang",
    Location: "Liaoning, China",
    IATA: "SHE",
  },
  {
    id: "3200cea8-2a9a-4ed2-89c2-0e6396995ab4",
    airportName: "Shenzhen",
    Location: "Guangdong, China",
    IATA: "SZX",
  },
  {
    id: "e1b87d02-9e2b-4511-a39d-42248384833d",
    airportName: "Sheridan, WY",
    Location: "USA",
    IATA: "SHR",
  },
  {
    id: "70725318-9bb0-4ae5-9b80-e460be03c4eb",
    airportName: "Shreveport, La",
    Location: "USA",
    IATA: "SHV",
  },
  {
    id: "049c90b6-4b1d-4c8a-ba65-604ebf099b9f",
    airportName: "Shute Harbour",
    Location: "Australia",
    IATA: "JHQ",
  },
  {
    id: "c6cb2080-4261-421e-8550-447f2b7395ea",
    airportName: "Sibu",
    Location: "Malaysia",
    IATA: "SBW",
  },
  {
    id: "c6a5e383-6298-49b2-9bc2-c7c156bcd39b",
    airportName: "Sidney, MT",
    Location: "USA",
    IATA: "SDY",
  },
  {
    id: "a2273be9-9fa6-4fc0-8861-5519009eb757",
    airportName: "Silistra",
    Location: "Bulgaria",
    IATA: "SLS",
  },
  {
    id: "e77d5b6d-0430-4c26-9973-1aeb1bcba939",
    airportName: "Simferopol",
    Location: "Ukraine",
    IATA: "SIP",
  },
  {
    id: "15fb3883-27ef-432d-a2e8-913691168017",
    airportName: "Sindhri",
    Location: "Pakistan",
    IATA: "MPD",
  },
  {
    id: "fd90fdb9-111c-467c-8c23-64fd444f331e",
    airportName: "Singapore - Paya Lebar",
    Location: "Singapore",
    IATA: "QPG",
  },
  {
    id: "713dc5df-57a1-4749-88ec-ecff27b4917d",
    airportName: "Singapore - Changi",
    Location: "Singapore",
    IATA: "SIN",
  },
  {
    id: "4879079b-9265-482c-8ea2-a7666e0bebb4",
    airportName: "Singleton",
    Location: "Australia",
    IATA: "SIX",
  },
  {
    id: "30e9a33e-9406-471b-a099-8dad1ce866b0",
    airportName: "Sioux City, IA",
    Location: "USA",
    IATA: "SUX",
  },
  {
    id: "ccda99a1-272e-454a-bd4c-79f1d94ef02b",
    airportName: "Sioux Falls, SD",
    Location: "USA",
    IATA: "FSD",
  },
  {
    id: "23e975c9-01c7-4b79-b382-5a4838a5e086",
    airportName: "Sishen",
    Location: "South Africa",
    IATA: "SIS",
  },
  {
    id: "0f9b70a2-8b03-420f-adb2-af0c477e777c",
    airportName: "Sitka, AK",
    Location: "USA",
    IATA: "SIT",
  },
  {
    id: "28a4808b-36b1-45e3-9e6b-77d192577af4",
    airportName: "Siwa",
    Location: "Egypt",
    IATA: "SEW",
  },
  {
    id: "127f88fe-c1c0-4c8e-87ba-db92667ebdc3",
    airportName: "Skagway, AK",
    Location: "USA",
    IATA: "SGY",
  },
  {
    id: "88af6909-3b7c-4be5-a2f8-a975d9720d22",
    airportName: "Skardu",
    Location: "Pakistan",
    IATA: "KDU",
  },
  {
    id: "99376077-e4ce-4671-a322-22f4db3c4044",
    airportName: "Skiathos",
    Location: "Greece",
    IATA: "JSI",
  },
  {
    id: "9e11982e-034c-4c42-a729-f9880dd39bba",
    airportName: "Skopje",
    Location: "Macedonia",
    IATA: "SKP",
  },
  {
    id: "fd95c710-d845-469d-bf48-b6b3009e25f9",
    airportName: "Skrydstrup",
    Location: "Denmark",
    IATA: "SKS",
  },
  {
    id: "bd4d9cd2-5c28-4ea9-9ab6-9143741597c9",
    airportName: "Skukuza",
    Location: "South Africa",
    IATA: "SZK",
  },
  {
    id: "2f1d5dfd-30bc-493b-82a7-eb0ace92cc0d",
    airportName: "Sligo",
    Location: "Ireland",
    IATA: "SXL",
  },
  {
    id: "fe8648ea-da79-45a8-9e57-369700a28521",
    airportName: "Smithers",
    Location: "Canada",
    IATA: "YYD",
  },
  {
    id: "0684be24-c94f-4a66-a788-c9a4c8cb5c4c",
    airportName: "Sodankylae",
    Location: "Finland",
    IATA: "SOT",
  },
  {
    id: "52e3e64d-e890-4790-8006-a3bda89ddcb1",
    airportName: "Soenderborg",
    Location: "Denmark",
    IATA: "SGD",
  },
  {
    id: "d6c7e6bc-6255-4d89-9ab7-8a1d21f77fa5",
    airportName: "Soendre Stroemfjord",
    Location: "Greenland",
    IATA: "SFJ",
  },
  {
    id: "2ddc2466-ebe6-4ee8-9c6b-5adf69cfcec9",
    airportName: "Sofia - Vrazhdebna",
    Location: "Bulgaria",
    IATA: "SOF",
  },
  {
    id: "d599ca88-81a3-4019-8f87-8918344b9590",
    airportName: "Sogndal",
    Location: "Norway",
    IATA: "SOG",
  },
  {
    id: "6f701305-aeca-461c-b2a0-ed873adb2064",
    airportName: "Southampton - Eastleigh",
    Location: "United Kingdom",
    IATA: "SOU",
  },
  {
    id: "0073bbd4-3e2e-4431-b93c-a070dcb1555f",
    airportName: "South Bend, IN",
    Location: "USA",
    IATA: "SBN",
  },
  {
    id: "cdadb393-9aa9-46e6-a5da-3208149a1bd5",
    airportName: "South Indian Lake, MB",
    Location: "Canada",
    IATA: "XSI",
  },
  {
    id: "07b2b990-76c6-443d-b3fb-9f8430fe9b65",
    airportName: "South Molle Island",
    Location: "Australia",
    IATA: "SOI",
  },
  {
    id: "a28f4d53-b165-44a6-bb04-ccd5bdae5644",
    airportName: "Southend",
    Location: "United Kingdom",
    IATA: "SEN",
  },
  {
    id: "1d153f23-5127-41f3-a723-8d0c597e85fc",
    airportName: "Split",
    Location: "Croatia (Hrvatska)",
    IATA: "SPU",
  },
  {
    id: "414c4090-fe44-41c7-ac1a-c7b17a3340e6",
    airportName: "Spokane, WA",
    Location: "USA",
    IATA: "GEG",
  },
  {
    id: "5db24392-d6cd-4d20-a99d-eacc6d38a665",
    airportName: "Springbok",
    Location: "South Africa",
    IATA: "SBU",
  },
  {
    id: "c5f39c85-5cda-4dae-b0dd-ed445041d18d",
    airportName: "Springfield, IL",
    Location: "USA",
    IATA: "SPI",
  },
  {
    id: "76560536-fc28-429a-a8eb-b92242e308d3",
    airportName: "Springfield, MO",
    Location: "USA",
    IATA: "SGF",
  },
  {
    id: "29ba5403-64f0-42b4-8233-e396ee8b8b92",
    airportName: "Srinagar",
    Location: "India",
    IATA: "SXR",
  },
  {
    id: "db8a2c20-e241-4aea-a662-d2ee946f85d0",
    airportName: "St. Augustin, PQ",
    Location: "Canada",
    IATA: "YIF",
  },
  {
    id: "7802ca57-7e82-4cc6-82bb-baeaa09ff121",
    airportName: "St. Croix",
    Location: "Virgin Islands",
    IATA: "STX",
  },
  {
    id: "0971a78e-b2b7-44ee-9e18-ccb65345d1e8",
    airportName: "St. Etienne",
    Location: "France",
    IATA: "EBU",
  },
  {
    id: "40a64b76-430a-4013-87b6-add91d88cb41",
    airportName: "St. George, UT",
    Location: "USA",
    IATA: "SGU",
  },
  {
    id: "2575f912-0e3b-48f0-b15e-96d2a4032d68",
    airportName: "St. John's",
    Location: "Canada",
    IATA: "YYT",
  },
  {
    id: "f694dee0-0e45-4111-84f9-2b58398a9615",
    airportName: "St. Kitts",
    Location: "St. Kitts And Nevis",
    IATA: "SKB",
  },
  {
    id: "08157610-0bdf-45e8-9d81-fa6cd8282bec",
    airportName: "St. Louis - Lambert, MO",
    Location: "USA",
    IATA: "STL",
  },
  {
    id: "903ba2ae-096f-43a4-b7a4-e00edc171ae6",
    airportName: "St. Lucia Hewanorra",
    Location: "St. Lucia",
    IATA: "UVF",
  },
  {
    id: "e92ab605-fc63-4eb5-abd4-09ee12d8ceb5",
    airportName: "St. Lucia Vigle",
    Location: "St. Lucia",
    IATA: "SLU",
  },
  {
    id: "e3dac317-8882-4cf6-a178-4d9ed64ca966",
    airportName: "St. Marteen",
    Location: "Netherlands Antilles",
    IATA: "SXM",
  },
  {
    id: "b81b0569-2792-45c8-b132-d82f44b676f1",
    airportName: "St. Martin",
    Location: "St. Martin",
    IATA: "SFG",
  },
  {
    id: "6732b8d1-4aa0-4e4c-8614-59b7b9ecb4fb",
    airportName: "St. Petersburg (Leningrad) - Pulkovo",
    Location: "Russia",
    IATA: "LED",
  },
  {
    id: "4c337141-b25b-48bd-805f-f23744b76005",
    airportName: "St. Pierre, NF",
    Location: "Canada",
    IATA: "FSP",
  },
  {
    id: "2bf2595a-e236-43e5-884d-14b8eff9af79",
    airportName: "St. Thomas",
    Location: "Virgin Islands",
    IATA: "STT",
  },
  {
    id: "dbafa6c0-18f9-48c9-955b-700e70c3a52c",
    airportName: "St. Vincent",
    Location: "St. Vincent",
    IATA: "SVD",
  },
  {
    id: "c16a1557-db79-4a1a-a347-55fb1940d046",
    airportName: "State College/Belefonte, PA",
    Location: "USA",
    IATA: "SCE",
  },
  {
    id: "f5870be9-d5cc-4a5e-89a3-bd76ae9077d0",
    airportName: "Stavanger",
    Location: "Norway",
    IATA: "SVG",
  },
  {
    id: "02074a7f-b4ca-41df-8756-ede528785a79",
    airportName: "Steamboat Springs, CO",
    Location: "USA",
    IATA: "HDN",
  },
  {
    id: "0f0229bc-465f-4a0e-b928-3de21fe2ed33",
    airportName: "Stettin",
    Location: "Poland",
    IATA: "SZZ",
  },
  {
    id: "fa65836e-fdd6-4bdc-887f-d369acd4a593",
    airportName: "Stockholm Metropolitan Area",
    Location: "Sweden",
    IATA: "STO",
  },
  {
    id: "db211eb7-a509-4b5a-8111-dc442e753e16",
    airportName: "Stockholm - Arlanda",
    Location: "Sweden",
    IATA: "ARN",
  },
  {
    id: "c89eb652-7ef6-41ac-9142-580d4f604443",
    airportName: "Stockholm - Bromma",
    Location: "Sweden",
    IATA: "BMA",
  },
  {
    id: "525e7656-06cb-40f7-9b53-6ab111d00862",
    airportName: "Stockton, CA",
    Location: "USA",
    IATA: "SCK",
  },
  {
    id: "c81e8499-94bc-4213-ab8b-68e02928b3b7",
    airportName: "Stornway",
    Location: "United Kingdom",
    IATA: "SYY",
  },
  {
    id: "33e18913-2985-49f2-b165-5d83a15bb619",
    airportName: "Strassburg",
    Location: "France",
    IATA: "SXB",
  },
  {
    id: "56d28185-39eb-4f39-bd05-d96e2138c6b8",
    airportName: "Streaky Bay",
    Location: "Australia",
    IATA: "KBY",
  },
  {
    id: "e63d47ba-4b49-4da3-996e-d70a3e8887fe",
    airportName: "Stuttgart - Echterdingen",
    Location: "Germany",
    IATA: "STR",
  },
  {
    id: "8e69b73e-1d16-4661-96eb-516e09e7a002",
    airportName: "Sui",
    Location: "Pakistan",
    IATA: "SUL",
  },
  {
    id: "37c61a66-e42d-41d3-9902-d961a0636800",
    airportName: "Sukkur",
    Location: "Pakistan",
    IATA: "SKZ",
  },
  {
    id: "74786b71-af49-425a-a955-2cce79b822f1",
    airportName: "Sumburgh (Shetland)",
    Location: "United Kingdom",
    IATA: "LSI",
  },
  {
    id: "ddddf84b-2c50-4b0d-afac-7202573f462a",
    airportName: "Sun Valley, ID",
    Location: "USA",
    IATA: "SUN",
  },
  {
    id: "98030b27-5654-4166-834c-8e1d2c368fe2",
    airportName: "Sundsvall",
    Location: "Sweden",
    IATA: "SDL",
  },
  {
    id: "c26f0f37-bb72-44b3-b00a-752feae6bf63",
    airportName: "Sunshine Coast",
    Location: "Australia",
    IATA: "MCY",
  },
  {
    id: "41367479-8ec5-4fb2-936a-a609392d9413",
    airportName: "Surabaya - Juanda",
    Location: "Indonesia",
    IATA: "SUB",
  },
  {
    id: "ac6d4150-8724-4976-9ffe-5e88d52093f9",
    airportName: "Surat",
    Location: "India",
    IATA: "STV",
  },
  {
    id: "dd9e6e69-35ca-45ce-937c-f89d3d38acb6",
    airportName: "Swakopmund",
    Location: "Namibia",
    IATA: "SWP",
  },
  {
    id: "4c0c5492-abaa-4013-b962-41e8b30c05b6",
    airportName: "Sydney - Kingsford Smith",
    Location: "Australia",
    IATA: "SYD",
  },
  {
    id: "4bc30f36-c59d-4bf0-938d-655af724e005",
    airportName: "Sylhet",
    Location: "Bangladesh",
    IATA: "ZYL",
  },
  {
    id: "52a845d2-b54f-48bf-89d0-48d434648cd8",
    airportName: "Syracuse, NY",
    Location: "USA",
    IATA: "SYR",
  },
  {
    id: "e351cca8-b6bd-4d69-80ee-128645261807",
    airportName: "Tabuk",
    Location: "Saudi Arabia",
    IATA: "TUU",
  },
  {
    id: "cff8e080-e759-46a4-a5f7-8d9680087d28",
    airportName: "Taif",
    Location: "Saudi Arabia",
    IATA: "TIF",
  },
  {
    id: "0ce701a9-3c51-40d2-aa40-bf99a2ef2ca7",
    airportName: "Taipei - Chiang Kai Shek",
    Location: "Taiwan",
    IATA: "TPE",
  },
  {
    id: "0ec5fda2-e7ff-4e0d-b868-03a1cecb17be",
    airportName: "Taipei - Sung Shan",
    Location: "Taiwan",
    IATA: "TAY",
  },
  {
    id: "1aee7cf7-9b9f-4257-aead-dea896295981",
    airportName: "Taiyuan",
    Location: "Shanxi, China",
    IATA: "TYN",
  },
  {
    id: "1f84bc68-b816-41cb-b673-d02abeb509a5",
    airportName: "Takamatsu",
    Location: "Japan",
    IATA: "TAK",
  },
  {
    id: "456a5da9-36f4-48cc-b493-8e1c2cdeb275",
    airportName: "Talkeetna, AK",
    Location: "USA",
    IATA: "TKA",
  },
  {
    id: "973a9e67-d05c-46f2-a7f4-52027044f8e9",
    airportName: "Tallahassee, FL",
    Location: "USA",
    IATA: "TLH",
  },
  {
    id: "38e93f27-fa74-4cff-9e6d-03fcbe08853a",
    airportName: "Tallinn - Pirita Harbour",
    Location: "Estonia",
    IATA: "QUF",
  },
  {
    id: "c290d212-4edc-44bb-a02d-19fa18de28ff",
    airportName: "Tallinn - Ulemiste",
    Location: "Estonia",
    IATA: "TLL",
  },
  {
    id: "7505dfbb-e4f3-4707-aee9-eaab42b50588",
    airportName: "Tampa Int'l, FL",
    Location: "USA",
    IATA: "TPA",
  },
  {
    id: "9c6cd371-94a0-42a5-a899-f6b68f14e37f",
    airportName: "Tampere",
    Location: "Finland",
    IATA: "TMP",
  },
  {
    id: "d5a84cb0-19e2-4492-8245-32518bcda41c",
    airportName: "Tampico - Gen. F. Javier Mina",
    Location: "Mexico",
    IATA: "TAM",
  },
  {
    id: "9e207d1b-cb05-48e8-984e-3e70dd744d57",
    airportName: "Tamworth",
    Location: "Australia",
    IATA: "TMW",
  },
  {
    id: "0e8d968e-557a-4044-9f40-edd0ea3a6631",
    airportName: "Tanger - Boukhalef",
    Location: "Morocco",
    IATA: "TNG",
  },
  {
    id: "471b23f1-b493-4e5e-8966-cea3e2d9bba5",
    airportName: "Taree",
    Location: "Australia",
    IATA: "TRO",
  },
  {
    id: "df10f618-341d-4668-87d8-9f5dd23d29aa",
    airportName: "Targovishte",
    Location: "Bulgaria",
    IATA: "TGV",
  },
  {
    id: "d383df64-898a-4586-9154-b5ab4613dfdd",
    airportName: "Tashkent - Vostochny",
    Location: "Uzbekistan",
    IATA: "TAS",
  },
  {
    id: "bc9acb3b-cb23-4346-afa3-bf48c47d6bd5",
    airportName: "Tawau",
    Location: "Malaysia",
    IATA: "TWU",
  },
  {
    id: "5a373a16-7d8c-42d7-8e18-8e294890d7dd",
    airportName: "Tbilisi - Novo Alexeyevka",
    Location: "Georgia",
    IATA: "TBS",
  },
  {
    id: "5ee6c37e-610b-4302-9fff-17b1775039a3",
    airportName: "Te Anau",
    Location: "New Zealand",
    IATA: "TEU",
  },
  {
    id: "b538a0fc-7fd0-4850-8f67-6d56af29b468",
    airportName: "Teesside, Durham Tees Valley",
    Location: "United Kingdom",
    IATA: "MME",
  },
  {
    id: "797bb102-0d84-4d38-90f2-e501cc2c7b80",
    airportName: "Tegucigalpa",
    Location: "Honduras",
    IATA: "TGU",
  },
  {
    id: "a5a141ef-8077-417d-aeae-d3c42072289a",
    airportName: "Tehran (Teheran) - Mehrabad",
    Location: "Iran",
    IATA: "THR",
  },
  {
    id: "35532a77-ade8-4d08-9704-63667be57a3e",
    airportName: "Tel Aviv - Ben Gurion Int'l",
    Location: "Israel",
    IATA: "TLV",
  },
  {
    id: "1d94756c-4c2a-494a-93b7-b70c0681b1b7",
    airportName: "Telluride, CO",
    Location: "USA",
    IATA: "TEX",
  },
  {
    id: "2df3b094-0094-4ade-9b85-d4e5d2b075c4",
    airportName: "Temora",
    Location: "Australia",
    IATA: "TEM",
  },
  {
    id: "739389e8-657d-4243-a9db-09c3de974e6f",
    airportName: "Tenerife",
    Location: "Spain",
    IATA: "TCI",
  },
  {
    id: "4ec8ddef-d7f9-419c-b184-7a586b26c267",
    airportName: "Tennant Creek",
    Location: "Australia",
    IATA: "TCA",
  },
  {
    id: "fb815254-9720-4eca-8958-f9e496a00954",
    airportName: "Terceira",
    Location: "Portugal",
    IATA: "TER",
  },
  {
    id: "9e6f5ee7-c0bb-41a6-bd77-86e8ba6cd776",
    airportName: "Teresina",
    Location: "Brazil",
    IATA: "THE",
  },
  {
    id: "3785fb93-5b0d-4c77-b2c0-076b26ee50d0",
    airportName: "Termez (Termes)",
    Location: "Uzbekistan",
    IATA: "TMZ",
  },
  {
    id: "ab06ff6c-ce21-4a73-b9e5-0282328db420",
    airportName: "Terrace",
    Location: "Canada",
    IATA: "YXT",
  },
  {
    id: "bee79e64-3833-4ba3-b6e4-a48142150a45",
    airportName: "Terre Haute, IN",
    Location: "USA",
    IATA: "HUF",
  },
  {
    id: "79e9e1d3-18da-4e41-9e9a-55df361a7a41",
    airportName: "Texarkana, AR",
    Location: "USA",
    IATA: "TXK",
  },
  {
    id: "be47e834-56f3-4b05-8c6e-265bc5009175",
    airportName: "Thaba'Nchu",
    Location: "South Africa",
    IATA: "TCU",
  },
  {
    id: "cd7104b5-7963-44dd-ac81-bcc9d849430a",
    airportName: "The Pas",
    Location: "Canada",
    IATA: "YQD",
  },
  {
    id: "034ea950-3322-45fe-afcc-f2fbbc9db8a3",
    airportName: "Thief River Falls, MN",
    Location: "USA",
    IATA: "TVF",
  },
  {
    id: "370335ae-1532-49b4-9a1b-2de60fa9d535",
    airportName: "Thira",
    Location: "Greece",
    IATA: "JTR",
  },
  {
    id: "a039c56b-6a2d-4a83-8b3a-693959f9b9ed",
    airportName: "Thiruvananthapuram",
    Location: "India",
    IATA: "TRV",
  },
  {
    id: "5f82bccc-6254-4a2a-82bd-74a496fe61ce",
    airportName: "Thisted",
    Location: "Denmark",
    IATA: "TED",
  },
  {
    id: "96aab170-8232-48da-8223-9a7dd0f6661c",
    airportName: "Thompson",
    Location: "Canada",
    IATA: "YTH",
  },
  {
    id: "11b43bc3-b5b2-4784-9222-aac3b147d052",
    airportName: "Thorne Bay, AK",
    Location: "USA",
    IATA: "KTB",
  },
  {
    id: "23cc4bba-b4b1-4518-b1a5-fd3113370852",
    airportName: "Thunder Bay",
    Location: "Canada",
    IATA: "YQT",
  },
  {
    id: "804723cd-a07b-43c1-99ce-325d7cfda4d3",
    airportName: "Thursday Island",
    Location: "Australia",
    IATA: "TIS",
  },
  {
    id: "103a5fd5-07f7-40db-94e5-abb8358b2097",
    airportName: "Tianjin",
    Location: "China",
    IATA: "TSN",
  },
  {
    id: "207932cd-2f46-4ace-bd12-7d79a26cf511",
    airportName: "Tijuana - Rodriguez",
    Location: "Mexico",
    IATA: "TIJ",
  },
  {
    id: "fab6cf38-6d62-4965-833a-fbc5b3e91827",
    airportName: "Tioman",
    Location: "Indonesia",
    IATA: "TOD",
  },
  {
    id: "b4857bc7-99c6-466b-bac8-cc856b8a12d6",
    airportName: "Tirana - Rinas",
    Location: "Albania",
    IATA: "TIA",
  },
  {
    id: "d7bdb021-36b8-438e-a23f-0f74ddf7f0d1",
    airportName: "Tiruchirapally",
    Location: "India",
    IATA: "TRZ",
  },
  {
    id: "0afb3fa7-ec33-483d-a6cd-b283babbd736",
    airportName: "Tivat",
    Location: "Yugoslavia",
    IATA: "TIV",
  },
  {
    id: "0deaad3c-0180-408d-b61e-e4a1bcfa0844",
    airportName: "Tobago",
    Location: "Tobago",
    IATA: "TAB",
  },
  {
    id: "dcf80b27-6e83-485f-98ef-56c002d37a9a",
    airportName: "Tokushima",
    Location: "Japan",
    IATA: "TKS",
  },
  {
    id: "e15932e6-d397-413c-8c54-068adb46fe1f",
    airportName: "Tokyo",
    Location: "Japan",
    IATA: "TYO",
  },
  {
    id: "1fe082bf-38b4-4c03-a5e9-1651a912493a",
    airportName: "Tokyo - Haneda",
    Location: "Japan",
    IATA: "HND",
  },
  {
    id: "646616c2-b2ae-4714-9e76-a825ce559754",
    airportName: "Tokyo - Narita",
    Location: "Japan",
    IATA: "NRT",
  },
  {
    id: "d11d854b-e3af-4464-9b1d-a34a85e35f6c",
    airportName: "Toledo, OH",
    Location: "USA",
    IATA: "TOL",
  },
  {
    id: "4f05bfda-ea8b-42a3-a7ed-fb79fb797ddf",
    airportName: "Tom Price",
    Location: "Australia",
    IATA: "TPR",
  },
  {
    id: "1f557fd5-28c2-40be-aef5-ddf09e00bfe7",
    airportName: "Toowoomba",
    Location: "Australia",
    IATA: "TWB",
  },
  {
    id: "7c566ddb-c87b-42ae-82ac-ef6004163645",
    airportName: "Toronto Island",
    Location: "Canada",
    IATA: "YTZ",
  },
  {
    id: "8bad980c-0c08-4ee5-ae29-acd9a46a18f1",
    airportName: "Toronto - Lester B. Pearson",
    Location: "Canada",
    IATA: "YYZ",
  },
  {
    id: "62e9d9ad-df14-4b0a-ad8d-229604450a02",
    airportName: "Toronto",
    Location: "Canada",
    IATA: "YTO",
  },
  {
    id: "b37cf32d-75cf-431b-96a0-6882b6ca2614",
    airportName: "Touho",
    Location: "New Caledonia",
    IATA: "TOU",
  },
  {
    id: "86fe5abe-fd0e-4761-aec5-a0391b96aaa5",
    airportName: "Toulouse - Blagnac",
    Location: "France",
    IATA: "TLS",
  },
  {
    id: "881feca0-ac21-4b48-8a78-7f342563069f",
    airportName: "Townsville",
    Location: "Australia",
    IATA: "TSV",
  },
  {
    id: "8cf5615b-e670-403e-80c6-5a9a1eba88eb",
    airportName: "Toyama",
    Location: "Japan",
    IATA: "TOY",
  },
  {
    id: "a3fbb8f4-b08e-4e7c-9d83-76c9f161277e",
    airportName: "Trapani",
    Location: "Italy",
    IATA: "TPS",
  },
  {
    id: "46352dea-05e0-43bf-9be0-7026a4556e61",
    airportName: "Traverse City, MI",
    Location: "USA",
    IATA: "TVC",
  },
  {
    id: "9eef5550-b635-40c5-8c02-06df513ed60d",
    airportName: "Treasure Cay",
    Location: "Bahamas",
    IATA: "TCB",
  },
  {
    id: "2fca7e91-365b-4470-9ec7-d5665739f2ae",
    airportName: "Trenton/Princeton, NJ",
    Location: "USA",
    IATA: "TTN",
  },
  {
    id: "7c8d3edb-eeb0-460b-9936-3edc58062ad7",
    airportName: "Treviso",
    Location: "Italy",
    IATA: "TSF",
  },
  {
    id: "c93ef00a-079f-4e2b-bf9c-28600f96292a",
    airportName: "Tri-Cities Regional, TN/VA",
    Location: "USA",
    IATA: "TRI",
  },
  {
    id: "f4bca592-c4f6-47e4-82c4-c4394cb28044",
    airportName: "Trieste",
    Location: "Italy",
    IATA: "TRS",
  },
  {
    id: "20d6452a-b3be-43ce-b7f7-b2b3d0b616a5",
    airportName: "Tripoli - Tripoli Int'l",
    Location: "Libya",
    IATA: "TIP",
  },
  {
    id: "808a77df-f83b-4b4f-aba6-c6f4beabe958",
    airportName: "Tromsoe",
    Location: "Norway",
    IATA: "TOS",
  },
  {
    id: "3b360c74-ecec-4152-baa6-185fff1bd3a9",
    airportName: "Trondheim",
    Location: "Norway",
    IATA: "TRD",
  },
  {
    id: "6f0ed85f-b16a-4fe5-87a4-24d2eeb78060",
    airportName: "Tsumeb",
    Location: "Namibia",
    IATA: "TSB",
  },
  {
    id: "430eaa0c-b76b-491c-ac91-64b07e2af592",
    airportName: "Tucson, AZ",
    Location: "USA",
    IATA: "TUS",
  },
  {
    id: "61e14204-1519-4b6b-8f20-2eea400ba8b5",
    airportName: "Tulepo, MS",
    Location: "USA",
    IATA: "TUP",
  },
  {
    id: "31c5c1cf-0a6e-43d2-8e41-dcb8bd9cb110",
    airportName: "Tulsa, OK",
    Location: "USA",
    IATA: "TUL",
  },
  {
    id: "9f100247-5955-4c76-997b-3465ca75770a",
    airportName: "Tunis - Carthage",
    Location: "Tunisia",
    IATA: "TUN",
  },
  {
    id: "b7c7cc7f-f876-4c87-babe-545b278ff0d9",
    airportName: "Turbat",
    Location: "Pakistan",
    IATA: "TUK",
  },
  {
    id: "ce2f65c6-92b3-46f8-94f6-18b4e4e50d06",
    airportName: "Turin",
    Location: "Italy",
    IATA: "TRN",
  },
  {
    id: "da9f199c-4273-4ce7-b1ec-087bcdf694af",
    airportName: "Turku",
    Location: "Finland",
    IATA: "TKU",
  },
  {
    id: "ddaa8e52-72e8-4ddc-848e-6ea2bc47e1f0",
    airportName: "Tuscaloosa, AL",
    Location: "USA",
    IATA: "TCL",
  },
  {
    id: "6fd03545-97df-4c30-a9c4-ae856c9ece19",
    airportName: "Tuxtla Gutierrez",
    Location: "Mexico",
    IATA: "TGZ",
  },
  {
    id: "9fedb3e3-e675-40fa-8ef4-58f0cf5f544f",
    airportName: "Twin Falls, ID",
    Location: "USA",
    IATA: "TWF",
  },
  {
    id: "2f405b3d-e779-4499-b25d-a3d895bf9d3e",
    airportName: "Tyler, TX",
    Location: "USA",
    IATA: "TYR",
  },
  {
    id: "6e2d84a9-62a7-4dad-a469-e1e1997f9785",
    airportName: "Uberlandia, MG",
    Location: "Brazil",
    IATA: "UDI",
  },
  {
    id: "83926a02-6669-4cbb-b6da-def8ddd3a03c",
    airportName: "Ujung Pandang",
    Location: "Indonesia",
    IATA: "UPG",
  },
  {
    id: "c2e41ca4-925b-46c7-ac2a-d5229098210c",
    airportName: "Ulaanbaatar - Buyant Uhaa",
    Location: "Mongolia",
    IATA: "ULN",
  },
  {
    id: "0c07a916-39e7-4619-a1ea-a112d1a919bc",
    airportName: "Ulan-Ude",
    Location: "Russia",
    IATA: "UUD",
  },
  {
    id: "12bd717b-6a7f-45b3-a884-412fb8b271d5",
    airportName: "Ulundi",
    Location: "South Africa",
    IATA: "ULD",
  },
  {
    id: "7ee17422-b4a7-4b9d-9912-7bf923700cf8",
    airportName: "Umtata",
    Location: "South Africa",
    IATA: "UTT",
  },
  {
    id: "7b639550-5b45-40b1-9e12-508ca5db7fd5",
    airportName: "Upington",
    Location: "South Africa",
    IATA: "UTN",
  },
  {
    id: "8f1dce2f-b81b-4f1e-919d-1218edd09955",
    airportName: "Urumqi",
    Location: "Xinjiang, China",
    IATA: "URC",
  },
  {
    id: "bf6e66d2-9ead-4309-b2f8-c9b110ed09ff",
    airportName: "Utapao (Pattaya)",
    Location: "Thailand",
    IATA: "UTP",
  },
  {
    id: "a561c145-dae4-4518-9a17-e8e9e27843e8",
    airportName: "Utica, NY",
    Location: "USA",
    IATA: "UCA",
  },
  {
    id: "beb1345a-0785-4a49-a92f-7e723c5ab455",
    airportName: "Vaasa",
    Location: "Finland",
    IATA: "VAA",
  },
  {
    id: "9cee40ed-8725-4377-bb58-7cc56c82b4f9",
    airportName: "Vaexjoe",
    Location: "Sweden",
    IATA: "VXO",
  },
  {
    id: "712dc59d-3636-4094-8970-527b29ae013b",
    airportName: "Vail, CO",
    Location: "USA",
    IATA: "EGE",
  },
  {
    id: "96d6605f-992e-4610-8eee-74ebdc664fed",
    airportName: "Val d'Or",
    Location: "Canada",
    IATA: "YVO",
  },
  {
    id: "71ba0008-5081-4da8-81d5-ef86cb2d652b",
    airportName: "Valdez, AK",
    Location: "USA",
    IATA: "VDZ",
  },
  {
    id: "7849527e-adcc-448d-b7ee-d10e1cfd8bbe",
    airportName: "Valdosta, GA",
    Location: "USA",
    IATA: "VLD",
  },
  {
    id: "900810a7-bb67-4280-858b-f056a3e89158",
    airportName: "Valencia",
    Location: "Spain",
    IATA: "VLC",
  },
  {
    id: "12314128-0a82-4906-96f8-3f7b0e22e373",
    airportName: "Valencia",
    Location: "Venezuela",
    IATA: "VLN",
  },
  {
    id: "a8d1b772-8981-436e-b28c-7657dc005097",
    airportName: "Valladolid",
    Location: "Spain",
    IATA: "VLL",
  },
  {
    id: "e7395843-a5d2-41a0-a0dc-cf1fbd1c19a0",
    airportName: "Valparaiso",
    Location: "Chile",
    IATA: "VAP",
  },
  {
    id: "34a20799-e092-4654-afef-06ad85d508e0",
    airportName: "Valverde",
    Location: "Spain",
    IATA: "VDE",
  },
  {
    id: "d478eb94-0aca-4418-8969-5320480ec941",
    airportName: "Vancouver - Vancouver Int'l",
    Location: "Canada",
    IATA: "YVR",
  },
  {
    id: "f0712020-9767-4c82-b47c-564bf8e9b117",
    airportName: "Varadero",
    Location: "Cuba",
    IATA: "VRA",
  },
  {
    id: "cbab5153-b53b-4164-b945-0bd36a2804f4",
    airportName: "Varanasi",
    Location: "India",
    IATA: "VNS",
  },
  {
    id: "e93a5a01-027c-41ec-878c-2add810faa38",
    airportName: "Varkaus",
    Location: "Finland",
    IATA: "VRK",
  },
  {
    id: "87bc05c8-4a9e-45b9-a7aa-907245c64ca6",
    airportName: "Varna",
    Location: "Bulgaria",
    IATA: "VAR",
  },
  {
    id: "b4f416af-cf59-4cf4-b1f5-3d3afbd19648",
    airportName: "Vasteras",
    Location: "Sweden",
    IATA: "VST",
  },
  {
    id: "7825dd78-12e9-4a37-b4c6-283f8c9be095",
    airportName: "Velikiye Luki (Welikije Luki)",
    Location: "Russia",
    IATA: "VLU",
  },
  {
    id: "2fb28032-a051-4cbe-bb26-e9342d2415d5",
    airportName: "Venice - Marco Polo",
    Location: "Italy",
    IATA: "VCE",
  },
  {
    id: "6effb0e2-bd3b-4f91-ba41-2a21b51d811a",
    airportName: "Veracruz",
    Location: "Mexico",
    IATA: "VER",
  },
  {
    id: "463e9e47-27d6-4dee-a0c2-8d0b1e95632e",
    airportName: "Vernal, UT",
    Location: "USA",
    IATA: "VEL",
  },
  {
    id: "31d16cee-87a3-41b5-bbef-39f9f1b0f84a",
    airportName: "Vero Beach/Ft. Pierce, FL",
    Location: "USA",
    IATA: "VRB",
  },
  {
    id: "cd280aa3-8953-4dd7-a89f-79690020ee06",
    airportName: "Verona",
    Location: "Italy",
    IATA: "VRN",
  },
  {
    id: "51bca5c1-0a2d-4e2e-86e7-4484291d91dd",
    airportName: "Victoria",
    Location: "Canada",
    IATA: "YYJ",
  },
  {
    id: "3be61974-955a-4e7f-a2e5-207c6046294d",
    airportName: "Victoria Falls",
    Location: "Zimbabwe",
    IATA: "VFA",
  },
  {
    id: "1568ab86-b1a3-4eef-8ffa-2ddd7563ab10",
    airportName: "Vidin",
    Location: "Bulgaria",
    IATA: "VID",
  },
  {
    id: "a8bf4938-1cc7-452f-8a54-1d19efedd190",
    airportName: "Vientiane - Wattay",
    Location: "Laos",
    IATA: "VTE",
  },
  {
    id: "8500a297-dca6-4209-9b96-a1977a088e64",
    airportName: "Vigo",
    Location: "Spain",
    IATA: "VGO",
  },
  {
    id: "1273bc3b-f88a-48df-8681-140cb24d79c9",
    airportName: "Villahermosa",
    Location: "Mexico",
    IATA: "VSA",
  },
  {
    id: "cb888278-4c39-4d54-a108-21744140b828",
    airportName: "Virgin Gorda",
    Location: "Virgin Islands",
    IATA: "VIJ",
  },
  {
    id: "49af023e-b311-41a9-8ad8-1d1920e6162e",
    airportName: "Visalia, CA",
    Location: "USA",
    IATA: "VIS",
  },
  {
    id: "5565b2d5-becc-4fa0-b40a-67d56bd1440b",
    airportName: "Visby",
    Location: "Sweden",
    IATA: "VBY",
  },
  {
    id: "5bef5668-11aa-41b7-8740-deba2b5472be",
    airportName: "Vitoria",
    Location: "Spain",
    IATA: "VIT",
  },
  {
    id: "563329fc-98c3-4bb2-b90a-278b32e8aa81",
    airportName: "Vitoria",
    Location: "Brazil",
    IATA: "VIX",
  },
  {
    id: "646e8143-8cec-4f97-aa3d-2ec167f3d684",
    airportName: "Vryheid",
    Location: "South Africa",
    IATA: "VYD",
  },
  {
    id: "f697fb0e-4145-4dd3-a351-5bd694edac0b",
    airportName: "Wabush",
    Location: "Canada",
    IATA: "YWK",
  },
  {
    id: "516b32cd-0381-445e-8a8b-5b6e3fb8c5b3",
    airportName: "Waco, TX",
    Location: "USA",
    IATA: "ACT",
  },
  {
    id: "308fe0ae-e4fd-45f3-93ef-5ce247bb6800",
    airportName: "Wagga",
    Location: "Australia",
    IATA: "WGA",
  },
  {
    id: "90eeb86b-a88a-43cb-a665-6c1ef37c614f",
    airportName: "Walla Walla, WA",
    Location: "USA",
    IATA: "ALW",
  },
  {
    id: "abdda7d8-f03c-4e3c-a130-536ee7779a40",
    airportName: "Wallis",
    Location: "Wallis and Futuna Islands",
    IATA: "WLS",
  },
  {
    id: "0efdb562-a8be-4814-a0ae-288054a73829",
    airportName: "Walvis Bay",
    Location: "South Africa",
    IATA: "WVB",
  },
  {
    id: "daab412d-92f6-4203-ace1-f2bf6fc111e8",
    airportName: "Warrnambool",
    Location: "Australia",
    IATA: "WMB",
  },
  {
    id: "84956583-969d-4bbd-b4ba-9171acfc28ec",
    airportName: "Warsaw",
    Location: "Poland",
    IATA: "WAW",
  },
  {
    id: "fdb87c6d-ab38-4179-ba1a-226073724602",
    airportName: "Washington DC - Dulles Int'l",
    Location: "USA",
    IATA: "IAD",
  },
  {
    id: "ce809f18-7553-48c8-b953-402a77df8e04",
    airportName: "Washington DC - Ronald Reagan National",
    Location: "USA",
    IATA: "DCA",
  },
  {
    id: "c91b8b19-26e7-401d-99bd-0e60e516b68e",
    airportName: "Washington, DC",
    Location: "USA",
    IATA: "WAS",
  },
  {
    id: "2b27cdc8-1a4b-4536-962f-1cf0e597e81a",
    airportName: "Waterloo, IA",
    Location: "USA",
    IATA: "ALO",
  },
  {
    id: "825ac8d0-c086-4bf6-83a3-dafaa5b68f2d",
    airportName: "Watertown, SD",
    Location: "USA",
    IATA: "ATY",
  },
  {
    id: "bb5cc56e-6d22-4203-b6b7-d24ef8577b20",
    airportName: "Wausau/Stevens Point, WI",
    Location: "USA",
    IATA: "CWA",
  },
  {
    id: "9dd0e8fe-95aa-4c3b-b44d-de6c7bf82795",
    airportName: "Weipa",
    Location: "Australia",
    IATA: "WEI",
  },
  {
    id: "fd56e6c1-0185-4502-bc52-ef164337738f",
    airportName: "Welkom",
    Location: "South Africa",
    IATA: "WEL",
  },
  {
    id: "d3861f74-98fd-4604-a78a-45e56da79cb9",
    airportName: "Wellington",
    Location: "New Zealand",
    IATA: "WLG",
  },
  {
    id: "61e6a2fd-62e1-418b-80e5-2bd33d3a9297",
    airportName: "Wenatchee, WA",
    Location: "USA",
    IATA: "EAT",
  },
  {
    id: "faa607f4-8005-4c74-b32d-a77a389a86af",
    airportName: "West Palm Beach, FL",
    Location: "USA",
    IATA: "PBI",
  },
  {
    id: "27e2fefe-371b-4dde-81cf-b40aab210520",
    airportName: "West Yellowstone, MT",
    Location: "USA",
    IATA: "WYS",
  },
  {
    id: "4cfe3c90-02fb-4618-ab69-7945dccf76fc",
    airportName: "Westerland",
    Location: "Germany",
    IATA: "GWT",
  },
  {
    id: "8bbc61a3-e397-445b-9d96-b8357143970d",
    airportName: "Whakatane",
    Location: "New Zealand",
    IATA: "WHK",
  },
  {
    id: "90564d6b-2299-4fa9-957c-cd712d27af81",
    airportName: "Whale Cove, NT",
    Location: "Canada",
    IATA: "YXN",
  },
  {
    id: "40bb3367-a9d9-4329-9423-cd0194ad417e",
    airportName: "Whangarei",
    Location: "New Zealand",
    IATA: "WRE",
  },
  {
    id: "e2849e26-d607-42c3-9f76-67224635cfe8",
    airportName: "White Plains, NY",
    Location: "USA",
    IATA: "HPN",
  },
  {
    id: "bb55c802-2e48-4bef-a3aa-c97c879a4076",
    airportName: "Whitehorse",
    Location: "Canada",
    IATA: "YXY",
  },
  {
    id: "3ad6f9f8-a8ae-40de-b79d-34174daa03b3",
    airportName: "Whitsunday Resort",
    Location: "Australia",
    IATA: "HAP",
  },
  {
    id: "c5a726c9-149a-47f3-96ca-6aa27083b033",
    airportName: "Whyalla",
    Location: "Australia",
    IATA: "WYA",
  },
  {
    id: "b371c767-3be1-41d2-b8d2-3f1552674f10",
    airportName: "Wichita Falls, TX",
    Location: "USA",
    IATA: "SPS",
  },
  {
    id: "1d094e25-3d77-468d-acd5-a6531ada7bbf",
    airportName: "Wichita, KS",
    Location: "USA",
    IATA: "ICT",
  },
  {
    id: "bb2145c7-8222-4047-9544-ba79e8896cb1",
    airportName: "Wick",
    Location: "United Kingdom",
    IATA: "WIC",
  },
  {
    id: "b3da1fd4-fd20-4dcd-b1a6-0e1ab4f644d3",
    airportName: "Wickham",
    Location: "Australia",
    IATA: "WHM",
  },
  {
    id: "611bfefe-807b-4dcd-be4e-259c9e87e6d4",
    airportName: "Wien (Vienna) - Vienna Int'l",
    Location: "Austria",
    IATA: "VIE",
  },
  {
    id: "93153c48-b52d-480f-a3ba-fbf562f0560a",
    airportName: "Wiesbaden, Air Base",
    Location: "Germany",
    IATA: "WIE",
  },
  {
    id: "f5d90963-7e55-4913-992c-20c4d422b216",
    airportName: "Wilkes Barre/Scranton, PA",
    Location: "USA",
    IATA: "AVP",
  },
  {
    id: "885632df-a35e-4561-933a-72a0795de16a",
    airportName: "Williamsport, PA",
    Location: "USA",
    IATA: "IPT",
  },
  {
    id: "8408993d-c047-4adc-b2cc-27c8aaf94f49",
    airportName: "Williston, ND",
    Location: "USA",
    IATA: "ISL",
  },
  {
    id: "b30bde8f-6688-43aa-b101-5020402fb2e8",
    airportName: "Wilmington, NC",
    Location: "USA",
    IATA: "ILM",
  },
  {
    id: "9b0605cd-a1b3-4384-8803-b4f10299cb29",
    airportName: "Wilna (Vilnius)",
    Location: "Lithuania",
    IATA: "VNO",
  },
  {
    id: "3dcc654e-c75a-44ee-9f57-ba741c26624a",
    airportName: "Wiluna",
    Location: "Australia",
    IATA: "WUN",
  },
  {
    id: "35c70a03-f1ca-415a-9023-f0e0a870952d",
    airportName: "Windhoek - Eros",
    Location: "Namibia",
    IATA: "ERS",
  },
  {
    id: "5b6c1027-31fc-4a45-9fa9-bc55a12cb64c",
    airportName: "Windhoek - Hosea Kutako Int'l",
    Location: "Namibia",
    IATA: "WDH",
  },
  {
    id: "959a1fdd-9d21-449f-98f4-cb85af838dbf",
    airportName: "Windsor Ontario",
    Location: "Canada",
    IATA: "YQG",
  },
  {
    id: "5087da9c-8387-43b1-95de-d416cca5c9a2",
    airportName: "Winnipeg Int'l",
    Location: "Canada",
    IATA: "YWG",
  },
  {
    id: "82d35b2b-c64a-4b58-9f73-58a099565de2",
    airportName: "Wolf Point, MT",
    Location: "USA",
    IATA: "OLF",
  },
  {
    id: "e781ceff-b97e-49d9-9eab-4bf4945d3840",
    airportName: "Wollongong",
    Location: "Australia",
    IATA: "WOL",
  },
  {
    id: "41147ecf-8174-4faf-a438-2185bbee519b",
    airportName: "Woomera",
    Location: "Australia",
    IATA: "UMR",
  },
  {
    id: "e8fb2682-30de-412d-b329-5fc0c4efc5be",
    airportName: "Worcester, MA",
    Location: "USA",
    IATA: "ORH",
  },
  {
    id: "b38514ee-8d55-4c67-a5bc-a82e12003136",
    airportName: "Worland, WY",
    Location: "USA",
    IATA: "WRL",
  },
  {
    id: "2bd1765c-df55-4456-b1a5-87835d3b8b95",
    airportName: "Wrangell, AK",
    Location: "USA",
    IATA: "WRG",
  },
  {
    id: "23fda45d-5631-4801-a49f-e93cfac4613b",
    airportName: "Wuhan",
    Location: "Hubei, China",
    IATA: "WUH",
  },
  {
    id: "242789fb-3871-4b2f-9ebe-6e81bdb22671",
    airportName: "Wyndham",
    Location: "Australia",
    IATA: "WYN",
  },
  {
    id: "09a0c6fb-0809-4c1c-96f5-121c6b983d48",
    airportName: "Xiamen",
    Location: "Fujian, China",
    IATA: "XMN",
  },
  {
    id: "ad5c1e74-ea22-4efc-a676-baeeef00cd1e",
    airportName: "Xi'an - Xianyang",
    Location: "Shaanxi, China",
    IATA: "XIY",
  },
  {
    id: "0318cba1-013d-4f64-8f18-c6627e44d344",
    airportName: "Yakima, WA",
    Location: "USA",
    IATA: "YKM",
  },
  {
    id: "3a1696a8-5cea-4ddc-ab82-2d5610662bb1",
    airportName: "Yakutat, AK",
    Location: "USA",
    IATA: "YAK",
  },
  {
    id: "7bb3647a-4f35-4bf4-8584-3d3def6109b2",
    airportName: "Yakutsk",
    Location: "Russian Federation",
    IATA: "YKS",
  },
  {
    id: "191b1f69-0c52-4747-b214-66a1c103f0d5",
    airportName: "Yamagata, Junmachi",
    Location: "Japan",
    IATA: "GAJ",
  },
  {
    id: "da73b4be-138f-4dfe-bb33-0a34dbe029e5",
    airportName: "Yamoussoukro",
    Location: "Côte d'Ivoire (Ivory Coast)",
    IATA: "ASK",
  },
  {
    id: "3d803830-ec01-4d7a-8e5b-769d9d70536c",
    airportName: "Yanbu",
    Location: "Saudi Arabia",
    IATA: "YNB",
  },
  {
    id: "e48138a7-cd66-42dd-9500-ad314fc9ee10",
    airportName: "Yaounde",
    Location: "Cameroon",
    IATA: "YAO",
  },
  {
    id: "02a51687-a499-4b32-b255-e8182f006bf4",
    airportName: "Yellowknife",
    Location: "Canada",
    IATA: "YZF",
  },
  {
    id: "4cd1c2b3-fa09-4dce-8e1d-bb97c20498ab",
    airportName: "Yokohama",
    Location: "Japan",
    IATA: "YOK",
  },
  {
    id: "407a1da2-4b93-4a59-a9d1-fe38a315e9cc",
    airportName: "Yuma, AZ",
    Location: "USA",
    IATA: "YUM",
  },
  {
    id: "c57bf34b-4fa7-47c8-abd1-13b25bf2f8f2",
    airportName: "Zacatecas",
    Location: "Mexico",
    IATA: "ZCL",
  },
  {
    id: "55811a11-a22f-4962-99bf-1e8ecc8ab9b3",
    airportName: "Zadar",
    Location: "Croatia (Hrvatska)",
    IATA: "ZAD",
  },
  {
    id: "18732e12-84b4-45be-b1b2-da81c5685bcc",
    airportName: "Zagreb - Pleso",
    Location: "Croatia (Hrvatska)",
    IATA: "ZAG",
  },
  {
    id: "711e9183-f70a-4407-bd05-fc17060396e1",
    airportName: "Zakynthos",
    Location: "Greece",
    IATA: "ZTH",
  },
  {
    id: "64520878-4805-4124-8711-765ec4b80a2d",
    airportName: "Zaragoza",
    Location: "Spain",
    IATA: "ZAZ",
  },
  {
    id: "b492c5cc-9b73-4d0d-bd61-dc17b757af28",
    airportName: "Zhob",
    Location: "Pakistan",
    IATA: "PZH",
  },
  {
    id: "f464763b-252d-4f1a-8580-f36c21a749d8",
    airportName: "Zinder",
    Location: "Niger",
    IATA: "ZND",
  },
  {
    id: "a6e9805c-2c0f-461c-bcc2-d93de89c4ffe",
    airportName: "Zouerate",
    Location: "Mauritania",
    IATA: "OUZ",
  },
  {
    id: "6c13a11d-90e1-455d-a01f-bd0ceb03e6e3",
    airportName: "Zurich (Zürich) - Kloten",
    Location: "Switzerland",
    IATA: "ZRH",
  },
];
