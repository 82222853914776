import { Link } from "react-router-dom";
import HeroNav from "../components/homeComponent/HeroNav.component";
import { Banner } from "../utils/images";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet-async";

const Disclaimer = () => {
  return (
    <>
      <Helmet>
        <title>Disclaimer</title>
        <meta name="description" content="Disclaimer to book flights" />
        <meta name="keywords" content="flight,booking,contact us" />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta
          property="og:description"
          content="contact us for flight booking"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <div
        className={`w-full h-full md:h-[35rem] lg:h-[15rem] bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <HeroNav />
        <h1 className="text-white text-7xl font-bold w-[70vw] mx-auto mt-10">
          Disclaimer
        </h1>
      </div>
      <div className="flex flex-col gap-y-4 w-[70vw] mx-auto my-10 text-justify">
        <p className="text-sm text-gray-700 font-medium">
          There are chances that the published information on this website
          pertaining to software, products or services may contain some errors,
          which may be based on pricing. In other words,{" "}
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          and its associates do not vouch 100% accuracy, and hereby disclaim all
          liabilities of any inaccuracy related to any error as well as
          description of the air, car, cruise and other travel based
          products/services, being displayed on this website (including, without
          any limitation, the pricing, pictures, general product descriptions,
          or more). Moreover, dtt.inc holds the right to edit or add corrections
          to any issue related to pricing or reservations made on inapt price.
          Thus, we at{" "}
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          may offer you to carry on with your awaiting reservation at the
          correct price, or we may cancel your reservation without imposing any
          fine.
        </p>
        <p className="text-sm text-gray-700 font-medium">
          This website encompasses ratings that are calculated on the basis of
          general guidelines; hence,{" "}
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          and its associates do not guarantee their faultlessness.
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          does not provide any kind of guarantee pertaining to the availability
          of various products/services And, their suppliers as well as
          affiliates are free to modify or correct website information at any
          moment. In addition, we ensure that we would never make representation
          about the aptness of software, services, products, or any other
          information displayed on this website. And, presence of these products
          or services on this website does not include any kind of
          endorsement/recommendation by{" "}
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          or any of its associates. The information/services/products are
          offered "as is" without any warranty. Hence,{" "}
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          along with its associates and suppliers repudiates all the warranties
          and conditions, displayed/hosted on its servers/websites.
        </p>
        <p className="text-sm text-gray-700 font-medium">
          The suppliers as well as carriers, offering travel or other services
          on this website are not agents of dtt.inc, as they work as independent
          contractors. Hence,{" "}
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          and its affiliates are not liable for the mistakes, actions,
          exclusions, warranties, negligence or representations made by any
          supplier.
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          and its affiliates are not liable for it, and will not process any
          refund in any case. Moreover, they do not have any accountability for
          any additional expense, re-routing, or actions being organised by any
          government authority.
        </p>
        <p className="text-sm text-gray-700 font-medium">
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          as well as its associates along with their suppliers hold
          responsibility in various kinds of damages, which could be direct,
          indirect, punitive, incidental, special or consequential damages
          arising out of, or in any way associated with, your access to, display
          of or use of this website or with delay or inability to access,
          display or use this website (which includes, but is not limited to,
          your dependence upon opinions that appear on this website; any
          computer virus, information, software, linked site(s), products and
          services being obtained through this website; or otherwise arising out
          of the access to, display of or use of this website) whether based on
          a theory of negligence, contract, tort, strict liability, consumer
          protection laws, or otherwise, and even if{" "}
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          and its affiliates along with their respective suppliers have been
          advised of the possibility of occurrence of such damages.
        </p>
        <p className="text-sm text-gray-700 font-medium">
          The allotment of risk between the parties is reflected by the
          liability limitation. And, the limitations presented in the above
          mentioned section will come into existence even if any limited remedy
          specified in these terms and conditions is found to have failed of its
          core objective. The limitations of liability as mentioned in these
          terms & conditions inure to the benefit of dtt.inc and its associates
          as well as suppliers.
        </p>
      </div>
    </>
  );
};

export default Disclaimer;
