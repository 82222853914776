import { Link } from "react-router-dom";
import HeroNav from "../components/homeComponent/HeroNav.component";
import { Banner } from "../utils/images";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About US</title>
        <meta name="description" content="about dreams travel and tours" />
        <meta name="keywords" content="travel,tours" />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta property="og:description" content="dreams travel and tours" />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <div
        className={`w-full h-full md:h-[35rem] lg:h-[15rem] bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <HeroNav />
        <h1 className="text-white text-7xl font-bold w-[70vw] mx-auto mt-10">
          About US
        </h1>
      </div>
      <div className="w-[70vw] mx-auto my-10 text-justify">
        <h2 className="text-lg font-bold text-gray-700 capitalize">
          Far From The Ordinary
        </h2>
        <p>
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          is a proven leader in corporate and leisure travel management with
          over 15 years of experience in delivering innovative, interesting and
          cost-effective travel solutions. Our team is made up of fantastic
          people who are trained to help you with not only to travel smart but
          also achieve more in terms of saving on your expenses. They also keep
          an eye on when early bird and special deals are released, so that they
          can save you even more! Using our long-standing travel industry
          relationships, leading technology and exceptional team we organize the
          best travel solutions for you!
        </p>
        <p>
          So, whether you are travelling for business or simply need the perfect
          holiday, leave it to us.
        </p>
        <br />
        <p>
          We are headquartered in USA with business offices and operations in
          Canada, Nigeria, Ghana, South Africa and India. We offer full services
          including ticketing and payment in any currency in these countries.
        </p>
        <p>
          Our clients include embassies, foreign missions, UNO, WHO, Sub
          agencies & Corporate houses.
        </p>
        <p>
          We are and IATA accredited agent members of Cruise Lines International
          Association (CLIA), American Society of Travel Agents (ASTA) and
          Travel Agents Federation of India (TAFI).
        </p>
        <p>
          Call or email us if you have any questions, or if you'd like to know
          more about our services.
        </p>
        <p>
          <span>Mission –</span>Our mission is to exceed our customer's
          expectations, both in terms of service and quality with
          professionalism
        </p>
        <p>
          <span>Vision -</span>To be a leader in the travel industry providing
          cost efficient and effective services to our customers
        </p>
      </div>
    </>
  );
};

export default AboutUs;
