import { useParams } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { useEffect, useState } from "react";
import { BlogI } from "../types/blogs.types";
import parse from "html-react-parser";
import LoadingComponent from "../components/Loading.component";
import NoDataFoundComponent from "../components/NoDataFound.component";
import HeroSection from "../components/homeComponent/HeroSection.component";
import { Helmet } from "react-helmet-async";
import { FaMinus, FaPlus } from "react-icons/fa6";
import ContactBanner from "../components/Banner/ContactBanner";
import CallBtn from "../components/Buttons/CallBtn";

const TopAirlines = () => {
  const { id, url } = useParams<{ id: string; url: string }>();

  const [blog, setBlog] = useState<BlogI | null>(null);
  const [status, setStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");
  const [active, setActive] = useState(null);

  const toggle = (i: any) => {
    if (active === i) {
      return setActive(null);
    }
    setActive(i);
  };

  const fetchBlog = async (id: string) => {
    try {
      setStatus("loading");
      const response = await fetch(`${process.env.REACT_APP_GETBLOG}/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (!data.success) {
        setStatus("error");
      }
      setBlog(() => data.data);
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  };

  useEffect(() => {
    if (id) {
      fetchBlog(id);
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{blog?.metaTitle}</title>
        <meta name="description" content={blog?.metaDescription} />
        <meta name="keywords" content={blog?.metaKeyword} />
        <meta property="og:title" content={blog?.metaTitle} />
        <meta property="og:description" content={blog?.metaDescription} />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <HeroSection />
      {status === "loading" && <LoadingComponent />}
      {status === "success" && blog && (
        <>
          <div className="w-[98vw] lg:w-[80vw] xl:w-[70vw] mx-auto my-5">
            <div className="hidden xs:block">
              <ContactBanner />
            </div>
            <div className=" xs:hidden">
              <CallBtn />
            </div>
          </div>
          <div className="flex flex-col prose lg:prose-xl prose-blue my-10 w-[90vw] mx-auto">
            {blog && parse(blog.content)}
          </div>
          <div className="w-[98vw] lg:w-[80vw] xl:w-[70vw] mx-auto my-5">
            <div className="hidden xs:block">
              <ContactBanner />
            </div>
            <div className=" xs:hidden">
              <CallBtn />
            </div>
          </div>
          {blog.faqs.length > 0 && (
            <div className="flex flex-col justify-center items-center w-[95%] md:w-[58vw] mx-auto">
              <h2 className="text-gray-700 capitalize font-bold text-2xl mb-5">
                <span className="font-thin">Frequently Asked </span>
                <span>Questions</span>
              </h2>
              <div className="w-full">
                {blog.faqs.map((faq, index) => (
                  <div
                    className="bg-gradient-to-r from-[#ec2027] to-[#c81212] mb-2 text-white px-2 py-2 cursor-pointer rounded-lg"
                    key={index}
                    onClick={() => toggle(index)}
                  >
                    <div className="flex items-center justify-between">
                      <h1 className="md:text-lg font-semibold">
                        {faq.question ? faq.question : "Question 1"}
                      </h1>
                      <div className="bg-[#ffffff] rounded-full flex items-center p-1 md:p-2 cursor-pointer">
                        {active === index ? (
                          <FaMinus className="text-black text-sm md:text-base" />
                        ) : (
                          <FaPlus className="text-black text-sm md:text-base" />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        active === index
                          ? "w-[90%] h-auto max-h-[999px] transition-all text-sm md:text-base duration-300 mt-2"
                          : "w-[90%] max-h-0 overflow-hidden transition-all text-sm md:text-base duration-300"
                      }
                    >
                      {faq.answer
                        ? faq.answer
                        : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse, cupiditate! Aliquid repudiandae, aspernatur totam nobis temporibus officia placeat explicabo tempora incidunt, fugiat mollitia ducimus voluptas ad nulla itaque id autem unde! Fuga suscipit pariatur rem est minus dolores doloremque harum quae molestiae nostrum minima, blanditiis eaque tempore? Odit modi dolorem provident eaque voluptates beatae tempora expedita doloribus eligendi libero."}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {status === "error" && <NoDataFoundComponent />}
    </>
  );
};

export default TopAirlines;
