import html2canvas from "html2canvas";
import { contactInfo } from "../../dummydata/contactInfo";
import { DTT_LOGO } from "../../utils/icons";
import {
  AmadeusCertificate,
  AmericanExpressCard,
  ArcCertificate,
  CompliAssureSecured,
  IATAN,
  MaestroCard,
  MasterCard,
  MasterCardSecureCode,
  SaberCertificate,
  TaaCertificate,
  TravelPortCertificate,
  VerifiedAndSecuredCertificate,
  VerifiedVisaCard,
  VisaCard,
} from "../../utils/images";
import {
  CgDetailsMore,
  FaCheck,
  FaFileSignature,
  FaMobile,
  FaRegCreditCard,
  IoPersonSharp,
  PiPrinterFill,
  RiFlightTakeoffLine,
  TbListDetails,
} from "../../utils/solidIcons";
import jsPDF from "jspdf";
import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { airportList } from "../../utils/airportList";
import {
  AirportListI,
  PassengerI,
} from "../../types/flightSearchReducer.types";
import {
  calculateDiscountAmt,
  calculateDiscountedFare,
  convertToAMPM,
  formatFlightTime,
} from "../../utils/ExtraFunction";
import { airlinesImg } from "../../utils/airlineImgList";

const TicketPDF = () => {
  const [isDiscountAvailable, setIsDiscountAvailable] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const {
    address,
    bookingNum,
    contact,
    paymentInfo,
    pricingData,
    flightData,
    searchParams,
    ticketIssuedDate,
    userBasicinfo,
  } = JSON.parse(atob(`${params.get("id")}`));

  // console.log(JSON.parse(atob(`${params.get("id")}`)));

  const TotalFare: number = parseFloat(
    (
      +flightData.solution.adtFare * +flightData.solution.adults +
      +flightData.solution.adtTax * +flightData.solution.adults +
      ((+flightData.solution.chdFare
        ? +flightData.solution.chdFare * +flightData.solution.children
        : 0) +
        (+flightData.solution.chdTax
          ? +flightData.solution.chdTax * +flightData.solution.children
          : 0)) +
      ((+flightData.solution.infFare
        ? +flightData.solution.infFare * +flightData.solution.infants
        : 0) +
        (+flightData.solution.infTax
          ? +flightData.solution.infTax * +flightData.solution.infants
          : 0))
    ).toFixed(2)
  );
  const contentRef = useRef<HTMLDivElement>(null);

  const handleDownloadPDF = async () => {
    if (!contentRef.current) return;

    // Capture the content as an image
    const canvas = await html2canvas(contentRef.current, {
      scale: 3, // Increase scale for better quality
      useCORS: true, // Enable if you are loading images from external URLs
    });
    const imageData = canvas.toDataURL("image/png");

    // Initialize jsPDF and add the captured image to the PDF
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pageWidth;
    const imgHeight = (canvas.height * pageWidth) / canvas.width;

    let position = 0;
    pdf.addImage(imageData, "PNG", 0, position, imgWidth, imgHeight);

    // If content overflows, add new pages and adjust position
    if (imgHeight > pageHeight) {
      let leftHeight = imgHeight;
      position = 0;
      while (leftHeight > 0) {
        pdf.addPage();
        position -= pageHeight;
        pdf.addImage(imageData, "PNG", 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
      }
    }

    // Save the PDF
    pdf.save("ticket.pdf");
  };
  return (
    <div
      ref={contentRef}
      className="w-full sm:w-3/4 mx-auto flex  flex-col gap-y-6 p-4"
    >
      <div className="flex items-center justify-between">
        <div>
          <img src={DTT_LOGO} alt="dream travel and tours logo" width={50} />
        </div>
        <div className="flex items-center gap-x-2">
          <FaMobile className="text-3xl text-[#c81212]" />
          <div className="flex flex-col items-start">
            <Link
              to={`tel:${contactInfo.mobileNum}`}
              className="text-xs font-medium text-gray-500"
            >
              24/7 Telephone Number
            </Link>
            <p className="text-sm font-bold text-gray-700">
              {contactInfo.mobileNum}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <h1 className="mx-auto flex items-center gap-2">
          <span className="text-lg font-bold text-green-500">
            Congratulations!
          </span>
          <span className="text-sm font-medium text-gray-700">
            Your flight booking is under process...
          </span>
        </h1>
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-gray-700">
            Your booking number{" "}
            <span className="text-base font-bold">{bookingNum}</span>, Booked on{" "}
            <span className="text-base font-bold">
              {new Date(ticketIssuedDate).toLocaleString(
                "en-US", // Options to format the date
                {
                  weekday: "short", // "Thu"
                  month: "short", // "Nov"
                  day: "2-digit", // "14"
                  year: "numeric", // "2024"
                  hour: "2-digit", // "06"
                  minute: "2-digit", // "15"
                  second: "2-digit", // "33"
                  hour12: true, // 12-hour format with AM/PM
                }
              )}
            </span>
          </p>
          <button
            className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] px-4 py-2 rounded-md"
            onClick={handleDownloadPDF}
          >
            <PiPrinterFill className="text-2xl text-white" />
            <p className="text-sm font-medium text-white">Print</p>
          </button>
        </div>
      </div>
      <div className="border flex flex-col rounded-md">
        <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-2 ps-2">
          <FaCheck className="text-base font-bold text-white" />
          <span className="text-base font-bold text-white capitalize">
            Booking Status
          </span>
        </p>
        <p className="text-sm font-medium text-gray-700 p-2">
          Your payment is under process. The confirmation for your itinerary
          will be emailed shortly. This is not an e-ticket hence not valid for
          traveling. E-ticket will be issued and emailed to you after successful
          verification of Credit/Debit Card.
        </p>
      </div>
      <div className="flex flex-col border rounded-md">
        <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-2 ps-2">
          <IoPersonSharp className="text-base font-bold text-white" />
          <span className="text-base font-bold text-white capitalize">
            Customer Information
          </span>
        </p>
        <div className="flex items-center text-sm font-medium text-gray-700 p-2 justify-between">
          <p>
            <span className="font-bold">Traveler : </span>
            <span>{`${userBasicinfo[0].firstName.trim()} ${
              userBasicinfo[0].middleName
                ? userBasicinfo[0].middleName.trim()
                : ""
            } ${userBasicinfo[0].lastName.trim()}`}</span>
          </p>
          <p>
            <span className="font-bold">Phone : </span>
            <span>{contact.billingPhone}</span>
          </p>
          <p>
            <span className="font-bold">Email ID : </span>
            <span>{contact.email}</span>
          </p>
        </div>
      </div>

      {/***************Flight details*************/}

      <div className=" flex  flex-col border rounded-md">
        <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-2 ps-2">
          <TbListDetails className="text-base font-bold text-white" />
          <span className="text-base font-bold text-white capitalize">
            Flight details
          </span>
        </p>
        <div className="  flex  flex-col divide-y p-2">
          {searchParams.tripType === "roundtrip" ? (
            [
              ...pricingData.journeys.journey_0,
              ...pricingData.journeys.journey_1,
            ].map((journey, index) => (
              <div key={index} className=" flex  flex-col py-2">
                <div className="flex  justify-between items-center border-b border-dashed py-2 mb-2">
                  <p className="flex items-center gap-x-2">
                    <span className="text-sm font-bold text-gray-700">
                      {
                        airportList.filter(
                          (airport: AirportListI) =>
                            airport.IATA === journey.departure
                        )[0].airportName
                      }
                    </span>
                    <RiFlightTakeoffLine className="text-xl font-medium text-gray-700" />{" "}
                    <span className="text-sm font-bold text-gray-700">
                      {
                        airportList.filter(
                          (airport: AirportListI) =>
                            airport.IATA === journey.arrival
                        )[0].airportName
                      }
                    </span>
                    <span className="text-xs font-extralight text-gray-700">
                      (
                      {new Date(`${journey.departureDate}`).toLocaleString(
                        "en-US",
                        {
                          weekday: "short", // "Thu"
                          month: "short", // "Nov"
                          day: "2-digit", // "14"
                          year: "numeric", // "2024"
                          hour: "2-digit", // "06"
                          minute: "2-digit", // "15"
                          second: "2-digit", // "33"
                          hour12: true, // 12-hour format with AM/PM
                        }
                      )}
                      )
                    </span>
                  </p>
                  <p className="text-sm font-medium text-gray-700">
                    Trip Time: {formatFlightTime(journey.flightTime)} | Non Stop
                  </p>
                </div>
                <div className="flex justify-between items-center text-sm font-medium text-gray-700">
                  <div className="">
                    <img
                      src={
                        airlinesImg.filter(
                          (airline, index) =>
                            airline.AirLine_code === journey.airline
                        )[0].image_link
                      }
                      alt={
                        airlinesImg.filter(
                          (airline, index) =>
                            airline.AirLine_code === journey.airline
                        )[0].AirLine_code
                      }
                      width={30}
                    />
                    <p className="font-extralight">
                      {
                        airlinesImg.filter(
                          (airline, index) =>
                            airline.AirLine_code === journey.airline
                        )[0].Company_Name
                      }
                    </p>
                    <p className="font-extralight">
                      flight {journey.flightNum}
                    </p>
                  </div>
                  <div className="">
                    <h4 className="font-bold">From</h4>
                    <p className="font-extralight">
                      {`${
                        airportList.filter(
                          (airport: AirportListI) =>
                            airport.IATA === journey.departure
                        )[0].IATA
                      } ${
                        airportList.filter(
                          (airport: AirportListI) =>
                            airport.IATA === journey.departure
                        )[0].airportName
                      }  ${
                        airportList.filter(
                          (airport: AirportListI) =>
                            airport.IATA === journey.departure
                        )[0].Location
                      }`}
                    </p>
                    <p className="font-extralight">
                      Terminal:{" "}
                      {journey.departureTerminal
                        ? journey.departureTerminal
                        : "Not Available"}
                    </p>
                    <p className="font-extralight">{`${new Date(
                      journey.departureDate
                    ).toLocaleDateString("en-US", {
                      day: "2-digit", // "14"
                      month: "short", // "Nov"
                      year: "numeric",
                    })} ${convertToAMPM(journey.departureTime)}`}</p>
                  </div>
                  <div>
                    <h4 className="font-bold">To</h4>
                    <p className="font-extralight">
                      {`${
                        airportList.filter(
                          (airport: AirportListI) =>
                            airport.IATA === journey.arrival
                        )[0].IATA
                      } ${
                        airportList.filter(
                          (airport: AirportListI) =>
                            airport.IATA === journey.arrival
                        )[0].airportName
                      }  ${
                        airportList.filter(
                          (airport: AirportListI) =>
                            airport.IATA === journey.arrival
                        )[0].Location
                      }`}
                    </p>
                    <p className="font-extralight">
                      Terminal :{" "}
                      {journey.arrivalTerminal
                        ? journey.arrivalTerminal
                        : "Not Available"}
                    </p>
                    <p className="font-extralight">{`${new Date(
                      journey.arrivalDate
                    ).toLocaleDateString("en-US", {
                      day: "2-digit", // "14"
                      month: "short", // "Nov"
                      year: "numeric",
                    })} ${convertToAMPM(journey.arrivalTime)}`}</p>
                  </div>
                  <div>
                    <p className="font-extralight">
                      {formatFlightTime(journey.flightTime)}
                    </p>
                    <p className="font-extralight">{journey.cabinClass}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        <p className="p-2 text-sm font-medium text-gray-700">
          <span className="font-bold">Please Note:</span>
          <span className="font-normal">
            As Airlines have frequent schedule changes, please call the Airline
            24 hours prior to scheduled departure to reconfirm your flight
            details.
          </span>
        </p>
      </div>
      <div className="flex flex-col border rounded-md gap-y-4">
        <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-2 ps-2">
          <FaRegCreditCard className="text-base font-bold text-white" />
          <span className="text-base font-bold text-white capitalize">
            Fare Details
          </span>
        </p>
        <div className="p-2">
          <table className="w-full table-auto border border-collapse">
            <thead>
              <tr className="bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white">
                <th className="border text-center py-1 text-sm font-medium">
                  Traveller Type
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Ticket Price
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Taxes & Fee
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border text-center py-1 text-sm font-normal text-gray-700">
                  {+flightData.solution.adults} x Adult
                </td>
                <td className="border text-center py-1 text-sm font-normal text-gray-700">
                  ${+flightData.solution.adtFare * +flightData.solution.adults}
                </td>
                <td className="border text-center py-1 text-sm font-normal text-gray-700">
                  ${+flightData.solution.adtTax * +flightData.solution.adults}
                </td>
                <td className="border text-center py-1 text-sm font-normal text-gray-700">
                  $
                  {parseFloat(
                    `${
                      +flightData.solution.adtFare *
                        +flightData.solution.adults +
                      +flightData.solution.adtTax * +flightData.solution.adults
                    }`
                  ).toFixed(2)}
                </td>
              </tr>
              {flightData.solution.children > 0 && (
                <tr>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {+flightData.solution.children} x Children
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {`${
                      +flightData.solution.chdFare
                        ? "$" +
                          parseFloat(
                            `${
                              +flightData.solution.chdFare *
                              +flightData.solution.children
                            }`
                          ).toFixed(2)
                        : "-"
                    }`}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {`${
                      +flightData.solution.chdTax
                        ? "$" +
                          parseFloat(
                            `${
                              +flightData.solution.chdTax *
                              +flightData.solution.children
                            }`
                          ).toFixed(2)
                        : "-"
                    }`}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    $
                    {parseFloat(
                      `${
                        +flightData.solution.chdFare *
                          +flightData.solution.children +
                        +flightData.solution.chdTax *
                          +flightData.solution.children
                      }`
                    ).toFixed(2)}
                  </td>
                </tr>
              )}
              {flightData.solution.infants > 0 && (
                <tr>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {+flightData.solution.infants} x infant
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {`${
                      +flightData.solution.infFare
                        ? "$" +
                          parseFloat(
                            `${
                              +flightData.solution.infFare *
                              +flightData.solution.infants
                            }`
                          ).toFixed(2)
                        : "-"
                    }`}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {`${
                      +flightData.solution.infTax
                        ? "$" +
                          parseFloat(
                            `${
                              +flightData.solution.infTax *
                              +flightData.solution.infants
                            }`
                          ).toFixed(2)
                        : "-"
                    }`}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {`${
                      +flightData.solution.infFare
                        ? "$" +
                          parseFloat(
                            `${
                              +flightData.solution.infFare *
                                +flightData.solution.infants +
                              +flightData.solution.infTax *
                                +flightData.solution.infants
                            }`
                          ).toFixed(2)
                        : "-"
                    }`}
                  </td>
                </tr>
              )}
              <tr>
                <td
                  className="border text-center text-extrabold py-1 text-sm font-bold text-gray-700"
                  colSpan={3}
                >
                  Subtotal:
                </td>
                <td className="border text-center py-1 text-sm font-normal text-gray-700">
                  ${+TotalFare}
                </td>
              </tr>
              {isDiscountAvailable && (
                <tr>
                  <td
                    className="border text-center py-1 text-sm font-bold text-green-400"
                    colSpan={3}
                  >
                    Discount:
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-green-400">
                    -${+calculateDiscountAmt(TotalFare, 10)}
                  </td>
                </tr>
              )}
              {isDiscountAvailable && (
                <tr>
                  <td
                    className="border text-center py-1 text-sm font-bold text-gray-700"
                    colSpan={3}
                  >
                    Booking Amount:
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    ${+calculateDiscountedFare(TotalFare, 10)}
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td
                  className="border text-center py-1 text-sm font-bold text-gray-700"
                  colSpan={2}
                >
                  Charged on Credit Card
                </td>
                <td className="border text-center py-1 text-sm font-normal text-gray-700">
                  VI - (****
                  {`${paymentInfo.cardNum}`.substring(
                    `${paymentInfo.cardNum}`.length - 4,
                    `${paymentInfo.cardNum}`.length
                  )}
                  ){" "}
                </td>
                <td className="border text-center py-1 text-sm font-normal text-gray-700">
                  $
                  {isDiscountAvailable
                    ? +calculateDiscountedFare(TotalFare, 10)
                    : +TotalFare}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className=" text-sm text-gray-700 p-2">
          <h3 className="font-bold">Please Note:</h3>
          <ul className="font-extralight text-xs list-disc px-4">
            <li>All fares are quoted in USD.</li>
            <li>Some airlines may charge baggage fees.</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col border rounded-md gap-y-4">
        <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-2 ps-2">
          <CgDetailsMore className="text-base font-bold text-white" />
          <span className="text-base font-bold text-white capitalize">
            Traveler details
          </span>
        </p>
        <div className="p-2 overflow-x-auto">
          <table className=" min-w-full   w-full table-auto border border-collapse">
            <thead>
              <tr className="bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white">
                <th className="border text-center py-1 text-sm font-medium">
                  Traveler type
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Title
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  First Name
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Middler Name
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Last Name
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Date of Birth
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Gender
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Meal Preference
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Frequent flyer number
                </th>
              </tr>
            </thead>
            <tbody>
              {userBasicinfo.map((user: PassengerI, index: number) => (
                <tr key={index}>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {user.psgType === "ADT" && "Adult"}
                    {user.psgType === "CHD" && "Children"}
                    {user.psgType === "INF" && "Infant"}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {user.title}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {user.firstName}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {`${user.middleName ? user.middleName : "-"}`}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {user.lastName}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {new Date(user.birthday).toLocaleString("en-US", {
                      day: "2-digit", // "14"
                      month: "short", // "Nov"
                      year: "numeric",
                    })}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {`${user.sex === "M" ? "Male" : "Female"}`}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    -
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    -
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col border rounded-md gap-y-4">
        <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-2 ps-2">
          <FaFileSignature className="text-base font-bold text-white" />
          <span className="text-base font-bold text-white capitalize">
            Charge authorization, your electronic Signature copy
          </span>
        </p>
        <p className="px-2">
          <input type="checkbox" name="terms" id="terms" checked disabled />
          <span className="ms-2 text-sm font-normal text-gray-700">
            I,
            {paymentInfo.cardHolderName} agree to pay a total amount of $
            {isDiscountAvailable
              ? calculateDiscountedFare(TotalFare, 10)
              : TotalFare}{" "}
            (Credit/Debit Card Number: ****
            {`${paymentInfo.cardNum}`.substring(
              `${paymentInfo.cardNum}`.length - 4,
              `${paymentInfo.cardNum}`.length
            )}
            ) for the above mentioned itinerary. I have verified the dates and
            times of the flights and name(s) of traveler(s) are correct. I have
            read the Terms and Conditions and I understand that:
          </span>
        </p>
        <ul className="px-2 list-disc ms-8 mb-2 text-sm font-normal text-gray-700">
          <li>
            Most fares and taxes are non-refundable and service fee is
            non-refundable, the tickets are non-transferable and name changes on
            the tickets are not permitted.
          </li>
          <li>
            All government taxes and service fees are included in the total
            price.
          </li>
          <li>
            Date and routing changes will be subject to the airlines penalties,
            fare difference and our service fees.
          </li>
          <li>I understand this is to serve as my legal signature.</li>
        </ul>
      </div>
      <div className=" w-full flex-wrap flex items-center gap-x-4">
        <div className="w-full">
          <h2 className="text-sm font-bold text-gray-700">We Accept</h2>
          <div className="flex  items-center justify-between">
            <img
              src={MasterCardSecureCode}
              alt="master card secure"
              width={50}
            />
            <img
              src={VerifiedVisaCard}
              alt="verified visa card logo"
              width={50}
            />
            <img src={MasterCard} alt="master card logo" width={50} />
            <img src={MaestroCard} alt="maestro card logo" width={50} />
            <img
              src={AmericanExpressCard}
              alt="american express card logo"
              width={50}
            />
            <img src={VisaCard} alt="visa card logo" width={50} />
          </div>
        </div>
        <div className="w-full overflow-x-auto">
          <h2 className="text-sm font-bold text-gray-700">
            Certification Partners
          </h2>
          <div className="flex items-center justify-between">
            <img src={ArcCertificate} alt="arc certificate" width={50} />
            <img
              src={CompliAssureSecured}
              alt="CompliAssure secured certificate"
              width={50}
            />
            {/* <img src={TaaCertificate} alt="TAAI certificate" width={20} /> */}
            <img src={SaberCertificate} alt="saber certificate" width={50} />
            <img
              src={TravelPortCertificate}
              alt="travel port certificate"
              width={50}
            />
            <img
              src={AmadeusCertificate}
              alt="Amadeus certificate"
              width={50}
            />
            <img src={IATAN} alt="IATAN certificate" width={100} />
            <img
              src={VerifiedAndSecuredCertificate}
              alt="verified and secured certificate"
              width={50}
            />
          </div>
        </div>
      </div>
      <p className="mx-auto text-sm font-bold text-gray-700">
        Copyright © {new Date().getFullYear()} All rights reserved.
      </p>
    </div>
  );
};

export default TicketPDF;
