import React, { useState } from "react";
import { NO_DATA } from "../../utils/images";
import { DataFilter } from "../../utils/filterSystem";
import { useNavigate } from "react-router-dom";
import { FlightJourneyDetailsI } from "../../types/flightSearchReducer.types";
import { useAppDispatch } from "../../store";
import { fetchFlightPrecisePricing } from "../../store/reducers/flightSearch.reducer";
import { AirlineFilterFunction } from "../../utils/ExtraFunction";
import { useSelector } from "react-redux";
import { airportList } from "../../utils/airportList";

interface Prop {
  solutions: any;
  flights: any;
  segments: any;
}

const TicketDetailAccordin: React.FC<Prop> = ({
  solutions,
  segments,
  flights,
}) => {
  const [active, setActive] = useState(false);
  const [filter, setFilter] = useState("Details");
  const state = useSelector((state: any) => state.flights);
  const Navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const totalTimeFromDeparture = flights.find(
    (id: { flightId: any }) => id.flightId === solutions.journeys.journey_0[0]
  )?.journeyTime;
  const ifStopInDepartureFlight = flights.find(
    (id: { flightId: any }) => id.flightId === solutions.journeys.journey_0[0]
  )?.segmengtIds;

  const fare = (solutions.adtFare + solutions.adtTax).toFixed();

  const segment = DataFilter(
    solutions.journeys.journey_0[0],
    flights,
    segments
  );

  const Time: string[] = [];
  const State: string[] = [];
  const baggageWeight: { BaggageW: string; CarryW: string }[] = [];

  const formatTime = (time: number) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours}h ${minutes}m`;
  };

  segment.map((item) => {
    Time.push(item.strDepartureTime, item.strArrivalTime);
    State.push(item.departure, item.arrival);
    return 0;
  });

  solutions.baggageMap.ADT.map((item: any) =>
    baggageWeight.push({
      BaggageW: item.baggageWeight,
      CarryW: item.carryOnWeight,
    })
  );

  let Departure: FlightJourneyDetailsI[] = [];
  const dataToArray = () => {
    segment.map((item) => {
      let departureDetails = {
        airline: item.airline,
        flightNum: item.flightNum,
        equipment: item.equipment,
        cabinClass: item.cabinClass,
        bookingCode: item.bookingCode,
        availabilityCount: item.availabilityCount,
        departure: item.departure,
        arrival: item.arrival,
        departureTerminal: item.departureTerminal,
        arrivalTerminal: item.arrivalTerminal,
        flightTime: item.flightTime,
        stayTime: item.stayTime,
        codeShare: item.codeShare,
        opFltNo: item.opFltNo,
        opFltAirline: item.opFltAirline,
        stopover: item.stopover,
        fareBasis: item.fareBasis,
        departureDate: item.strDepartureDate,
        departureTime: item.strDepartureTime,
        arrivalDate: item.strArrivalDate,
        arrivalTime: item.strArrivalTime,
      };
      Departure.push(departureDetails);
      return 0;
    });
  };

  dataToArray();

  const handleClick = () => {
    const precisePricingRequest = {
      journeys: {
        journey_0: Departure,
      },
      adults: solutions.adults,
      children: solutions.children,
      infants: solutions.infants,
      solutionId: solutions.solutionId,
      cabin: "",
    };

    Navigate(
      `/flight/details?id=${btoa(JSON.stringify(precisePricingRequest))}`
    );
  };

  return (
    <>
      <div className="w-full shadow-md mb-4 bg-white mt-5">
        <div
          className={
            active
              ? "w-full p-2 h-[12rem] md:h-[7rem] shadow-md"
              : "w-full p-2 h-fit mb-[1rem] md:mb-0 md:h-[5rem]"
          }
        >
          <div className="flex flex-col md:flex-row items-center w-full gap-4">
            <div className="flex flex-col md:flex-row items-center justify-between w-[85%] gap-3 md:gap-0">
              <div className="w-full md:w-auto flex justify-between items-center gap-1">
                <div className="flex items-center gap-4">
                  <div
                    className="bg-cover bg-no-repeat bg-center w-[2.5rem] h-[2.5rem]"
                    style={{
                      backgroundImage: `url(${
                        solutions.platingCarrier &&
                        AirlineFilterFunction(solutions.platingCarrier)
                          ? AirlineFilterFunction(solutions.platingCarrier)
                            ? AirlineFilterFunction(solutions.platingCarrier)
                                .image_link
                            : NO_DATA
                          : NO_DATA
                      })`,
                    }}
                  ></div>
                  <div>
                    <h2 className="text-base font-bold">
                      {solutions.platingCarrier &&
                      AirlineFilterFunction(solutions.platingCarrier)
                        ? AirlineFilterFunction(solutions.platingCarrier)
                          ? AirlineFilterFunction(solutions.platingCarrier)
                              .Company_Name
                          : solutions.platingCarrier
                        : "IndiGo"}
                    </h2>
                    <p className="text-xs flex w-fit font-semibold">
                      {solutions.platingCarrier &&
                      AirlineFilterFunction(solutions.platingCarrier)
                        ? AirlineFilterFunction(solutions.platingCarrier)
                          ? AirlineFilterFunction(solutions.platingCarrier)
                              .AirLine_code
                          : solutions.platingCarrier
                        : "7E"}{" "}
                    </p>
                  </div>
                </div>
                <div className="md:hidden">
                  <div>
                    <h2 className="text-lg font-bold flex gap-1 items-center justify-end w-fit">
                      <span>$</span>
                      {fare ? fare : 4000}
                    </h2>
                    <p className="text-xs font-medium">per adult</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between w-full md:mx-[1rem] lg:mx-[4rem]">
                <div>
                  <h2 className="text-base font-bold">
                    {Time ? Time[0] : "08:30"}
                  </h2>
                  <p className="text-sm font-medium">
                    {State ? State[0] : "New Delhi"}
                  </p>
                </div>
                <div className="w-fit">
                  <p className="text-center text-xs font-bold">
                    {state.flightListSearchStatus === "succeeded" &&
                    totalTimeFromDeparture
                      ? formatTime(totalTimeFromDeparture)
                      : "02h 15m"}
                  </p>
                  <hr className="w-[5rem] h-[5px] bg-[#ec2027]"></hr>
                  {state.flightListSearchStatus === "succeeded" && (
                    <p className="text-center text-xs font-bold">
                      {ifStopInDepartureFlight?.length === 1
                        ? "Non stop"
                        : `${ifStopInDepartureFlight?.length - 1} Stop`}
                    </p>
                  )}
                </div>
                <div>
                  <h2 className="text-base font-bold">
                    {Time ? Time[Time.length - 1] : "10:45"}
                  </h2>
                  <p className="text-sm font-medium text-end">
                    {State ? State[State.length - 1] : "Mumbai"}
                  </p>
                </div>
              </div>
              <div className="hidden md:block">
                <h2 className="text-lg font-bold flex gap-1 items-center justify-end w-fit">
                  <span>$</span>
                  {fare ? fare : 4000}
                </h2>
                <p className="text-xs text-end font-medium">per adult</p>
              </div>
            </div>
            <div className="w-full md:w-fit flex flex-col items-center justify-center">
              <button
                className="w-[6rem] md:w-fit flex items-center justify-center px-2 py-2 bg-[#ec2027] text-white rounded-full text-xs lg:text-base shadow-md font-semibold"
                onClick={handleClick}
              >
                Book Now
              </button>
              <div className="mt-3 w-full md:w-fit flex items-center justify-end">
                <span
                  className="cursor-pointer hover:text-[#ec2027] text-sm md:text-[11px] lg:text-sm font-semibold"
                  onClick={() => setActive(!active)}
                >
                  {!active ? "View Flight Details" : "Hide Flight Details"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            active
              ? "w-full h-auto max-h-[999px] transition-all duration-300 mt-4 bg-white p-2"
              : "w-full h-0 max-h-0 overflow-hidden transition-all duration-300 bg-white p-2"
          }
        >
          <div
            className={
              active
                ? "w-full h-fit flex items-center justify-between shadow-md border-[1px] border-black/10 px-4 rounded-md gap-2"
                : "hidden duration-100"
            }
          >
            <div
              className={
                filter === "Details"
                  ? "h-full md:w-[25%] text-xs bg-[#ec2027] text-white px-2 py-1 rounded-lg font-semibold text-center cursor-pointer "
                  : " cursor-pointer h-full md:w-[25%] hover:bg-[#ec2027] hover:text-white px-2 py-1 rounded-lg font-semibold text-center text-xs"
              }
              onClick={() => setFilter("Details")}
            >
              Flight Details
            </div>
            <div
              className={
                filter === "Summary"
                  ? "h-full md:w-[25%] text-xs bg-[#ec2027] text-white px-2 py-1 rounded-lg font-semibold text-center cursor-pointer "
                  : " cursor-pointer h-full md:w-[25%] hover:bg-[#ec2027] hover:text-white px-2 py-1 rounded-lg font-semibold text-center text-xs"
              }
              onClick={() => setFilter("Summary")}
            >
              Fare Summary
            </div>
            <div
              className={
                filter === "Info"
                  ? "h-full md:w-[25%] text-xs bg-[#ec2027] text-white px-2 py-1 rounded-lg font-semibold text-center cursor-pointer "
                  : " cursor-pointer h-full md:w-[25%] hover:bg-[#ec2027] hover:text-white px-2 py-1 rounded-lg font-semibold text-center text-xs"
              }
              onClick={() => setFilter("Info")}
            >
              Baggage Info
            </div>
            {/* <div className={filter === 'DateChange' ? 'h-full w-[25%] bg-[#ec2027] text-white px-2 py-1 rounded-lg font-semibold text-center' : 'h-full w-[25%] hover:bg-[#ec2027] hover:text-white px-2 py-1 rounded-lg font-semibold text-center'} onClick={() => setFilter('DateChange')} >Date Change</div> */}
          </div>
          <div className="w-full border-b-[1px] border-x-[1px] border-black mt-4 md:mt-3">
            {filter === "Details" &&
              segment.map((item: any, key: number) => (
                <div key={key}>
                  <div className="flex items-center h-[2rem] font-bold px-2 border-y-[1px] border-black">
                    {item.departure ? item.departure : "New Delhi"} to{" "}
                    {item.arrival ? item.arrival : "Mumbai"},{" "}
                    {item.strDepartureDate ? item.strDepartureDate : "31 Aug"}
                  </div>
                  <div className="my-3 px-2">
                    <div className="flex items-center gap-1">
                      <div
                        className="bg-cover bg-no-repeat bg-center w-[2rem] h-[2rem]"
                        style={{
                          backgroundImage: `url(${
                            item && AirlineFilterFunction(item.airline)
                              ? AirlineFilterFunction(item.airline)
                                ? AirlineFilterFunction(item.airline).image_link
                                : NO_DATA
                              : NO_DATA
                          })`,
                        }}
                      ></div>
                      <h2 className="text-base font-bold">
                        {item && AirlineFilterFunction(item.airline)
                          ? AirlineFilterFunction(item.airline)
                            ? AirlineFilterFunction(item.airline).Company_Name
                            : item.airline
                          : "No airline name"}
                      </h2>
                      <p className="text-base font-semibold">
                        {item.airline ? item.airline : "6E"} |{" "}
                        {item.flightNum ? item.flightNum : 6114}
                      </p>
                      {/* <span className="ring-2 ring-black/40 px-2 rounded-full text-xs font-bold text-black/50">
                            Airbus A321
                          </span> */}
                    </div>
                    <div className="flex flex-col md:flex-row items-start mt-4 w-full gap-2 md:gap-0 mb-2">
                      <div className="flex items-start w-full md:w-[70%] lg:w-[60%] justify-between">
                        <div className="flex flex-col gap-3">
                          <div>
                            <span className="text-base font-bold">
                              {item.strDepartureTime
                                ? item.strDepartureTime
                                : "22:15"}
                            </span>
                            <p className="text-sm font-bold">
                              {item.strDepartureDate
                                ? item.strDepartureDate
                                : "Sat, 31 Aug 24"}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm">
                              {item.departureTerminal
                                ? `Terminal T${item.departureTerminal}`
                                : "No Information"}
                            </p>
                            <p className="text-sm">
                              {item.departure
                                ? airportList.filter(
                                    (obj) => obj.IATA === item.departure
                                  ).length > 0
                                  ? airportList.filter(
                                      (obj) => obj.IATA === item.departure
                                    )[0].Location
                                  : item.departure
                                : `New Delhi, India`}
                            </p>
                          </div>
                        </div>
                        <div className="w-fit">
                          <h2 className="text-center text-xs lg:text-base font-bold">
                            {item.flightTime
                              ? formatTime(item.flightTime)
                              : "02h 10m"}
                          </h2>
                          <hr className="lg:w-[5rem] h-[5px] rounded-full bg-[#ec2027]"></hr>
                        </div>
                        <div className="flex flex-col gap-3">
                          <div>
                            <span className="text-base font-bold">
                              {item.strArrivalTime
                                ? item.strArrivalTime
                                : "00:25"}
                            </span>
                            <p className="text-sm font-bold">
                              {item.strArrivalDate
                                ? item.strArrivalDate
                                : "Sun, 1 sep 24"}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm">
                              {item.arrivalTerminal
                                ? `Terminal T${item.arrivalTerminal}`
                                : "No Information"}
                            </p>
                            <p className="text-sm">
                              {item.arrival
                                ? airportList.filter(
                                    (obj) =>
                                      obj.IATA.trim() === item.arrival.trim()
                                  ).length > 0
                                  ? airportList.filter(
                                      (obj) =>
                                        obj.IATA.trim() === item.arrival.trim()
                                    )[0].Location
                                  : item.arrival
                                : `New Delhi, India`}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-[30%] lg:w-[40%] flex flex-col items-center justify-center">
                        <div className="flex items-start justify-start gap-2">
                          <div className="text-xs lg:text-base font-bold uppercase">
                            Check-in Baggage:
                          </div>
                          <div className="text-xs lg:text-base font-bold uppercase">
                            {baggageWeight ? baggageWeight[0].BaggageW : "15WK"}
                          </div>
                        </div>
                        <div className="flex items-start justify-start gap-2">
                          <div className="text-xs lg:text-base font-bold uppercase">
                            Cabin Baggage:
                          </div>
                          <div className="text-xs lg:text-base font-bold uppercase">
                            {baggageWeight
                              ? baggageWeight[baggageWeight.length - 1].CarryW
                              : "15Wk"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {filter === "Summary" && (
              <>
                <div className="flex items-center h-[2rem] px-2 border-y-[1px] border-black">
                  Fare Breakup
                </div>
                <div className="my-3 px-2">
                  <div className="flex items-start w-[60%] md:w-[30%] justify-between">
                    <div className="flex flex-col gap-3">
                      <span className="text-base font-bold">Total :-</span>
                      <p className="text-base font-bold">Base Fare :-</p>
                      <p className="text-base font-bold">Surcharges :-</p>
                    </div>
                    <div className="flex flex-col gap-3">
                      <span className="text-base font-bold">
                        ${" "}
                        {(
                          solutions.adtFare +
                          solutions.adtTax +
                          solutions.chdFare +
                          solutions.chdTax +
                          solutions.qCharge +
                          solutions.tktFee +
                          solutions.platformServiceFee
                        ).toFixed(2)}
                      </span>
                      <p className="text-base font-bold">
                        $ {(solutions.adtFare + solutions.chdFare).toFixed(2)}
                      </p>
                      {solutions.children > 0 && (
                        <>
                          <p className="text-base font-bold">
                            $ {solutions.adtFare.toFixed(2)}
                          </p>
                          <p className="text-base font-bold">
                            $ {solutions.chdFare.toFixed(2)}
                          </p>
                        </>
                      )}
                      <p className="text-base font-bold">
                        ${" "}
                        {(
                          solutions.adtTax +
                          solutions.chdTax +
                          solutions.qCharge +
                          solutions.tktFee +
                          solutions.platformServiceFee
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {filter === "Info" && (
              <>
                <div className="flex items-center h-[2rem] px-2 border-y-[1px] border-black">
                  Baggage Allowance and Charges
                </div>
                <div className="my-3 px-2">
                  <div className="flex items-center justify-start gap-4">
                    <div className="text-base font-bold">Check In Baggage:</div>
                    <div className="text-base font-bold">
                      {baggageWeight ? baggageWeight[0].BaggageW : "15WK"}
                    </div>
                  </div>
                  <div className="flex items-center justify-start gap-4">
                    <div className="text-base font-bold">Cabin Baggage:</div>
                    <div className="text-base font-bold">
                      {baggageWeight ? baggageWeight[0].CarryW : "7WK"}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketDetailAccordin;
