import {
  AirlineDetailsI,
  FilterI,
  FlightJourneyResponseDetailsI,
} from "../types/flightSearchReducer.types";
import { airlinesImg } from "./airlineImgList";
/**
 * Retrieves a list of unique airline names from the provided flight segments.
 *
 * @param segments - An array of FlightJourneyResponseDetailsI representing flight segments
 * @returns An array of unique airline names extracted from the segments
 */
export const getAvailableAirlines = (
  segments: FlightJourneyResponseDetailsI[]
): AirlineDetailsI[] => {
  const airlines = new Set(
    segments.map((segment: FlightJourneyResponseDetailsI) => segment.airline)
  );
  return airlinesImg.filter((airlineDetails: AirlineDetailsI) =>
    airlines.has(airlineDetails.AirLine_code)
  );
};

export const filteredFlights = (
  flights: any[],
  solutions: any[],
  tripType: string
): any[] => {
  return solutions.flatMap((solution) => {
    const journey_0_Flights = solution.journeys.journey_0.map(
      (flightId: string) =>
        flights.find((flight: any) => flight.flightId === flightId)
    );

    if (tripType === "oneway") return journey_0_Flights;

    const journey_1_Flights = solution.journeys.journey_1.map(
      (flightId: string) =>
        flights.find((flight: any) => flight.flightId === flightId)
    );

    return [...journey_0_Flights, ...journey_1_Flights];
  });
};

export const filteredSegments = (
  flights: any[],
  segments: any[],
  filters: any[]
): any[] => {
  const filterSegment: any[] = [];
  const timeRanges = [
    { filterName: "early_morning", start: 0, end: 6 },
    { filterName: "morning", start: 6, end: 12 },
    { filterName: "afternoon", start: 12, end: 18 },
    { filterName: "late_departure", start: 18, end: 24 },
  ];

  // Extract airline filters
  const airlineFilters = filters
    .filter((filter: any) => filter.name.startsWith("airline_"))
    .map((filter: any) => filter.name.split("_")[1].toLowerCase());

  const isSegmentAlreadyIncluded = (segment: any) =>
    filterSegment.some(
      (filterSeg) => filterSeg.segmentId === segment.segmentId
    );

  const filterByTime = (start: number, end: number) => {
    flights.forEach((flight) => {
      flight.segmengtIds.forEach((segId: any) => {
        const segment = segments.find((seg: any) => seg.segmentId === segId);
        if (segment) {
          const [hours] = segment.strDepartureTime.split(":").map(Number);
          if (
            hours >= start &&
            hours < end &&
            !isSegmentAlreadyIncluded(segment)
          ) {
            filterSegment.push(segment);
          }
        }
      });
    });
  };

  const filterByAirline = () => {
    flights.forEach((flight) => {
      flight.segmengtIds.forEach((segId: any) => {
        const segment = segments.find((seg: any) => seg.segmentId === segId);
        if (
          segment &&
          airlineFilters.includes(segment.airline.toLowerCase()) &&
          !isSegmentAlreadyIncluded(segment)
        ) {
          filterSegment.push(segment);
        }
      });
    });
  };

  // Apply time filters
  timeRanges.forEach(({ filterName, start, end }) => {
    if (filters.some((filter: any) => filter.name === filterName)) {
      filterByTime(start, end);
    }
  });

  // Apply airline filters
  if (airlineFilters.length > 0) {
    filterByAirline();
  }

  // If no filters are applied, return all segments
  if (filterSegment.length === 0) {
    flights.forEach((flight) => {
      flight.segmengtIds.forEach((segId: any) => {
        const segment = segments.find((seg: any) => seg.segmentId === segId);
        if (segment && !isSegmentAlreadyIncluded(segment)) {
          filterSegment.push(segment);
        }
      });
    });
  }

  return filterSegment;
};
