import React, { useState } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import WorkingButton from "../Buttons/Working.Button";
import { FaCaretDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import {
  updateFlightSearchParams,
  TravelersPopupVisible,
  setIsCabinClassOptionsVisible,
  setIsFromFieldDropdown,
  setIsToFieldDropdown,
  setIsDepartureCalender,
  setIsReturnCalender,
} from "../../store/reducers/flightSearch.reducer";
import { BubbleEvent } from "../../utils/ExtraFunction";

const TavelerCount = () => {
  const state = useSelector((state: any) => state.flights);
  const dispatch = useAppDispatch();

  const onTravelersSelectedOptionClickedHandler = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    dispatch(setIsReturnCalender(false));
    dispatch(setIsCabinClassOptionsVisible(false));
    dispatch(setIsToFieldDropdown(false));
    dispatch(setIsDepartureCalender(false));
    dispatch(setIsFromFieldDropdown(false));
    dispatch(TravelersPopupVisible(!state.isTravelersPopupVisible));
  };

  const onClickIncrementTravelerBtnHandler = (
    travelerType: string,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    BubbleEvent(e);
    if (
      state.searchParams.adults +
        state.searchParams.children +
        state.searchParams.infants >
        0 &&
      state.searchParams.adults +
        state.searchParams.children +
        state.searchParams.infants <
        9
    ) {
      switch (travelerType) {
        case "adult":
          dispatch(
            updateFlightSearchParams({
              adults: state.searchParams.adults + 1,
            })
          );
          break;
        case "children":
          dispatch(
            updateFlightSearchParams({
              children: state.searchParams.children + 1,
            })
          );
          break;
        case "infant":
          dispatch(
            updateFlightSearchParams({
              infants: state.searchParams.infants + 1,
            })
          );
          break;
      }
    }
  };

  const onClickDecrementTravelerBtnHandler = (
    travelerType: string,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    BubbleEvent(e);
    if (
      state.searchParams.adults +
        state.searchParams.children +
        state.searchParams.infants >
      1
    ) {
      switch (travelerType) {
        case "adult":
          dispatch(
            updateFlightSearchParams({
              adults:
                state.searchParams.adults > 1
                  ? state.searchParams.adults - 1
                  : 1,
            })
          );
          break;
        case "children":
          dispatch(
            updateFlightSearchParams({
              children:
                state.searchParams.children > 0
                  ? state.searchParams.children - 1
                  : 0,
            })
          );
          break;
        case "infant":
          dispatch(
            updateFlightSearchParams({
              infants:
                state.searchParams.infants > 0
                  ? state.searchParams.infants - 1
                  : 0,
            })
          );
          break;
      }
    }
  };

  return (
    <div className="w-full md:w-fit">
      <div
        onClick={(e: any) => onTravelersSelectedOptionClickedHandler(e)}
        className="w-full cursor-pointer rounded-md p-1 bg-gradient-to-r from-[#ec2027] to-[#c81212] flex items-center justify-between gap-2"
      >
        <p className="text-white capitalize">
          <input
            type="text"
            id="adult"
            name="adults"
            value={state.searchParams.adults}
            hidden
            readOnly
          />
          <input
            type="text"
            id="children"
            name="children"
            value={state.searchParams.children}
            hidden
            readOnly
          />
          <input
            type="text"
            id="infant"
            name="infants"
            value={state.searchParams.infants}
            hidden
            readOnly
          />
          <span className="text-xs md:text-sm">
            {state.searchParams.adults +
              state.searchParams.children +
              state.searchParams.infants}{" "}
          </span>
          <span className="capitalize text-white text-xs md:text-sm">
            traveler
          </span>
        </p>
        <FaCaretDown
          onClick={(e: any) => onTravelersSelectedOptionClickedHandler(e)}
          className="text-white text-lg md:text-xl"
        />
      </div>
      {state.isTravelersPopupVisible && (
        <div className="absolute w-3/2 lg:w-[20%] shadow-[0_0px_10px_.1px_rgba(0,0,0,.5)] z-50 rounded-md bg-white mt-2 px-5 border">
          <div className="flex flex-col">
            <div className="flex items-center justify-between py-5">
              <p className="flex flex-col">
                <span className="text-sm md:text-md text-gray-700">Adults</span>
                <span className="text-xs md:text-sm text-gray-500">
                  12+ years
                </span>
              </p>
              <div className="flex items-center justify-evenly gap-x-2">
                <CiSquarePlus
                  onClick={(e: any) =>
                    onClickIncrementTravelerBtnHandler("adult", e)
                  }
                  className="text-3xl md:text-4xl text-gray-400 hover:text-blue-500 cursor-pointer"
                />
                <p className="text-xs md:text-sm">
                  {state.searchParams.adults}
                </p>
                <CiSquareMinus
                  onClick={(e: any) =>
                    onClickDecrementTravelerBtnHandler("adult", e)
                  }
                  className="text-3xl md:text-4xl text-gray-400 hover:text-blue-500 cursor-pointer"
                />
              </div>
            </div>
            <div className="flex items-center justify-between py-5">
              <p className="flex flex-col">
                <span className="text-sm md:text-md text-gray-700">
                  Children
                </span>
                <span className="text-xs md:text-sm text-gray-500">
                  2-11 years
                </span>
              </p>
              <div className="flex items-center justify-evenly gap-x-2">
                <CiSquarePlus
                  onClick={(e: any) =>
                    onClickIncrementTravelerBtnHandler("children", e)
                  }
                  className="text-3xl md:text-4xl text-gray-400 hover:text-blue-500 cursor-pointer"
                />
                <p className="text-xs md:text-sm">
                  {state.searchParams.children}
                </p>
                <CiSquareMinus
                  onClick={(e: any) =>
                    onClickDecrementTravelerBtnHandler("children", e)
                  }
                  className="text-3xl md:text-4xl text-gray-400 hover:text-blue-500 cursor-pointer"
                />
              </div>
            </div>
            <div className="flex items-center justify-between py-5">
              <p className="flex flex-col">
                <span className="text-sm md:text-md text-gray-700">
                  Infants
                </span>
                <span className="text-xs md:text-sm text-gray-500">
                  under 2 years
                </span>
              </p>
              <div className="flex items-center justify-evenly gap-x-2">
                <CiSquarePlus
                  onClick={(e: any) =>
                    onClickIncrementTravelerBtnHandler("infant", e)
                  }
                  className="text-3xl md:text-4xl text-gray-400 hover:text-blue-500 cursor-pointer"
                />
                <p className="text-xs md:text-sm">
                  {state.searchParams.infants}
                </p>
                <CiSquareMinus
                  onClick={(e: any) =>
                    onClickDecrementTravelerBtnHandler("infant", e)
                  }
                  className="text-3xl md:text-4xl text-gray-400 hover:text-blue-500 cursor-pointer"
                />
              </div>
            </div>
            <WorkingButton
              type="button"
              classes="border py-2 rounded-md mb-5 bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white text-xs md:text-sm"
              onClick={onTravelersSelectedOptionClickedHandler}
            >
              Done
            </WorkingButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default TavelerCount;
