import { contactInfo } from "../../dummydata/contactInfo";
import { CallSupport } from "../../utils/images";

const ContactBanner: React.FC<{}> = () => {
  return (
    <div
      className="relative flex w-full h-[8rem]"
      style={{
        backgroundImage: `url(${CallSupport})`,
        backgroundSize: "cover",
      }}
    >
      <div className="hidden md:flex w-[35rem] md:w-[40rem] h-4/5 absolute -top-9 left-1/2 -translate-x-1/2 translate-y-1/2 bg-white/70 items-center lg:justify-center">
        <div className="text-start flex flex-col px-4">
          <span className="text-sm font-bold text-gray-700">
            LOWEST FARE AVAILABLE.
          </span>
          <span className="text-sm font-medium text-gray-700">
            TALK TO ONE OF OUR{" "}
            <span className="text-lg font-medium text-[#ec2027]">
              TRAVEL EXPERTS
            </span>
          </span>
          <span className="text-sm font-medium text-gray-700">TODAY!</span>
        </div>
      </div>
      <div
        className="w-[20rem] h-full right-0 absolute flex items-center flex-col justify-center bg-gradient-to-l from-[#ec2027] to-[#c81212]"
        style={{
          clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 100%, 22% 50%)",
        }}
      >
        <div className="text-end">
          <p className="text-xs font-medium text-white">PHONE-ONLY FARES</p>
          <a
            href={`tel:${contactInfo.mobileNum}`}
            className="text-xl font-medium text-white"
          >
            {contactInfo.mobileNum}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
